import React, { useEffect, useRef, useState } from 'react'
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import "./style.scss"

interface TourProps {
  startTour: boolean;
  setStartTour: Function;
  hasTokens: boolean;
  hasPool: boolean;
  hasPoolOptionBtn: boolean;
  hasNoLpBtn: boolean; 
}

const Step = ({ title, desc }: { title: string, desc: string }) => (
  <div >
    <div className='tw-text-4xl tw-font-bold'>{title}</div>
    <div className='tw-text-base tw-font-medium tw-text-coolGray-500 tw-mt-2'>{desc}</div>
  </div >
)

const initialSteps = [
  {
    title: "",
    intro: <Step
      title="Welcome to BotSwap"
      desc="This is a quick tour to show you the core features of our DEX automation and trading platform"
    />,
    position: "right"
  },
  {
    element: "#tour_your_token",
    intro: <Step
      title="The app reads your wallet"
      desc="When you connect to the app, the contents of your wallet are displayed. Options for your tokens and DEX liquidity positions are shown"
    />,
    position: 'bottom-left-aligned',
  },
  {
    element: "#tour_pool_position",
    intro: <Step
      title="Minimise impermanent loss with DEX automation"
      desc="With DEX positions you can set automation parameters to withdraw that liquidity if the price changes beyond an acceptable range "
    />,
  },
  {
    title: "",
    intro: <Step
      title='Remember to “Do Your Own Research"'
      desc="This app is provided on an “as-is” basis and responsibility for your trades remains with you"
    />,
  }
]

const getSteps = (steps: Array<any>, hasTokens: boolean, hasPool: boolean, hasPoolOptionBtn: boolean, hasNoLpBtn: boolean) => {
  let updatedSteps = []
  if (hasTokens) {
    let poolOptionSteps = []
    if (hasPoolOptionBtn) {
      poolOptionSteps.push({
        element: "#tour_pool_option_btn",
        intro: <Step
          title="Choose DEX options with optimum APR with our Smart Yield finder"
          desc="With your tokens, we scan the top DEXs to find good APR options by supplying liquidity with your tokens"
        />,
        position: 'bottom-left-aligned',
      })
    }

    if (hasNoLpBtn) {
      poolOptionSteps.push({
        element: "#tour_no_lp_option",
        intro: <Step
          title="No LP recommendation"
          desc="BotSwap can't offer you liquidity pool options for this token yet. Please try different tokens for Smart Yield ideas"
        />,
      })
    }
    poolOptionSteps.push(
      {
        element: "#tour_swap_btn",
        intro: <Step
          title="Smart swap automation"
          desc="The app also provides a “smart swap” feature that enables you to set price limit orders on DEXs"
        />,
      },
    )
    updatedSteps = [
      ...steps.slice(0, 2),
      ...poolOptionSteps,
    ]
  } else {
    updatedSteps = [
      ...steps.slice(0, 2),
      {
        element: "#tour_no_token_data",
        intro: <Step
          title="Add tokens to your wallet to use BotSwap"
          desc="With tokens in your wallet you can find good options for yield, and automate swaps from within our app"
        />,
      },
    ]
  }
  if (hasPool) {
    updatedSteps = [...updatedSteps, ...steps.slice(2)]
  } else {
    updatedSteps = [...updatedSteps,
    {
      element: "#tour_pool_position",
      intro: <Step
        title="Your FET app balance powers smart features"
        desc="You are granted an initial balance to try the app for free. Top up your app with FET to power the AI that powers BotSwap"
      />,
    }, {
      element: "#tour_no_pool_data",
      intro: <Step
        title="Add LP tokens to your wallet to use Botswap"
        desc="With LP tokens in your wallet you can manage the risk of impermanent loss"
      />,
    }, {
      title: "",
      intro: <Step
        title='Remember to “Do Your Own Research"'
        desc="This app is provided on an “as-is” basis and responsibility for your trades remains with you"
      />,
    }]
  }
  return updatedSteps
}

export const Tour = ({ startTour, setStartTour, hasTokens, hasPool, hasPoolOptionBtn, hasNoLpBtn }: TourProps) => {
  const [steps, setSteps] = useState(getSteps(initialSteps, hasTokens, hasPool, hasPoolOptionBtn, hasNoLpBtn))
  var stepRef = useRef<any>(null)
  
  useEffect(() => {
    // align html structure as per design
    if (startTour) {
      let bullets = document.querySelector('.introjs-bullets');
      let ele = document.createElement("div")
      ele.className = "bullets-wrapper"

      if (bullets) {
        bullets.parentNode?.insertBefore(ele, bullets.nextElementSibling)
        ele.appendChild(bullets)
        let prev = document.querySelector(".introjs-prevbutton")

        if (prev) {
          prev.parentNode?.insertBefore(ele, prev.nextElementSibling)
        }
      }
    }
  }, [startTour])

  useEffect(() => {
    setSteps(getSteps(initialSteps, hasTokens, hasPool, hasPoolOptionBtn, hasNoLpBtn))
    if (startTour) {
      setStartTour(true)
    }
  }, [startTour, hasTokens, hasPool, hasPoolOptionBtn, hasNoLpBtn])

  const onExit = () => {
    setStartTour(false)
  }
  return (
    <Steps
      ref={stepRef}
      enabled={startTour}
      steps={steps}
      initialStep={0}
      onExit={onExit}
      onBeforeChange={(nextStep)=>{
        if(nextStep){
          stepRef.current.updateStepElement(nextStep)
        }
      }}
      options={{
        nextLabel: "Next step",
        prevLabel: "Go back",
        doneLabel: "Go to dashboard",
        scrollToElement: true,
        overlayOpacity: 0.6
      }}
    />
  )
}
