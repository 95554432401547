import { Link } from "react-router-dom";
import freeBotswap from "../../assets/account-botswap.png";
import LinkBtn from "../link-btn";

const TryBotswap = () => {
  return (
    <div className="tw-pb-24 tw-pt-10 tw-overflow-hidden tw-lg:pb-16">
      <div className="tw-max-w-11/12 tw-mx-auto">
        <div className="tw-rounded-lg tw-bg-coolGray-900 tw-flex tw-justify-between">
          <div className="tw-w-4/5 tw-pt-16 tw-pl-16">
            <h3 className="font-bold font-inter text-white text-3xl">
              TRY BOTSWAP FOR <span className="tw-text-green-300">FREE</span>
            </h3>
            <div className="tw-mt-6 tw-mb-10 tw-font-normal tw-text-x1 tw-text-base tw-text-white">
              BotSwap is now free for all users.
              <br /> Join 70,000 others enjoying DEX trading automation built on
              Fetch.ai
            </div>
            <Link to="/dashboard">
              <button className="tw-h-10 tw-px-10 tw-mr-24 tw-text-coolGray-900 tw-font-semibold text-base tw-duration-150 tw-bg-green-300 tw-rounded-full">
                Dashboard
              </button>
            </Link>
          </div>
          <div className="tw-relative tw-right-2 tw-content-end">
            <img src={freeBotswap} alt="Free Botswap" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryBotswap;
