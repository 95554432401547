import { Button, Form, Modal } from "react-bootstrap";
import { FetInfo } from "../../configs";
import React, { ChangeEvent, useState } from "react";
import Decimal from "decimal.js";
import style from "./SelectAmount.module.scss";
import { toNonCanonicalDisplay } from "../../utils/display/toNonCanonicalDisplay";

interface SelectAmountProps {
  fetBalance: string | undefined;
  onComplete: (value: string) => void;
}

function validateAmount(
  amount: string,
  fetBalance: string | undefined
): [boolean, string] {
  if (fetBalance === undefined) {
    return [true, "loading... "];
  }

  if (amount !== "") {
    try {
      const numAmount = new Decimal(amount).mul("1e18");
      const numFet = new Decimal(fetBalance);

      if (numAmount.greaterThan(numFet)) {
        return [
          true,
          "This amount is greater than the amount of available FET",
        ];
      }
      if (numAmount.lessThanOrEqualTo(0)) {
        return [true, "The amount should be greater than zero"];
      }
    } catch (e) {
      console.log(e);
      return [true, "Invalid amount"];
    }
  }

  return [false, ""];
}

export function SelectAmount(props: SelectAmountProps) {
  const [amount, setAmount] = useState<string>("");
  const [isInvalid, errorMessage] = validateAmount(amount, props.fetBalance);

  const handleFETAmount = (e: ChangeEvent<HTMLSelectElement>) => {
    const amount = e.target.value.trim();
    if (!isNaN(Number(amount))) {
      setAmount(amount);
    }
  };

  return (
    <>
      <Modal.Body className={style.modalBody}>
        <Form.Group>
          <Form.Label className={style.ctrlLableTextColor}>
            Amount of FET to send to Account
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Amount of FET"
            value={amount}
            onChange={handleFETAmount}
            isInvalid={isInvalid}
            className={style.input}
          />
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className={style.ctrlLableTextColor}>
            Your current FET Balance
          </Form.Label>
          <Form.Control
            type="text"
            value={toNonCanonicalDisplay(props.fetBalance, FetInfo)}
            disabled
            className={style.input}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className={style.modalFooter}>
        <Button
          className={`${style.primaryButton} ${style.modalButton}`}
          variant="primary"
          onClick={() => {
            props.onComplete(new Decimal(amount).mul("1e18").toFixed(0));
          }}
          disabled={isInvalid || amount === ""}
        >
          Charge Agent
        </Button>
      </Modal.Footer>
    </>
  );
}
