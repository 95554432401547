import React from "react";
import { getNetworkDetails } from "../configs";
import { isErr } from "../result";

interface NetworkNameTextProps {
  chainId: number | undefined;
}

export function NetworkNameText(props: NetworkNameTextProps) {
  const name = "Unknown";

  if (props.chainId === undefined) {
    return <span data-testid="network-name">{name}</span>;
  }

  const networkDetails = getNetworkDetails(props.chainId);

  if (isErr(networkDetails)) {
    return <span data-testid="network-name">{name}</span>;
  }

  return (
    <span data-testid="network-name">{networkDetails.value.chainName}</span>
  );
}
