import { useContext, useState } from "react";
import dashboardFree from "../../assets/dashboard-free.png";
import pointerGear from "../../assets/pointer-gear.png";
import { StateContext } from "../../state/state";
import { Tour } from "../Tour";

const BotswapFree = () => {
  const state = useContext(StateContext);
  const [startTour, setStartTour] = useState(false);
  const [hasTokens, sethasTokens] = useState(false);
  const [hasPool, sethasPool] = useState(false);
  const [hasPoolOptionBtn, setHasPoolOptionBtn] = useState(false);
  const [hasNoLpBtn, setHasNoLpBtn] = useState(false);

  const handleTourClick = () => {
    if (!state.assets.syncing && !state.assets.suggestedPoolSyncing) {
      if (
        [...state.assets.holdingTokensETH, ...state.assets.holdingTokensBSC]
          .length
      ) {
        sethasTokens(true);
        if (document.getElementById("tour_pool_option_btn")) {
          setHasPoolOptionBtn(true);
        } else {
          setHasPoolOptionBtn(false);
        }
        if (document.getElementById("tour_no_lp_option")) {
          setHasNoLpBtn(true);
        } else {
          setHasNoLpBtn(false);
        }
      } else {
        sethasTokens(false);
      }
      if (
        [...state.assets.holdingPoolsUniswap, ...state.assets.holdingPoolCake]
          .length
      ) {
        sethasPool(true);
      } else {
        sethasPool(false);
      }
      setStartTour(true);
    }
  };

  return (
    <div className="tw-px-3 tw-grid tw-grid-cols-8 tw-gap-3">
      <div className="tw-col-span-5 2xl:tw-col-span-6 tw-rounded-2xl tw-bg-coolGray-900 tw-flex tw-justify-between">
        <div className="tw-w-1/2 tw-pt-12 tw-pl-12">
          <h3 className="font-bold font-inter text-white text-3xl">
            BOTSWAP IS{" "}
            <span id="tour-free" className="tw-text-green-300">
              FREE
            </span>{" "}
            TO USE
          </h3>
          <div className="tw-mt-6 tw-mb-10 tw-text-xl tw-font-normal tw-font-medium tw-text-base tw-text-white">
            BotSwap is now free for all users. Join 70,000 others enjoying DEX
            trading automation built on Fetch.ai
          </div>
        </div>
        <div className="tw-relative tw-right-12 tw-content-end">
          <img
            id="tour-img"
            src={dashboardFree}
            alt="Free Botswap"
            className="tw-h-60"
          />
        </div>
      </div>

      <div className="tw-col-span-3 2xl:tw-col-span-2 tw-border-blue-700 tw-rounded-2xl tw-p-8 tw-bg-coolGray-900 tw-grid tw-grid-cols-5 ">
        <div className="tw-col-span-3 tw-flex tw-flex-col tw-justify-around">
          <h3 className="tw-text-3xl tw-font-semibold tw-text-white">
            Need help?
          </h3>
          <div
            className={`tw-text-base tw-font-semibold tw-bg-green-300 tw-text-coolGray-900 tw-cursor-pointer tw-shadow-sm tw-px-7 tw-py-3 tw-rounded-full tw-w-40 tw-text-center ${
              state.assets.syncing || state.assets.suggestedPoolSyncing
                ? "tw-opacity-80 tw-cursor-not-allowed"
                : ""
            }`}
            onClick={handleTourClick}
          >
            Start tour
          </div>
        </div>
        <div className="tw-col-span-2 tw-flex tw-items-center">
          <img
            className="tw-object-contain"
            src={pointerGear}
            alt="start tour"
          />
        </div>
      </div>
      {startTour && (
        <Tour
          startTour={startTour}
          setStartTour={setStartTour}
          hasTokens={hasTokens}
          hasPool={hasPool}
          hasPoolOptionBtn={hasPoolOptionBtn}
          hasNoLpBtn={hasNoLpBtn}
        />
      )}
    </div>
  );
};

export default BotswapFree;
