import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { StateStore } from "../../state/state";
import { Pair } from "../../state/pairCache";
import { Agent } from "../../state/agents";
import { ProxyContract } from "../../services/contracts/ProxyContract";
import { toMultiplyWithPrecision } from "../../services/contracts/PairContract";
import swapProxyABI from "../../abis/SwapProxy.json";
import withdrawAllProxyABI from "../../abis/WithdrawAllProxy.json";
import { TriggersAPI } from "../../api/TriggersAPI";
import {
  NewTrigger,
  SwapToken,
  Trigger,
  TriggerBase,
  TriggerType,
} from "../../state/triggers";
import { isErr, isOk } from "../../result";
import Provider from "../../services/Provider";
import { ProgressElement } from "./ProgressElement";
import { Decimal } from "decimal.js";
import { ConfigState } from "../ProgressIcon";
import {
  getNetworkDetails,
  mapChainId,
  Network,
  protocols,
} from "../../configs";
import { asAbi } from "../../services/contracts/utils";
import ERC20Contract from "../../services/contracts/ERC20Contract";
import { Weth } from "../../services/contracts/Weth";
import { addDecimal } from "../../utils/numeric/addBigNumber";
import { getBalance } from "../../utils/networkRequests/getBalance";
import { gweiToWei } from "../../utils/currency/GweiToWei";
import { trackEvents } from "../../utils/misc/trackEvent";

interface CreateTriggerProps {
  state: StateStore;
  onComplete: () => void;
  pair: Pair;
  agent: Agent;
  trigger: Required<NewTrigger>;
  setAllowBack: (bool: boolean) => void;
  triggerType: TriggerType;
  selectedToken?: SwapToken;
}

function calcGasRequired(trigger: TriggerBase): Decimal {
  return new Decimal(trigger.gasPrice).mul(new Decimal(trigger.gasAmount));
}

export function CreateTrigger(props: CreateTriggerProps) {
  const { state } = props;

  const [wethConversionState, setWethConversionState] = useState<ConfigState>(
    ConfigState.PENDING
  );

  const [approvalState, setApprovalState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [registerAgentState, setRegisterAgentState] = useState<ConfigState>(
    ConfigState.UNKNOWN
  );
  const [topUpAgentState, setTopUpAgentState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [createTriggerState, setCreateTriggerState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const _convertEthToWeth = {
    action: "wrap_conversion",
    success: "successful_wrap_conversion",
    fail: "failed_wrap_conversion",
  };
  const _approval = {
    action: "approval",
    success: "successful_approval",
    fail: "failed_approval",
  };
  const _registerAgent = {
    action: "register_agent",
    success: "successful_agent_registration",
    fail: "failed_agent_registration",
  };
  const _topUpAgent = {
    action: "top_up_agent",
    success: "successful_top_up_agent",
    fail: "failed_top_up_agent",
  };

  const _createTrigger = {
    action: "create_trigger",
    success: "successful_create_trigger",
    fail: "failed_create_trigger",
  };

  const getTriggerTypeContract = () => {
    if (state.web3.chainId === undefined) {
      return;
    }
    const triggerTypeABI =
      props.triggerType === TriggerType.SWAP
        ? swapProxyABI
        : withdrawAllProxyABI;

    const networkDetailsResult = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetailsResult)) {
      return;
    }

    const triggerTypeAddress =
      props.triggerType === TriggerType.SWAP
        ? networkDetailsResult.value.swapProxyContractAddress
        : networkDetailsResult.value.withdrawLiquidityProxyContractAddress;

    return new Provider._web3.eth.Contract(
      asAbi(triggerTypeABI),
      triggerTypeAddress
    );
  };

  const checkIfAgentIsRegistered = async (
    address: string,
    uuid: string
  ): Promise<boolean> => {
    if (state.web3.chainId === undefined) {
      return false;
    }
    const agentManagerContractAddress = await getTriggerTypeContract()
      ?.methods.getAgentManager()
      .call();

    const result = await ProxyContract.isAgentRegisteredForSpecificUser(
      address,
      uuid,
      state.web3.chainId,
      agentManagerContractAddress
    );

    if (isOk(result)) {
      return result.value;
    }
    return false;
  };

  const convertEthToWeth = async () => {
    setWethConversionState(ConfigState.IN_PROGRESS);

    if (state.web3.address === undefined) {
      setWethConversionState(ConfigState.ERROR);
      setErrorMessage("No web3 address");
      trackEvents(_convertEthToWeth.fail, props.triggerType, {
        action: _convertEthToWeth.action,
        stage: _convertEthToWeth.fail,
      });
      return;
    }

    if (state.web3.chainId === undefined) {
      setWethConversionState(ConfigState.ERROR);
      setErrorMessage("No web3 chain ID");
      trackEvents(_convertEthToWeth.fail, props.triggerType, {
        action: _convertEthToWeth.action,
        stage: _convertEthToWeth.fail,
      });
      return;
    }

    const networkDetails = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetails)) {
      setWethConversionState(ConfigState.ERROR);
      setErrorMessage(networkDetails.error.message);
      trackEvents(_convertEthToWeth.fail, props.triggerType, {
        action: _convertEthToWeth.action,
        stage: _convertEthToWeth.fail,
      });
      return;
    }

    const { decimals } = networkDetails.value.nativeCurrency;

    const gasPriceinWei = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );

    const result = await Weth.convert(
      state.web3.address,
      toMultiplyWithPrecision(props.trigger.amount, decimals).toString(),
      state.web3.chainId,
      gasPriceinWei
    );

    if (isErr(result)) {
      setWethConversionState(ConfigState.ERROR);
      setErrorMessage(result.error.message);
      trackEvents(_convertEthToWeth.fail, props.triggerType, {
        action: _convertEthToWeth.action,
        stage: _convertEthToWeth.fail,
      });
    } else {
      setWethConversionState(ConfigState.CONFIGURED);
      setErrorMessage("");
      trackEvents(_convertEthToWeth.success, props.triggerType, {
        action: _convertEthToWeth.action,
        stage: _convertEthToWeth.success,
      });
    }
  };

  const runApproval = async () => {
    let result;
    setApprovalState(ConfigState.IN_PROGRESS);
    setErrorMessage("");

    if (state.web3.address === undefined) {
      setApprovalState(ConfigState.ERROR);
      setErrorMessage("No web3 address");
      trackEvents(_approval.fail, props.triggerType, {
        action: _approval.action,
        stage: _approval.fail,
      });
      return;
    }

    if (state.web3.chainId === undefined) {
      setApprovalState(ConfigState.ERROR);
      setErrorMessage("No web3 chain ID");
      trackEvents(_approval.fail, props.triggerType, {
        action: _approval.action,
        stage: _approval.fail,
      });
      return;
    }
    if (
      props.triggerType === TriggerType.SWAP &&
      props?.selectedToken?.address === undefined
    ) {
      setApprovalState(ConfigState.ERROR);
      setErrorMessage("Token address not found");
      trackEvents(_approval.fail, props.triggerType, {
        action: _approval.action,
        stage: _approval.fail,
      });
      return;
    }
    let address;
    if (
      props.triggerType === TriggerType.SWAP &&
      props?.selectedToken?.address !== undefined
    ) {
      address = props?.selectedToken.address;
    } else {
      address = props.pair.address;
    }

    const networkDetails = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetails)) {
      setApprovalState(ConfigState.ERROR);
      setErrorMessage(networkDetails.error.message);
      trackEvents(_approval.fail, props.triggerType, {
        action: _approval.action,
        stage: _approval.fail,
      });
      return;
    }

    const proxyAddress =
      props.triggerType === TriggerType.WITHDRAW_LIQUIDITY
        ? networkDetails.value.withdrawLiquidityProxyContractAddress
        : networkDetails.value.swapProxyContractAddress;

    const allowance = await ERC20Contract.getAllowance(
      address,
      state.web3.address,
      proxyAddress
    );
    if (isErr(allowance)) {
      setApprovalState(ConfigState.ERROR);
      setErrorMessage(allowance.error.message);
      trackEvents(_approval.fail, props.triggerType, {
        action: _approval.action,
        stage: _approval.fail,
      });
      return;
    }

    // Addition of approval amount of triggers having same swap token for swap or pair for withdraw
    let AdditionOfTriggerApprovalAmount = "0";
    state.triggers.list().forEach((trigger) => {
      const tokenAddress =
        trigger.swap_token === 0
          ? trigger.pair.token0Address
          : trigger.pair.token1Address;
      const hasTrigger =
        props.triggerType === trigger.triggerType &&
        (trigger.triggerType === TriggerType.SWAP
          ? tokenAddress === props.selectedToken?.address
          : trigger.pair.address === props.pair.address);
      if (hasTrigger) {
        AdditionOfTriggerApprovalAmount = addDecimal(
          trigger.approveBalance.balance,
          AdditionOfTriggerApprovalAmount
        );
      }
    });

    const decimal = await ERC20Contract.getDecimals(address);
    if (isErr(decimal)) {
      setApprovalState(ConfigState.ERROR);
      setErrorMessage(decimal.error.message);
      trackEvents(_approval.fail, props.triggerType, {
        action: _approval.action,
        stage: _approval.fail,
      });
      return;
    }

    const toApprove = toMultiplyWithPrecision(
      props.trigger.amount,
      Number(decimal.value)
    );
    const totalApprovalAmount = addDecimal(
      toApprove,
      AdditionOfTriggerApprovalAmount
    );

    // if we have allowance greater or equal to total approval amount then no need to approve current amount
    // else approve total approval balance
    if (new Decimal(allowance.value).gte(totalApprovalAmount)) {
      props.trigger.amount = toApprove;
      setApprovalState(ConfigState.CONFIGURED);
      setErrorMessage("");
      trackEvents(_approval.success, props.triggerType, {
        action: _approval.action,
        stage: _approval.success,
      });
    } else {
      const gasPriceInWai = gweiToWei(
        state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
      );
      result = await ERC20Contract.approve(
        address,
        state.web3.address,
        proxyAddress,
        totalApprovalAmount,
        gasPriceInWai
      );

      if (isErr(result)) {
        setApprovalState(ConfigState.ERROR);
        setErrorMessage(result.error.message);
        trackEvents(_approval.fail, props.triggerType, {
          action: _approval.action,
          stage: _approval.fail,
        });
      } else {
        props.trigger.amount = toApprove;
        setApprovalState(ConfigState.CONFIGURED);
        setErrorMessage("");
        trackEvents(_approval.success, props.triggerType, {
          action: _approval.action,
          stage: _approval.success,
        });
      }
    }
  };
  const amountSendToAgent = async (agent: Agent) => {
    const agentBalance = await getBalance(agent.agentAddress, Provider.web3);

    /**
     * We check if there is enough gas already on the agent for all the triggers and if this is true then we do not add further gas
     *
     * We go through all triggers and calculate the gas that they need and then compare this to the amount of gas on the agent.
     */
    if (isOk(agentBalance)) {
      const numAgentBalance = new Decimal(agentBalance.value);
      let totalGasRequired = new Decimal(0);

      // sum all the of gas requirements for all the existing triggers for this agents
      state.triggers
        .list()
        .filter((trigger: Trigger) => {
          return Boolean(trigger.agentUuid === agent.uuid);
        })
        .forEach((trigger: Trigger) => {
          totalGasRequired = totalGasRequired.add(calcGasRequired(trigger));
        });

      // add the gas required for this new trigger
      totalGasRequired = totalGasRequired.add(calcGasRequired(props.trigger));
      if (totalGasRequired.greaterThan(numAgentBalance)) {
        return totalGasRequired.sub(numAgentBalance);
      }
      return new Decimal("0")
    } else {
      // just send entire amount if an error getting balance
      return calcGasRequired(props.trigger);
    }
  }

  const runRegisterAgent = async () => {
    setRegisterAgentState(ConfigState.IN_PROGRESS);
    setErrorMessage("");

    if (state.web3.chainId === undefined) {
      setRegisterAgentState(ConfigState.ERROR);
      setErrorMessage("no chain detected");
      trackEvents(_registerAgent.fail, props.triggerType, {
        action: _registerAgent.action,
        stage: _registerAgent.fail,
      });
    }

    const chainId = state.web3.chainId as number;

    const { address } = state.web3;

    const agentManagerContractAddress = await getTriggerTypeContract()
      ?.methods.getAgentManager()
      .call();

    const gasPriceinWai = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );
    let amount = await amountSendToAgent(props.agent)
    const result = await ProxyContract.registerAgentForUser({
      agentAddress: props.agent.agentAddress,
      agentUUID: props.agent.uuid,
      chainId,
      userAddress: address as string,
      contractAddress: agentManagerContractAddress as any,
      amount: amount.toFixed(0),
      gasPrice: gasPriceinWai as string,
    });

    if (isErr(result)) {
      setRegisterAgentState(ConfigState.ERROR);
      setErrorMessage(result.error.message);
      trackEvents(_registerAgent.fail, props.triggerType, {
        action: _registerAgent.action,
        stage: _registerAgent.fail,
      });
    } else {
      setRegisterAgentState(ConfigState.CONFIGURED);
      setErrorMessage("");
      trackEvents(_registerAgent.success, props.triggerType, {
        action: _registerAgent.action,
        stage: _registerAgent.success,
      });
    }
  };

  const runTopUpAgent = async () => {
    setTopUpAgentState(ConfigState.IN_PROGRESS);
    setErrorMessage("");

    if (state.web3.address === undefined) {
      setTopUpAgentState(ConfigState.ERROR);
      setErrorMessage("No web3 address");
      trackEvents(_topUpAgent.fail, props.triggerType, {
        action: _topUpAgent.action,
        stage: _topUpAgent.fail,
      });
      return;
    }

    const agentBalance = await getBalance(
      props.agent.agentAddress,
      Provider.web3
    );

    /**
     * We check if there is enough gas already on the agent for all the triggers and if this is true then we do not add further gas
     *
     * We go through all triggers and calculate the gas that they need and then compare this to the amount of gas on the agent.
     */
    let ethToSend: Decimal | undefined;
    if (isOk(agentBalance)) {
      const numAgentBalance = new Decimal(agentBalance.value);
      let totalGasRequired = new Decimal(0);

      // sum all the of gas requirements for all the existing triggers for this agents
      state.triggers
        .list()
        .filter((trigger: Trigger) => {
          return Boolean(trigger.agentUuid === props.agent.uuid);
        })
        .forEach((trigger: Trigger) => {
          totalGasRequired = totalGasRequired.add(calcGasRequired(trigger));
        });

      // add the gas required for this new trigger
      totalGasRequired = totalGasRequired.add(calcGasRequired(props.trigger));
      if (totalGasRequired.greaterThan(numAgentBalance)) {
        ethToSend = totalGasRequired.sub(numAgentBalance);
      }
    } else {
      // just send entire amount if an error getting balance
      ethToSend = calcGasRequired(props.trigger);
    }
    const gasPriceInWai = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );

    let result;
    if (ethToSend !== undefined) {
      result = await Provider.sendFunds(
        state.web3.address,
        props.agent.agentAddress,
        ethToSend.toFixed(0),
        gasPriceInWai
      );
    }

    if (result !== undefined && isErr(result)) {
      setTopUpAgentState(ConfigState.ERROR);
      setErrorMessage(result.error.toString());
      trackEvents(_topUpAgent.fail, props.triggerType, {
        action: _topUpAgent.action,
        stage: _topUpAgent.fail,
      });
    } else {
      setTopUpAgentState(ConfigState.CONFIGURED);
      setErrorMessage("");
      trackEvents(_topUpAgent.success, props.triggerType, {
        action: _topUpAgent.action,
        stage: _topUpAgent.success,
      });
    }
  };

  const runCreateTrigger = async () => {
    setCreateTriggerState(ConfigState.IN_PROGRESS);
    setErrorMessage("");

    if (state.account.user === undefined) {
      setCreateTriggerState(ConfigState.ERROR);
      setErrorMessage("No selected user");
      trackEvents(_createTrigger.fail, props.triggerType, {
        action: _createTrigger.action,
        stage: _createTrigger.fail,
      });
      return;
    }
    const triggersAPI = new TriggersAPI();
    if (state.web3.chainId === undefined) {
      setCreateTriggerState(ConfigState.ERROR);
      setErrorMessage("No network selected");
      trackEvents(_createTrigger.fail, props.triggerType, {
        action: _createTrigger.action,
        stage: _createTrigger.fail,
      });
      return;
    }
    // This is a temporary way to calculate the protocol type based on the chain which will be changed in future
    const protocolType =
      mapChainId(state.web3.chainId) === Network.ETH
        ? protocols.TYPE_UNISWAP
        : protocols.TYPE_PANCAKESWAP;

    const trigger = await triggersAPI.createTrigger(
      state.account.user.token,
      props.trigger,
      state.web3.networkId,
      state.web3.chainId,
      props.triggerType,
      protocolType
    );

    if (isErr(trigger)) {
      setCreateTriggerState(ConfigState.ERROR);
      setErrorMessage("Unable to publish trigger to agent");
      trackEvents(_createTrigger.fail, props.triggerType, {
        action: _createTrigger.action,
        stage: _createTrigger.fail,
      });
      return;
    }
    state.triggers.add(trigger.value);
    setCreateTriggerState(ConfigState.CONFIGURED);
    setErrorMessage("");
  };

  const checkWrappedConversionApproval = () => {
    if (
      props.triggerType === TriggerType.SWAP &&
      props.selectedToken &&
      props.selectedToken.isNative
    ) {
      return wethConversionState === ConfigState.CONFIGURED;
    }
    return true;
  };

  const runCreation = async () => {
    // Step 1. Check to make sure that a duplicate registration is not needed
    if (registerAgentState === ConfigState.UNKNOWN) {
      setRegisterAgentState(ConfigState.IN_PROGRESS);
      setErrorMessage("");

      if (state.web3.address === undefined) {
        setRegisterAgentState(ConfigState.ERROR);
        setErrorMessage("No web3 address");
        return;
      }
      const alreadyRegistered = await checkIfAgentIsRegistered(
        state.web3.address,
        props.agent.uuid
      );
      if (alreadyRegistered) {
        trackEvents(_registerAgent.success, props.triggerType, {
          action: _registerAgent.action,
          stage: _registerAgent.success,
        });
        setRegisterAgentState(ConfigState.CONFIGURED);
      } else {
        setRegisterAgentState(ConfigState.PENDING);
      }
      return;
    }

    if (
      props.triggerType === TriggerType.SWAP &&
      props.selectedToken &&
      props.selectedToken.isNative &&
      wethConversionState === ConfigState.PENDING
    ) {
      await convertEthToWeth();
      return;
    }

    if (
      checkWrappedConversionApproval() &&
      approvalState === ConfigState.PENDING
    ) {
      await runApproval();
      return;
    }

    if (
      checkWrappedConversionApproval() &&
      approvalState === ConfigState.CONFIGURED &&
      registerAgentState === ConfigState.PENDING
    ) {
      await runRegisterAgent();
      return;
    }

    if (
      checkWrappedConversionApproval() &&
      approvalState === ConfigState.CONFIGURED &&
      registerAgentState === ConfigState.CONFIGURED &&
      topUpAgentState === ConfigState.PENDING
    ) {
      await runTopUpAgent();
      return;
    }

    if (
      checkWrappedConversionApproval() &&
      approvalState === ConfigState.CONFIGURED &&
      registerAgentState === ConfigState.CONFIGURED &&
      topUpAgentState === ConfigState.CONFIGURED &&
      createTriggerState === ConfigState.PENDING
    ) {
      await runCreateTrigger();
      return;
    }

    if (
      checkWrappedConversionApproval() &&
      approvalState === ConfigState.CONFIGURED &&
      registerAgentState === ConfigState.CONFIGURED &&
      topUpAgentState === ConfigState.CONFIGURED &&
      createTriggerState === ConfigState.CONFIGURED
    ) {
      props.onComplete();
    }
  };

  useEffect(() => {
    // If none of the states are in progress then allow back
    const allowBack = ![
      approvalState,
      registerAgentState,
      topUpAgentState,
      createTriggerState,
      wethConversionState,
    ].includes(ConfigState.IN_PROGRESS);
    props.setAllowBack(allowBack);

    if (inProgress) {
      return;
    }

    setInProgress(true);
    runCreation()
      .then(() => { })
      .catch((err) => {
        console.log("Error", err);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [
    approvalState,
    registerAgentState,
    topUpAgentState,
    createTriggerState,
    wethConversionState,
    inProgress,
  ]);

  return (
    <div style={{ marginBottom: 16 }}>
      {errorMessage.length > 0 && (
        <Alert variant="warning">{errorMessage}</Alert>
      )}
      {props.triggerType === TriggerType.SWAP &&
        props.selectedToken &&
        props.selectedToken.isNative && (
          <ProgressElement
            state={wethConversionState}
            title={`Convert ${props.selectedToken.symbol} to Wrapped ${props.selectedToken.symbol}`}
            enableRetry
            chainId={state.web3.chainId}
            onRetry={() => setWethConversionState(ConfigState.PENDING)}
          />
        )}
      <ProgressElement
        state={approvalState}
        title="Approve contract access to funds"
        enableRetry
        chainId={state.web3.chainId}
        onRetry={() => setApprovalState(ConfigState.PENDING)}
      />
      <ProgressElement
        state={registerAgentState}
        chainId={state.web3.chainId}
        title="Register Agent with Contract"
        enableRetry
        onRetry={() => setRegisterAgentState(ConfigState.PENDING)}
      />
      <ProgressElement
        state={topUpAgentState}
        title={`Send ${state.web3.networkId === Network.BSC ? "BNB" : "ETH"
          } to Agent`}
        chainId={state.web3.chainId}
        enableRetry
        onRetry={() => setTopUpAgentState(ConfigState.PENDING)}
      />
      <ProgressElement
        chainId={state.web3.chainId}
        state={createTriggerState}
        title="Publish Trigger"
        enableRetry
        onRetry={() => setCreateTriggerState(ConfigState.PENDING)}
      />
    </div>
  );
}
