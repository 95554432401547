import { gql } from "@apollo/client";
import Decimal from "decimal.js";
import UniswapPairABI from "../abis/UniswapPair.json";
import {
  ENVIRONMENT_CONFIG,
  getNetworkDetails,
  Network,
  protocols,
} from "../configs";
import { anErr, anOk, isOk, Result } from "../result";
import ERC20Contract from "../services/contracts/ERC20Contract";
import {
  PairContract,
  toDecimalWithPrecision,
} from "../services/contracts/PairContract";
import { asAbi } from "../services/contracts/utils";
import Provider from "../services/Provider";
import {
  CryptoToken,
  HoldingPool,
  HoldingToken,
  PoolDetails,
  TokenPoolsResponse,
} from "../state/assets";
import client from "./client";
import { apiChainIdToNetwork, BNB_ID, ETH_ID } from "./common";
import {
  fetchAllTokens,
  fetchHoldingTokens,
  fetchPoolInfoFromTokenId,
  fetchTokenPools,
} from "./TokenQueries";

function isEthOrRinkeby(chainId: number) {
  return Boolean(
    apiChainIdToNetwork(chainId) === Network.ETH ||
      apiChainIdToNetwork(chainId) === Network.RINKEBY
  );
}

export class TokenAPI {
  static async fetchAllTokens(
    token: string,
    page: number
  ): Promise<Result<CryptoToken[]>> {
    try {
      const { data, errors } = await client.query({
        query: gql(fetchAllTokens),
        variables: {
          perPage: 1000,
          page,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to fetch agent: ${errors[0].message ?? "unknown error"}`
        );
      const { tokens } = data;
      return anOk(tokens.data);
    } catch (e) {
      return anErr(`Unable to fetch user agents: GraphQL Error`);
    }
  }

  static async getPairValueUSD(
    token0Decimal: string,
    token1Decimal: string,
    price0: string,
    price1: string,
    balance: string,
    pairAddress: string
  ) {
    // Logic
    // Token0_address: Pair(lp_address).token0() yes
    // Token1_address: Pair(lp_address).token1() yes
    // Calculate price0 from token0 address yes
    // Calculate price1 from token1 address yes
    // Calculate Reserves: Pair(lp_address).getReserves() yes
    // Calculate totalSupply: Pair(lp_address).totalSupply() yes
    // Lp_unit_price: ((reserves[0]*price0) + (reserves[1]*price1))/total_supply
    // Final USD value: balance * lp_unit_price

    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      pairAddress
    );

    let totalSupply;
    let reserves;

    try {
      totalSupply = await contract.methods.totalSupply().call();
      reserves = await contract.methods.getReserves().call();
    } catch {
      return anErr("error");
    }
    const liqidity0 = new Decimal(
      toDecimalWithPrecision(
        new Decimal(reserves.reserve0).mul(price0),
        Number(token0Decimal)
      )
    );
    const liqidity1 = new Decimal(
      toDecimalWithPrecision(
        new Decimal(reserves.reserve1).mul(price1),
        Number(token1Decimal)
      )
    );
    const lpUnitPrice = liqidity0.add(liqidity1).div(totalSupply);
    const priceUSD = new Decimal(balance).mul(lpUnitPrice);
    return anOk(priceUSD);
  }

  static async getPoolDetails(
    assetAdress: string,
    allTokens: CryptoToken[],
    userAddress: string,
    chainId: number,
    swapType: number
  ) {
    let token0Details;
    let token1Details;
    const tokenAddresses = await PairContract.getTokenAddresses(assetAdress);
    if (isOk(tokenAddresses)) {
      const wrappedTokenAddress = getNetworkDetails(chainId);
      if (isOk(wrappedTokenAddress)) {
        // Check if token0  address is equal to WETH address
        if (
          wrappedTokenAddress.value.wrappedNativeTokenAddress.toUpperCase() ===
          tokenAddresses.value.token0.toUpperCase()
        ) {
          const nativeAddress = isEthOrRinkeby(chainId) ? ETH_ID : BNB_ID;
          const nativeTokenDetails = allTokens.find(
            (token) => token.id === nativeAddress
          );
          if (nativeTokenDetails) {
            token0Details = {
              ...nativeTokenDetails,
              address: tokenAddresses.value.token0,
            };
          }
        } else {
          // Else find token 0 in stored tokens
          token0Details = allTokens.find(
            (token) =>
              token.address.toUpperCase() ===
              tokenAddresses.value.token0.toUpperCase()
          );
        }

        // Check if token1  address is equal to WETH address
        if (
          wrappedTokenAddress.value.wrappedNativeTokenAddress.toUpperCase() ===
          tokenAddresses.value.token1.toUpperCase()
        ) {
          const nativeAddress = isEthOrRinkeby(chainId) ? ETH_ID : BNB_ID;
          const nativeTokenDetails = allTokens.find(
            (token) => token.id === nativeAddress
          );
          if (nativeTokenDetails) {
            token1Details = {
              ...nativeTokenDetails,
              address: tokenAddresses.value.token1,
            };
          }
        } else {
          token1Details = allTokens.find(
            (token) =>
              token.address.toUpperCase() ===
              tokenAddresses.value.token1.toUpperCase()
          );
        }
      } else {
        token0Details = allTokens.find(
          (token) =>
            token.address.toUpperCase() ===
            tokenAddresses.value.token0.toUpperCase()
        );

        token1Details = allTokens.find(
          (token) =>
            token.address.toUpperCase() ===
            tokenAddresses.value.token1.toUpperCase()
        );
      }

      if (token0Details && token1Details) {
        const token0Decimal = await ERC20Contract.getDecimals(
          token0Details.address
        );
        const token1Decimal = await ERC20Contract.getDecimals(
          token1Details.address
        );
        if (isOk(token0Decimal) && isOk(token1Decimal)) {
          const balance = await PairContract.getBalanceOnPairContract(
            assetAdress,
            userAddress
          );
          const decimal = await PairContract.getDecimalOnPairContract(
            assetAdress
          );
          const symbol = await PairContract.getSymbolOnPairContract(
            assetAdress
          );

          let priceUSD;
          if (isOk(balance) && isOk(decimal) && isOk(symbol)) {
            const balanceWithPrecision = toDecimalWithPrecision(
              balance.value.toString(),
              decimal.value.toNumber()
            );
            priceUSD = await TokenAPI.getPairValueUSD(
              token0Decimal.value,
              token1Decimal.value,
              token0Details.price,
              token1Details.price,
              balance.value.toString(),
              assetAdress
            );
            if (isOk(priceUSD)) {
              const pool = {
                address: assetAdress,
                token1Address: token1Details.address,
                token1: token1Details.symbol.toUpperCase(),
                token1LogoUrl: token1Details.logo_url,
                token1marketCap: token0Details.market_cap,
                token0Address: token0Details.address,
                token0: token0Details.symbol.toUpperCase(),
                token0LogoUrl: token0Details.logo_url,
                token0marketCap: token0Details.market_cap,
                network: token0Details.chain,
                token0Decimal: token0Decimal.value.toString(),
                token1Decimal: token1Decimal.value.toString(),
                balance: balanceWithPrecision.toString(),
                symbol: symbol.value.toString().toUpperCase(),
                decimal: decimal.value.toString(),
                priceUSD: priceUSD.value.toString(),
                swapType: swapType,
              };
              return pool;
            }
          }
        }
      }
    }
  }

  static async fetchHoldingAssets(
    token: string,
    address: string,
    allTokens: CryptoToken[],
    chainId: number
  ) {
    try {
      const { data, errors } = await client.query({
        query: gql(fetchHoldingTokens),
        variables: {
          address,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to fetch holding tokens: ${
            errors[0].message ?? "unknown error"
          }`
        );
      const { accountAsset } = data;
      const holdingTokensETH: HoldingToken[] = [];
      const holdingTokensBSC: HoldingToken[] = [];
      const holdingPoolUniSwap: HoldingPool[] = [];
      const holdingPoolCakeSwap: HoldingPool[] = [];
      let currentMarketValueUSD = new Decimal("0");

      let ethNativeToken: HoldingToken | null = null;
      let bnbNativeToken: HoldingToken | null = null;

      const nativeTokenBal = await Provider.getBalance(address);
      const networkDetails = getNetworkDetails(chainId);
      if (isOk(networkDetails) && isOk(nativeTokenBal)) {
        if (!new Decimal(nativeTokenBal.value).isZero()) {
          let decimals = networkDetails.value.nativeCurrency.decimals;
          let wrappedAddress = networkDetails.value.wrappedNativeTokenAddress;
          // IF ETH
          if (isEthOrRinkeby(chainId)) {
            const ethTokenDetails = allTokens.find(
              (token) => token.id === ETH_ID
            );
            if (ethTokenDetails) {
              const decimalBalance = toDecimalWithPrecision(
                nativeTokenBal.value,
                Number(ethTokenDetails.decimals)
              );
              const balanceUsd = new Decimal(decimalBalance.toString()).mul(
                ethTokenDetails.price
              );
              ethNativeToken = {
                ...ethTokenDetails,
                address: wrappedAddress,
                symbol: ethTokenDetails.symbol.toUpperCase(),
                balance: decimalBalance,
                balanceUsd: balanceUsd.toString(),
                isNative: true,
                decimal: Number(ethTokenDetails.decimals),
              };
              currentMarketValueUSD = currentMarketValueUSD.add(
                balanceUsd.toString()
              );
            }
          } else {
            // IF BNB
            const bscTokenDetails = allTokens.find(
              (token) => token.id === BNB_ID
            );
            if (bscTokenDetails) {
              const decimalBalance = toDecimalWithPrecision(
                nativeTokenBal.value,
                Number(bscTokenDetails.decimals)
              );
              const balanceUsd = new Decimal(decimalBalance.toString()).mul(
                bscTokenDetails.price
              );
              bnbNativeToken = {
                ...bscTokenDetails,
                address: wrappedAddress,
                symbol: bscTokenDetails.symbol.toUpperCase(),
                balance: decimalBalance,
                balanceUsd: balanceUsd.toString(),
                isNative: true,
                decimal: Number(bscTokenDetails.decimals),
              };
              currentMarketValueUSD = currentMarketValueUSD.add(
                balanceUsd.toString()
              );
            }
          }
        }
      }

      // Filter ETH holding tokens
      if (isEthOrRinkeby(chainId)) {
        for (const asset of accountAsset.eth_refreshed_assets) {
          if (asset.is_uni_v2_lp === true) {
            // Its a uniswap pool
            const poolDetails = await TokenAPI.getPoolDetails(
              asset.address,
              allTokens,
              address,
              chainId,
              protocols.TYPE_UNISWAP
            );
            if (poolDetails) {
              // add pool price(in usd) to calculate current Market Value USD
              currentMarketValueUSD = currentMarketValueUSD.add(
                poolDetails.priceUSD
              );
              holdingPoolUniSwap.push(poolDetails);
            }
            // holdingPoolUniSwap.push(poolDetails);
          } else {
            // It's a token
            for (const token of allTokens) {
              if (token.address.toUpperCase() === asset.address.toUpperCase()) {
                let tokenBalance;
                if (apiChainIdToNetwork(chainId) === Network.ETH) {
                  tokenBalance = await ERC20Contract.getBalance(
                    address,
                    token.address
                  );
                }
                let tokenBalWithPrecision;
                if (tokenBalance && isOk(tokenBalance)) {
                  tokenBalWithPrecision = toDecimalWithPrecision(
                    tokenBalance.value,
                    Number(token.decimals)
                  );
                } else {
                  tokenBalWithPrecision = toDecimalWithPrecision(
                    asset.balance,
                    Number(token.decimals)
                  );
                }
                const balanceUsd = new Decimal(tokenBalWithPrecision).mul(
                  token.price
                );
                // add token price(in usd) to calculate current Market Value USD
                currentMarketValueUSD = currentMarketValueUSD.add(balanceUsd);
                holdingTokensETH.push({
                  ...token,
                  symbol: token.symbol.toUpperCase(),
                  balanceUsd: balanceUsd.toString(),
                  balance: tokenBalWithPrecision,
                  isNative: false,
                  decimal: Number(token.decimals),
                });
              }
            }
          }
        }
      } else {
        for (const asset of accountAsset.bsc_refreshed_assets) {
          if (asset.is_cake_v2_lp === true) {
            // Its a pancake pool
            if (chainId !== ENVIRONMENT_CONFIG.bscChainId) {
              continue;
            }
            const poolDetails = await TokenAPI.getPoolDetails(
              asset.address,
              allTokens,
              address,
              chainId,
              protocols.TYPE_PANCAKESWAP
            );
            if (poolDetails) {
              // add pool price(in usd) to calculate current Market Value USD
              currentMarketValueUSD = currentMarketValueUSD.add(
                poolDetails.priceUSD
              );
              holdingPoolCakeSwap.push(poolDetails);
            }
          } else {
            // Its a token
            for (const token of allTokens) {
              if (token.address === asset.address) {
                let tokenBalance;
                if (chainId === ENVIRONMENT_CONFIG.bscChainId) {
                  tokenBalance = await ERC20Contract.getBalance(
                    address,
                    token.address
                  );
                }
                let tokenBalWithPrecision;
                if (tokenBalance && isOk(tokenBalance)) {
                  tokenBalWithPrecision = toDecimalWithPrecision(
                    tokenBalance.value,
                    Number(token.decimals)
                  );
                } else {
                  tokenBalWithPrecision = toDecimalWithPrecision(
                    asset.balance,
                    Number(token.decimals)
                  );
                }
                const balanceUsd = new Decimal(tokenBalWithPrecision).mul(
                  token.price
                );
                // add token price(in usd) to calculate current Market Value USD
                currentMarketValueUSD = currentMarketValueUSD.add(balanceUsd);
                holdingTokensBSC.push({
                  ...token,
                  symbol: token.symbol.toUpperCase(),
                  balanceUsd: balanceUsd.toString(),
                  balance: tokenBalWithPrecision,
                  isNative: false,
                  decimal: Number(token.decimals),
                });
              }
            }
          }
        }
      }
      const result = {
        Tokens: {
          ETH: ethNativeToken
            ? [ethNativeToken, ...holdingTokensETH]
            : holdingTokensETH,
          BSC: bnbNativeToken
            ? [bnbNativeToken, ...holdingTokensBSC]
            : holdingTokensBSC,
        },
        Pairs: {
          UNISWAP: holdingPoolUniSwap,
          CAKE: holdingPoolCakeSwap,
        },
        currentMarketValueUSD: currentMarketValueUSD.toString(),
      };
      return anOk(result);
    } catch (e) {
      return anErr("Unable to fetch tokens: GraphQL Error");
    }
  }

  static async fetchtPoolInfoFromPoolIds(accessToken: string, ids: string[]) {
    try {
      const { data, errors } = await client.query({
        query: gql(fetchPoolInfoFromTokenId),
        variables: {
          ids,
        },
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to suggested pool details: ${
            errors[0].message ?? "unknown error"
          }`
        );
      const { data: poolDetails }: { data: PoolDetails[] } = data.getChainPools;
      return anOk(poolDetails);
    } catch (e) {
      return anErr(`Unable to fetch pool from pool Ids: GraphQL Error`);
    }
  }

  static async fetchSuggestedPoodIds(
    accessToken: string,
    tokens: string[]
  ): Promise<Result<TokenPoolsResponse>> {
    try {
      const { data, errors } = await client.query({
        query: gql(fetchTokenPools),
        variables: {
          tokens,
        },
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to suggested pool: ${errors[0].message ?? "unknown error"}`
        );
      const { getChainTokenPools }: { getChainTokenPools: TokenPoolsResponse } =
        data;
      return anOk(getChainTokenPools);
    } catch (e) {
      return anErr(`Unable to fetch suggested Pool: GraphQL Error`);
    }
  }
}
