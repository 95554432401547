import React, { ReactNode } from 'react'

interface ModalWrapperProps {
  children: ReactNode,
  title: string,
  onClose: Function,
  isHidden: boolean
}

export const ModalWrapper = ({ children, title, onClose, isHidden }: ModalWrapperProps) => {
  if (!isHidden) {
    return (
      <>
        <div className="tw-justify-center tw-items-center tw-flex tw-fixed  tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none">
          <div className="tw-relative tw-w-96 tw-my-6 tw-mx-auto tw-max-w-3xl">
            <div className="tw-border-0 tw-rounded-lg tw-shadow-lg tw-relative tw-flex tw-flex-col tw-w-full tw-bg-gray-50 tw-outline-none focus:tw-outline-none">
              <div className="tw-flex tw-items-start tw-justify-between tw-p-5 tw-border-b tw-border-solid tw-border-blueGray-200 tw-rounded-t">
                <h3 className="tw-text-xl tw-font-medium">{title}</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => {
                    document.body.style.overflow = "unset";
                    onClose()
                  }}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8089 9L17.4386 3.37036C17.8021 2.99603 18.0038 2.49369 17.9999 1.97191C17.9961 1.45012 17.7872 0.950781 17.4182 0.58181C17.0492 0.212839 16.5499 0.00386652 16.0281 5.31169e-05C15.5063 -0.00376028 15.004 0.197892 14.6296 0.56143L9 6.19107L3.37036 0.56143C2.99603 0.197892 2.49369 -0.00376028 1.97191 5.31169e-05C1.45012 0.00386652 0.950781 0.212839 0.58181 0.58181C0.212839 0.950781 0.00386652 1.45012 5.31169e-05 1.97191C-0.00376028 2.49369 0.197892 2.99603 0.56143 3.37036L6.19107 9L0.56143 14.6296C0.197892 15.004 -0.00376028 15.5063 5.31169e-05 16.0281C0.00386652 16.5499 0.212839 17.0492 0.58181 17.4182C0.950781 17.7872 1.45012 17.9961 1.97191 17.9999C2.49369 18.0038 2.99603 17.8021 3.37036 17.4386L9 11.8089L14.6296 17.4386C15.004 17.8021 15.5063 18.0038 16.0281 17.9999C16.5499 17.9961 17.0492 17.7872 17.4182 17.4182C17.7872 17.0492 17.9961 16.5499 17.9999 16.0281C18.0038 15.5063 17.8021 15.004 17.4386 14.6296L11.8089 9Z"
                        fill="#111827"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="tw-relative tw-p-6 tw-flex-auto">
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 tw-bg-black" />
      </>
    )
  }
  return null

}