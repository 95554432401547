import React, { useEffect, useState } from "react";
import style from "./feedbackBanner.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export const FeedbackBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  const handleCancle = () => {
    setShowBanner(false);
    localStorage.setItem("last_crossed", moment().format());
  };

  useEffect(() => {
    const lastcrossed = localStorage.getItem("last_crossed");
    if (!lastcrossed) {
      setShowBanner(true);
    } else {
      // show banner a day after last crossed
      const aDayAfterLastCrossed = moment(lastcrossed).add(1, "days");
      const now = moment();
      if (now > aDayAfterLastCrossed) {
        setShowBanner(true);
      } else {
        setShowBanner(false);
      }
    }
  }, []);

  return (
    <div
      className={`${style.topBanner} ${!showBanner ? style.hideBanner : null}`}
    >
      <div className={style.bannerContainer}>
        <div className={style.bannerContent}>
          <a
            href="https://forms.gle/sgyF62mHaYCmd8E57"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help us improve our product, take a short survey, for a chance to
            win 100 FET
            <FontAwesomeIcon icon={faChevronRight} className={style.icon} />
          </a>
        </div>
        <div onClick={handleCancle} className={style.cancleBtn}>
          <FontAwesomeIcon icon={faTimes} className={style.icon} />
        </div>
      </div>
    </div>
  );
};
