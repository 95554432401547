import { useContext, useEffect, useState } from "react";
import { StateContext } from "../state/state";
import { GweiDropdown } from "./GweiDropdown/index";
import { mapChainId } from "../configs";
import { enumToString, NATIVE_TOKEN_ADDRESS } from "../api/common";
import { Chain, CryptoToken } from "../state/assets";
import Web3 from "web3";
import { gweiToWei } from "../utils/currency/GweiToWei";
import { GasPriceInfo } from "./SelectGasPrice/SelectGasPrice";
import { formatBigNumber } from "../utils/numeric/formatBigNumber";
import { Tooltip } from "./Tooltip";
import { useHistory } from "react-router";
import HelpIcon from "../assets/help-icon.png";

interface costValue {
  amount: string;
  usd: string;
}

const GAS_COST_EST = 500000;
const ACCOUNT_GAS_COST_EST = 200000;

const displayCost = (cost: costValue, ethToken: CryptoToken) => {
  return (
    <span className="tw-font-semibold tw-flex tw-whitespace-nowrap">
      {formatBigNumber(cost.amount).same ? (
        `${formatBigNumber(cost.amount).trunc} ${ethToken.symbol.toUpperCase()}`
      ) : (
        <Tooltip
          id="refund-cost-eth"
          tooltipText={`${formatBigNumber(cost.amount).full
            } ${ethToken.symbol.toUpperCase()}`}
        >
          {formatBigNumber(cost.amount).trunc}&nbsp;
          {ethToken.symbol.toUpperCase()}
        </Tooltip>
      )}{" "}
      &nbsp;/ &nbsp;<wbr/>
      {formatBigNumber(cost.usd || "0.00").same ? (
        `$ ${formatBigNumber(cost.usd || "0.00").trunc}`
      ) : (
        <Tooltip
          id="refund-cost-usd"
          tooltipText={`$ ${formatBigNumber(cost.usd || "0.00").full}`}
        >
          $ {formatBigNumber(cost.usd || "0.00").trunc}
        </Tooltip>
      )}
    </span>
  );
};

export const Costs = ({
  txnSpeed,
  setTxnSpeed,
  setEmptyCustom,
}: {
  txnSpeed: number;
  setTxnSpeed: Function;
  setEmptyCustom: Function;
}) => {
  const [ethToken, setEthToken] = useState<CryptoToken>();
  const state = useContext(StateContext);
  const history = useHistory();
  const [gasCostEst, setGasCostEst] = useState<costValue>();
  const [agentsCost, setAgentsCost] = useState<costValue>();
  const [totalCost, setTotalCost] = useState<costValue>();

  useEffect(() => {
    const eth = state.assets.allTokens.find(
      (token) =>
        token.address == NATIVE_TOKEN_ADDRESS &&
        token.chain === enumToString(Chain, mapChainId(state.web3.chainId || 2))
    );

    if (eth === undefined) {
      history.push("/dashboard");
      return;
    }

    setEthToken(eth);
  }, [state.assets.allTokens.length, state.web3.chainId]);

  useEffect(() => {
    if (ethToken) {
      const agentsWei = gweiToWei(`${txnSpeed * GAS_COST_EST}`);
      const agentsEth = Web3.utils.fromWei(agentsWei, "ether");
      const amount = parseFloat(agentsEth).toFixed(3) || "0.00";
      const usd = (parseFloat(agentsEth) * parseFloat(ethToken.price)).toFixed(
        2
      );
      setAgentsCost({ amount, usd });
      if (state.account.gasPrice) {
        const gasWei = gweiToWei(
          `${parseInt(state.account.gasPrice.price) * ACCOUNT_GAS_COST_EST}`
        );
        const gasEth = Web3.utils.fromWei(gasWei, "ether");
        const amount = parseFloat(gasEth).toFixed(3) || "0.00";
        const usd = (parseFloat(gasEth) * parseFloat(ethToken.price)).toFixed(
          2
        );
        setGasCostEst({ amount, usd });

        const totalAmount =
          (parseFloat(agentsEth) + parseFloat(gasEth)).toFixed(3) || "0.00";
        const totalUsd = (
          (parseFloat(agentsEth) + parseFloat(gasEth)) *
          parseFloat(ethToken.price)
        ).toFixed(2);
        setTotalCost({ amount: totalAmount, usd: totalUsd });
      }
    }
  }, [state.account.gasPrice, ethToken, txnSpeed]);

  return (
    <div className="tw-shadow-lg tw-rounded-xl tw-overflow-hidden lg:tw-col-span-5">
      <div className="tw-bg-coolGray-200 tw-text-coolGray-900 tw-text-xl tw-font-medium tw-pl-7 tw-py-2">
        Costs
      </div>
      <div className="tw-bg-white">
        <div className="tw-flex tw-w-full tw-justify-between tw-items-center tw-border-b tw-border-coolGray-300">
          <div className="tw-flex tw-text-xl tw-font-normal tw-text-coolGray-900 tw-px-7 tw-items-center">
            Transaction speed&nbsp;:&nbsp;
            <Tooltip
              id="help-txn-speed"
              tooltipText={` This is the gas price that will be used for the transaction when the trigger is executed.<br/> The selected value helps to fund your selected agent with enough ${ethToken?.symbol.toUpperCase()} to do a transaction.<br/>  A high value ensures that the transaction does not fail due to chain congestion.<br/> You can always withdraw the extra funds if the gas price during trigger execution is less than the configured value now.`}
            >
              <img src={HelpIcon} width="18px" />
            </Tooltip>
          </div>
          <div className="tw-text-coolGray-900 tw-px-7 tw-py-5">
            <GweiDropdown
              setTxnSpeed={setTxnSpeed}
              setEmptyCustom={setEmptyCustom}
              chain={enumToString(Chain, mapChainId(state.web3.chainId || 2))}
            />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-text-xl tw-font-normal tw-text-coolGray-900 tw-px-7 tw-py-5 tw-border-b tw-border-coolGray-300">
          Monthly automation cost&nbsp;:&nbsp;
          <Tooltip
            id="help-txn-speed"
            tooltipText={`This is the service fee you need to pay to keep this automation enabled.`}
          >
            <img src={HelpIcon} width="18px" />
          </Tooltip>
          &nbsp;
          <span className="tw-font-semibold">FREE</span>
        </div>
        <div className="tw-text-xl tw-font-normal tw-text-coolGray-900 tw-px-7 tw-py-5 tw-border-b tw-border-coolGray-300 tw-flex tw-items-center">
          Estimated total setup cost&nbsp;:&nbsp;
          <Tooltip
            id="help-txn-speed"
            tooltipText={`This is the amount in ${ethToken?.symbol.toUpperCase()} that you would need to spend to set up this automation.`}
          >
            <img src={HelpIcon} width="18px" />
          </Tooltip>
          &nbsp;
          {totalCost && ethToken
            ? displayCost(totalCost, ethToken)
            : "Processing"}
        </div>
        <div className="tw-text-xl tw-font-normal tw-text-coolGray-900 tw-px-7 tw-py-5 tw-flex">
          Refundable costs:&nbsp;
          {agentsCost && ethToken
            ? displayCost(agentsCost, ethToken)
            : "Processing"}
        </div>
        <div className="tw-grid tw-grid-cols-2 tw-mx-7 tw-pb-5 tw-text-coolGray-500 tw-text-sm tw-font-normal">
          <div>
            Gas cost estimate:{" "}
            {gasCostEst && ethToken
              ? displayCost(gasCostEst, ethToken)
              : "Processing"}
          </div>
          <div className="tw-flex">
            Agent deposit:&nbsp;
            {agentsCost && ethToken
              ? displayCost(agentsCost, ethToken)
              : "Processing"}
          </div>
        </div>
      </div>
    </div>
  );
};
