/**
 * Get substring of address with three appended in middle of  dots; for UI display
 *
 * @param val subject
 * @param beginningLength number of chars to keep before  dots
 * @param endingLength number of chars to keep after dots
 * @returns {string} formatted string
 * @param dots how many dots in middle of string
 */
const format = (
  val: string,
  beginningLength = 12,
  endingLength = 12,
  dots = 5
): string => {
  if (val === "") return "";
  return (
    val.substring(0, beginningLength) +
    ".".repeat(dots) +
    val.substring(val.length - endingLength)
  );
};
export { format };
