import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import cryptocurrencyBackup from "../../assets/cryptocurrency.svg";
import { HoldingPool } from "../../state/assets";

interface TokenDropdownProps {
  selected: HoldingPool;
  poolList: HoldingPool[];
  setSelected: Function;
}

export const PoolDropdown = ({
  poolList,
  selected,
  setSelected,
}: TokenDropdownProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [searchToken, setSearchToken] = useState<string>("");
  const [list, setList] = useState<HoldingPool[]>(poolList);

  useEffect(() => {
    const searchCriteria = (pool: HoldingPool) => {
      return (
        pool.token1.toLowerCase().includes(searchToken.toLowerCase()) ||
        pool.token0.toLowerCase().includes(searchToken.toLowerCase()) ||
        pool.symbol.toLowerCase().includes(searchToken.toLowerCase())
      );
    };
    const filteredList = poolList.filter((pool) => searchCriteria(pool));
    if (filteredList.length) {
      setList(filteredList);
    } else setList([]);
  }, [searchToken]);

  return (
    <>
      {!isHidden && (
        <>
          <div className="tw-justify-center tw-items-center tw-flex tw-fixed tw-inset-0 tw-z-50 tw-outline-none focus:tw-outline-none">
            <div className="tw-relative tw-w-96 tw-my-6 tw-mx-auto tw-max-w-3xl">
              <div className="tw-border-0 tw-rounded-lg tw-shadow-lg tw-relative tw-flex tw-flex-col tw-w-full tw-bg-gray-50 tw-outline-none focus:tw-outline-none">
                <div className="tw-flex tw-items-start tw-justify-between tw-p-5 tw-border-b tw-border-solid tw-border-blueGray-200 tw-rounded-t">
                  <h3 className="tw-text-xl tw-font-medium">Select a token</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setSearchToken("");
                      setIsHidden(true);
                      document.body.style.overflow = "unset";
                    }}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.8089 9L17.4386 3.37036C17.8021 2.99603 18.0038 2.49369 17.9999 1.97191C17.9961 1.45012 17.7872 0.950781 17.4182 0.58181C17.0492 0.212839 16.5499 0.00386652 16.0281 5.31169e-05C15.5063 -0.00376028 15.004 0.197892 14.6296 0.56143L9 6.19107L3.37036 0.56143C2.99603 0.197892 2.49369 -0.00376028 1.97191 5.31169e-05C1.45012 0.00386652 0.950781 0.212839 0.58181 0.58181C0.212839 0.950781 0.00386652 1.45012 5.31169e-05 1.97191C-0.00376028 2.49369 0.197892 2.99603 0.56143 3.37036L6.19107 9L0.56143 14.6296C0.197892 15.004 -0.00376028 15.5063 5.31169e-05 16.0281C0.00386652 16.5499 0.212839 17.0492 0.58181 17.4182C0.950781 17.7872 1.45012 17.9961 1.97191 17.9999C2.49369 18.0038 2.99603 17.8021 3.37036 17.4386L9 11.8089L14.6296 17.4386C15.004 17.8021 15.5063 18.0038 16.0281 17.9999C16.5499 17.9961 17.0492 17.7872 17.4182 17.4182C17.7872 17.0492 17.9961 16.5499 17.9999 16.0281C18.0038 15.5063 17.8021 15.004 17.4386 14.6296L11.8089 9Z"
                          fill="#111827"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className="tw-relative tw-p-6 tw-flex-auto">
                  <input
                    value={searchToken}
                    onChange={(evt) => setSearchToken(evt.target.value)}
                    className="token-search-text tw-w-full"
                    aria-label="Default"
                    placeholder="Enter name, symbol or address"
                  />
                  <div className="token-list">
                    {list.map((pool, i) => {
                      return (
                        <div
                          key={pool.address}
                          className="tw-flex tw-items-center token-row"
                          onClick={() => {
                            setSelected(pool);
                            setSearchToken("");
                            setIsHidden(true);
                            document.body.style.overflow = "unset";
                          }}
                        >
                          <div className="tw-flex tw-mr-5">
                            <img
                              src={pool.token0LogoUrl}
                              alt="token1"
                              className="tw-w-10 tw-h-10"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = cryptocurrencyBackup;
                              }}
                            />
                            <img
                              src={pool.token1LogoUrl}
                              alt="token2"
                              className="tw-relative tw--ml-3 tw-w-10 tw-h-10"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = cryptocurrencyBackup;
                              }}
                            />
                          </div>
                          <div className="token-name tw-gray-900 tw-text-xl tw-font-medium">
                            {pool.token0}/{pool.token1}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-opacity-25 tw-fixed tw-inset-0 tw-z-40 tw-bg-black" />
        </>
      )}
      <div className="tw-bg-coolGray-200 tw-rounded tw-py-2 tw-w-max">
        <div className="tw-relative">
          <div
            onClick={() => {
              document.body.style.overflow = "hidden";
              setIsHidden(!isHidden);
            }}
            className={`${
              isHidden ? "tw-rounded-full" : "tw-rounded-t"
            } tw-cursor-pointer tw-px-2 tw-flex tw-justify-between tw-items-center`}
          >
            <div className="tw-bg-coolGray-200 tw-text-3xl tw-font-medium tw-px-8 tw-py-2 tw-rounded tw-flex tw-items-center">
              <div className="tw-flex tw-mr-5">
                <img
                  src={selected.token0LogoUrl}
                  alt="token1"
                  className="tw-w-10 tw-h-10"
                />
                <img
                  src={selected.token1LogoUrl}
                  alt="token2"
                  className="tw-relative tw--ml-3 tw-w-10 tw-h-10"
                />
              </div>
              {selected.token0}/{selected.token1}
            </div>

            <ArrowIcon className="tw-transform tw--rotate-90 tw-ml-4 tw-mr-2 tw-w-2.5" />
          </div>
        </div>
      </div>
    </>
  );
};
