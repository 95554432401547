/**
 * Has more decimals than the max number allowed by fet, or not?
 * Since we must convert string inputs eg "3.66" FET to amounts of canonical FET we must test if
 * there are too many decimals to convert eg > decimals of FET.
 *
 * @param amount value to check
 * @returns {boolean} Has or not more decimal places than allowed in non-canonical FET amount
 */
import { countDecimalPlaces } from "../../components/TopUpVaultModal/utils";

export const truncateToDecimalPlaces = (
  amount: string,
  decimals: number
): string => {
  const decimalCount = countDecimalPlaces(amount);

  if(decimalCount < decimals) {
      return amount;
  }

   const wholeNumberPart = amount.split(".")[0];
   const decimalComponent = amount.split(".")[1];

   return wholeNumberPart + "." + decimalComponent.substring(0, decimals)
};
