import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";
import { Network } from "../../configs";
import { SelectGasPrice } from "../SelectGasPrice/SelectGasPrice";
import { Web3AddressNav } from "../Web3AddressNav";
import { NetworkSlider } from "../NetworkSlider";
import style from "./Navbar.module.scss";

function showEthSlider(
  address: string,
  web3Unlocked: boolean,
  web3Installed: boolean
) {
  return !!(web3Installed && web3Unlocked && address !== "");
}

interface NavbarProps {
  address: string;
  chainId: number;
  network: Network;
  web3Unlocked: boolean;
  web3Installed: boolean;
}

function buildNavItemsClasses(url: string, currentUrl: string) {
  if (url === currentUrl) {
    return classNames(style.NavLink, style.NavLinkActive);
  }
  return classNames(style.NavLink, style.NavLinkInactive);
}

export function TopNavbar(props: NavbarProps) {
  const { pathname } = useLocation();

  return (
    <div className="tw-flex tw-justify-end tw-items-center sm:tw-mx-16 tw-h-24 tw-bg-coolGray-50">
      <SelectGasPrice />
      <NetworkSlider
        address={props.address}
        chainId={props.chainId}
        web3Unlocked={props.web3Unlocked}
        web3Installed={props.web3Installed}
      />
      <Web3AddressNav
        address={props.address}
        chainId={props.chainId}
        network={props.network}
        web3Unlocked={props.web3Unlocked}
        web3Installed={props.web3Installed}
        pathname={pathname}
      />
    </div>
  );
}
