import { eventNameAnalysis } from "./utils/misc/trackEvent";

export type OkResult<T> = {
  success: true;
  value: T;
};

export type ErrorResult = {
  success: false;
  error: Error;
};

export type Result<T> = OkResult<T> | ErrorResult;

export function isOk<T>(result: Result<T>): result is OkResult<T> {
  return result.success;
}

export function isErr<T>(result: Result<T>): result is ErrorResult {
  return !result.success;
}

export function anOk<T>(value: T): OkResult<T> {
  return {
    success: true,
    value,
  };
}

export function anErr(message: string, context?: any, suppress: boolean = false): ErrorResult {
  eventNameAnalysis("exception", { description: message });

  if(!suppress) {
    console.trace(`WARN: ${message}`, context);
  }

  return {
    success: false,
    error: new Error(message),
  };
}
