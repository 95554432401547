import { isTokenSame } from "./isTokenSame";
import { CryptoToken, HoldingToken } from "../../state/assets";

/**
 * Just gets the first other token that is not token 1 as token 2
 *
 * @param token1
 * @param holdingAssets
 */
export const findToken2 = (
  token1: HoldingToken,
  holdingAssets: Array<HoldingToken>
): HoldingToken | undefined => {
  return holdingAssets.find((tkn) => !isTokenSame(token1, tkn));
};
