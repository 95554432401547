import { Agent } from "../../state/agents";

/**
 * Is the currently selected address in metamask equal to the address associated with the agent
 *
 * @param address The users web3 address
 * @param agent The agent to be compared against
 */
export const isCurrentAgent = (
  address: string | undefined,
  agent: Agent | undefined
): boolean => {
  if (address === undefined || agent === undefined) {
    return false;
  }
  return Boolean(address === agent.userAddress);
};
