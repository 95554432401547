/**
 * run time version of Omit to omit specified object properties
 *
 * @see {@link https://stackoverflow.com/questions/55704071/how-to-omit-a-property-from-a-type}
 *
 * @param obj
 * @param args
 */
export function omitObjectProperties(obj: any, ...args: string[]): any {
  for (const key of args) {
    delete obj[key];
  }
  return obj;
}
