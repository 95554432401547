import { action, makeAutoObservable } from "mobx";
import { UserPreferences } from "../api/AccountsAPI";

export class Preferences {
  get userPreferences(): UserPreferences | undefined {
    return this._userPreferences;
  }

  @action
  set userPreferences(value: UserPreferences | undefined) {
    this._userPreferences = value;
  }

  private _userPreferences: UserPreferences | undefined;

  constructor() {
    makeAutoObservable(this);
    this._userPreferences = undefined;
  }
}
