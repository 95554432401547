import { AbiItem } from "web3-utils";
import Decimal from "decimal.js";
import { TokenData } from "./UniswapERC20";
import { UnitInfo } from "../../utils/display/toNonCanonicalDisplay";

// just a pool on kovan and here to help during development to be deleted afterwards
// const EXAMPLE_KOVAN_PAIR_FOR_REFERENCE_AND_TESTING = '0x55e12f1801ECcdDe42282985db90b467B9A678E8'

export function asAbi(value: unknown): AbiItem[] {
  return value as AbiItem[];
}


/**
 *  Changes between two types
 *
 * @param tokenData
 */
export const tokenDataToUnitInfo = (tokenData: TokenData): UnitInfo => {
  return {
    units: [
      {
        power: tokenData.decimals,
        name: tokenData.symbol,
      },
    ],
    decimals: tokenData.decimals,
  };
};
