import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { NATIVE_TOKEN_ADDRESS } from "../../api/common";
import HelpIcon from "../../assets/help-icon.png";
import { ThresholdToken } from "../../pages/automations/swapAutomation";
import { isOk } from "../../result";
import ERC20Contract from "../../services/contracts/ERC20Contract";
import { CryptoToken } from "../../state/assets";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import Tooltip from "../../utils/tooltip/tooltip";
import { Dropdown } from "../Dropdown";
import { PricePercentageBox } from "../PricePercentageBox";
import { PricePercentageInput } from "../PricePercentageInput";
import { Tooltip as HelpToolTip } from "../Tooltip";

interface AutomationStrategyProps {
  tokens: ThresholdToken[];
  threshold: number;
  setThreshold: Function;
  thresholdToken: CryptoToken;
  setThresholdToken: Function;
  thresholdMsg: string;
  setThresholdMsg: Function;
  selectedPercentage: number;
  setSelectedPercentage: Function;
  inputPercentage: number;
  setInputPercentage: Function;
}

const pricePercentages = [
  {
    percentage: -50,
    value: 0.5,
  },
  {
    percentage: -35,
    value: 0.65,
  },
  {
    percentage: -25,
    value: 0.75,
  },
  {
    percentage: -10,
    value: 0.9,
  },
];

export const AutomationStrategy = ({
  tokens,
  threshold,
  setThreshold,
  thresholdToken,
  setThresholdToken,
  thresholdMsg,
  setThresholdMsg,
  selectedPercentage,
  setSelectedPercentage,
  inputPercentage,
  setInputPercentage,
}: AutomationStrategyProps) => {
  const [otherToken, setOtherToken] = useState<CryptoToken>(tokens[1]);
  const [decimals, setDecimals] = useState<number>(6);

  // Threshold error msg
  useEffect(() => {
    if (threshold === undefined) setThresholdMsg(``);
    else if (threshold <= 0) setThresholdMsg(`Not Valid Threshold`);
    else if (tooManyDecimals(threshold.toString()))
      setThresholdMsg(
        `Decimal places should not be greater than ${otherToken.symbol.toUpperCase()} decimal limit`
      );
    else setThresholdMsg("");
  }, [threshold]);

  // update thresholdToken on dropdown token changes
  useEffect(() => {
    const thresholdTkn = tokens[0]?.validThresholdToken ? tokens[0] : tokens[1];
    setThresholdToken({ ...thresholdTkn, validThresholdToken: true });
  }, [tokens]);

  // update otherToken on thresholdToken changes
  useEffect(() => {
    const getdecimals = async (otherToken: CryptoToken) => {
      let decimals = 18;
      if (otherToken.address !== NATIVE_TOKEN_ADDRESS) {
        const resultDecimals = await ERC20Contract.getDecimals(
          otherToken.address
        );
        if (isOk(resultDecimals)) decimals = parseInt(resultDecimals.value);
      }
      setDecimals(decimals);
    };
    const otherToken =
      tokens.find((tkn) => tkn.address !== thresholdToken.address) || tokens[1];
    setOtherToken(otherToken);
    getdecimals(otherToken);
  }, [thresholdToken]);

  // reset percentage and threshold on otherToken changes
  useEffect(() => {
    handleSelectedPercentage(-25);
  }, [otherToken]);

  const calcSpotPrice = (percentageValue: number) => {
    const multiplier = (100 + percentageValue) / 100;
    const usdPrice = parseFloat(thresholdToken.price) * multiplier;
    const spotPrice = (usdPrice / parseFloat(otherToken.price)).toFixed(
      decimals
    );
    return { spotPrice: parseFloat(spotPrice), usdPrice };
  };

  const tooManyDecimals = (subject: string): boolean => {
    if (subject === "") return false;
    const decimalPlaces = new Decimal(subject).decimalPlaces();
    return decimalPlaces > decimals;
  };

  const handleSelected = (token: CryptoToken) => {
    setThresholdToken(token);
  };

  const handleSelectedPercentage = (value: number) => {
    setSelectedPercentage(value);
    setInputPercentage(0);
    const threshold = calcSpotPrice(value);
    setThreshold(threshold.spotPrice);
  };

  const handleInputPercentage = (value: number) => {
    if (value == 0 || !value) {
      handleSelectedPercentage(-25);
    } else if (value >= -100 && value <= 100) {
      const negativePercentage = -Math.abs(value);
      setSelectedPercentage(0);
      setInputPercentage(negativePercentage);
      const threshold = calcSpotPrice(negativePercentage);
      setThreshold(threshold.spotPrice);
    }
  };

  return (
    <div className="tw-shadow-lg tw-rounded-xl tw-overflow-hidden tw-flex tw-flex-col lg:tw-col-span-6">
      <div className="tw-bg-coolGray-200 tw-px-7 tw-py-2 tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-text-coolGray-900 tw-text-xl tw-font-medium tw-items-center">
          Automation Strategy&nbsp;
          <HelpToolTip
            id="help-txn-speed"
            tooltipText={`The trading strategy lets you define rules for when the withdraw liquidity action should trigger.`}
          >
            <img src={HelpIcon} style={{width:"20px", height:"20px"}} />
          </HelpToolTip>
        </div>
        <div className="tw-text-base tw-font-normal tw-text-coolGray-900 tw-whitespace-nowrap tw-ml-1">
          Spot price:<wbr/> 1 {thresholdToken?.symbol.toUpperCase()} ={" "}
          <Tooltip
            child={`${formatBigNumber(calcSpotPrice(0).spotPrice.toString()).trunc
              }`}
            tooltipTextClassName="tool-tip-center"
            text={`${formatBigNumber(calcSpotPrice(0).spotPrice.toString()).full
              }`}
            hideTooltip={
              formatBigNumber(calcSpotPrice(0).spotPrice.toString()).same
            }
          />{" "}
          {otherToken?.symbol?.toUpperCase()} (
          <Tooltip
            child={`$${formatBigNumber(calcSpotPrice(0).usdPrice.toString()).trunc
              }`}
            tooltipTextClassName="tool-tip-center"
            text={`$${formatBigNumber(calcSpotPrice(0).usdPrice.toString()).full
              }`}
            hideTooltip={
              formatBigNumber(calcSpotPrice(0).usdPrice.toString()).same
            }
          />
          )
        </div>
      </div>
      <div className="tw-p-7 tw-bg-white tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-pb-0">
        <div className="tw-text-xl tw-font-medium tw-text-coolGray-900 tw-flex tw-items-center tw-mb-4 lg:tw-mb-0">
          Remove liquidity if
          <span className="mx-2">
            <Dropdown
              selected={thresholdToken}
              handleSelected={handleSelected}
              tokens={tokens}
            />
          </span>
          changes by more than:
        </div>
        <div className="tw-grid tw-grid-cols-6 tw-gap-7 tw-mt-4">
          <PricePercentageInput
            inputPercentage={inputPercentage}
            handleInputPercentage={handleInputPercentage}
            price={calcSpotPrice(inputPercentage)}
          />
          {pricePercentages.map(({ percentage }) => (
            <PricePercentageBox
              otherTokenSymbol={otherToken.symbol.toUpperCase()}
              thresholdTokenSymbol={thresholdToken.symbol.toUpperCase()}
              percentage={percentage}
              price={calcSpotPrice(percentage)}
              spotPriceValue={calcSpotPrice(0)}
              isSelected={selectedPercentage === percentage}
              onClick={handleSelectedPercentage}
            />
          ))}
        </div>
        <span className="tw-w-full tw-mt-1 tw-text-sm tw-text-red-600">
          {thresholdMsg}
        </span>
      </div>
    </div>
  );
};
