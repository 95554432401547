import {formatBigNumber} from "../../../utils/numeric/formatBigNumber";
import {Tooltip} from "../../../components/Tooltip";
import React from "react";
import {CryptoToken} from "../../../state/assets";
import {convertCurrency} from "../../../utils/currency/convertCurrency";
import {convertFromCurrency} from "../../../utils/currency/convertFromCurrency";


export const amountTooltip = (id: string, amount: string | undefined) => {
  if (formatBigNumber(amount || "0.00").same)
    return "$ " + formatBigNumber(amount || "0.00").trunc;

  return (
    <Tooltip
      id={id}
      tooltipText={`$ ${formatBigNumber(amount ? amount : "0.00").full}`}
    >
      $ {formatBigNumber(amount || "0.00").trunc}
    </Tooltip>
  );
};

export function calcAmount(
  amount: string | undefined,
  toToken: CryptoToken | undefined,
  fromToken: CryptoToken | undefined
): string | undefined {
  if (!toToken || !fromToken) {
    return undefined;
  }

  const dollarAmount = convertCurrency(amount, toToken.price);
  return convertFromCurrency(dollarAmount, fromToken.price);
}


export const balanceTooltip = (id: string, balance: string | undefined) => {
  if (formatBigNumber(balance || "0.00").same)
    return formatBigNumber(balance || "0.00").trunc;

  return (
    <Tooltip id={id} tooltipText={`${formatBigNumber(balance || "0.00").full}`}>
      {formatBigNumber(balance || "0.00").trunc}
    </Tooltip>
  );
};