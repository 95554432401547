import Decimal from "decimal.js";
import React, { useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { isNumeric } from "../../utils/numeric/isNumeric";
import Tooltip from "../../utils/tooltip/tooltip";
import { OutsideClickHandler } from "../OutsideClickHandler";

interface Data {
  val: number;
  title: string;
}

enum Speed {
  SLOW = "Slow",
  MEDIUM = "Medium",
  FAST = "Fast",
  CUSTOM = "Custom",
}

interface GasPriceData {
  val: number | undefined;
  title: Speed;
}

const dataETH: GasPriceData[] = [
  {
    val: 100,
    title: Speed.SLOW,
  },
  {
    val: 200,
    title: Speed.MEDIUM,
  },
  {
    val: 300,
    title: Speed.FAST,
  },
];

const dataBSC: GasPriceData[] = [
  {
    val: 10,
    title: Speed.SLOW,
  },
  {
    val: 20,
    title: Speed.MEDIUM,
  },
  {
    val: 30,
    title: Speed.FAST,
  },
];

function formatCustom(s: number | undefined) {
  if (s === undefined) return "";

  if (s < 1000) return s;

  return s.toString().substring(0, 2) + "..";
}

function headerDisplayStr(selected: GasPriceData) {
  if (selected.val === undefined) return "";

  return `(${formatCustom(selected.val)} Gwei)`;
}

export const GweiDropdown = ({
  setTxnSpeed,
  setEmptyCustom,
  chain,
}: {
  setTxnSpeed: Function;
  setEmptyCustom: Function;
  chain: string;
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [customGas, setCustomGas] = useState<number | undefined>();
  const [data, setData] = useState<GasPriceData[]>(
    chain === "ETH" ? dataETH : dataBSC
  );
  const [selected, setSelected] = useState<GasPriceData>(
    chain === "ETH" ? dataETH[0] : dataBSC[0]
  );

  /**
   * don't show unless custom speed
   */
  const shouldHide = () => {
    const filtered = data.filter((el) => {
      return Boolean(
        el.title === Speed.FAST ||
          el.title === Speed.MEDIUM ||
          el.title === Speed.SLOW
      );
    });
    const mapped = filtered.map((el) => el.val);
    return mapped.includes(selected.val) || selected.val === undefined;
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setIsHidden(true)}>
      <div className="tw-inline-block tw-w-40">
        <div className="tw-relative">
          <div
            onClick={() => setIsHidden(!isHidden)}
            className={`${
              isHidden ? "tw-rounded-full" : "tw-rounded-t"
            } tw-cursor-pointer tw-bg-coolGray-100 tw-border tw-border-coolGray-300 tw-px-2 tw-py-1 tw-flex tw-justify-between tw-items-center`}
          >
            <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
              <div className="tw-text-sm tw-font-semibold">
                {selected.title}
              </div>
              <div className="tw-text-xs tw-font-normal tw-mr-2">
                <Tooltip
                  child={headerDisplayStr(selected)}
                  text={`${
                    formatBigNumber(selected.val?.toString() || "0.00").full
                  } Gwei`}
                  hideTooltip={shouldHide()}
                />
              </div>
            </div>
            <ArrowIcon className="tw-transform tw--rotate-90 tw-w-2.5" />
          </div>
          <div
            className={`tw-absolute tw-z-10 tw-top-full tw-w-40 tw-rounded-b ${
              isHidden ? "tw-hidden " : null
            }`}
          >
            {data.map((speed, index) => (
              <div
                key={index}
                onClick={() => {
                  setEmptyCustom(false);
                  setSelected(speed);
                  setTxnSpeed(speed.val);
                  setIsHidden(true);
                }}
                className="last:tw-rounded-b tw-cursor-pointer tw-border tw-border-t-0 tw-border-coolGray-300 tw-bg-white tw-py-2 tw-px-2 tw-flex tw-items-center"
              >
                <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
                  <div className="tw-text-sm tw-font-semibold">
                    {speed.title}
                  </div>
                  <div className="tw-text-xs tw-font-normal tw-mr-2">
                    ({speed.val} Gwei)
                  </div>
                </div>
              </div>
            ))}
            <div
              key={99}
              onClick={() => {
                if (!customGas) {
                  setEmptyCustom(true);
                } else {
                  setEmptyCustom(false);
                }

                setSelected({
                  val: customGas,
                  title: Speed.CUSTOM,
                });
              }}
              className="last:tw-rounded-b tw-cursor-pointer tw-border tw-border-t-0 tw-border-coolGray-300 tw-bg-white tw-py-2 tw-px-2 tw-flex tw-items-center"
            >
              <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
                <div className="tw-text-sm tw-font-semibold">Custom</div>

                <input
                  className="tw-w-1/2 tw-bg-white tw-h-5 tw-rounded  tw-text-xs tw-font-normal tw-text-coolGray-900 tw-text-left tw-border tw-border-coolGray-300 tw-mt-1 tw-mb-1 tw-px-3 focus:tw-outline-none ml-px-20"
                  type="text"
                  value={customGas === undefined ? "" : customGas.toString()}
                  onChange={(e) => {
                    const val = e.target.value;

                    if (
                      val === "" ||
                      val === undefined ||
                      val.substring(0, 1) === "-" ||
                      !isNumeric(val) ||
                      val.includes(".")
                    ) {
                      setCustomGas(undefined);
                      setSelected({
                        val: undefined,
                        title: Speed.CUSTOM,
                      });
                      setEmptyCustom(true);
                      setTxnSpeed(undefined);
                      return;
                    }

                    if (new Decimal(val).gt(new Decimal(1000))) {
                      return;
                    }

                    if (!isNumeric(val) || val.includes(".")) {
                      return;
                    }

                    const parsed = parseInt(e.target.value);
                    setEmptyCustom(false);
                    setCustomGas(parsed);
                    setSelected({
                      val: parsed,
                      title: Speed.CUSTOM,
                    });
                    setTxnSpeed(parsed);
                  }}
                  placeholder="00.00"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
