import { GasPrices } from "../configs";
import { makeAutoObservable, observable } from "mobx";

export class Eth {
  gasPrices: GasPrices;

  setGasPrices(value: GasPrices) {
    this.gasPrices = value;
  }
  constructor() {
    this.gasPrices = {
      LOW: "",
      MEDIUM: "",
      HIGH: "",
    };
    makeAutoObservable(this, {
      gasPrices: observable,
    });
  }
}
