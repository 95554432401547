import classNames from "classnames";
import style from "../../pages/AgentsPage.module.scss";
import React from "react";

interface EmptyAgentRowProps {
  isMobile?: boolean;
}

export function EmptyAgentRow(props: EmptyAgentRowProps) {
  return (
    <tr data-testid="empty-agent-row">
      <td
        colSpan={props.isMobile ? 6 : 7}
        className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-16 tw-py-2.5 tw-text-coolGray-900"
      >
        No Agents currently configured for this network
      </td>
    </tr>
  );
}
