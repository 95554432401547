import React from "react";
import { HoldingPool, HoldingToken } from "../../state/assets";
import Decimal from "decimal.js";
import sortBoth from "../../assets/tablesorter/sort_both.svg";
import sortUp from "../../assets/tablesorter/sort_up.svg";
import sortDown from "../../assets/tablesorter/sort_down.svg";
import { formatPoolName } from "../../utils/display/formatPoolName";
import { Tooltip, Tooltip as HelpToolTip } from "../Tooltip";
import { Network } from "../../configs";
import HelpIcon from "../../assets/help-icon.png";

export enum SORT_STAGES {
  BOTH,
  DOWN,
  UP,
}

export enum SORT_PROPERTIES {
  POOL_NAME,
  MARKET_VALUE,
  BALANCE,
  DEFAULT,
  USD_BALANCE,
}

function alphabeticPoolNameCompare(a: HoldingPool, b: HoldingPool) {
  const poolNameA = formatPoolName(a);
  const poolNameB = formatPoolName(b);
  return poolNameA.localeCompare(poolNameB);
}

function compareStringDecimals(
  a: string | undefined,
  b: string | undefined
): 1 | -1 | 0 {
  if (a === undefined && b === undefined) return 0;
  if (a === undefined) return 1;
  if (b === undefined) return -1;

  const decA = new Decimal(a);
  const decB = new Decimal(b);
  if (decA.equals(decB)) return 0;
  else return decA.lessThan(decB) ? 1 : -1;
}

function changeSortState(setState: Function, stage: SORT_STAGES): SORT_STAGES {
  let nextStage: SORT_STAGES;

  switch (stage) {
    case SORT_STAGES.BOTH:
      nextStage = SORT_STAGES.UP;
      break;
    case SORT_STAGES.UP:
      nextStage = SORT_STAGES.DOWN;
      break;
    case SORT_STAGES.DOWN:
      nextStage = SORT_STAGES.BOTH;
      break;
  }

  setState(nextStage);
  return nextStage;
}

/*
this one can be used to determine which image to show eg which sorting image
 */
function whichSort(
  stage: SORT_STAGES,
  activeSortProperty: SORT_PROPERTIES,
  sortProperty: SORT_PROPERTIES
): { image: string; tooltipText: string } {
  // if it is not active return the both image
  if (sortProperty !== activeSortProperty && stage !== SORT_STAGES.BOTH) {
    return { image: sortBoth, tooltipText: "Sort ascending" };
  }

  switch (stage) {
    case SORT_STAGES.BOTH:
      return { image: sortBoth, tooltipText: "Sort ascending" };
      break;
    case SORT_STAGES.UP:
      return { image: sortUp, tooltipText: "Sort descending" };
      break;
    case SORT_STAGES.DOWN:
      return { image: sortDown, tooltipText: "Sort initial" };
      break;
  }
}

export function sortPoolsOrTokens(
  unsortedPools: HoldingPool[] | HoldingToken[],
  direction: SORT_STAGES,
  property: SORT_PROPERTIES
) {
  if (property === SORT_PROPERTIES.DEFAULT || direction === SORT_STAGES.BOTH) {
    return unsortedPools;
  }

  if (property === SORT_PROPERTIES.BALANCE && direction === SORT_STAGES.UP) {
    return (unsortedPools as HoldingPool[]).sort(
      (a: HoldingPool, b: HoldingPool) =>
        compareStringDecimals(b.balance, a.balance)
    );
  }

  if (property === SORT_PROPERTIES.BALANCE && direction === SORT_STAGES.DOWN) {
    return (unsortedPools as HoldingPool[]).sort(
      (a: HoldingPool, b: HoldingPool) =>
        compareStringDecimals(a.balance, b.balance)
    );
  }

  if (
    property === SORT_PROPERTIES.POOL_NAME &&
    direction === SORT_STAGES.DOWN
  ) {
    return (unsortedPools as HoldingPool[]).sort(
      (a: HoldingPool, b: HoldingPool) => alphabeticPoolNameCompare(a, b)
    );
  }

  if (property === SORT_PROPERTIES.POOL_NAME && direction === SORT_STAGES.UP) {
    return (unsortedPools as HoldingPool[]).sort(
      (a: HoldingPool, b: HoldingPool) => alphabeticPoolNameCompare(b, a)
    );
  }

  if (
    property === SORT_PROPERTIES.MARKET_VALUE &&
    direction === SORT_STAGES.DOWN
  ) {
    return (unsortedPools as HoldingPool[]).sort(
      (a: HoldingPool, b: HoldingPool) =>
        compareStringDecimals(b.priceUSD, a.priceUSD)
    );
  }

  if (
    property === SORT_PROPERTIES.MARKET_VALUE &&
    direction === SORT_STAGES.UP
  ) {
    return (unsortedPools as HoldingPool[]).sort(
      (a: HoldingPool, b: HoldingPool) =>
        compareStringDecimals(a.priceUSD, b.priceUSD)
    );
  }

  if (
    property === SORT_PROPERTIES.USD_BALANCE &&
    direction === SORT_STAGES.DOWN
  ) {
    return (unsortedPools as HoldingToken[]).sort(
      (a: HoldingToken, b: HoldingToken) =>
        compareStringDecimals(b.balanceUsd, a.balanceUsd)
    );
  }

  if (
    property === SORT_PROPERTIES.USD_BALANCE &&
    direction === SORT_STAGES.UP
  ) {
    return (unsortedPools as HoldingToken[]).sort(
      (a: HoldingToken, b: HoldingToken) =>
        compareStringDecimals(a.balanceUsd, b.balanceUsd)
    );
  }

  return unsortedPools;
}

interface SorterProps {
  setActiveSortProperty: (property: SORT_PROPERTIES) => void;
  setSortState: Function;
  currentItems: HoldingPool[] | HoldingToken[] | null;
  setCurrentPools?: (pools: HoldingPool[]) => void;
  setCurrentTokens?: (tokens: HoldingToken[]) => void;
  currentSortStateOfProperty: SORT_STAGES;
  sortProperty: SORT_PROPERTIES;
  activeSortProperty: SORT_PROPERTIES;
}

/**
 * we only wish to show this sorter image if and only if there is data to be sorted eg not on empty rows,
 *
 * @param unsorted
 * @param property
 */
function shouldShowSortImage(
  currentItems: HoldingPool[] | HoldingToken[] | null
): boolean {
  if (currentItems === null) return false;
  return Boolean(currentItems.length > 1);
}

export const Sorter = ({
  setActiveSortProperty,
  setSortState,
  currentItems,
  setCurrentPools,
  setCurrentTokens,
  currentSortStateOfProperty,
  sortProperty,
  activeSortProperty,
}: SorterProps) => {
  let { image, tooltipText } = whichSort(
    currentSortStateOfProperty,
    activeSortProperty,
    sortProperty
  );

  const clickHandler = () => {
    const nextState = changeSortState(setSortState, currentSortStateOfProperty);

    setActiveSortProperty(sortProperty);

    if (currentItems === null) {
      return;
    }

    const sorted = sortPoolsOrTokens(currentItems, nextState, sortProperty);

    if (setCurrentPools) setCurrentPools(sorted as HoldingPool[]);
    if (setCurrentTokens) setCurrentTokens(sorted as HoldingToken[]);
  };

  return shouldShowSortImage(currentItems) ? (
    <Tooltip id="help-txn-speed" tooltipText={tooltipText}>
      <img
        onClick={clickHandler}
        src={image}
        alt="close"
        className="tw-inline tw-ml-1 tw-block tw-relative tw-z-50 tw-p-1 tw--m-1"
      />
    </Tooltip>
  ) : (
    <></>
  );
};
