import { Network } from "../configs";
import classNames from "classnames";
import React from "react";

interface NetworkTypePillProps {
  network: Network;
}

export function NetworkTypePill(props: NetworkTypePillProps) {
  return (
    <>
      {props.network === Network.ETH && (
        <span role="network-pill-eth" className={classNames("Pill", "Green")}>
          ETH
        </span>
      )}
      {props.network === Network.BSC && (
        <span role="network-pill-bsc" className={classNames("Pill", "Orange")}>
          BSC
        </span>
      )}
    </>
  );
}
