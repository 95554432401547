import { Fragment, ReactNode, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface ModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  widthClass: string;
  closeOnOutsideClick?: boolean;
  children: ReactNode;
}

const Modal = ({
  open,
  setOpen,
  widthClass = "6xl",
  closeOnOutsideClick = true,
  children,
}: ModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        className="tw-fixed tw-z-10 tw-inset-0 tw-overflow-y-auto"
        onClose={setOpen}
      >
        <div className="tw-flex tw-items-end tw-justify-center tw-min-h-screen tw-pt-4 tw-px-4 tw-pb-20 tw-text-center sm:tw-block sm:tw-p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity ${!closeOnOutsideClick ? "tw-pointer-events-none" : null
                }`}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="tw-hidden sm:tw-inline-block sm:tw-align-middle sm:tw-h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`tw-inline-block tw-align-bottom tw-bg-white tw-rounded-3xl tw-p-0 tw-text-left tw-overflow-hidden tw-shadow-xl tw-transform tw-transition-all sm:tw-my-8 sm:tw-align-middle sm:tw-max-w-sm sm:tw-w-full md:tw-max-w-${widthClass} md:tw-w-full`}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
