import { asAbi } from "./utils";
import Provider from "../Provider";
import agentManagerProxyABI from "../../abis/AgentManagerProxy.json";
import { anErr, anOk, Result } from "../../result";

export interface RegisterAgentForUserParams {
  agentUUID: string;
  userAddress: string;
  agentAddress: string;
  chainId: number;
  contractAddress: any;
  amount: string;
  gasPrice: string;
}

/**
 * I wanted to call it "proxy" but the name created many random IDE errors
 */
export class ProxyContract {
  public static async isAgentRegisteredForSpecificUser(
    userAddress: string,
    agentUUID: string,
    chainId: number,
    contractAddress: any
  ): Promise<Result<boolean>> {
    const proxyContract = new Provider._web3.eth.Contract(
      asAbi(agentManagerProxyABI),
      contractAddress
    );

    try {
      return anOk(
        await proxyContract.methods.userAgents(userAddress, agentUUID).call()
      );
    } catch (error) {
      return anErr(
        `Unable to determine if the agent is already registered`,
        error
      );
    }
  }

  public static async registerAgentForUser({
    agentUUID,
    userAddress,
    agentAddress,
    chainId,
    contractAddress,
    amount,
    gasPrice,
  }: RegisterAgentForUserParams): Promise<Result<undefined>> {
    const proxyContract = new Provider._web3.eth.Contract(
      asAbi(agentManagerProxyABI),
      contractAddress
    );

    try {
      await proxyContract.methods
        .registerAgentForUser(agentUUID, userAddress, agentAddress)
        .send({ from: userAddress, value: amount, gasPrice });

      return anOk(undefined);
    } catch (error) {
      return anErr("Error trying to register agent for user", error);
    }
  }
}
