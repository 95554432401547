import React, { useContext, useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Decimal from "decimal.js";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { getNetworkDetails, Network } from "../../configs";
import { isOk } from "../../result";
import { FactoryContract } from "../../services/contracts/Factory";
import { PairContract } from "../../services/contracts/PairContract";
import { Pair } from "../../state/pairCache";
import { StateContext } from "../../state/state";
import {
  SwapToken,
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerType,
} from "../../state/triggers";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { Validation } from "../../validation";
import TooltipBox from "../../utils/tooltip/tooltip";
import style from "./SelectTokenAndPool.module.scss";
import { TokenInputDropdown } from "./TokenInputDropdown";
import { BNB_ID, enumToString, ETH_ID, NATIVE_TOKEN_ADDRESS } from "../../api/common";
import { Chain } from "../../state/assets";

const holdingTokens: SwapToken[] = []; // GET from upper state

export enum InvalidReason {
  ALREADY_REGISTERED,
  NO_BALANCE,
  NETWORK_ERROR,
  INVALID_ADDRESS,
  INVALID_POOL,
  POOL_NOT_v2,
  UNSUPPORTED_NETWORK,
  POOL_NOT_ON_NETWORK,
  POOL_NOT_PANCAKE_SWAP,
  UNABLE_TO_FETCH_PAIR,
  TWO_TRIGGERS_ON_POOL,
  INVALID_TOKEN_ADDRESS,
  NO_TOKENS,
  ZERO_PAIR_PRICE,
}

function getPoolValidationMessage(
  validation: InputValidation
): string | undefined {
  if (validation.validity !== Validation.INVALID) {
    return;
  }

  switch (validation.reason) {
    case InvalidReason.INVALID_POOL:
      return "There is no pair for selected tokens. Indirect swaps are not supported at the moment";
    case InvalidReason.UNABLE_TO_FETCH_PAIR:
      return "Not able to fetch pair, please close and try again";
    case InvalidReason.TWO_TRIGGERS_ON_POOL:
      return "You already have two triggers for the above from token - to token combination";
    case InvalidReason.UNSUPPORTED_NETWORK:
      return "Network or chain connection error, please refresh and try again";
    default:
      return "Unknown error";
  }
}

export interface InputValidation {
  validity: Validation;
  reason?: InvalidReason;
}

export enum SwapInputType {
  FROM,
  TO,
}

interface SelectTokenAndPoolProps {
  allTokens: SwapToken[];
  setAllTokens: (val: SwapToken[]) => void;
  holdingTokens: SwapToken[];
  approveAmount: string;
  setApproveAmount: (val: string) => void;
  selectedFromToken: SwapToken | undefined;
  setSelectedFromToken: (val: SwapToken | undefined) => void;
  balance: string;
  setBalance: (val: string) => void;
  pair: Pair | undefined;
  setPair: (val: Pair | undefined) => void;
  selectedToToken: SwapToken | undefined;
  setSelectedToToken: (val: SwapToken | undefined) => void;
  setUniqueTokenPoolCombination: (val: boolean) => void;
  setTokenIndex: (val: TriggerChoices | undefined) => void;
  preSelectedToken: SwapToken | undefined;
}

export const SelectTokenAndPool = ({
  allTokens,
  setAllTokens,
  holdingTokens,
  approveAmount,
  setApproveAmount,
  selectedFromToken,
  setSelectedFromToken,
  balance,
  setBalance,
  pair,
  setPair,
  selectedToToken,
  setSelectedToToken,
  setUniqueTokenPoolCombination,
  setTokenIndex,
  preSelectedToken,
}: SelectTokenAndPoolProps) => {
  const [fromTokenList, setFromTokenList] = useState<SwapToken[]>([]);
  const [toTokenList, setToTokenList] = useState<SwapToken[]>([]);

  const [filteredFromHoldingTokens, setFilteredFromHoldingTokens] = useState<
    SwapToken[]
  >([]);
  const [filteredToHoldingTokens, setFilteredToHoldingTokens] = useState<
    SwapToken[]
  >([]);

  const [fromTokenInputText, setFromTokenInputText] = useState<string>("");
  const [toTokenInputText, setToTokenInputText] = useState<string>("");

  const [isFromInputFocus, setIsFromInputFocus] = useState<boolean>(false);
  const [isToInputFocus, setIsToInputFocus] = useState<boolean>(false);

  const [isAddressPasted, setIsAddressPasted] = useState<boolean>(false);
  const [amountError, setAmountError] = useState("");
  const [queryingPool, setQueryingPool] = useState<boolean>(false);
  const [poolValidation, setPoolValidation] = useState<InputValidation>({
    validity: Validation.UNKNOWN,
  });

  const state = useContext(StateContext);

  const updateAmount = (amount: string) => {
    setAmountError("");
    if (amount === "") {
      setApproveAmount(amount);
      return;
    }
    const balanceBN = new Decimal(balance);
    if (new Decimal(amount).lte(new Decimal(0))) {
      setAmountError("Invalid approve amount");
    }
    if (new Decimal(amount).gt(balanceBN)) {
      setAmountError("Invalid approve amount");
      return;
    }
    // const maxDecimalPlaces = maxDecimals();
    if (!selectedFromToken) {
      return;
    }
    const maxDecimalPlaces = selectedFromToken.decimal;
    const decimalPlaces = new Decimal(amount).decimalPlaces();
    // Not allow to enter decimal part more than 18 digit
    if (decimalPlaces > Number(maxDecimalPlaces)) {
      setAmountError(
        `Decimal places should not be greater than ${maxDecimalPlaces} decimal limit`
      );
      return;
    }
    setApproveAmount(amount);
  };

  const getTokenIndex = (fromToken: string, selectedPair: Pair) => {
    return selectedPair.token0Address.toUpperCase() === fromToken.toUpperCase()
      ? 0
      : 1;
  };

  const getArmedTriggersForTokenAndPair = (
    fromToken: string,
    selectedPair: Pair
  ): number => {
    const triggers = state.triggers.list();
    return triggers.filter((trigger: Trigger) => {
      const swap_token =
        trigger.swap_token !== undefined ? trigger.swap_token : trigger.token;
      return Boolean(
        trigger.pair.address.toUpperCase() ===
        selectedPair.address.toUpperCase() &&
        getTokenIndex(fromToken, selectedPair) === swap_token &&
        ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
          trigger.state
        ) &&
        trigger.triggerType === TriggerType.SWAP
      );
    }).length;
  };

  const hasBalance = (balance: string) => {
    if (!balance || new Decimal(balance).lte(new Decimal("0"))) {
      return false;
    }
    return true;
  };

  const fetchPair = async (fromToken: string, toToken: string) => {
    setPair(undefined);
    setPoolValidation({ validity: Validation.UNKNOWN });

    if (state.web3.networkId === Network.UNKNOWN) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }

    setQueryingPool(true);

    const { chainId } = state.web3;
    if (!chainId) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }
    const networkDetails = getNetworkDetails(chainId);
    let wrappedContractAddress;
    if (isOk(networkDetails)) {
      wrappedContractAddress = networkDetails.value.wrappedNativeTokenAddress;
    }
    if (fromToken === NATIVE_TOKEN_ADDRESS && wrappedContractAddress) {
      fromToken = wrappedContractAddress;
    }
    const pairAddress = await FactoryContract.fetchPair(
      fromToken,
      toToken,
      chainId
    );

    if (pairAddress.success) {
      if (pairAddress.value === "0x0000000000000000000000000000000000000000") {
        setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.INVALID_POOL,
        });
        setQueryingPool(false);
        return;
      }

      const poolInformation = await PairContract.fetchPoolInformation(
        pairAddress.value,
        state.web3.networkId,
        chainId
      );

      if (poolInformation.success) {
        const tokenIndex = getTokenIndex(fromToken, poolInformation.value);
        setTokenIndex(tokenIndex);
        setPair(poolInformation.value);
        if (
          getArmedTriggersForTokenAndPair(fromToken, poolInformation.value) >= 2
        ) {
          setPoolValidation({
            validity: Validation.INVALID,
            reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
          });
          setUniqueTokenPoolCombination(true);
        } else {
          setUniqueTokenPoolCombination(false);
        }
      }
      setQueryingPool(false);
    } else {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNABLE_TO_FETCH_PAIR,
      });
      setQueryingPool(false);
    }
  };

  const filterAllTokens = (fromTokenAddress: string) => {
    return state.assets.allTokens
      .filter(
        (token) =>
          token.chain === enumToString(Chain, state.web3.networkId) &&
          token.address.toUpperCase() !== fromTokenAddress.toUpperCase() &&
          token.address !== NATIVE_TOKEN_ADDRESS
      )
      .map((token) => ({
        address: token.address,
        symbol: token.symbol.toUpperCase(),
        chain: token.chain,
        users: 0,
        isNative: token.id === ETH_ID || token.id === BNB_ID,
      }));
  };

  const onFromTokenSelect = (selectedToken: SwapToken) => {
    setApproveAmount("");
    setSelectedToToken(undefined);
    setToTokenInputText("");
    const filtredHoldings = holdingTokens.filter(
      (token) =>
        token.address.toUpperCase() !== selectedToken.address.toUpperCase()
    );
    const allTokenList = filterAllTokens(selectedToken.address);
    setAllTokens(allTokenList);
    setToTokenList([...filtredHoldings, ...allTokenList]);
    setFilteredToHoldingTokens([...filtredHoldings, ...allTokenList]);
  };

  useEffect(() => {
    setFromTokenList(holdingTokens);
    setFilteredFromHoldingTokens(holdingTokens);
    if (preSelectedToken) {
      setSelectedFromToken(preSelectedToken);
      setFromTokenInputText(preSelectedToken.symbol);
      onFromTokenSelect(preSelectedToken);
    }
    if (selectedFromToken) {
      setFromTokenInputText(selectedFromToken.symbol);
      onFromTokenSelect(selectedFromToken);
    }
    if (selectedToToken) {
      setToTokenInputText(selectedToToken.symbol);
    }
  }, []);

  return (
    <Form.Group>
      <Form.Label className={style.ctrlLabelTextColor}>From</Form.Label>
      {
        <TokenInputDropdown
          tokenList={fromTokenList}
          setTokenList={setFromTokenList}
          selectedToken={selectedFromToken}
          setSelectedToken={setSelectedFromToken}
          filteredHoldingTokens={filteredFromHoldingTokens}
          setFilteredHoldingTokens={setFilteredFromHoldingTokens}
          allHoldingToken={holdingTokens}
          balance={balance}
          setBalance={setBalance}
          noBalanceCheck={false}
          onTokenSelect={onFromTokenSelect}
          inputText={fromTokenInputText}
          setInputText={setFromTokenInputText}
          isTokenFocus={isFromInputFocus}
          setIsTokenFocus={setIsFromInputFocus}
          setSearchAddress={setIsAddressPasted}
          isAddressPasted={isAddressPasted}
          queryingPool={false}
          name={SwapInputType.FROM}
        />
      }
      {selectedFromToken && selectedFromToken.balance && (
        <p className="tw-text-sm tw-text-green-500">
          <TooltipBox
            child={`Balance: ${formatBigNumber(selectedFromToken.balance).trunc
              } ${selectedFromToken.symbol}`}
            tooltipTextClassName={"tool-tip-balance"}
            text={`${selectedFromToken.balance} ${selectedFromToken.symbol}`}
          />
        </p>
      )}

      {
        <>
          {selectedFromToken && (
            <>
              <Form.Label className={style.ctrlLabelTextColor}>To</Form.Label>
              <TokenInputDropdown
                tokenList={toTokenList}
                setTokenList={setToTokenList}
                selectedToken={selectedToToken}
                setSelectedToken={setSelectedToToken}
                filteredHoldingTokens={filteredToHoldingTokens}
                setFilteredHoldingTokens={setFilteredToHoldingTokens}
                allHoldingToken={holdingTokens}
                balance={""}
                setBalance={(val: string) => { }}
                noBalanceCheck={false}
                onTokenSelect={(token: SwapToken) => {
                  fetchPair(selectedFromToken.address, token.address);
                }}
                inputText={toTokenInputText}
                setInputText={setToTokenInputText}
                isTokenFocus={isToInputFocus}
                setIsTokenFocus={setIsToInputFocus}
                setSearchAddress={setIsAddressPasted}
                isAddressPasted={isAddressPasted}
                queryingPool={queryingPool}
                name={SwapInputType.TO}
              />

              <p className={style.poolValidation}>
                {getPoolValidationMessage(poolValidation)}
              </p>

              {/* Approve amount section */}
              <Form.Label className={style.ctrlLabelTextColor}>
                Amount to approve for agent based swap
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">
                      {`The amount of ${selectedFromToken.symbol} that you want to be swapped.
                      Since you are the owner of your ${selectedFromToken.symbol} even after approval,
                      in case your actual ${selectedFromToken.symbol} balance eventually reduces from the approved amount you configure
                      then the whole of your ${selectedFromToken.symbol} balance will be swapped when the trigger executes.`}
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className={style.icon}
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Group style={{ position: "relative" }}>
                <Form.Control
                  type="number"
                  placeholder="0.0"
                  value={approveAmount}
                  onChange={(e) => updateAmount(e.target.value)}
                  className={`${style.input} ${amountError !== "" ? style.errorInput : null
                    }`}
                  disabled={!hasBalance(balance)}
                  isInvalid={amountError !== ""}
                />
                {hasBalance(balance) && (
                  <div
                    className={classNames(
                      style.maxButton,
                      amountError !== "" ? style.invalid : null
                    )}
                    onClick={() => setApproveAmount(balance)}
                  >
                    MAX
                  </div>
                )}
              </Form.Group>
              {amountError && (
                <div className="tw-text-red-600">{amountError}</div>
              )}
            </>
          )}
        </>
      }
    </Form.Group>
  );
};
