import BN from "bn.js";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { AgentsAPI } from "../api/AgentsAPI";
import {
  AgentFundsModal,
  AgentFundsModalProps,
  MODAL_TYPE,
} from "../components/AgentFundsModal/AgentFundsModal";
import { AgentFundStatus, AgentRow } from "../components/AgentTable/AgentRow";
import { EmptyAgentRow } from "../components/AgentTable/EmptyAgentRow";
import { SyncingTableBody } from "../components/AgentTable/SyncingTableBody";
import { DeleteAgentModal } from "../components/DeleteAgentModal/DeleteAgentModal";
import { NewAgentModal } from "../components/NewAgentModal";
import { Network } from "../configs";
import { Costing } from "../services/Costing";
import { AccountDetails } from "../state/account";
import { Agent, AgentType } from "../state/agents";
import { StateStore } from "../state/state";
import { isMobile } from "../utils/display/isMobile";
import { isCurrentAgent } from "../utils/misc/isCurrentAgent";
import { eventNameAnalysis } from "../utils/misc/trackEvent";

export type ErrorType = "warning" | "success";

interface AgentsPageProps {
  state: StateStore;
}

export interface Error {
  type: ErrorType;
  message: string;
}

function absoluteDecimalString(decimalString: string): string {
  return new BN(decimalString).abs().toString();
}

/**
 * Order agent list so that those that are the currently selected appear at the top of the list.
 */
function orderAgentsByCurrentAddress(
  web3Address: string | undefined,
  a: Agent,
  b: Agent
): 0 | 1 | -1 {
  const aCurrent = isCurrentAgent(web3Address, a);
  const bCurrent = isCurrentAgent(web3Address, b);

  if (aCurrent && !bCurrent) {
    return -1;
  }

  if (!aCurrent && bCurrent) {
    return 1;
  }

  return 0;
}

function orderAgentsByCurrentNetwork(
  network: Network,
  a: Agent,
  b: Agent
): 0 | 1 | -1 {
  const aNetwork = network === a.network;
  const bNetwork = network === b.network;

  if (aNetwork && !bNetwork) {
    return -1;
  }
  if (!aNetwork && bNetwork) {
    return 1;
  }
  return 0;
}

function orderAgents(
  network: Network,
  address: string | undefined,
  a: Agent,
  b: Agent
): 0 | 1 | -1 {
  const result = orderAgentsByCurrentNetwork(network, a, b);
  if (result !== 0) {
    return result;
  }

  return orderAgentsByCurrentAddress(address, a, b);
}

export const AgentsPage = observer((props: AgentsPageProps) => {
  const [newAgent, setNewAgent] = useState<boolean>(false);
  const [showDeleteAgentModal, setShowDeleteAgentModal] =
    useState<boolean>(false);
  const [agentToDelete, setAgentToDelete] = useState<Agent | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<Error | undefined>();
  const [showCollapsible, setShowCollapsible] = useState<string | undefined>(
    undefined
  );
  const [pending, setPending] = useState<boolean>(false);
  const [agentFundsModalProps, setAgentFundsModalProps] = useState<
    AgentFundsModalProps | undefined
  >(undefined);

  const { state } = props;

  const mobile: boolean = isMobile.any();

  const orderUserAgents = (a: Agent, b: Agent) =>
    orderAgents(state.web3.networkId, state.web3.address, a, b);
  const agentsList = state.agents.list().sort(orderUserAgents);

  const onNew = () => {
    setNewAgent(true);
  };

  const onCancel = () => {
    setNewAgent(false);
    setErrorMessage(undefined);
    setPending(false);
  };

  const agentExcessFunds = (agentUuid: string): string | null => {
    const requiredFunds = state.triggers.agentTriggersRequiredFunds(agentUuid);
    const agent = state.agents.get(agentUuid);
    if (agent === undefined || agent.balance === undefined) return null;

    return new BN(agent.balance).sub(new BN(requiredFunds)).toString();
  };

  function toolTipMessage(): string {
    if (!state.web3.isReady()) return "Web3 not ready";

    if (!state?.account?.accountDetails?.agentLimit)
      return "no available agents";

    if (Costing.hasServiceExpired(state)) return "service expired";

    if (state.web3.networkId === Network.UNKNOWN) return "unknown network";

    // this is a default that should not be triggered unless the logic for disabling of button is not the same
    // as the logic for this message. if it is shown at all it represents a bug.
    return "Click here to create a new Agent";
  }

  function hasExcessFunds(agentUuid: string): boolean {
    const res = agentExcessFunds(agentUuid);

    if (res === null) return false;
    if (new BN(res).gt(new BN("0"))) {
      return true;
    }
    return false;
  }

  function hasInsufficientFunds(agentUuid: string): boolean {
    const res = agentExcessFunds(agentUuid);

    if (res === null) return false;
    if (new BN(res).lt(new BN("0"))) {
      return true;
    }
    return false;
  }

  const onSubmit = async (type: AgentType) => {
    setPending(true);
    const token = state.account.user?.token;
    if (token === undefined) {
      setErrorMessage({
        type: "warning",
        message: "Unable to create new agent",
      });
      setPending(false);
      return;
    }

    const { address } = state.web3;

    if (typeof address === "undefined") {
      setErrorMessage({
        type: "warning",
        message: "No Address selected in MetaMask",
      });
      setPending(false);
      return;
    }

    if (typeof state.web3.chainId === "undefined") {
      setErrorMessage({
        type: "warning",
        message: "select Network in MetaMask",
      });
      setPending(false);
      return;
    }

    try {
      const newAgent = await AgentsAPI.createAgent(
        token,
        address,
        type,
        state.web3.networkId
      );
      state.agents.add(newAgent);
      setErrorMessage(undefined);
      setPending(false);

      eventNameAnalysis("agent_created", { type: "stop-loss" });
    } catch (err) {
      setErrorMessage({
        type: "warning",
        message: "Unable to create users new agent",
      });
      setPending(false);
    }

    setNewAgent(false);
  };

  const onDelete = async (agent: Agent) => {
    if (state.account.user?.token === undefined) {
      return;
    }

    // todo no feedback about failure to delete
    if (state.web3.address === undefined) {
      return;
    }

    if (state.triggers.agentHasNonCompletedTriggers(agent.uuid)) {
      setErrorMessage({
        type: "warning",
        message:
          "Unable to delete selected agent it currently has non completed triggers associated with it",
      });
      setPending(false);
      return;
    }

    setAgentToDelete(agent);
    setShowDeleteAgentModal(true);
  };

  useEffect(() => {
    if (errorMessage === undefined) {
      return;
    }

    const timeout = setTimeout(() => {
      setErrorMessage(undefined); // clear the error message
      setPending(false);
    }, 6000);
    return () => {
      clearTimeout(timeout);
    };
  }, [errorMessage]);

  let tableContents;
  if (state.agents.syncing) {
    tableContents = <SyncingTableBody />;
  } else {
    tableContents = (
      <tbody>
        {agentsList.length === 0 && <EmptyAgentRow isMobile={mobile} />}
        {agentsList.map((agent: Agent, idx: number) => {
          const excessFunds: string | null = agentExcessFunds(agent.uuid);
          const agentTriggers = state.triggers.agentTriggers(agent.uuid);

          // determine the status of the funds
          let agentFundStatus: AgentFundStatus;
          if (hasExcessFunds(agent.uuid)) {
            agentFundStatus = "surplus";
          } else if (hasInsufficientFunds(agent.uuid)) {
            agentFundStatus = "shortage";
          } else {
            agentFundStatus = "correct";
          }

          return (
            <AgentRow
              key={idx}
              state={state}
              network={state.web3.networkId}
              agent={agent}
              agentTriggers={agentTriggers}
              isMobile={mobile}
              expand={showCollapsible === agent.uuid && !agent.deletePending}
              isOwner={agent.userAddress === state.web3.address}
              agentFundStatus={agentFundStatus}
              agentFundOutstandingAmount={
                excessFunds === null ? "0" : excessFunds
              }
              onToggleExpand={() => {
                // if we are not showing a collapsable then we show one, if we are showing one and the button is shown we hide it.
                if (showCollapsible === agent.uuid) {
                  setShowCollapsible(undefined);
                } else {
                  setShowCollapsible(agent.uuid);
                }
              }}
              onDelete={() => onDelete(agent)}
              onTopUp={() => {
                setAgentFundsModalProps({
                  agentUUID: agent.uuid,
                  type: MODAL_TYPE.TOPUP,
                  agentAddress: agent.agentAddress,
                  amount: absoluteDecimalString(excessFunds as string),
                  onCancel: () => {
                    setAgentFundsModalProps({
                      ...(agentFundsModalProps as AgentFundsModalProps),
                      show: false,
                    });
                  },
                  pending: false,
                  web3LoggedIn: false,
                  show: true,
                });
              }}
              onWithdraw={() => {
                setAgentFundsModalProps({
                  agentUUID: agent.uuid,
                  type: MODAL_TYPE.WITHDRAW,
                  agentAddress: agent.agentAddress,
                  amount: absoluteDecimalString(excessFunds as string),
                  onCancel: () => {
                    setAgentFundsModalProps({
                      ...(agentFundsModalProps as AgentFundsModalProps),
                      show: false,
                    });
                  },
                  pending: false,
                  web3LoggedIn: false,
                  show: true,
                });
              }}
              setErrorMessage={setErrorMessage}
            />
          );
        })}
      </tbody>
    );
  }

  return (
    <>
      <div>
        <div className="tw-max-w-full tw-px-4 sm:tw-px-6 md:tw-px-8">
          <div className="tw-m-auto tw-w-full tw-h-full tw-overflow-y-auto">
            <div className="tw-text-coolGray-90 tw-text-center tw-mb-2.5 tw-flex tw-flex-row tw-justify-between tw-items-center ">
              <h1 className="tw-text-5xl tw-leading-9 tw-mb-8 tw-ml-1 tw-font-Inter tw-font-bold">
                Agents
              </h1>

              {/* <Tooltip
          child={
            <Button
              data-testid="show-new-agent-modal"
              className="createButton"
              onClick={onNew}
              variant="primary"
              disabled={
                state.web3.networkId === Network.UNKNOWN ||
                !state?.account?.accountDetails?.agentLimit ||
                Costing.hasServiceExpired(state)
              }
            >
              <img
                src={plus}
                className={style.plusIcon}
                style={{ marginRight: 5, marginBottom: 3 }}
                alt="plus"
              />
              New Agent
            </Button>
          }
          tooltipTextClassName="tool-tip-trigger-button"
          textClassName={style.btnContainer}
          text={toolTipMessage()}
        /> */}
            </div>
            {errorMessage && (
              <Alert variant={errorMessage.type}>{errorMessage.message}</Alert>
            )}
            <div className="tw-overflow-x-auto tw-overflow-y-hidden">
              <table className="tw-bg-white tw-min-w-full tw-shadow-md tw-rounded-3xl tw-overflow-hidden tw-mb-12">
                <thead>
                  <tr className="tw-bg-coolGray-200">
                    <th className="tw-not-italic tw-font-medium tw-text-base tw-h-12 tw-text-center tw-leading-5 tw-py-2.5 tw-pr-3.5 tw-text-coolGray-900">
                      <span>Agent Name</span>
                    </th>
                    <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                      Type
                    </th>
                    <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                      Created
                    </th>
                    <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                      Balance
                    </th>
                    <th className="tw-px-3.5 tw-text-center tw-items-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                        Actions
                      </th>
                  </tr>
                </thead>
                {tableContents}
              </table>
            </div>
            {state.account?.accountDetails?.agentLimit &&
              state.account.accountDetails.agentLimit > 0 && (
                <NewAgentModal
                  show={newAgent}
                  numAgents={state.agents.length}
                  maxAgents={
                    (state.account.accountDetails as AccountDetails).agentLimit
                  }
                  web3Installed={state.web3.installed}
                  web3LoggedIn={state.web3.loggedIn}
                  web3Address={state.web3.address ?? ""}
                  balance={state.account.accountDetails?.balance ?? "0"}
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                  pending={pending}
                  estimatedMonthlyCost={Costing.estimatedMonthlyCostOfAgentsDisplay(
                    state.account.planDetails
                  )}
                  chainId={state.web3.chainId}
                />
              )}
            {agentFundsModalProps !== undefined && (
              <AgentFundsModal
                type={agentFundsModalProps.type}
                show={agentFundsModalProps.show}
                amount={agentFundsModalProps.amount}
                agentAddress={agentFundsModalProps.agentAddress}
                web3LoggedIn
                onCancel={agentFundsModalProps.onCancel}
                pending={agentFundsModalProps.pending}
                agentUUID={agentFundsModalProps.agentUUID}
              />
            )}
            {agentToDelete !== undefined && (
              <DeleteAgentModal
                agent={agentToDelete}
                show={showDeleteAgentModal}
                onCancel={() => setShowDeleteAgentModal(false)}
                web3LoggedIn={state.web3.loggedIn}
                state={state}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
});
