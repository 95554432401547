import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";

import { clearState, fastLaneStateSync } from "../../api/Sync";
import { AuthenticationAPI } from "../../api/AuthenticationAPI";
import { ENABLE_SIGNUP } from "../../configs";
import { isErr } from "../../result";
import { eventNameAnalysis } from "../../utils/misc/trackEvent";
import { AgentStore } from "../../state/agents";
import { TriggerStore } from "../../state/triggers";
import { DashboardStore } from "../../state/dashboard";
import { Eth } from "../../state/eth";
import { FeatureFlags } from "../../state/features";
import { StateContext } from "../../state/state";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import Provider from "../../services/Provider";
import { ConnectButton } from "./connectButton";
import { Cards } from "../dashboard/Dashboard.data";
import { isNavigatorOnline } from "../../utils/networkRequests/isNavigatorOnline";
import { isOnline } from "../../utils/networkRequests/isOnline";
import { ToastModal } from "../../components/ToastModal";
import {PairCache} from "../../state/pairCache";

const ERROR_MESSAGE = "an unexpected error occured";
const ERROR_MESSAGE_DISABLE_METAMASK =
  "To connect with Torus disable Metamask extension";
const OFFLINE_MESSAGE = "offline";
const SIX_SECONDS_IN_MILLISECONDS = 6000;

const formatIfErrorMessageDenied = (s: string) => {
  if (s.includes("User denied message signature")) {
    return "";
  }
  return s;
};

const deleteWindowClosedErrorMessage = (s: string) => {
  if (s.toLowerCase().includes("window closed")) {
    return "";
  }
  return s;
};

const deleteUserClosedErrorMessage = (s: string) => {
  if (s.toLowerCase().includes("user cancelled login")) {
    return "";
  }
  return s;
};

export const IntroPage = observer(() => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showToastModal, setShowToastModal] = useState<boolean>(false);

  const state = useContext(StateContext);

  /**
   * Torus login error occurs when user tries to login using Torus and Metamask is running in the browser. To counteract this we tell them to turn off
   * metamask and retry.
   *
   * @param provider
   */
  const hasTorusLoginError = async (provider: any): Promise<boolean> => {
    const metaMaskAvailable = await Provider.isMetaMaskInstalled();

    if (!isProviderTorus(provider)) {
      return false;
    }

    if (metaMaskAvailable) {
      return true;
    }

    return false;
  };

  const closeToastModal = () => {
    setShowToastModal(false);
  };

  const setCloseModalOnDelay = () => {
    setTimeout(closeToastModal, SIX_SECONDS_IN_MILLISECONDS);
  };

  const openToastModal = () => {
    setShowToastModal(true);
    setCloseModalOnDelay();
  };

  function replaceSelectedErrorMessages(errorMessage: string): string {
    errorMessage = formatIfErrorMessageDenied(errorMessage);
    errorMessage = deleteWindowClosedErrorMessage(errorMessage);
    errorMessage = deleteUserClosedErrorMessage(errorMessage);
    return errorMessage;
  }

  const handleClickWrapper = async () => {
    setErrorMessage("");

    if (!isNavigatorOnline()) {
      setErrorMessage(OFFLINE_MESSAGE);
      openToastModal();
      setInProgress(false);
      return;
    }

    let error = false;
    let loggedIn = false;
    setInProgress(true);

    if (!(await isOnline())) {
      setErrorMessage(OFFLINE_MESSAGE);
      openToastModal();
      setInProgress(false);
      return;
    }

    try {
      loggedIn = await loginLogic();
    } catch (e: any) {
      let modifiedErrorMessage = replaceSelectedErrorMessages(e.message)

      //lets not show toast modal when error is empty
      if(modifiedErrorMessage == "")
      {
       return
      }

      setErrorMessage(modifiedErrorMessage);
      openToastModal();
    } finally {
      if (loggedIn) {
        setErrorMessage("");
        setShowToastModal(false);
      }
      setInProgress(false);
    }
  };

  const isProviderTorus = (provider: any): boolean => {
    return Boolean(
      provider &&
        provider.hasOwnProperty("isTorus") &&
        provider.isTorus === true
    );
  };

  /**
   * wierd signature whereby failing to login is if a) false is returned or b) it throws. If true is returned then login was succesful.
   *
   */
  const loginLogic = async (): Promise<boolean> => {
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      disableInjectedProvider: false,
      providerOptions: Provider.getProviderOptions(), // required
    });
    const provider = await web3Modal.connect();
    const previouslyLoggedInAddress = state.web3.address;
    const library = new ethers.providers.Web3Provider(provider);
    if (!library) {
      setErrorMessage(ERROR_MESSAGE);
      openToastModal();
      return false;
    }

    await Provider.init(provider);

    const hasTorusLoginErrorRes = await hasTorusLoginError(provider);

    if (hasTorusLoginErrorRes) {
      setErrorMessage(ERROR_MESSAGE_DISABLE_METAMASK);
      openToastModal();
      return false;
    }

    ``;
    const accounts = await library.listAccounts();

    if (!accounts) {
      setErrorMessage(ERROR_MESSAGE);
      openToastModal();
      return false;
    }

    let account = accounts[0];

    await state.web3.setLoggedIn(true, clearState.bind(null, state));

    if (state.web3.address == undefined) {
      setErrorMessage(ERROR_MESSAGE);
      openToastModal();
      return false;
    }
    const { address } = state.web3;
    // sometimes when Torus provider is running it returns the address from coinbase via its provider method and yet a different address when coinbase app is running in background and so
    // if this happens user needs to disable coinbase application, and can be prompted to do so.
    if (account !== address) {
      setErrorMessage(
        "address mismatch: try disabling any extensions that may be interfering with this application"
      );
      return false;
    }

    const challenge = await AuthenticationAPI.web3RequestAccountChallenge(
      address,
      ENABLE_SIGNUP
    );

    if (isErr(challenge)) {
      setErrorMessage("Error: network error");
      openToastModal();
      return false;
    }

    if (!library || !library.provider.request) {
      setErrorMessage(ERROR_MESSAGE);
      openToastModal();
      return false;
    }

    const signature = await library.provider.request({
      method: "personal_sign",
      params: [challenge.value, account],
    });

    const token = await AuthenticationAPI.web3SubmitAccountSignature(
      address,
      signature
    );
    if (isErr(token)) {
      return false;
    }

    // capture the analytics
    eventNameAnalysis("login", {});
    // so we don't flash old data upon login if account different to previous we clear state first on relogin
    if (
      previouslyLoggedInAddress !== undefined &&
      previouslyLoggedInAddress === address
    ) {
      state.agents = new AgentStore(state.localStorage);
      state.triggers = new TriggerStore(state.localStorage);
      state.pairCache = new PairCache();
      state.dashboard = new DashboardStore();
      state.eth = new Eth();
      state.features = new FeatureFlags();
    }

    // login with the address and token
    state.account.login(address, token.value);
    await fastLaneStateSync(state); // upon successful login sync the state with the server
    return true;
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center">
      <ToastModal
        message={errorMessage}
        show={showToastModal}
        close={closeToastModal}
      ></ToastModal>
      <div>
        <div className="tw-pt-16 tw-pb-12">{/* <Header /> */}</div>
        <div className="tw-max-w-5xl tw-mx-auto">
          <h2 className="tw-text-7xl tw-font-bold tw-text-coolGray-900 tw-text-center">
            Welcome to <span className="tw-text-green-300">BotSwap</span>
          </h2>
          <p className="tw-max-w-3xl tw-mx-auto tw-my-7 tw-px-3 tw-text-3xl tw-font-normal tw-leading-10">
            Automate your DeFi and DEX trading strategy using simple to setup
            and maintain crypto trading tools
          </p>
          <ConnectButton
            onClick={handleClickWrapper}
            inProgress={inProgress}
          ></ConnectButton>
        </div>
      </div>
      <div className="tw-w-full tw-max-w-10xl tw-px-8">
        <div className="tw-bg-coolGray-900 tw-rounded-3xl tw-my-16 tw-p-9 tw-w-full tw-flex tw-justify-around tw-flex-wrap">
          {Cards.map((card) => (
            <div key={card.id}>
              <div className="tw-text-3xl tw-font-bold tw-text-white tw-text-center tw-h-24">
                {card.title}
              </div>
              <div>
                <img src={card.img} alt="triggers" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
