import React, {useEffect, useState} from "react";
import {Decimal} from "decimal.js";
import {TokenDropdown} from "../../../components/TokenDropdown";
import {isNumeric} from "../../../utils/numeric/isNumeric";
import {hasTooManyDecimalPlaces} from "../../../utils/numeric/hasTooManyDecimalPlaces";
import {CryptoToken, HoldingToken} from "../../../state/assets";
import {SwapTokenWrapper} from "./index";
import {amountTooltip, balanceTooltip, calcAmount} from "./utils";


interface FromTokenBoxProps {
  fromToken: HoldingToken | undefined;
  toToken: CryptoToken | undefined;
  setFromToken: Function;
  tokenList: HoldingToken[];
  fromAmount: string | undefined;
  setFromAmount: (s: string) => void;
  setToAmount: (s: string) => void;
  setInputToTokenError: (s: boolean) => void;
  setDecimalsToTokenExceeded: (s: boolean) => void;
  decimalsExceeded: boolean;
  setDecimalsExceeded: Function;
  inputError: boolean;
  setInputError: Function;
}

export const FromTokenBox = ({
                                 fromToken,
                                 toToken,
                                 tokenList,
                                 fromAmount,
                                 setFromAmount,
                                 setToAmount,
                                 setInputToTokenError,
                                 setDecimalsToTokenExceeded,
                                 setFromToken,
                                 decimalsExceeded,
                                 setDecimalsExceeded,
                                 inputError,
                                 setInputError,
                             }: FromTokenBoxProps) => {
    const [fromTokenUSD, setFromTokenUSD] = useState<string>("");
    useEffect(() => {
        if (fromToken) {
            const price = parseFloat(fromToken.price);
            const calcResult = !fromAmount
                ? ""
                : new Decimal(fromAmount).mul(new Decimal(price)).toString();
            setFromTokenUSD(calcResult);
        }
    }, [fromAmount, fromToken]);

    return (
        <SwapTokenWrapper title="From">
            <div className="tw-px-12 tw-py-4 tw-bg-white tw-flex-1 tw-flex tw-items-center">
                <div className="tw-flex tw-flex-col tw-rounded-2xl tw-w-full">
                    {fromToken && (
                        <div className="tw-flex tw-justify-end">
                            Current balance:&nbsp;
                            {balanceTooltip("from-amount", fromToken.balance)} &nbsp;
                            {fromToken.symbol.toUpperCase()}
                            <span
                                className="tw-bg-green-300 tw-rounded tw-text-coolGray-900 tw-text-xs tw-font-medium tw-px-3 tw-py-1 tw-ml-7 tw-cursor-pointer"
                                onClick={() => {
                                    setInputToTokenError(false);
                                    setDecimalsToTokenExceeded(false);
                                    //probably should return some response
                                    if (!toToken) {
                                        return;
                                    }

                                    const maxAmount = fromToken.balance || "0";
                                    setFromAmount(maxAmount);
                                    const toAmount = calcAmount(maxAmount, fromToken, toToken);

                                    if (toAmount === undefined) {
                                        return;
                                    }

                                    setToAmount(toAmount);
                                    setInputError(false);
                                    setDecimalsExceeded(false);
                                }}
                            >
                {" "}
                                Add max{" "}
              </span>
                        </div>
                    )}
                    <div className="tw-flex tw-items-center tw-justify-between">
                        {fromToken ? (
                            <TokenDropdown
                                otherToken={undefined}
                                tokenList={tokenList}
                                selected={fromToken}
                                setSelected={setFromToken}
                                isFromToken={true}
                            />
                        ) : (
                            <div>Loading...</div>
                        )}

                        <div className="tw-w-full tw-ml-5">
                            <input
                                className="tw-w-full tw-bg-white tw-h-12 tw-rounded tw-text-xl tw-font-medium tw-text-coolGray-900 tw-text-right tw-border tw-border-coolGray-300 tw-my-2 tw-px-3 focus:tw-ring-2 focus:tw-ring-blue-600 "
                                type="text"
                                value={fromAmount}
                                onChange={(e) => {
                                    setInputToTokenError(false);
                                    setDecimalsToTokenExceeded(false);

                                    if (!fromToken) return;
                                    const val = e.target.value;

                                    if (val === "" || val.substring(0, 1) === "-") {
                                        setInputError(false);
                                        setDecimalsExceeded(false);
                                        setFromAmount("");
                                        return;
                                    }

                                    if (!isNumeric(val) && val !== ".") {
                                        setInputError(true);
                                        setDecimalsExceeded(false);
                                        return;
                                    }

                                    if (new Decimal(val).gt(new Decimal(fromToken.balance))) {
                                        setInputError(true);
                                        setDecimalsExceeded(false);
                                        return;
                                    }

                                    if (new Decimal(val).lt(new Decimal(0))) {
                                        setInputError(false);
                                        setDecimalsExceeded(false);
                                        setFromAmount("");
                                        return;
                                    }

                                    if (hasTooManyDecimalPlaces(val, fromToken.decimal)) {
                                        setInputError(false);
                                        setDecimalsExceeded(true);
                                        return;
                                    }

                                    setFromAmount(val);
                                    const toAmount = calcAmount(val, fromToken, toToken);

                                    if (toAmount === undefined) {
                                        return;
                                    }

                                    setToAmount(toAmount);

                                    setInputError(false);
                                    setDecimalsExceeded(false);
                                }}
                                placeholder="00.00"
                            />
                        </div>
                    </div>

                    <div className="tw-flex tw-justify-end tw-mb-2">
                        <div className="tw-text-red-700 tw-mr-3">
                            {inputError ? "Invalid input" : ""}
                        </div>
                        <div className="tw-text-red-700 tw-mr-3">
                            {decimalsExceeded ? "Max decimals exceeded" : ""}
                        </div>
                        {amountTooltip("from-amount-usd", fromTokenUSD)}
                    </div>
                </div>
            </div>
        </SwapTokenWrapper>
    );
};