import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "../../components/Tooltip";
import {
  getTokenErrorMessage,
  getPercentage,
  swapTradErrorLogAnalysis,
  getPoolErrorMessage,
} from "./utils";
import { eventAnalysis } from "../../utils/misc/trackEvent";
import { HoldingToken, PoolDetails } from "../../state/assets";
import { StateContext } from "../../state/state";
import { TriggerType } from "../../state/triggers";
import { PoolOptionModal } from "./PoolOptionModal";

interface PoolOptionProps {
  token: HoldingToken;
  pool: PoolDetails | undefined;
}

export const PoolOptionBtn = ({ token, pool }: PoolOptionProps) => {
  const state = useContext(StateContext);
  let btnRef = useRef<HTMLDivElement | null>(null)
  const history = useHistory();
  const [isTextCroped, setIsTextCroped] = useState(false);

  const getOtherToken = (token: string, poolDetails: PoolDetails) => {
    return token.toUpperCase() === poolDetails.token0_address.toUpperCase()
      ? poolDetails.token1_address
      : poolDetails.token0_address;
  };

  useEffect(() => {
    if (btnRef?.current) {
      if (btnRef.current.scrollWidth <= btnRef.current.offsetWidth) {
        setIsTextCroped(false);
      } else {
        setIsTextCroped(true);
      }
    }
  }, [btnRef.current?.scrollWidth, btnRef.current?.offsetWidth]);

  if (!pool) return <></>;
  const getPoolOptionErrorMessage = () => {
    let errorMessage = getTokenErrorMessage(state, token, true);
    if (!errorMessage || errorMessage == "")
      errorMessage = getPoolErrorMessage(state, token.chain, pool.address);

    return errorMessage;
  };
  return (
    <Tooltip
      tooltipText={getPoolOptionErrorMessage()}
      hide={getPoolOptionErrorMessage() === ""}
    >
      <div
        id="tour_pool_option_btn"
        className={`tw-whitespace-nowrap tw-w-60 tw-bg-green-300 tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-3  tw-text-center  tw-rounded-full tw-text-coolGray-900 tw-text-base tw-font-semibold tw-shadow-base 
  ${getPoolOptionErrorMessage() !== "" ? "tw-opacity-60" : "tw-cursor-pointer"
          } `}
        onClick={() => {
          if (getPoolOptionErrorMessage() !== "") {
            swapTradErrorLogAnalysis(
              token,
              TriggerType.TRADING_AUTOMATION,
              state
            );
            return;
          }
          eventAnalysis(
            "provide_liquidity_click",
            TriggerType.TRADING_AUTOMATION,
            "Provide liquidity button clicked"
          );
          history.push(
            `/dashboard/tradingAutomation/${token.address}/${getOtherToken(
              token.address,
              pool
            )}`
          );
        }}
      >
        <Tooltip
          id={`${pool.symbol}_${pool.fees_per_liquidity}`}
          tooltipText={pool.symbol}
          className="tw-flex"
          hide={getPoolOptionErrorMessage() === "" ? !isTextCroped : true}
        >
          <div className="tw-relative tw-h-6">
            <div ref={btnRef} className="tw-inline-block tw-overflow-hidden tw-truncate tw-max-w-7rem ">
              {pool.symbol}
            </div>
          </div>
        </Tooltip>
        &nbsp;
        <span>/</span>
        &nbsp;
        {
          `${getPercentage(pool.fees_per_liquidity)} % APR`
        }
      </div>
    </Tooltip>
  );
};
const NoLiquidityPoolBtn = () => {
  return (
    <div
      id="tour_no_lp_option"
      className={`tw-shadow-md tw-rounded-full tw-border tw-border-coolGray-300 tw-text-base tw-font-medium tw-text-coolGray-400 tw-px-5 tw-py-3 tw-mr-6 tw-flex tw-justify-center tw-w-min tw-items-center tw-bg-blueGray-100 tw-whitespace-nowrap`}
    >
      No liquidity pool options
    </div>
  );
};

export const PoolOptionBtns = ({
  token,
  pools,
}: {
  token: HoldingToken;
  pools: PoolDetails[];
}) => {
  const state = useContext(StateContext);
  const [isHidden, setIsHidden] = useState(true);

  const handleBtnClick = () => {
    setIsHidden(false);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      {state.assets.suggestedPoolSyncing ? (
        <div className="tw-flex tw-justify-center">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : pools.length ? (
        <div className="tw-flex tw-justify-between tw-gap-2 tw-min-w-270px">
          <div id="poolBtnContainer" className="tw-flex tw-gap-3">
            {pools.slice(0, 2).map((pool, index) => (
              <PoolOptionBtn key={index} token={token} pool={pool} />
            ))}
          </div>
          {
            pools.length > 2 && (
              <div
                className={`tw-shadow-md tw-rounded-full tw-border tw-border-coolGray-300 tw-px-5 tw-pb-4 tw-mr-6 tw-flex tw-justify-center tw-w-min tw-items-center tw-bg-blueGray-50 
                ${getTokenErrorMessage(state, token, true) !== ""
                    ? "tw-opacity-60"
                    : "tw-cursor-pointer"
                  }`}
                onClick={() =>
                  getTokenErrorMessage(state, token, true) === "" &&
                  handleBtnClick()
                }
              >
                <div className="tw-text-3xl tw-font-bold tw-leading-7">...</div>
              </div>
            )
          }
        </div >
      ) : (
        <NoLiquidityPoolBtn />
      )}
      <PoolOptionModal
        isHidden={isHidden}
        onClose={() => setIsHidden(true)}
        pools={pools}
        token={token}
      />
    </>
  );
};
