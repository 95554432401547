import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function SyncingTableBody() {
  return (
    <tbody data-testid="page-syncing">
      <tr>
        <td colSpan={12} className="TableNotification">
          <span>Syncing...</span>
          <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />
        </td>
      </tr>
    </tbody>
  );
}
