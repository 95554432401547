import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { APP_NAME, VERSION } from "../configs";
import { links } from "./Navbars/Navbar";
import { eventNameAnalysis } from "../utils/misc/trackEvent";

export const PageTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    eventNameAnalysis("page_view", {
      page_location: location.pathname + location.search,
    });

    let screenName = "Unknown";
    const link = links.find((l) => l.url === location.pathname);
    if (link !== undefined) {
      screenName = link.screenName;
    }

    eventNameAnalysis("screen_view", {
      app_name: APP_NAME,
      screen_name: screenName,
      app_version: VERSION,
    });
  }, [location]);

  return null;
};
