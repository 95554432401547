import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import { AgentsAPI } from "../../api/AgentsAPI";
import { StateStore } from "../../state/state";
import editPencil from "../../assets/edit-pencil.svg";
import errorOutline from "../../assets/error-outline.svg";
import checkCircle from "../../assets/check-circle.svg";
import style from "./agentNameEditBox.module.scss";
import Tooltip from "../../utils/tooltip/tooltip";
import { Error } from "../../pages/AgentsPage";

export const AgentNameEditBox = ({
  name,
  isOtherEditable,
  setOtherEditable,
  uuid,
  state,
  pendingDelete,
  setErrorMessage,
}: {
  name: string;
  isOtherEditable: boolean;
  setOtherEditable: (item: boolean) => void;
  uuid: string;
  state: StateStore;
  pendingDelete: boolean;
  setErrorMessage: (error: Error) => void;
}) => {
  const [isDisabled, setDisabled] = useState(true);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputAgentName, setInputAgentName] = useState(name);
  const [agentName, setAgentName] = useState(name); // state to store previous agent name
  const [showTooltip, setShowTooltip] = useState(true);

  const maxAgentNameLength = 30;

  const handleEdit = () => {
    setDisabled(false);
    setOtherEditable(true);
  };

  const inputChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value.length <= maxAgentNameLength) {
      setInputAgentName(e.target.value);
      if (!e.target.value.trim()) {
        setErrorMessage({
          type: "warning",
          message: "Please enter the agent name",
        });
        setIsSuccess(false);
      } else {
        setIsSuccess(null);
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (state.account.user?.token === undefined) {
      return;
    }

    if (agentName === inputAgentName) {
      setDisabled(true);
      setOtherEditable(false);

      if (inputRef.current !== null) {
        inputRef.current.blur();
      }

      return;
    }

    if (!inputAgentName) {
      setErrorMessage({
        type: "warning",
        message: "Please enter the agent name",
      });
      setIsSuccess(false);
      return;
    }
    try {
      // If previous agent name is same as current then dont call api
      state.agents.updateName(uuid, inputAgentName.trim());
      setAgentName(inputAgentName.trim());
      await AgentsAPI.updateAgentName(
        state.account.user.token,
        uuid,
        inputAgentName.trim()
      );
      setErrorMessage({
        type: "success",
        message: "Agent name updated successfully",
      });
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(null);
        setDisabled(true);
        setTimeout(() => {
          setOtherEditable(false);
        }, 500);
      }, 1500);
    } catch (err) {
      setIsSuccess(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (inputRef?.current) {
        return;
      }
    }
    if (!isDisabled) {
      if (inputRef?.current) {
        inputRef?.current.focus();
      }
    }
  }, [isDisabled, isSuccess]);

  useEffect(() => {
    setInputAgentName(name);
  }, [name]);

  useEffect(() => {
    if (inputRef?.current) {
      if (inputRef.current.scrollWidth <= inputRef.current.offsetWidth) {
        setShowTooltip(false);
      } else {
        setShowTooltip(true);
      }
    }
  }, [inputRef.current?.scrollWidth, inputRef.current?.offsetWidth]);

  return (
    <div
      className={`${style.agentNameContainer} ${
        !isDisabled && style.editableInput
      }`}
    >
      <Tooltip
        child={
          <Form onSubmit={handleSubmit}>
            <Form.Control
              data-testid="agent-editable-form"
              ref={inputRef}
              type="text"
              value={inputAgentName}
              className={style.agentInput}
              onChange={inputChangeHandler}
              disabled={isDisabled}
              onBlur={handleSubmit}
            />
          </Form>
        }
        tooltipTextClassName="tool-tip-agent-name"
        text={name}
        hideTooltip={isOtherEditable || !showTooltip}
      />

      {isSuccess !== null && !isDisabled && (
        <div className={style.icon}>
          <img src={isSuccess ? checkCircle : errorOutline} alt="icon" />
        </div>
      )}
      {isDisabled && !isOtherEditable && !pendingDelete && (
        <div className={style.editIcon} onClick={handleEdit}>
          <img src={editPencil} alt="edit" />
        </div>
      )}
    </div>
  );
};
