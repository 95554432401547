export const accountDetailsQuery = `query Query {
  account {
    agentLimit
    balance
    createdAt
  }
}`;

export const planDetails = `query Plan {
  plan {
    name
    agentSecondRate
  }
}`;

export const addressesQuery = `query Query {
  accountAddress {
    address
  }
}`;

export const accountEventsQuery = `query AccountEvents($params: PaginationParams) {
  accountEvents(params: $params) {
    count
    totalPages
    perPage
    page
    results {
      id
      event
      amount
      details
      tx
      timestamp
    }
  }
}`;

export const fundsWithdraw = `mutation AgentFundsWithdraw($agentFundsWithdrawId: ID!, $input: FundsWithdraw!) {
    agentFundsWithdraw(id: $agentFundsWithdrawId, input: $input)
  }`;
