import { action, makeObservable, override } from "mobx";
import { anErr, anOk, isErr, Result } from "../result";
import { TriggersAPIStructure } from "../api/TriggersAPI";
import { AccessToken } from "./token";
import { Pair } from "./pairCache";
import BN from "bn.js";
import { getNetworkDetails, Network } from "../configs";
import { updateTriggers } from "../api/Sync";
import { CollectionStore } from "./utils/collectionStore";
import ERC20Contract from "../services/contracts/ERC20Contract";
import Decimal from "decimal.js";
import { LocalStorage } from "./localStorage";
import { formatBigNumber } from "../utils/numeric/formatBigNumber";
import { snooze } from "../utils/misc/snooze";
import { getProxyContractAddressForTrigger } from "../utils/misc/getProxyContractAddressForTrigger";

export enum TriggerState {
  DISABLED,
  ARMED,
  TRIGGERING,
  COMPLETED,
  FAILED,
  PROCESSING,
  UNKNOWN = 99,
}

export enum TriggerType {
  WITHDRAW_LIQUIDITY,
  SWAP,
  TRADING_AUTOMATION,
}

export enum TriggerChoices {
  TOKEN0,
  TOKEN1,
}

export interface deleteParams<T extends TriggersAPIStructure> {
  uuid: string;
  triggersAPI: T;
  token: AccessToken;
  address: string;
  chainId: number;
  gasPrice: string;
}

export function parseTriggerState(value: string): TriggerState {
  switch (value) {
    case "DISABLED":
      return TriggerState.DISABLED;
    case "ARMED":
      return TriggerState.ARMED;
    case "TRIGGERING":
      return TriggerState.TRIGGERING;
    case "COMPLETED":
      return TriggerState.COMPLETED;
    case "FAILED":
      return TriggerState.FAILED;
    case "PROCESSING":
      return TriggerState.PROCESSING;
    default:
      return TriggerState.UNKNOWN;
  }
}

export function parseTriggerType(value: string): TriggerType {
  switch (value) {
    case "WITHDRAW_LIQUIDITY":
      return TriggerType.WITHDRAW_LIQUIDITY;
    case "SWAP":
      return TriggerType.SWAP;
    default:
      return 99;
  }
}

export function parseTriggerChoices(value: string): TriggerChoices {
  switch (value) {
    case "TOKEN0":
      return TriggerChoices.TOKEN0;
    default:
      return TriggerChoices.TOKEN1;
  }
}

export interface TriggerBase {
  network: Network;
  agentUuid: string;
  pair: Pair;
  threshold: string;
  price0Min: string;
  price0Max: string;
  price1Min: string;
  price1Max: string;
  token: TriggerChoices;
  swap_token?: TriggerChoices;
  gasPrice: string;
  gasAmount: string;
  slippage?: number;
  proxyAddress?: string | null;
}

export interface NewTrigger extends TriggerBase {
  amount: string;
}

export interface Trigger extends TriggerBase {
  uuid: string; // the uuid for the trigger
  state: TriggerState;
  createdAt: string;
  pendingDeletion?: boolean;
  triggerType: number;
  approveBalance: ApprovedBalance;
  amount: string;
  tx_hash: null | string;
}

export interface SwapToken {
  address: string;
  symbol: string;
  chain: number;
  users: number;
  isNative?: boolean;
  balance?: string;
  decimal?: number;
}

export interface ApprovedBalance {
  balance: string;
  symbol: string;
  decimal: any;
}

export class TriggerStore extends CollectionStore<Trigger> {
  localStorage: LocalStorage;

  get deleteErrorMessage(): string | undefined {
    return this._deleteErrorMessage;
  }

  set deleteErrorMessage(value: string | undefined) {
    this._deleteErrorMessage = value;
  }

  public syncing = true;

  private _deleteErrorMessage?: string;

  constructor(localStorage: LocalStorage) {
    super("uuid");
    makeObservable(this, {
      delete: action,
      chainSwitched: action,
      updatePending: action,
    });
    this.localStorage = localStorage;
  }

  agentHasNonCompletedTriggers(agentUuid: string): boolean {
    return this.list().some((trigger: Trigger) => {
      return (
        trigger.agentUuid === agentUuid &&
        ![TriggerState.COMPLETED, TriggerState.FAILED].includes(trigger.state)
      );
    });
  }

  agentTriggers(agentUuid: string): Array<Trigger> {
    return this.list().filter(
      (trigger: Trigger) => trigger.agentUuid === agentUuid
    );
  }

  overWrite(items: Array<Trigger>) {
    if (items.length && this.localStorage.hasEverHadTrigger === false) {
      this.localStorage.hasEverHadTrigger = true;
    }

    // we preserve the status of any pendingdeletion on overrwite so not a pure overwrite
    for (let i = 0; i < items.length; i += 1) {
      if (this.has(items[i].uuid)) {
        items[i].pendingDeletion = (
          this.get(items[i].uuid) as Trigger
        ).pendingDeletion;
      }
    }
    super.overWrite(items);
  }

  agentNonCompletedTriggersCount(agentUuid: string): number {
    return this.list().filter(
      (trigger: Trigger) =>
        trigger.agentUuid === agentUuid &&
        ![TriggerState.COMPLETED, TriggerState.FAILED].includes(trigger.state)
    ).length;
  }

  agentTriggersRequiredFunds(agentUuid: string): BN {
    let sum = new BN(0);

    this.list()
      .filter(
        (trigger: Trigger) =>
          trigger.agentUuid === agentUuid &&
          ![TriggerState.COMPLETED, TriggerState.FAILED].includes(trigger.state)
      )
      .forEach((trigger: Trigger) => {
        const total = new BN(trigger.gasPrice).mul(new BN(trigger.gasAmount));
        sum = sum.add(total);
      });
    return sum;
  }

  getSwapTokenAddress(trigger: Trigger): string {
    const swapToken = trigger.swap_token ?? trigger.token;
    return swapToken === 1
      ? trigger.pair.token1Address
      : trigger.pair.token0Address;
  }

  async delete<T extends TriggersAPIStructure>({
    uuid,
    triggersAPI,
    token,
    address,
    chainId,
    gasPrice,
  }: deleteParams<T>): Promise<Result<string>> {
    // check trigger exists in state prior to calling this method as it will hide the state
    if (!this.items.has(uuid)) {
      return anOk("");
    }

    const existingTrigger = this.get(uuid) as Trigger;

    if (
      existingTrigger !== undefined &&
      existingTrigger.pendingDeletion === true
    ) {
      return anOk("");
    }

    this.updatePending(uuid, true);
    if (existingTrigger.state !== TriggerState.COMPLETED) {
      //  I tried without success to move this to a batch request for multiple deletes.
      const tokenInAddress =
        existingTrigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY
          ? existingTrigger.pair.address
          : this.getSwapTokenAddress(existingTrigger);

      const networkDetails = getNetworkDetails(chainId);

      if (isErr(networkDetails)) {
        return networkDetails;
      }

      const proxyContractAddress = getProxyContractAddressForTrigger(
        existingTrigger.proxyAddress,
        existingTrigger.triggerType,
        networkDetails.value
      );

      const allowanceResult = await ERC20Contract.getAllowance(
        tokenInAddress,
        address,
        proxyContractAddress
      );

      if (isErr(allowanceResult)) {
        this.updatePending(uuid, false);
        return anErr(allowanceResult.error.message);
      }

      const allowance = new Decimal(allowanceResult.value);

      const approvedBalance = new Decimal(
        existingTrigger.approveBalance.balance
      );

      const toApprove = allowance.gte(approvedBalance)
        ? allowance.sub(approvedBalance)
        : approvedBalance;

      const result = await ERC20Contract.approve(
        tokenInAddress,
        address,
        proxyContractAddress,
        formatBigNumber(toApprove.toString()).full,
        gasPrice
      );

      if (isErr(result)) {
        this.updatePending(uuid, false);
        return anErr(result.error.message);
      }
    }
    const triggerCountPreDeletion = this.length;
    try {
      await triggersAPI.deleteTrigger(token as AccessToken, uuid);
    } catch (err) {
      this.updatePending(uuid, false);
      return anErr("Unable to delete the trigger");
    }
    const timeout = 10000;

    let keepGoing = true;
    setTimeout(function () {
      keepGoing = false;
    }, timeout); // 1 min in milliseconds

    let triggerCountPostDeletion = this.length;
    // we wait for the agent to actually be removed from state (and api responses) before closing modal
    while (triggerCountPreDeletion === triggerCountPostDeletion && keepGoing) {
      triggerCountPostDeletion = this.length;

      // if the token is expired and being updated then we just give them a single chance to renew it else we display an error
      await snooze(400);
    }

    return anOk("");
  }

  updatePending(uuid: string, pendingStatus: boolean): boolean {
    let success = false;

    const trigger = this.items.get(uuid);
    if (trigger !== undefined) {
      trigger.pendingDeletion = pendingStatus;
      this.items.set(uuid, trigger);
      success = true;
    }
    return success;
  }

  async chainSwitched(token: AccessToken, networkId: Network): Promise<void> {
    await updateTriggers(token, this, networkId);
  }

  add(item: Trigger): boolean {
    this.localStorage.hasEverHadTrigger = true;
    return super.add(item);
  }
}
