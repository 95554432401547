import { action, computed, makeObservable, observable } from "mobx";

/**
 *   @see {@link https://mobx.js.org/subclassing.html}
 */
export class CollectionStore<T extends { [index: string]: any }> {
  items = new Map<string, T>();

  key: string;

  constructor(key: keyof T & string) {
    makeObservable(this, {
      items: observable,
      key: observable,
      length: computed,
      add: action,
      clear: action,
      remove: action,
      overWrite: action,
    });
    this.key = key;
  }

  get length(): number {
    return this.items.size;
  }

  list(): T[] {
    return [...this.items.values()];
  }

  keys(): string[] {
    return [...this.items.keys()];
  }

  get(identifier: string): T | undefined {
    return this.items.get(identifier);
  }

  has(identifier: string): boolean {
    return this.items.has(identifier);
  }

  add(item: T): boolean {
    if (this.items.has(item[this.key])) {
      return false;
    }

    this.items.set(item[this.key], item);
    return true;
  }

  overWrite(items: Array<T>) {
    this.items.clear();

    for (const item of items) {
      this.items.set(item[this.key], { ...item });
    }
  }

  remove(address: string): boolean {
    return this.items.delete(address);
  }

  clear(): void {
    this.items.clear();
  }
}
