import { PlanDetails } from "../state/account";
import Decimal from "decimal.js";
import moment from "moment";
import { StateStore } from "../state/state";
import { FetInfo } from "../configs";
import { toNonCanonicalDisplay } from "../utils/display/toNonCanonicalDisplay";

export class Costing {
  public static hasServiceExpired(state: StateStore): undefined | boolean {
    return false;
    // if (
    //   state.account.planDetails === undefined ||
    //   state.account.accountDetails?.balance === undefined
    // ) {
    //   return undefined;
    // }

    // const dailyUsage = Costing.computeUsage(
    //   state.agents.length,
    //   1,
    //   state.account.planDetails
    // );
    // const { balance } = state.account.accountDetails;
    // const numDailyUsage = new Decimal(dailyUsage);
    // const numBalance = new Decimal(balance);
    // let estimatedRemainingDays = 0;

    // if (numDailyUsage.lessThanOrEqualTo(0)) {
    //   return false;
    // }

    // if (numDailyUsage.greaterThan(0)) {
    //   estimatedRemainingDays = numBalance.div(numDailyUsage).toNumber();
    // }
    // // cause estimatedRemainingDays will never be 0 but moment will start considering it 0 if < 0.5
    // return estimatedRemainingDays < 0.5;
  }

  /**
   * Returns estimated service end as a display string to show to the user.
   *
   * @param state
   */
  public static estimatedServiceEnd(state: StateStore): string | undefined {
    return "Never";

    // if (
    //   state.account.planDetails === undefined ||
    //   state.account.accountDetails?.balance === undefined
    // ) {
    //   return undefined;
    // }

    // const dailyUsage = Costing.computeUsage(
    //   state.agents.length,
    //   1,
    //   state.account.planDetails
    // );
    // const { balance } = state.account.accountDetails;
    // const numDailyUsage = new Decimal(dailyUsage);
    // const numBalance = new Decimal(balance);
    // let estimatedRemainingDays = 0;

    // if (numDailyUsage.lessThanOrEqualTo(0)) {
    //   return "Never";
    // }

    // if (numDailyUsage.greaterThan(0)) {
    //   estimatedRemainingDays = numBalance.div(numDailyUsage).toNumber();
    // }

    // return moment().add(estimatedRemainingDays, "days").fromNow();
  }

  private static computeUsage(
    totalAgents: number,
    days: number,
    details?: PlanDetails
  ): string {
    if (details === undefined || days === 0) {
      return "0";
    }

    const agentSecondRate = new Decimal(details.agentSecondPrice);
    const totalAgentDaySeconds = totalAgents * 24 * 60 * 60 * days; // 28 (representing days)  * 24h * 60m * 60s

    return agentSecondRate.mul(totalAgentDaySeconds).toFixed(0);
  }

  public static estimatedMonthlyCostOfAgentsDisplay(
    details?: PlanDetails,
    numOfAgents = 1
  ): string {
    const agentDays = 28 * numOfAgents;
    const cost = Costing.computeUsage(1, agentDays, details);
    return toNonCanonicalDisplay(cost, FetInfo);
  }
}
