import { ProgressBar } from "react-bootstrap";
import React from "react";

interface AgentCapacityBarProps {
  numAgents: number;
  maxAgents: number;
  addAgent?: boolean;
}

export function AgentCapacityBar(props: AgentCapacityBarProps) {
  const addAgent = props.addAgent ?? false;

  if (props.numAgents >= props.maxAgents) {
    return <ProgressBar variant="danger" now={1} max={1} striped />;
  }

  if (addAgent) {
    const variant = props.numAgents + 1 >= props.maxAgents ? "warning" : "info";

    return (
      <ProgressBar label="fooist" className="striped-progress">
        <ProgressBar
          variant={variant}
          now={props.numAgents}
          max={props.maxAgents}
          key={1}
        />
      </ProgressBar>
    );
  }
  return <ProgressBar now={props.numAgents} max={props.maxAgents} />;
}
