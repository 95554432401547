import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import style from "./ConfirmPage.module.scss";
import { AuthenticationAPI } from "../api/AuthenticationAPI";
import { observer } from "mobx-react-lite";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

enum Progress {
  IN_PROGRESS,
  SUCCESS,
  FAILED,
}

const getContent = (progress: Progress) => {
  switch (progress) {
    case Progress.IN_PROGRESS:
      return (
        <>
          <h4>Confirmation in progress</h4>
          <FontAwesomeIcon icon={faSpinner} spin />
          <h6>Please do not close or refresh this window</h6>
        </>
      );
    case Progress.SUCCESS:
      return (
        <>
          <p>
            Your email has been verified successfully, you will be redirected to
            the DeFi Agents app in 5 seconds
          </p>
          <FontAwesomeIcon icon={faSpinner} spin />
        </>
      );
    case Progress.FAILED:
      return (
        <>
          <p>
            Email verification failed as the link is invalid or expired, please
            resend the email confirmation link again from the accounts section
            in the app.
          </p>
          <p>You will be redirected to the DeFi Agents app in 5 seconds</p>
          <FontAwesomeIcon icon={faSpinner} spin />
        </>
      );
  }
};

export const ConfirmPage = observer(() => {
  const [progress, setProgress] = useState<Progress>(Progress.IN_PROGRESS);
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const confirmAccount = async () => {
      try {
        const confirmationBytes = search.split("?")[1];
        const decodedObject = Buffer.from(
          confirmationBytes,
          "base64"
        ).toString();
        const userDetails = JSON.parse(decodedObject);
        if (!userDetails.email || !userDetails.uuid) {
          return setProgress(Progress.FAILED);
        }

        const status = await AuthenticationAPI.confirmAccount(
          userDetails.email,
          userDetails.uuid
        );

        if (status) {
          setProgress(Progress.SUCCESS);
        } else {
          setProgress(Progress.FAILED);
        }
      } catch {
        setProgress(Progress.FAILED);
      } finally {
        setTimeout(() => {
          history.push("/account");
        }, 5000);
      }
    };

    confirmAccount();
  }, []);

  return <div className={style.content}>{getContent(progress)}</div>;
});
