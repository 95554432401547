export const createAgent = `mutation CreateAgent($input: NewAgent!) {
    createAgent(input: $input) {
      agentAddress
      uuid
      enabled
      chain
      userAddress
      name
      createdAt
      withdrawPending
      deletePending
    }
  }`;

export const deleteAgent = `mutation Mutation($deleteAgentId: ID!) {
    deleteAgent(id: $deleteAgentId)
  }`;

export const updateAgent = `mutation Mutation($updateAgentId: ID!, $input: UpdateAgent!) {
    updateAgent(id: $updateAgentId, input: $input) {
      name
    }
  }`;

export const fetchAgentsQuery = `query Query {
  agents {
    uuid
    chain
    enabled
    agentAddress
    userAddress
    name
    createdAt
    withdrawPending
    deletePending
  }
}`;
