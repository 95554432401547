import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Network } from "../configs";
import { StateContext } from "../state/state";
import style from "./FreeAccountPage.module.scss";
import { FreeProfileSection } from "./FreeProfileSection/FreeProfileSection";
import { TopUpVaultModal } from "./TopUpVaultModal";

interface FreeAccountPageProps {
  network: Network;
  numAgents: number | undefined;
  agentLimit: number | undefined;
  dailyUsage: string;
  balance: string | undefined;
  fetBalance: string | undefined;
  isAddressRegistered: boolean;
  estimatedServiceEnd: string | undefined;
}

function changeExpiredText(s: string | undefined): string | undefined {
  if (s === "a few seconds ago") return "service expired";
  return s;
}

export function FreeAccountPage(props: FreeAccountPageProps) {
  const state = useContext(StateContext);
  const estimatedServiceEnd = changeExpiredText(props.estimatedServiceEnd);

  const [show, setShow] = useState<boolean>(false);
  const [showVaultModal, setShowVaultModal] = useState<boolean>(false);

  // hide the tooltip if it has been clicked
  useEffect(() => {
    if (!show) {
      return;
    }

    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [show]);

  return (
    <>
      <div className="tw-flex tw-justify-between">
        <h1 className="tw-text-5xl tw-leading-9 tw-mb-8 tw-ml-1 tw-font-Inter tw-font-bold">
          Account
        </h1>
      </div>
      <div className={style.container}>
        <div className={style.summaryContainer}>
          <div className={style.summary}>
            <div className="tw-grid tw-grid-cols-1 tw-divide-y tw-justify-between">
              <div className={style.rowLine}>
                <div className={style.heading}>Summary</div>
              </div>
              <div className={style.rowLine}>
                <div className={style.item}>Agent Usage</div>
                <div className={style.itemval}>
                  {props.numAgents !== undefined &&
                  props.agentLimit !== undefined ? (
                    `${props.numAgents} / ${props.agentLimit}`
                  ) : (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ marginRight: 10 }}
                    />
                  )}
                </div>
              </div>
              <div className={style.rowLine}>
                <div className={style.item}>Estimated Service Expiry</div>
                <div className={style.itemval}>
                  {estimatedServiceEnd !== undefined ? (
                    estimatedServiceEnd
                  ) : (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      style={{ marginRight: 10 }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <FreeProfileSection />
      </div>
      <TopUpVaultModal
        show={showVaultModal}
        fetBalance={props.fetBalance}
        close={() => setShowVaultModal(false)}
        isAddressRegistered={props.isAddressRegistered}
      />
    </>
  );
}
