import { API_URL, Network } from "../configs";
import { anErr, anOk, isErr, Result, OkResult } from "../result";
import { Pair } from "../state/pairCache";
import { SwapToken } from "../state/triggers";
import {
  apiChainIdToNetwork,
  apiChainToNetwork,
  networkToApiChainId,
} from "./common";
import { PoolData } from "../components/DropdownSearchPool/DropdownSearchPool.data";
import { wrappedNameConversion } from "../utils/display/wrappedNameConversion";
import client from "./client";
import { gql } from "@apollo/client";
import { fetchPoolQuery } from "./PoolsQueries";

declare interface Pool {
  address: string;
  price: string;
  type: number;
}

declare interface PoolsAPIResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<PoolData>;
}

declare interface PoolGraphQLResponse {
  address: string;
  chain: string;
  chainId: number;
  type: string;
  price: string;
  price0Avg: string;
  price1Avg: string;
  token0Sym: string;
  token1Sym: string;
  token0Decimals: number;
  token1Decimals: number;
  token0Address: string;
  token1Address: string;
  updatedAt: string;
}

declare interface SwapTokenResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<SwapToken>;
}



declare interface HoldingPoolAPIResponse {
  address: string;
  chain: number;
  symbols: string;
}

declare interface PoolsDataAPIResponse {
  address: string;
  chain: Network;
  token0: string;
  token1: string;
  symbols: string;
}

function isNonEmptyString(s: unknown): boolean {
  return Boolean(typeof s === "string" && s !== "");
}

function hasAllPropertiesRequiredForPair(
  apiResponse: PoolGraphQLResponse
): boolean {
  return Boolean(
    apiResponse.chain !== undefined &&
      isNonEmptyString(apiResponse.address) &&
      isNonEmptyString(apiResponse.price) &&
      isNonEmptyString(apiResponse.token0Sym) &&
      apiResponse.token0Decimals !== undefined &&
      isNonEmptyString(apiResponse.token1Sym) &&
      apiResponse.token1Decimals !== undefined
  );
}

function buildPoolData(apiResponse: PoolsDataAPIResponse): PoolData {
  return {
    address: apiResponse.address,
    chainId: apiResponse.chain,
    name: apiResponse.symbols,
  };
}

function buildPair(
  apiResponse: Partial<PoolGraphQLResponse>
): Partial<Pair> | Pair {
  // defaut is just for testing on decimals since currently admin cannot allow this to be added.
  return {
    network: apiChainIdToNetwork(apiResponse.chainId as number),
    address: apiResponse.address,
    price: apiResponse.price,
    token0Address: apiResponse.token0Address,
    token0: wrappedNameConversion(
      apiResponse.chainId,
      apiResponse.token0Sym,
      apiResponse.address
    ),
    token0Decimals: apiResponse.token0Decimals,
    token1Address: apiResponse.token1Address,
    token1: wrappedNameConversion(
      apiResponse.chainId,
      apiResponse.token1Sym,
      apiResponse.address
    ),
    token1Decimals: apiResponse.token1Decimals,
  };
}

export class PoolsAPI {
  /**
   * currently this fetched all pools for a user
   * @param token
   */

  // Not being called
  static async fetchPools(
    token: string,
    nextApiUrl: string | null | undefined,
    inputText: string,
    network: Network
  ): Promise<Result<PoolsAPIResponse>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (nextApiUrl === null) {
      return anErr("Token not found");
    }

    let data;
    let response;
    const chainId = networkToApiChainId(network);
    if (isErr(chainId))
      return anOk({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
    try {
      const apiEndPoint =
        nextApiUrl === undefined
          ? `${API_URL}/chain/pools/?chain=${chainId.value}&search=${inputText}`
          : nextApiUrl;
      response = await fetch(apiEndPoint, config);
      data = await response.json();
    } catch (err) {
      // Instead of throwing error. return empty reponse
      return anOk({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
    }

    if (response.status !== 200) {
      // Instead of throwing error. return empty reponse
      return anOk({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
    }
    const poolsData = {
      ...data,
      results: data.results.map((poolsDataAPIResponse: PoolsDataAPIResponse) =>
        buildPoolData(poolsDataAPIResponse)
      ),
    };
    return anOk(poolsData);
  }

  static async fetchPool(
    token: string,
    poolAddress: string
  ): Promise<Result<Pair>> {
    try {
      const { data, errors } = await client.query({
        query: gql(fetchPoolQuery),
        fetchPolicy: "no-cache",
        variables: {
          address: poolAddress,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
      if (errors) {
        return anErr(errors[0].message);
      }

      const { pool } = data;
      const pairData = { ...pool, chainId: apiChainToNetwork(pool.chain) };
      return anOk(buildPair(pairData) as Pair);
    } catch (err: any) {
      return anErr(err.message);
    }
  }

  // No GraphQl for this
  static async createPool(token: string, pool?: Pool): Promise<true | Error> {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        address: "test_address_2",
        type: 1,
        price: "100",
      }),
    };

    let data;
    let response;
    try {
      response = await fetch(`${API_URL}/stoploss/pools/`, config);
      data = await response.json();
    } catch {
      return new Error("error");
    }

    if (response.status === 200) {
      return true;
    }
    return new Error(data.detail);
  }

  static async fetchSwapToken(
    token: string,
    network: Network,
    nextTokenUrl: string | null | undefined,
    inputText: string,
    tokenAddress?: string
  ): Promise<Result<SwapTokenResponse>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    if (nextTokenUrl === null) {
      return anErr("Token not found");
    }

    let data;
    let response;
    let chainId;
    try {
      chainId = networkToApiChainId(network);
      if (isErr(chainId)) {
        return anOk({
          count: 0,
          next: null,
          previous: null,
          results: [],
        });
      }
      const tokenAddressParamStr = tokenAddress ? `&pair=${tokenAddress}` : "";
      const searchParamStr = inputText ? `&search=${inputText}` : "";
      const apiEndpoint =
        nextTokenUrl === undefined
          ? `${API_URL}/chain/tokens?chain=${chainId.value}${tokenAddressParamStr}${searchParamStr}`
          : nextTokenUrl;
      response = await fetch(apiEndpoint, config);
      data = await response.json();
    } catch (err) {
      // Instead of throwing error. return empty reponse
      return anOk({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
    }
    if (response.status !== 200) {
      // Instead of throwing error. return empty reponse
      return anOk({
        count: 0,
        next: null,
        previous: null,
        results: [],
      });
    }
    return anOk(data);
  }

  static async fetchHoldingTokens(
    token: string,
    address: string,
    network: Network
  ): Promise<Result<SwapToken[]>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let data;
    let response;
    const chainId = networkToApiChainId(network);
    if (isErr(chainId)) {
      return anOk([]);
    }
    try {
      response = await fetch(
        `${API_URL}/chain/tokens/${address}?chain=${chainId.value}`,
        config
      );
      data = await response.json();
    } catch {
      // Instead of throwing error. return empty reponse
      return anOk([]);
    }

    if (response.status === 200) {
      return anOk(data);
    }
    // Instead of throwing error. return empty reponse
    return anOk([]);
  }

  static async fetchHoldingPools(
    token: string,
    address: string,
    network: Network
  ): Promise<Result<HoldingPoolAPIResponse[]>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let data;
    let response;
    const chainId = networkToApiChainId(network);
    if (isErr(chainId)) {
      return anOk([]);
    }
    try {
      response = await fetch(
        `${API_URL}/chain/pools/${address}?chain=${chainId.value}`,
        config
      );
      data = await response.json();
    } catch {
      // Instead of throwing error. return empty reponse
      return anOk([]);
    }

    if (response.status === 200) {
      return anOk(data);
    }
    // Instead of throwing error. return empty reponse
    return anOk([]);
  }
}
