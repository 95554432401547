import React from "react";
import ReactPaginate from "react-paginate";

import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";

interface PaginationProps {
  handlePageClick: (e: any) => void;
  pageCount: number;
}

const Pagination = ({ handlePageClick, pageCount }: PaginationProps) => {
  return pageCount > 1 ? (
    <div className="tw-flex tw-justify-center tw-mt-12">
      <ReactPaginate
        breakLabel="..."
        breakClassName="tw-p-4 tw-rounded-full"
        nextLabel={
          <div className="tw-transform tw-rotate-180 tw-bg-coolGray-200 tw-relative tw-w-9 tw-h-9 tw-m-3 tw-rounded-full">
            <ArrowIcon className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-2/4 tw--translate-y-2/4" />
          </div>
        }
        previousLabel={
          <div className="tw-bg-coolGray-200 tw-relative tw-w-10 tw-h-10 tw-m-3 tw-rounded-full">
            <ArrowIcon className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-2/4 tw--translate-y-2/4" />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        disabledClassName="tw-peers"
        pageClassName="tw-relative tw-w-10 tw-h-10 tw-m-3 tw-rounded-full"
        pageLinkClassName="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-2/4 tw--translate-y-2/4 tw-text-coolGray-700 tw-text-base tw-font-semibold"
        containerClassName="tw-flex tw-items-center tw-mx-auto"
        activeClassName="tw-relative tw-w-10 tw-h-10 tw-m-3 tw-bg-green-300 tw-rounded-full"
        activeLinkClassName="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw-translate-x-2/4 tw-translate-y-2/4"
      />
    </div>
  ) : null;
};

export default Pagination;
