import Web3 from "web3";
import { enumToString, NATIVE_TOKEN_ADDRESS } from "../../api/common";
import { GasPriceInfo } from "../../components/SelectGasPrice/SelectGasPrice";
import { mapChainId } from "../../configs";
import { Chain, CryptoToken, HoldingToken } from "../../state/assets";
import { gweiToWei } from "../currency/GweiToWei";

const GAS_COST_EST = 500000;
const ACCOUNT_GAS_COST_EST = 200000;

export const calcTotalGasCosts = (
  allTokens: CryptoToken[],
  chainId: number,
  txnSpeed: number,
  gasPrice: GasPriceInfo | undefined
): number => {
  const ethToken = allTokens.find(
    (token) =>
      token.address == NATIVE_TOKEN_ADDRESS &&
      token.chain === enumToString(Chain, mapChainId(chainId || 2))
  );

  if (ethToken) {
    const agentsWei = gweiToWei(`${txnSpeed * GAS_COST_EST}`);
    const agentsEth = Web3.utils.fromWei(agentsWei, "ether");

    if (gasPrice) {
      const gasWei = gweiToWei(
        `${parseInt(gasPrice.price) * ACCOUNT_GAS_COST_EST}`
      );
      const gasEth = Web3.utils.fromWei(gasWei, "ether");
      const totalAmount = parseFloat(agentsEth) + parseFloat(gasEth);
      return totalAmount;
    } else return 0;
  } else return 0;
};

export const isNativeTokenSufficient = (
  totalCost: number,
  holdingAssets: HoldingToken[],
  tokenAmount: string
) => {
  const neededNativeBalance = totalCost * 1.3;
  const nativeToken = holdingAssets.find((asset) => asset.isNative);
  if (!nativeToken) return false;
  if (parseFloat(nativeToken.balance) < neededNativeBalance) return false;
  if (
    parseFloat(nativeToken.balance) - parseFloat(tokenAmount) <
    neededNativeBalance
  )
    return false;

  return true;
};
