const REGEX = /(\d+)(\d{3})/;

/**
 * Adds commas into large numbers to display better as per British number formatting style.
 *
 * @see {@link https://stackoverflow.com/questions/5731193/how-to-format-numbers}
 *
 * @param number a number as a string to insert commas into or undefined
 * @returns {string} number with comma separators or empty string if undefined passed
 */

export const addCommas = (
  number: string | undefined,
  includingDecimalSeperator: boolean = false
): string => {
  const str: string = typeof number === "undefined" ? "" : number;
  const split = str.split(".");

  let decimal;
  if (includingDecimalSeperator && split.length > 1) {
    let integer = split[0];
    decimal = "." + insertSeperator(integer, " ");
  } else if (split.length > 1) {
    decimal = `.${split[1]}`;
  } else {
    decimal = "";
  }
  return insertSeperator(split[0], ",") + decimal;
};
function insertSeperator(integer: string, separator: string): string {
  while (REGEX.test(integer)) {
    integer = integer.replace(REGEX, `$1${separator}$2`);
  }
  return integer;
}
