import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { Tooltip } from "../Tooltip";

interface PercentageBoxProps {
  otherTokenSymbol: string;
  thresholdTokenSymbol: string | undefined;
  percentage: number;
  price: { usdPrice: number; spotPrice: number };
  spotPriceValue: { usdPrice: number; spotPrice: number };
  isSelected: boolean;
  onClick: (val: number) => void;
}

const DOLLAR_DECIMALS = 2;

export const PricePercentageBox = ({
  otherTokenSymbol,
  thresholdTokenSymbol,
  percentage,
  price,
  spotPriceValue,
  isSelected,
  onClick,
}: PercentageBoxProps) => {
  const toolTipText = () => {
    return `1 ${thresholdTokenSymbol !== undefined ? thresholdTokenSymbol : ""} = ${formatBigNumber(
      price.spotPrice ? price.spotPrice.toString() : "0.00"
    ).full
      } ${otherTokenSymbol}`;
  };
  return (
    <div onClick={() => onClick(percentage)} className="tw-cursor-pointer">
      <div
        className={`${isSelected
          ? "tw-bg-green-300"
          : "tw-bg-coolGray-100 tw-border tw-border-coolGray-300"
          } tw-rounded-full tw-flex tw-justify-center tw-items-center tw-h-7 tw-mb-4 tw-text-xl tw-font-normal`}
      >
        <Tooltip
          id="threshold-percentage"
          tooltipText={toolTipText()}
        >{`${percentage}%`}</Tooltip>
      </div>
      <div className="tw-text-center">
        <Tooltip
          id="threhold-value"
          tooltipText={`${formatBigNumber(
            price.spotPrice ? price.spotPrice.toString() : "0.00"
          ).full
            }`}
          hide={
            formatBigNumber(
              price.spotPrice ? price.spotPrice.toString() : "0.00"
            ).same
          }
        >{`${formatBigNumber(price.spotPrice ? price.spotPrice.toString() : "0.00")
          .trunc
          }`}</Tooltip>
      </div>
      <div className="tw-text-center tw-text-xs tw-whitespace-nowrap">
        <Tooltip
          id="threhold-dollar-value"
          tooltipText={`$ ${formatBigNumber(
            price.spotPrice ? price.usdPrice.toString() + "00000000" : "0.00",
            DOLLAR_DECIMALS
          ).full
            }`}
          hide={
            formatBigNumber(
              price.spotPrice ? price.usdPrice.toString() + "00000000" : "0.00",
              DOLLAR_DECIMALS
            ).same
          }
        >{`($ ${formatBigNumber(
          price.spotPrice ? price.usdPrice.toString() : "0.00",
          DOLLAR_DECIMALS
        ).trunc
          })`}</Tooltip>
      </div>
    </div>
  );
};
