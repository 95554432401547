/**
 * Does string represent a number
 *
 *  @see {@link https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number}
 *
 */

export const isNumeric = (str: unknown): boolean => {
  return (
    !isNaN(str as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str as string))
  ); // ...and ensure strings of whitespace fail
};
