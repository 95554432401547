import React, { useEffect, useState } from "react";
import { StateStore } from "../state/state";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import style from "./PoolSearchInput.module.scss";
import { Pair, PairCache } from "../state/pairCache";
import Web3 from "web3";
import { Decimal } from "decimal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  CalcLPTokenRes,
  NETWORK_SYMBOLS,
  PairContract,
  toDecimalWithPrecision,
} from "../services/contracts/PairContract";
import { isErr, isOk } from "../result";
import { Validation } from "../validation";
import { Agent } from "../state/agents";
import { Trigger, TriggerState, TriggerType } from "../state/triggers";
import { enforcePoolBalance, Network } from "../configs";
import { DropdownSearch } from "./DropdownSearchPool/DropdownSearchPool";
import { PoolData } from "./DropdownSearchPool/DropdownSearchPool.data";
import ERC20Contract from "../services/contracts/ERC20Contract";
import classNames from "classnames";
import TooltipBox from "../utils/tooltip/tooltip";
import { InvalidReason, PoolValidation } from "./NewTriggerModal";
import useDebounce from "../utils/misc/debounce";
import { formatBigNumber } from "../utils/numeric/formatBigNumber";
import { apiChainToNetwork, enumToString } from "../api/common";
import { Chain, HoldingPool } from "../state/assets";

export interface ValidatePoolParams {
  text: string;
  pair: Pair | null;
  alreadyRegistered: boolean;
  balance: Decimal | undefined;
  twoTriggersOnPool: boolean;
}

function hasBalance(balance: undefined | Decimal): boolean {
  if (typeof balance === "undefined") return false;
  return !balance.isZero();
}

function isEthereumAddress(address: string): boolean {
  return Web3.utils.isAddress(address);
}

function validatePoolText({
  text,
  pair = null,
  alreadyRegistered,
  balance,
  twoTriggersOnPool,
}: ValidatePoolParams): PoolValidation {
  if (typeof balance !== "undefined" && !hasBalance(balance)) {
    return {
      validity: Validation.INVALID,
      reason: InvalidReason.NO_BALANCE,
    };
  }
  if (text === "") {
    return {
      validity: Validation.UNKNOWN,
    };
  }
  if (pair === undefined) {
    return {
      validity: Validation.INVALID,
    };
  }
  if (alreadyRegistered) {
    return {
      validity: Validation.INVALID,
      reason: InvalidReason.ALREADY_REGISTERED,
    };
  }
  if (twoTriggersOnPool) {
    return {
      validity: Validation.INVALID,
      reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
    };
  }
  return { validity: Validation.VALID };
}

interface PoolSearchInputProps {
  state: StateStore;
  pools: HoldingPool[];
  preSelectedPoolAddress: string | undefined;
  cachedPairs: PairCache;
  pair?: Pair;
  agent: Agent;
  onPairChange: (p: Pair | undefined) => void;
  setHasBalance: (hasBalance: boolean) => void;
  lpToken: CalcLPTokenRes | undefined;
  setLpToken: (str: CalcLPTokenRes | undefined) => void;
  setTwoAgentsRegisteredToPool: (twoAgentsRegisteredToPool: boolean) => void;
  twoAgentsRegisteredToPool: boolean;
  balance: string;
  setBalance: (bal: string) => void;
  approveAmount: string;
  setApproveAmount: (amount: string) => void;
  holdingPools: PoolData[];
  setHoldingPools: (pools: PoolData[]) => void;
  poolValidation: PoolValidation;
  setPoolValidation: (validation: PoolValidation) => void;
  alreadySelectedPool: HoldingPool | undefined;
}

export const PoolSearchInput = (props: PoolSearchInputProps) => {
  const [queryingPoolInfo, setQueryingPoolInfo] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [tokenInputText, setTokenInputText] = useState<string>(
    props.pair !== undefined ? props.pair.address : ""
  );
  const [isAddressPaste, setIsAddressPaste] = useState<boolean>(false);
  const debounceText = useDebounce<string>(tokenInputText, 1000); // 1 sec delay

  const [fetchingHoldingToken, setFetchingHoldingToken] =
    useState<boolean>(true);

  const [filteredHoldingTokens, setFilteredHoldingTokens] = useState<
    HoldingPool[]
  >([]);

  const { state } = props;

  let isInvalidAmount = false;
  if (props.approveAmount) {
    isInvalidAmount = new Decimal(props.approveAmount).lte(0);
  }
  const fetchTokenBalance = async (tokenAddress: string) => {
    if (state.web3.address) {
      const balance = await ERC20Contract.getBalance(
        state.web3.address,
        tokenAddress
      );

      if (isOk(balance)) {
        const decimals = await ERC20Contract.getDecimals(tokenAddress);
        if (isOk(decimals)) {
          const decimalBalance = toDecimalWithPrecision(
            balance.value,
            parseInt(decimals.value)
          );

          props.setBalance(decimalBalance);
        }
      }
    }
  };

  function updateAmount(amount: string) {
    if (amount === "") {
      props.setApproveAmount(amount);
      return;
    }
    const balanceBN = new Decimal(props.balance);
    if (new Decimal(amount).lte(new Decimal(0))) {
      isInvalidAmount = true;
    }

    if (new Decimal(amount).gt(balanceBN)) {
      isInvalidAmount = true;
      return;
    }
    // Not allow to enter decimal part more than 18 digit
    const [, decimalPart] = amount.split(".");
    if (decimalPart && decimalPart.length > 18) {
      return;
    }

    // don't allow more than 150 characters
    if (amount.length > 150) {
      return;
    }

    props.setApproveAmount(amount);
  }

  const twoOrMoreTriggersOnPool = (poolAddress: string): boolean => {
    const triggers = state.triggers.list();
    return Boolean(
      triggers.filter((trigger: Trigger) => {
        return Boolean(
          trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
          ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
            trigger.state
          ) &&
          trigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY
        );
      }).length >= 2
    );
  };

  const agentAlreadyRegisteredToPool = (
    poolAddress: string,
    agentUuid: string
  ): boolean => {
    const triggers = state.triggers.list();
    return triggers.some((trigger: Trigger) => {
      return Boolean(
        trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
        trigger.agentUuid === agentUuid
      );
    });
  };

  const fullPoolTextValidation = async (text: string) => {
    setQueryingPoolInfo(true);
    await fullPoolTextValidationInner(text);
    //adding 10 sec delay as bakend takes time to respond with updated trigger list
    setTimeout(() => {
      setQueryingPoolInfo(false);
    }, 10000);
  };

  const fullPoolTextValidationInner = async (text: string) => {
    let pair: Pair | undefined;
    const { address } = state.web3;
    if (address === undefined) {
      props.setPoolValidation({ validity: Validation.UNKNOWN });
      return;
    }

    // clear the previous liquidity
    props.setLpToken(undefined);

    // handle empty text case
    if (text === "") {
      props.setBalance("");
      props.onPairChange(pair);
      return;
    }

    // check to see if this looks like a valid address
    if (!isEthereumAddress(text)) {
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.INVALID_ADDRESS,
      });
      props.onPairChange(pair);
      return;
    }
    const registered = agentAlreadyRegisteredToPool(text, props.agent.uuid);

    const twoTriggersOnPool = twoOrMoreTriggersOnPool(text);

    if (twoTriggersOnPool) {
      props.setTwoAgentsRegisteredToPool(true);
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
      });
    } else {
      props.setTwoAgentsRegisteredToPool(false);
    }

    // they are signed in to get here so will have an address
    const holdingPools = [
      ...props.state.assets.holdingPoolsUniswap,
      ...props.state.assets.holdingPoolCake,
    ];
    const holdingPool = holdingPools.find(
      (pool) => pool.address.toUpperCase() === text.toUpperCase()
    );
    // If holding pool address is not found in stored pools
    if (holdingPool === undefined) {
      props.setPoolValidation({ validity: Validation.UNKNOWN });

      const result = await PairContract.fetchPoolInformation(
        text,
        state.web3.networkId,
        state.web3.chainId
      );

      if (isErr(result)) {
        props.setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.INVALID_POOL,
        });
        return;
      }

      pair = result.value;
      const balanceResult = await PairContract.getBalanceOnPairContract(
        text,
        address
      );
      if (isOk(balanceResult)) {
        props.setHasBalance(hasBalance(new Decimal(balanceResult.value)));
      }
      const lpTokenStingResult = await PairContract.calcLPTokenString(
        address,
        text,
        state.web3.chainId
      );

      if (isOk(lpTokenStingResult)) {
        props.setLpToken(lpTokenStingResult.value);
      }
      fetchTokenBalance(pair.address);
      props.onPairChange(pair);
      return;
    }
    const balanceResult = holdingPool.balance;

    const lpTokenStingResult = await PairContract.calcLPTokenString(
      address,
      text,
      state.web3.chainId
    );

    if (isOk(lpTokenStingResult)) {
      props.setLpToken(lpTokenStingResult.value);
    }

    if (state.web3.networkId === Network.UNKNOWN) {
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }

    const isPoolOnUndefinedNetworkRes = await PairContract.isNetwork(
      text,
      undefined
    );

    const isPoolOnUndefinedNetwork = Boolean(
      isOk(isPoolOnUndefinedNetworkRes) && isPoolOnUndefinedNetworkRes.value
    );

    if (isPoolOnUndefinedNetwork) {
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.POOL_NOT_ON_NETWORK,
      });
      return;
    }

    if (state.web3.networkId === Network.BSC) {
      const isPoolBSCResult = await PairContract.isNetwork(
        text,
        NETWORK_SYMBOLS.CAKE_LP
      );
      const isPoolBSC = Boolean(isOk(isPoolBSCResult) && isPoolBSCResult.value);

      if (!isPoolBSC) {
        props.setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.POOL_NOT_PANCAKE_SWAP,
        });

        return;
      }
    }

    if (state.web3.networkId === Network.ETH) {
      const isPoolV2Result = await PairContract.isNetwork(
        text,
        NETWORK_SYMBOLS.UNI_V2
      );
      const isPoolV2 = Boolean(isOk(isPoolV2Result) && isPoolV2Result.value);

      if (!isPoolV2) {
        props.setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.POOL_NOT_v2,
        });
        return;
      }
    }

    if (balanceResult === undefined) {
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.NETWORK_ERROR,
      });

      return;
    }
    const price = await PairContract.fetchPairPrice(
      holdingPool.address,
      Number(holdingPool.token0Decimal),
      Number(holdingPool.token1Decimal)
    );

    if (isErr(price)) {
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.POOL_PRICE_NOT_FOUND,
      });
      return;
    }

    const balanceRetrieved = balanceResult;
    if (enforcePoolBalance()) {
      props.setHasBalance(hasBalance(new Decimal(balanceRetrieved)));
    } else {
      props.setHasBalance(true);
    } pair = {
      address: holdingPool.address,
      price: price.value.toString(),
      liquidity: new Decimal(holdingPool.balance),
      network: apiChainToNetwork(enumToString(Chain, holdingPool.network)),
      token0: holdingPool.token0.toUpperCase(),
      token1: holdingPool.token1.toUpperCase(),
      token0Decimals: Number(holdingPool.token0Decimal),
      token1Decimals: Number(holdingPool.token1Decimal),
      token0Address: holdingPool.token0Address,
      token1Address: holdingPool.token1Address,
    };
    props.cachedPairs.add(pair);
    // compute the validity
    props.setPoolValidation(
      validatePoolText({
        text,
        pair,
        alreadyRegistered: registered,
        balance: new Decimal(balanceRetrieved),
        twoTriggersOnPool,
      })
    );
    fetchTokenBalance(pair.address);

    props.onPairChange(pair);
  };

  const onPoolSelect = (pool: string | undefined) => {
    props.setApproveAmount("");
    if (pool !== undefined) {
      setTokenInputText(pool);
      fullPoolTextValidation(pool);
    } else {
      // clear previously selected pair
      props.onPairChange(undefined);
    }
  };

  useEffect(() => {
    setFilteredHoldingTokens(props.pools);
    if (props.preSelectedPoolAddress && props.pair === undefined) {
      onPoolSelect(props.preSelectedPoolAddress);
    }
    if (!props.pair) {
      props.setPoolValidation({ validity: Validation.UNKNOWN });
    }
  }, []);

  useEffect(() => {
    const twoTriggersOnPool = twoOrMoreTriggersOnPool(tokenInputText);

    if (twoTriggersOnPool) {
      props.setTwoAgentsRegisteredToPool(true);
      props.setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
      });
    } else {
      props.setTwoAgentsRegisteredToPool(false);
    }
  }, [state.triggers.length, tokenInputText]);

  return (
    <>
      <Form.Group>
        <Form.Label className={style.ctrlLabelTextColor}>
          Enter Pair Address
        </Form.Label>
        {
          <DropdownSearch
            filteredHoldingTokens={filteredHoldingTokens}
            setFilteredHoldingTokens={setFilteredHoldingTokens}
            allHoldingToken={props.pools}
            poolValidation={props.poolValidation}
            setPoolValidation={props.setPoolValidation}
            onPoolSelected={onPoolSelect}
            inputText={tokenInputText}
            setInputText={setTokenInputText}
            isFocus={isFocus}
            setIsFocus={setIsFocus}
            pair={props.pair}
            state={props.state}
            setIsAddressPaste={setIsAddressPaste}
            isAddressPaste={isAddressPaste}
            setPair={props.onPairChange}
          />
        }
        {queryingPoolInfo && (
          <div className={style.informationContainer}>
            <FontAwesomeIcon icon={faSpinner} spin />
            <p>Looking up pool information...</p>
          </div>
        )}
      </Form.Group>

      {!queryingPoolInfo && props.pair !== undefined && (
        <>
          <p className={style.ctrlLabelTextColor}>Selected Pair</p>
          <p className={style.mainText}>
            {props.pair.token0} - {props.pair.token1}
          </p>
          <p className={style.addressText}>{props.pair.address}</p>
          <>
            <p className={style.ctrlLabelTextColor}>Liquidity</p>
            {props.lpToken !== undefined && props.lpToken.hasLiquidity ? (
              <p className={style.mainText}>
                {
                  <TooltipBox
                    child={formatBigNumber(props.lpToken.numLpTokens).trunc}
                    tooltipTextClassName="tool-tip-balance"
                    text={`${props.balance}`}
                    hideTooltip={props.balance === "0"}
                  />
                }{" "}
                LP tokens ({props.lpToken.numToken0}
                {" ,"}
                {props.lpToken.numToken1})
              </p>
            ) : (
              <p className={style.mainText}>No Liquidity</p>
            )}
          </>
          <Form.Label className={style.ctrlLabelTextColor}>
            Amount to approve for withdraw
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  {`The amount of ${props.pair.token0}-${props.pair.token1} LP token you want to be withdrawn.
                    Since you are the owner of your ${props.pair.token0}-${props.pair.token1} LP tokens even after approval,
                    in case your actual  ${props.pair.token0}-${props.pair.token1} LP token balance eventually reduces from the approved amount you configure
                    then the whole of your ${props.pair.token0}-${props.pair.token1} LP token balance will be withdrawn when the trigger executes.`}
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} className={style.icon} />
            </OverlayTrigger>
          </Form.Label>
          <Form.Group style={{ position: "relative" }}>
            <Form.Control
              data-testid="approve-amount"
              type="number"
              placeholder="0.0"
              value={props.approveAmount}
              onChange={(e) => updateAmount(e.target.value)}
              className={`${style.input} ${isInvalidAmount ? style.errorInput : null
                }`}
              disabled={!props.balance}
              isInvalid={Boolean(isInvalidAmount)}
            />
            {props.balance && (
              <div
                className={classNames(
                  style.maxButton,
                  isInvalidAmount ? style.invalid : null
                )}
                onClick={() => props.setApproveAmount(props.balance)}
              >
                MAX
              </div>
            )}
          </Form.Group>
        </>
      )}
    </>
  );
};
