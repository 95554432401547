import BN from "bn.js";
import { Feature } from "./state/features";
import { WINDOW } from "./window.types";
import { anErr, anOk, Result } from "./result";
import { UnitInfo } from "./utils/display/toNonCanonicalDisplay";
import { omitObjectProperties } from "./utils/misc/omitObjectProperties";

export const ZERO_ROUTER_ADDRESS =
  "0x0000000000000000000000000000000000000000000000000000000000000000";

export const ZERO_PAIR_ADDRESS = "0x0000000000000000000000000000000000000000";

export const API_URL: string =
  process.env.REACT_APP_API_URL ?? "http://127.0.0.1:8000";

export const BITSKI_CLIENT_ID: string | null =
  process.env.BITSKI_CLIENT_ID ?? null;

export const BITSKI_CALLBACK_URL: string | null =
  process.env.REACT_APP_API_URL ?? null;

export const INFURA_ID: string | null =
  process.env.INFURA_ID ??
  "cab205e574974e6d903844cb7da7537d";

export const TOKEN_RENEWAL_OFFSET: number = parseInt(
  process.env.REACT_APP_TOKEN_RENEWAL_OFFSET ?? (2 * 60).toString()
); // seconds

export enum ENVIROMENT_TYPES {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  STAGING = "staging",
  PREPROD = "preprod",
  QA = "qa",
}

type Environment = "development" | "production" | "staging" | "preprod" | "qa";
function toEnv(value: string | undefined): ENVIROMENT_TYPES {
  if (
    value === ENVIROMENT_TYPES.PRODUCTION ||
    value === ENVIROMENT_TYPES.PREPROD ||
    value === ENVIROMENT_TYPES.STAGING ||
    value === ENVIROMENT_TYPES.QA
  ) {
    return value;
  }
  return ENVIROMENT_TYPES.DEVELOPMENT;
}

export const ENVIRONMENT: Environment = toEnv(
  process.env.REACT_APP_ENVIRONMENT
);

export const CHROME_STORE_METAMASK_URL =
  "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
export const FIREFOX_STORE_METAMASK_URL =
  "https://addons.mozilla.org/en-GB/firefox/addon/ether-metamask/";

export const APP_NAME: string = process.env.REACT_APP_NAME ?? "Development";
export const VERSION: string = process.env.REACT_APP_VERSION ?? "0.0.0-dev";

export const ENABLE_ETH_ADDRESS_DISPLAY: boolean = process.env
  .ENABLE_ETH_ADDRESS_DISPLAY
  ? Boolean(process.env.ENABLE_ETH_ADDRESS_DISPLAY)
  : false;

export const PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE: BN = new BN(
  process.env.GAS_AMOUNT ?? "500000"
);

export interface GasPrices {
  LOW: string;
  MEDIUM: string;
  HIGH: string;
}

export const GAS_PRICES_ETH: GasPrices = {
  LOW: process.env.GAS_PRICES_LOW ?? "100",
  MEDIUM: process.env.GAS_PRICES_MEDIUM ?? "200",
  HIGH: process.env.GAS_PRICES_MEDIUM ?? "300",
};

export const GAS_PRICES_BSC: GasPrices = {
  LOW: "10",
  MEDIUM: "20",
  HIGH: "30",
};

const GAS_STATION_API_KEY_BSC = "6f9e1bdf73b3429994f592a6c796e8f1";

// try to estimate this is only a fallback
export const GAS_AMOUNT_ESTIMATE_VAULT_DEPOSIT: string =
  process.env.VAULT_DEPOSIT_GAS_AMOUNT ?? "2500000";

// try to estimate this is only a fallback
export const GAS_AMOUNT_ESTIMATE_APPROVE_ON_ERC20: string =
  process.env.ERC20_APPROVE_GAS_AMOUNT ?? "2500000";

export const ENABLE_SIGNUP: boolean =
  (process.env.REACT_APP_ENABLE_SIGNUP ?? "true") === "true";

export const BINANCE_API = "https://api.binance.com/api/v3/";

export const INTEGRAL_DIGIT_COUNT = 2;
export const LAST_INTEGRAL_DIGIT_COUNT = 2;
export const FRACTIONAL_DIGIT_COUNT = 4;

interface CurrencyInfo {
  name: string;
  symbol: string;
  decimals: number;
  unitInfo: UnitInfo;
}

const BNBInfo: Omit<CurrencyInfo, "unitInfo"> = {
  name: "Binance Coin",
  symbol: "BNB",
  decimals: 18,
};

/**
 * First value of array is primary
 *
 */
export interface NetworkParameters {
  chainId: number;
  proxyContractAddress: string;
  withdrawLiquidityProxyContractAddress: string;
  swapProxyContractAddress: string;
  fetchTokenContract?: string;
  vaultContractAddress?: string;
  wrappedNativeTokenAddress: string;
  legacyWithdrawProxyContractAddress: string;
  legacySwapProxyContractAddress: string;
  factoryContractAddress: string;
  routerV2ContractAddress: string;
  chainName: string;
  nativeCurrency: Omit<CurrencyInfo, "unitInfo">;
  rpcUrls: Array<string>;
  blockExplorerUrls: Array<string>;
}

export const EthereumInfo: UnitInfo = {
  units: [
    {
      power: 0,
      name: "wei",
    },
    {
      power: 9,
      name: "Gwei",
    },
    {
      power: 18,
      name: "ETH",
    },
  ],
  decimals: 18,
};

export const BSCInfo: UnitInfo = {
  units: [
    {
      power: 0,
      name: "wei",
    },
    {
      power: 9,
      name: "Gwei",
    },
    {
      power: 18,
      name: "BNB",
    },
  ],
  decimals: 18,
};

export const FetInfo: UnitInfo = {
  units: [
    {
      power: 0,
      name: "aFET",
    },
    {
      power: 3,
      name: "fFET",
    },
    {
      power: 6,
      name: "pFET",
    },
    {
      power: 9,
      name: "nFET",
    },
    {
      power: 12,
      name: "uFET",
    },
    {
      power: 15,
      name: "mFET",
    },
    {
      power: 18,
      name: "FET",
    },
  ],
  decimals: 18,
};

export const MAINNET_CHAIN_ID = 1;
export const RINKEBY_CHAIN_ID = 4;
export const KOVAN_CHAIN_ID = 42;
export const BSC_TEST_NET_CHAIN_ID = 97;
export const BSC_MAIN_NET_CHAIN_ID = 56;
export const MAIN_WEBSITE_URI = "https://botswap.fi";
export const UNISWAP_POOLS = "https://v2.info.uniswap.org/pair/";
export const PANCAKE_POOLS = "https://pancakeswap.info/pool/";
export const protocols = {
  TYPE_UNISWAP: 1,
  TYPE_PANCAKESWAP: 2,
};

export enum Network {
  ETH,
  BSC,
  UNKNOWN,
  RINKEBY = 4,
}

const getProxyContractAddress = (chainId: number) => {
  if (ENVIRONMENT === "staging") {
    return mapChainId(chainId) === Network.ETH
      ? {
          withdraw: "0x340E6bbcD801a703DfAD1011B48b31AB6928c5De",
          swap: "0x45fEe1D744c0AC247f0be2c0f867Fe9b5745F8B8",
          vault: "0xa2838583027d633D44fA837D7F60Da450D2A052A",
          legacyWithdrawProxy: "0x1c090E26717E8e36dD6b3AF88ff71Bbd1c6Fe394",
          legacySwapProxy: "0x98eae847B3f4388ee52D2b5C8b4857c3E986ac86",
        }
      : {
          withdraw: "0x1750F9868Af50971e8eba8A309aff155659285ee",
          swap: "0x89106E850dcF5d68E6066793cbcBAA852B6Aa998",
          vault: "0x5a43eCf6cE9b43AA4D32eEF8D6bE6c145d414870",
          legacyWithdrawProxy: "0xaF785C18627132eA7b48B6AD77B3002881597158",
          legacySwapProxy: "0x2Ed2dfE0a060c32fcBDA21bB3fc3F908fd38f1e9",
        };
  }
  // QA
  return mapChainId(chainId) === Network.ETH
    ? {
        withdraw: "0x3e5A4BcDF282f6a779856E804BE69091c505fBA7",
        swap: "0xb22eBe89e8649952F38C4aF596D64324d223B312",
        vault: "0x49a6825f223dcd9b619b33081DdA4024179134e0",
        legacyWithdrawProxy: "0x1c090E26717E8e36dD6b3AF88ff71Bbd1c6Fe394",
        legacySwapProxy: "0x98eae847B3f4388ee52D2b5C8b4857c3E986ac86",
      }
    : {
        withdraw: "0xd9E215C84d15F3fA2F4AC9b8D371f125E343C610",
        swap: "0xADff54d9ac7426Ac578F342cC2eE8803f686d5fC",
        vault: "0x48988a57eb690d8476D3d300eA07ab94078884dc",
        legacyWithdrawProxy: "0xaF785C18627132eA7b48B6AD77B3002881597158",
        legacySwapProxy: "0x2Ed2dfE0a060c32fcBDA21bB3fc3F908fd38f1e9",
      };
};

export const getNetworkDetails = (
  chainId: number
): Result<NetworkParameters> => {
  switch (chainId) {
    case KOVAN_CHAIN_ID:
      return anOk({
        chainId: KOVAN_CHAIN_ID,
        proxyContractAddress: "0x1c090E26717E8e36dD6b3AF88ff71Bbd1c6Fe394",
        withdrawLiquidityProxyContractAddress:
          getProxyContractAddress(chainId).withdraw,
        swapProxyContractAddress: getProxyContractAddress(chainId).swap,
        fetchTokenContract: "0xe4c685195D111eeb1ee9b509f17357761Ee2d786",
        vaultContractAddress: getProxyContractAddress(chainId).vault,
        wrappedNativeTokenAddress: "0xd0A1E359811322d97991E03f863a0C30C2cF029C",
        legacyWithdrawProxyContractAddress:
          getProxyContractAddress(chainId).legacyWithdrawProxy,
        legacySwapProxyContractAddress:
          getProxyContractAddress(chainId).legacySwapProxy,
        factoryContractAddress: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
        chainName: "Kovan",
        nativeCurrency: omitObjectProperties(EthereumInfo, "unitInfo"),
        rpcUrls: ["https://kovan.poa.network"],
        blockExplorerUrls: ["https://kovan.etherscan.io"],
        routerV2ContractAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      });

    case MAINNET_CHAIN_ID:
      return anOk({
        chainId: MAINNET_CHAIN_ID,
        proxyContractAddress: "0xFCdbB0C4706fF8cc1EEEF7AfA9cAD4fE863Fd2d5",
        withdrawLiquidityProxyContractAddress:
          "0x9c2ac11fe3ee8D91AC0adDb24F452E148e090c93",
        swapProxyContractAddress: "0x14d7F298Da82764fc980f3Ae80D83F78d96F9f6f",
        fetchTokenContract: "0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85",
        vaultContractAddress: "0x23422470f684e9F405D86CE593C3730ef6df3A48",
        wrappedNativeTokenAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
        legacyWithdrawProxyContractAddress:
          "0xFCdbB0C4706fF8cc1EEEF7AfA9cAD4fE863Fd2d5",
        legacySwapProxyContractAddress:
          "0xEB6Ec6988F9c75a9f7A1311cC731CA6270873ef4",
        factoryContractAddress: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
        chainName: "ETH Mainnet",
        nativeCurrency: omitObjectProperties(EthereumInfo, "unitInfo"),
        rpcUrls: [""],
        blockExplorerUrls: ["https://etherscan.io"],
        routerV2ContractAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      });

    case BSC_MAIN_NET_CHAIN_ID:
      return anOk({
        chainId: BSC_MAIN_NET_CHAIN_ID,
        proxyContractAddress: "0xFCdbB0C4706fF8cc1EEEF7AfA9cAD4fE863Fd2d5",
        withdrawLiquidityProxyContractAddress:
          "0x7F01D86dF972E0c8878A3373C289D821eFF99a3B",
        swapProxyContractAddress: "0x4C185C95d4Aca592EC10C677b261C426d8847F54",
        factoryContractAddress: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
        vaultContractAddress: "0xfD113B9A1AE64F9402C7AEB2dB2cA865bA3848b2",
        fetchTokenContract: "0x031b41e504677879370e9dbcf937283a8691fa7f",
        wrappedNativeTokenAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
        legacyWithdrawProxyContractAddress:
          "0xFCdbB0C4706fF8cc1EEEF7AfA9cAD4fE863Fd2d5",
        legacySwapProxyContractAddress:
          "0x873c434966c3078886087b076eA152C7f115a59d",
        chainName: "BSC Mainnet",
        nativeCurrency: omitObjectProperties(BNBInfo, "unitInfo"),
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com"],
        routerV2ContractAddress: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
      });

    case BSC_TEST_NET_CHAIN_ID:
      return anOk({
        chainId: BSC_TEST_NET_CHAIN_ID,
        proxyContractAddress: "0xaF785C18627132eA7b48B6AD77B3002881597158",
        withdrawLiquidityProxyContractAddress:
          getProxyContractAddress(chainId).withdraw,
        swapProxyContractAddress: getProxyContractAddress(chainId).swap,
        factoryContractAddress: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
        vaultContractAddress: getProxyContractAddress(chainId).vault,
        fetchTokenContract: "0xcca1a8f33a4b4e89964ceb216bbd5ebadb38c155",
        wrappedNativeTokenAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
        legacyWithdrawProxyContractAddress:
          getProxyContractAddress(chainId).legacyWithdrawProxy,
        legacySwapProxyContractAddress:
          getProxyContractAddress(chainId).legacySwapProxy,
        chainName: "BSC Testnet",
        nativeCurrency: omitObjectProperties(BNBInfo, "unitInfo"),
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
        blockExplorerUrls: ["https://testnet.bscscan.com"],
        routerV2ContractAddress: "0xdc4904b5f716Ff30d8495e35dC99c109bb5eCf81",
      });

    case RINKEBY_CHAIN_ID:
      return anOk({
        chainId: RINKEBY_CHAIN_ID,
        proxyContractAddress: "0x56379dde1EbdCe6aCa67C768Cc1d77375b490E95",
        withdrawLiquidityProxyContractAddress:
          "0x47a74EE8752749701FE72F871825a42ee1D699bA",
        swapProxyContractAddress: "0xC753c9eEf5371995670233E5f3E4e98554B54244",
        fetchTokenContract: "0x51128B6dd2946d30Ea1Bc38Ed1307fCD14B52dA5",
        vaultContractAddress: "0x367c7d19eBd296006B7f7a11C5bcB799c0DF8b87",
        wrappedNativeTokenAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
        legacyWithdrawProxyContractAddress:
          "0x56379dde1EbdCe6aCa67C768Cc1d77375b490E95",
        legacySwapProxyContractAddress:
          "0x2aA68BBe129beE41a7D6B717B0fC5650bB3A0C4B",
        factoryContractAddress: "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f",
        chainName: "Rinkeby",
        nativeCurrency: omitObjectProperties(EthereumInfo, "unitInfo"),
        rpcUrls: ["https://rinkeby-light.eth.linkpool.io/"],
        blockExplorerUrls: ["https://rinkeby.etherscan.io"],
        routerV2ContractAddress: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
      });

    default:
      return anErr(`unsupported network with chain id ${chainId}`, undefined, true);
  }
};

interface EnvironmentConfig {
  ethChainId: number;
  bscChainId: number;
}

export const ENVIRONMENT_CONFIG: EnvironmentConfig = ((): EnvironmentConfig => {
  switch (ENVIRONMENT) {
    case "development":
    case "staging":
    case "qa":
      return {
        ethChainId: RINKEBY_CHAIN_ID,
        bscChainId: BSC_TEST_NET_CHAIN_ID,
      };
    case "production":
    case "preprod":
      return {
        ethChainId: MAINNET_CHAIN_ID,
        bscChainId: BSC_MAIN_NET_CHAIN_ID,
      };
  }
})();

export const GRAPHQL_API: string =
  process.env.REACT_APP_GRAPHQL_API ??
  "https://gateway-agentsplatform.sandbox-london-b.fetch-ai.com/";

export function mapChainId(chainId: number): Network {
  if (ENVIRONMENT_CONFIG.ethChainId === chainId) {
    return Network.ETH;
  }
  if (ENVIRONMENT_CONFIG.bscChainId === chainId) {
    return Network.BSC;
  }
  return Network.UNKNOWN;
}

export const ENABLE_SYNC_LOGGING = false;

// useful to disable when debugging
let cfgEnforcePoolBalance = true;
export function enforcePoolBalance(): boolean {
  return cfgEnforcePoolBalance;
}

// Firebase config

export const FIREBASE_APP_ID: string | undefined =
  process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID: string | undefined =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

function splitCommas(text: string | undefined): string[] {
  if (text === undefined) {
    return [];
  }

  return text.split(",").map((x) => x.trim());
}

export const DEFAULT_ENABLED_FEATURES: Feature[] = splitCommas(
  process.env.REACT_APP_DEFAULT_ENABLED_FEATURES
) as Feature[];

export const DEFAULT_DISABLED_FEATURES: Feature[] = splitCommas(
  process.env.REACT_APP_DEFAULT_DISABLED_FEATURES
) as Feature[];

export const KEY_EVENT_ENTER = "Enter";
export const KEY_EVENT_SPACE = " ";

let debugEnabledFeatures: string[] = [];
let debugDisabledFeatures: string[] = [];

export function notifyEnabledFeatures(features: Set<string>) {
  debugEnabledFeatures = [...features];
}

export function notifyDisabledFeatures(features: Set<string>) {
  debugDisabledFeatures = [...features];
}

/**
 * designed for debugging in console so not dead code
 */
// @ts-ignore
window[WINDOW.DEFI_AGENTS] = {
  disablePoolBalanceCheck: () => {
    cfgEnforcePoolBalance = false;
    console.log("cfgEnforcePoolBalance:", cfgEnforcePoolBalance);
  },
  enablePoolBalanceCheck: () => {
    cfgEnforcePoolBalance = true;
    console.log("cfgEnforcePoolBalance:", cfgEnforcePoolBalance);
  },
  getFirebaseConfig: () => {
    console.log(
      "appId:",
      FIREBASE_APP_ID,
      "measurementId",
      FIREBASE_MEASUREMENT_ID
    );
  },
  getCurrentFeatures: () => {
    console.log("Enabled Features", debugEnabledFeatures);
    console.log("Disabled Features", debugDisabledFeatures);
  },
};
