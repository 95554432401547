import { Agent } from "../../state/agents";
import { Network } from "../../configs";

export function calcAvailableAgents(
  allAgents: Agent[],
  networkId: Network,
  web3Address: string | undefined,
  agentNonCompletedTriggersCount: (agentUuid: string) => number
): Array<Agent> {
  if (web3Address === undefined) return [];

  return allAgents.filter((agent) => {
    if (agent.network === networkId) {
      if (
        agent.maxTriggers &&
        agent.userAddress === web3Address &&
        agentNonCompletedTriggersCount(agent.uuid) < agent.maxTriggers
      ) {
        return true;
      }
    }
    return false;
  });
}
