import { action, makeAutoObservable } from "mobx";
import { AccessToken } from "./token";
import { GasPriceInfo } from "../components/SelectGasPrice/SelectGasPrice";
import { LocalStorage } from "./localStorage";

export interface UserDetails {
  address: string;
  token: AccessToken;
}

export interface TokenStorageObject {
  accessToken: string;
  refreshToken: string;
  address: string;
}

export interface AccountDetails {
  agentLimit: number;
  balance: string;
}

export interface PlanDetails {
  name: string;
  agentSecondPrice: string;
}

function clearUserDetails(localStorage: LocalStorage) {
  localStorage.tokenDetails = undefined;
}

function storeUserDetails(details: UserDetails, localStorage: LocalStorage) {
  if (details.token === undefined) {
    return;
  }

  const data: TokenStorageObject = {
    address: details.address,
    accessToken: details.token.accessToken,
    refreshToken: details.token.refreshToken,
  };

  localStorage.tokenDetails = data;
}

export function loadAccessToken(
  localStorage: LocalStorage
): { token: AccessToken } | undefined {
  const data = localStorage.tokenDetails;
  if (data === undefined) {
    return undefined;
  }

  // check the expected fields are present
  if (data.accessToken === undefined || data.refreshToken === undefined) {
    localStorage.tokenDetails = undefined;
    return undefined;
  }

  // attempt to reconstruct the access token
  const accessToken = new AccessToken(data.accessToken, data.refreshToken);
  if (accessToken.hasExpired()) {
    localStorage.tokenDetails = undefined;
    return undefined;
  }

  return {
    token: accessToken,
  };
}

export class AccountStore {
  get fetPrice(): string | undefined {
    return this._fetPrice;
  }

  set fetPrice(value: string | undefined) {
    this._fetPrice = value;
  }

  get addresses(): Array<string> {
    return this._addresses;
  }

  set addresses(value: Array<string>) {
    this._addresses = value;
  }

  get fetBalance(): string | undefined {
    return this._fetBalance;
  }

  set fetBalance(value: string | undefined) {
    this._fetBalance = value;
  }

  user?: UserDetails;

  private _fetBalance?: string;

  private _fetPrice?: string;

  private _addresses: Array<string>;

  accountDetails?: AccountDetails;

  planDetails?: PlanDetails;

  gasPrice?: GasPriceInfo;

  _localStorage: LocalStorage;

  constructor(localStorage: LocalStorage) {
    this._localStorage = localStorage;
    makeAutoObservable(this);
    this._addresses = [];
    // attempt to load previous data
    const previous = loadAccessToken(this._localStorage);

    if (this._localStorage.loggedIn && previous !== undefined) {
      this.user = {
        address: (this._localStorage.tokenDetails as TokenStorageObject)
          .address,
        ...previous,
      };
    }
  }

  @action
  login(address: string, token: AccessToken) {
    this.user = {
      address,
      token,
    };

    // ensure the login details are persisted
    storeUserDetails(this.user, this._localStorage);
    this._localStorage.setLoggedIn(true);
  }

  @action
  updateAccessToken(token: AccessToken) {
    if (this.user === undefined) {
      return;
    }

    // update the token
    this.user.token = token;

    // ensure the login details are persisted
    storeUserDetails(this.user, this._localStorage);
  }

  @action
  isLoggedIn(): boolean {
    return this.user !== undefined;
  }

  @action
  setUserDetails(accountDetails: AccountDetails): void {
    this.accountDetails = accountDetails;
  }

  @action
  setPlanDetails(details: PlanDetails): void {
    this.planDetails = details;
  }

  @action
  logout() {
    this._localStorage.setLoggedIn(false);
    this.user = undefined;
    clearUserDetails(this._localStorage);
  }

  @action
  updateGasPrice(selectedGasPrice: GasPriceInfo) {
    this.gasPrice = selectedGasPrice;
  }
}
