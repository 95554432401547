import { asAbi } from "./utils";
import Provider from "../Provider";
import { anErr, anOk, isErr, Result } from "../../result";

import FactoryAbi from "../../abis/IUniswapV2Factory.json";
import { getNetworkDetails } from "../../configs";

export class FactoryContract {
  /**
   * Fetch all info for the pool, but return null if any part of it fails including pool not existing
   *
   * @param poolAddress
   */
  public static async fetchPair(
    token0: string,
    token1: string,
    chainId: number
  ): Promise<Result<string>> {
    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      return networkDetails;
    }

    const contract = new Provider._web3.eth.Contract(
      asAbi(FactoryAbi),
      networkDetails.value.factoryContractAddress
    );

    try {
      const pair: string = await contract.methods
        .getPair(token0, token1)
        .call();

      return anOk(pair);
    } catch (err) {
      return anErr("Unable to fetch pair", err);
    }
  }
}
