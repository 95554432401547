import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmailValidator from "email-validator";
import lodash from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { AccountsAPI } from "../../api/AccountsAPI";
import { AuthenticationAPI } from "../../api/AuthenticationAPI";
import checkCircle from "../../assets/check-circle.svg";
import editPencil from "../../assets/edit-pencil.svg";
import errorOutline from "../../assets/error-outline.svg";
import { isErr } from "../../result";
import { StateContext } from "../../state/state";
import { eventNameAnalysis } from "../../utils/misc/trackEvent";
import Tooltip from "../../utils/tooltip/tooltip";
import { EditEmailModal } from "../editEmailModal";
import style from "../FreeAccountPage.module.scss";

enum FeedbackIcon {
  EMAIL,
  PREFERENCES,
  NONE,
}

function isEmailInvalid(value: string): boolean {
  if (value === "") {
    return false;
  }

  return !EmailValidator.validate(value);
}

export const FreeProfileSection = () => {
  const state = useContext(StateContext);

  const [allowEditEmail, setAllowEditEmail] = useState<boolean>(false);
  const [emailConfirmation, setEmailConfirmation] = useState<boolean>(false);
  const [email, setEmail] = useState(
    state.preferences.userPreferences?.email ?? ""
  );
  const [notifyOnTrigger, setNotifyOnTrigger] = useState(false);
  const [notifyOnServiceExpiry, setNotifyOnServiceExpiry] = useState(false);
  const [notifyOnLowFunds, setnotifyOnLowFunds] = useState(false);
  const [showEditEmailModal, setShowEditEmailModal] = useState(false);
  const [emailUpdateInProgress, setEmailUpdateInProgress] = useState(false);
  const [showSuccessIcon, setShowSuccessIcon] = useState<FeedbackIcon>(
    FeedbackIcon.NONE
  );
  const [showFailedIcon, setShowFailedIcon] = useState<FeedbackIcon>(
    FeedbackIcon.NONE
  );

  const registerUserEmail = async (email: string) => {
    if (
      state.account.user === undefined ||
      state.account.user.token === undefined ||
      state.account.user.token.hasExpired()
    ) {
      return;
    }
    try {
      setEmailUpdateInProgress(true);
      // we dont have input fields for names, probably will add in future. So hardcoded for now
      const registerRes = await AuthenticationAPI.registerAccount(
        email,
        "first_name",
        "last_name",
        state.account.user.token.accessToken
      );
      if (registerRes) {
        setEmail(email);
        setEmailUpdateInProgress(false);
        setShowFailedIcon(FeedbackIcon.NONE);
        setShowSuccessIcon(FeedbackIcon.EMAIL);
        setAllowEditEmail(true);
        eventNameAnalysis("email_register_success", {
          message: "Email update successful",
        });
        setTimeout(() => setShowSuccessIcon(FeedbackIcon.NONE), 3000);
      } else {
        setEmailUpdateInProgress(false);
        setShowFailedIcon(FeedbackIcon.EMAIL);
        eventNameAnalysis("email_register_err", {
          message: "Email update failed",
        });
      }
    } catch (error: any) {
      eventNameAnalysis("email_register_err", {
        message: `Email update failed:${error.message}`,
      });
    }
  };

  const updatePreferences = async (
    email: string,
    notifyOnTrigger: boolean,
    notifyOnLowFunds: boolean,
    notifyOnServiceExpiry: boolean,
    changedField: FeedbackIcon
  ) => {
    if (
      state.account.user === undefined ||
      state.account.user.token === undefined ||
      state.account.user.token.hasExpired()
    ) {
      return;
    }
    if (!isEmailInvalid(email)) {
      const preferences = await AccountsAPI.updatePreferences(
        email,
        notifyOnTrigger,
        notifyOnLowFunds,
        notifyOnServiceExpiry,
        false,
        state.account.user.token.accessToken
      );
      if (isErr(preferences)) {
        setShowFailedIcon(FeedbackIcon.PREFERENCES);
      }
      setShowSuccessIcon(changedField);
      setShowFailedIcon(FeedbackIcon.NONE);
      setTimeout(() => setShowSuccessIcon(FeedbackIcon.NONE), 3000);
    }
  };
  const debouncedUpdate = useRef(
    lodash.debounce(
      (
        email,
        notifyOnTrigger,
        notifyOnLowFunds,
        notifyOnServiceExpiry,
        changedField
      ) =>
        updatePreferences(
          email,
          notifyOnTrigger,
          notifyOnLowFunds,
          notifyOnServiceExpiry,
          changedField
        ),
      1000
    )
  ).current;

  useEffect(() => {
    setEmail(state.preferences.userPreferences?.email ?? "");
    setAllowEditEmail(true);

    const preferences = state.preferences.userPreferences;
    if (preferences === undefined) {
      return;
    }

    setEmailConfirmation(preferences.email_confirmed);
    setNotifyOnTrigger(preferences.notify_trigger_status);
    setNotifyOnServiceExpiry(preferences.notify_service_expiry);
    setnotifyOnLowFunds(preferences.notify_low_balance);
  }, [
    state.preferences.userPreferences?.email,
    state.preferences.userPreferences?.email_confirmed,
  ]);

  useEffect(() => {
    const preferences = state.preferences.userPreferences;
    if (preferences === undefined) {
      return;
    }

    setEmail(preferences.email);
    setEmailConfirmation(preferences.email_confirmed);
    setNotifyOnTrigger(preferences.notify_trigger_status);
    setNotifyOnServiceExpiry(preferences.notify_service_expiry);
    setnotifyOnLowFunds(preferences.notify_low_balance);
  }, []);

  const showEmailConfirm = () => {
    if (email === "") return false;
    return Boolean(!isEmailInvalid(email) && emailConfirmation === false);
  };

  /**
   * text area automatically wraps long words.
   * If we add spaces it wraps automatically.
   *
   * long email gets spaces added every 27 chars to wrap.
   *
   * @param email
   */
  const formatLongEmail = (str: string) => {
    return str.replace(/.{18}/g, "$& ");
  };

  return (
    <div className={style.profileContainer}>
      <div className={style.profile}>
        <div className="tw-grid tw-grid-cols-1 tw-divide-y tw-justify-between">
          <div className={style.profileHeading}>Profile</div>
          <div>
            <div className={style.emailContainer}>
              <div className={style.titleContainer}>
                <div className={style.titleInnerContainer}>
                  Email{" "}
                  {showSuccessIcon === FeedbackIcon.EMAIL && (
                    <img src={checkCircle} alt="success" />
                  )}
                  {showFailedIcon === FeedbackIcon.EMAIL && (
                    <img src={errorOutline} alt="error" />
                  )}
                </div>

                {allowEditEmail && !emailUpdateInProgress && (
                  <Tooltip
                    tooltipTextClassName="tool-tip-email-confirm"
                    child={
                      <img
                        onClick={() => setShowEditEmailModal(true)}
                        src={editPencil}
                        alt="edit"
                      />
                    }
                    text="Edit"
                  />
                )}
                {emailUpdateInProgress && (
                  <FontAwesomeIcon icon={faSpinner} spin />
                )}
              </div>
              <Form.Row className={style.emailContainer}>
                <Form.Group as={Col} xs={10}>
                  <Form.Text
                    className={`${style.emailInput} ${
                      !emailConfirmation ? style.noTick : null
                    }`}
                  >
                    {formatLongEmail(email)}
                  </Form.Text>
                </Form.Group>
                <Form.Group as={Col} xs={2}>
                  {showEmailConfirm() && (
                    <Tooltip
                      tooltipTextClassName="tool-tip-verify-email"
                      child={
                        <img
                          className={style.emailConfirm}
                          src={errorOutline}
                          alt="error"
                        />
                      }
                      text="We have sent you a verification link to this email. 
                  You will be able to set your notification preferences once verified."
                    />
                  )}
                </Form.Group>
              </Form.Row>
            </div>
            <div className={style.notificationContainer}>
              <div className={style.title}>
                <div className={style.titleInnerContainer}>
                  Notification preferences
                  {showSuccessIcon === FeedbackIcon.PREFERENCES && (
                    <img
                      onClick={() => setAllowEditEmail(true)}
                      src={checkCircle}
                      alt="edit"
                    />
                  )}
                  {showFailedIcon === FeedbackIcon.PREFERENCES && (
                    <img
                      onClick={() => setAllowEditEmail(true)}
                      src={errorOutline}
                      alt="error"
                    />
                  )}
                </div>
              </div>
              <div className="tw-flex tw-justify-around">
                <div className={style.inputContainer}>
                  <input
                    type="checkbox"
                    id="notify_on_triggers"
                    name="notify_on_triggers"
                    checked={notifyOnTrigger}
                    disabled={!emailConfirmation}
                    onChange={(e) => {
                      setNotifyOnTrigger(e.target.checked);
                      debouncedUpdate(
                        email,
                        e.target.checked,
                        notifyOnLowFunds,
                        notifyOnServiceExpiry,
                        FeedbackIcon.PREFERENCES
                      );
                    }}
                  />
                  <label
                    htmlFor="notify_on_triggers"
                    className={`${!emailConfirmation ? style.disabled : null} `}
                  >
                    {" "}
                    Notify on triggers
                  </label>
                </div>
                <div className={style.inputContainer}>
                  <input
                    type="checkbox"
                    id="notify_on_service_expiry"
                    name="notify_on_service_expiry"
                    checked={notifyOnServiceExpiry}
                    disabled={!emailConfirmation}
                    onChange={(e) => {
                      setNotifyOnServiceExpiry(e.target.checked);
                      debouncedUpdate(
                        email,
                        notifyOnTrigger,
                        notifyOnLowFunds,
                        e.target.checked,
                        FeedbackIcon.PREFERENCES
                      );
                    }}
                  />
                  <label
                    htmlFor="notify_on_service_expiry"
                    className={`${!emailConfirmation ? style.disabled : null} `}
                  >
                    Notify on service expiry
                  </label>
                </div>
                <div className={style.inputContainer}>
                  <input
                    type="checkbox"
                    id="notify_when_low_on_funds"
                    name="notify_when_low_on_funds"
                    checked={notifyOnLowFunds}
                    disabled={!emailConfirmation}
                    onChange={(e) => {
                      setnotifyOnLowFunds(e.target.checked);
                      debouncedUpdate(
                        email,
                        notifyOnTrigger,
                        e.target.checked,
                        notifyOnServiceExpiry,
                        FeedbackIcon.PREFERENCES
                      );
                    }}
                  />
                  <label
                    htmlFor="notify_when_low_on_funds"
                    className={`${!emailConfirmation ? style.disabled : null} `}
                  >
                    Notify when low on funds
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditEmailModal
        existingEmail={email}
        show={showEditEmailModal}
        close={() => {
          setShowEditEmailModal(false);
        }}
        confirmed={emailConfirmation}
        update={(regEmail) => {
          registerUserEmail(regEmail);
          setShowEditEmailModal(false);
        }}
      />
    </div>
  );
};
