import { action, computed, makeAutoObservable, runInAction } from "mobx";
import firebase from "firebase/app";
import {
  DEFAULT_DISABLED_FEATURES,
  DEFAULT_ENABLED_FEATURES,
  notifyDisabledFeatures,
  notifyEnabledFeatures,
} from "../configs";
import { getFirebaseConfig } from "../firebase";
import { WINDOW } from "../window.types";
import { snooze } from "../utils/misc/snooze";
import { inEnum } from "../utils/misc/inEnum";

export enum Feature {
  BSC = "bsc",
  AGENT_FUNDS_MANAGEMENT = "agent-fund-mgmt",
  OLD_LOGIN_PAGE = "old-login-page",
}

export class FeatureFlags {
  enabled = new Set<string>();

  disabled = new Set<string>();

  constructor() {
    makeAutoObservable(this);
    // trigger the monitoring of the remote configuration options
    this.monitorConfigurationChanges();

    // @ts-ignore
    window[WINDOW.DISABLE_FEATURE] = this.disable.bind(this);
    // @ts-ignore
    window[WINDOW.ENABLE_FEATURE] = this.enable.bind(this);
  }

  @computed
  isEnabled(feature: Feature): boolean {
    return this.enabled.has(feature) && !this.disabled.has(feature);
  }

  @action
  update(enabled: Set<Feature>, disabled: Set<Feature>) {
    this.enabled = enabled;
    this.disabled = disabled;
  }

  @action
  enable(feature: Feature) {
    if (!inEnum(feature, Feature)) {
      console.error(
        `Feature passed as param (${feature}) is not a listed feature`
      );
      return;
    }

    if (!this.enabled.has(feature)) {
      this.enabled.add(feature);
    }
    if (this.disabled.has(feature)) {
      this.disabled.delete(feature);
    }
    console.log(`feature successfully enabled : ${feature}`);
  }

  @action
  disable(feature: Feature) {
    if (!inEnum(feature, Feature)) {
      console.error(
        `Feature passed as param (${feature}) is not a listed feature`
      );
      return;
    }

    if (this.enabled.has(feature)) {
      this.enabled.delete(feature);
    }
    if (!this.disabled.has(feature)) {
      this.disabled.add(feature);
    }

    console.log(`feature successfully disabled : ${feature}`);
  }

  private async monitorConfigurationChanges() {
    // wait for the configuration to be available
    let cfg;
    for (;;) {
      cfg = getFirebaseConfig();

      if (cfg !== undefined) {
        cfg.defaultConfig = {
          enabled_features: JSON.stringify(DEFAULT_ENABLED_FEATURES),
          disabled_features: JSON.stringify(DEFAULT_DISABLED_FEATURES),
        };
        break;
      }

      await snooze(1000);
    }

    // main monitor loop
    for (;;) {
      await this.checkForConfigUpdates(cfg);
      await snooze(60000);
    }
  }

  private async checkForConfigUpdates(
    cfg: firebase.remoteConfig.RemoteConfig
  ): Promise<boolean> {
    try {
      await cfg.fetchAndActivate();

      const enabledFeatures = new Set<Feature>(
        JSON.parse(cfg.getString("enabled_features"))
      );

      const disabledFeatures = new Set<Feature>(
        JSON.parse(cfg.getString("disabled_features"))
      );

      // since we are seeing issues with the firebase remote configuration we
      // adjust this logic slightly so that hard coded default features are
      // always respected.
      const finalEnableFeatures = new Set<Feature>([
        ...enabledFeatures,
        ...DEFAULT_ENABLED_FEATURES,
      ]);
      const finalDisabledFeatures = new Set<Feature>([
        ...disabledFeatures,
        ...DEFAULT_DISABLED_FEATURES,
      ]);

      // capture updates for the debugging menu
      notifyEnabledFeatures(finalEnableFeatures);
      notifyDisabledFeatures(finalDisabledFeatures);

      runInAction(() => {
        this.update(finalEnableFeatures, finalDisabledFeatures);
      });
    } catch (e) {
      console.log("Failed to check for config updates", e);
    }

    return true;
  }
}
