import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import cryptocurrencyBackup from "../../assets/cryptocurrency.svg";
import { NewTriggerModal, Stage } from "../../components/NewTriggerModal";
import Pagination from "../../components/pagination";
import { Network } from "../../configs";
import { HoldingToken, PoolDetails } from "../../state/assets";
import { StateContext, StateStore } from "../../state/state";
import { TriggerType } from "../../state/triggers";
import { eventAnalysis } from "../../utils/misc/trackEvent";
import { Tooltip as HelpToolTip } from "../../components/Tooltip";
import HelpIcon from "../../assets/help-icon.png";
import { getTokenErrorMessage, swapTradErrorLogAnalysis } from "./utils";
import { PoolOptionBtns } from "./PoolOptionBtn";
import { FiltersBlock, FilteringOptions } from "./FiltersBlock";

import {
  SORT_PROPERTIES,
  SORT_STAGES,
  Sorter,
  sortPoolsOrTokens,
} from "../../components/Sorter";
import { TokenTableRow } from "./TokenTableRow";

export let TokenTable: React.FunctionComponent<object>;

TokenTable = observer(() => {
  const state = useContext(StateContext) as StateStore;
  const [currentItems, setCurrentItems] = useState<HoldingToken[] | null>(null);
  const [allTokens, setallTokens] = useState<HoldingToken[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [showNew, setShowNew] = useState(false);
  const [filteringOptions, setFilteringOptions] = useState<FilteringOptions>({
    query: "",
    ordering: null,
    chain: null,
  });
  const history = useHistory();
  const itemsPerPage = 5;
  let columnDivRef = useRef<HTMLTableElement | null>(null);

  const [balanceSortState, setBalanceSortState] = useState<SORT_STAGES>(
    SORT_STAGES.BOTH
  );
  const [activeSortProperty, setActiveSortProperty] = useState<SORT_PROPERTIES>(
    SORT_PROPERTIES.DEFAULT
  );

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allTokens.length;
    setItemOffset(newOffset);
  };

  const compareFeesPerLiquidity = (a: PoolDetails, b: PoolDetails) => {
    // sort based on fee per liquidity
    if (parseFloat(a.fees_per_liquidity) - parseFloat(b.fees_per_liquidity) > 0)
      return -1;
    if (parseFloat(a.fees_per_liquidity) - parseFloat(b.fees_per_liquidity) < 0)
      return 1;
    return 0;
  };

  const removeDuplicatePools = (pools: PoolDetails[]) => {
    const filteredPools = pools.reduce(
      (acc: PoolDetails[], current: PoolDetails) => {
        const poolIndex = acc.findIndex(
          (item) =>
            item.token0_address.toUpperCase() ===
              current.token0_address.toUpperCase() &&
            item.token1_address.toUpperCase() ===
              current.token1_address.toUpperCase()
        );

        if (!acc.length) {
          return acc.concat([current]);
        }

        if (poolIndex !== -1) {
          // select pool having higher APR
          if (
            parseFloat(acc[poolIndex].fees_per_liquidity) <
            parseFloat(current.fees_per_liquidity)
          ) {
            acc[poolIndex] = { ...current };
          }
          return acc;
        } else {
          return acc.concat([current]);
        }
      },
      []
    );
    return filteredPools;
  };

  const getPoolSuggestionBtnData = (
    tokenAddress: string,
    suggestedPools: PoolDetails[] | undefined
  ) => {
    if (!suggestedPools) return [];
    const pools = suggestedPools.filter(
      (pool) =>
        pool.token0_address.toUpperCase() === tokenAddress.toUpperCase() ||
        pool.token1_address.toUpperCase() === tokenAddress.toUpperCase()
    );
    return removeDuplicatePools(pools).sort(compareFeesPerLiquidity);
  };

  useEffect(() => {
    if (allTokens.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      const unsortedCurrentPools = allTokens.slice(itemOffset, endOffset);
      const sortedTokens = sortPoolsOrTokens(
        unsortedCurrentPools,
        balanceSortState,
        activeSortProperty
      );
      setCurrentItems(sortedTokens as HoldingToken[]);
      setPageCount(Math.ceil(allTokens.length / itemsPerPage));
    } else {
      setCurrentItems([]);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, allTokens]);


  function filterTokens(filteringOpts: FilteringOptions) {
      let tokens =
      state.web3.networkId === Network.ETH
        ? [...state.assets.holdingTokensETH, ...state.assets.holdingTokensBSC]
        : [...state.assets.holdingTokensBSC, ...state.assets.holdingTokensETH];

    // Filter by chain
    if (filteringOpts.chain) {
      tokens = tokens.filter((token) => token.chain === filteringOpts.chain);
    }
    // Filter by search query
    tokens = tokens.filter(
      (token) =>
        token.name.toLowerCase().includes(filteringOpts.query) ||
        token.symbol.toLowerCase().includes(filteringOpts.query)
    );
    // Sort
    if (filteringOpts.ordering === "chain") {
      tokens = tokens.sort((a, b) => a.chain - b.chain);
    }

    setallTokens(tokens);
  }

  useEffect(() => {
   filterTokens(filteringOptions)
  }, [
    state.assets.holdingTokensETH,
    state.assets.holdingTokensBSC,
    state.assets.syncing,
  ]);

  const tableBody = () => {
    if (state.assets.syncing) {
      return (
        <tr>
          <td colSpan={4}>
            <div className="tw-py-7 tw-flex tw-justify-center tw-items-center">
              <span>Syncing...</span>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: 10 }}
              />
            </div>
          </td>
        </tr>
      );
    }

    if (state.web3.networkId === Network.UNKNOWN) {
      return (
        <tr>
          <td colSpan={5}>
            <div className="tw-w-full tw-text-center tw-text-xl tw-font-medium tw-text-coolGray-500 tw-py-5 tw-flex tw-justify-center">
              <div id="tour_no_token_data" className="tw-inline-block">
                Unsupported Network
              </div>
            </div>
          </td>
        </tr>
      );
    }

    if (currentItems?.length) {
      return currentItems?.map((token, index) => {
        return (
          <TokenTableRow
            key={token.address}
            token={token}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = cryptocurrencyBackup;
            }}
            state={state}
            onClick={() => {
              if (getTokenErrorMessage(state, token) !== "") {
                swapTradErrorLogAnalysis(token, TriggerType.SWAP, state);
                return;
              }
              eventAnalysis(
                "automation_click",
                TriggerType.SWAP,
                "Swap automation button clicked"
              );
              history.push(`/dashboard/swapAutomation/${token.address}`);
            }}
            index={index}
            poolSuggestionBtnData={getPoolSuggestionBtnData(
              token.address,
              state.assets.suggestedPools
            )}
          />
        );
      });
    }

    return (
      <tr>
        <td colSpan={5}>
          <div className="tw-w-full tw-text-center tw-text-xl tw-font-medium tw-text-coolGray-500 tw-py-5 tw-flex tw-justify-center">
            <div id="tour_no_token_data" className="tw-inline-block">
              No Data
            </div>
          </div>
        </td>
      </tr>
    );
  };
  return (
    <>
      <div id="tour_your_token" className="tw-mt-6">
        <div className="tw-flex tw-text-3xl tw-text-coolGray-900 tw-font-medium tw-mb-8 tw-px-3 tw-items-center">
          Your Tokens&nbsp;
          <HelpToolTip
            id="help-txn-speed"
            tooltipText={`${
              state.web3.networkId === Network.ETH ? "ETH" : "BSC"
            } based tokens in your wallet.<br/> If you don’t see your token yet, please refresh this page after about 10 minutes.`}
          >
            <img src={HelpIcon} width="24px" />
          </HelpToolTip>
        </div>
        <div className="tw-max-w-full tw-overflow-x-auto tw-px-3 tw-pb-5">
          <table
            ref={columnDivRef}
            id="token_table"
            className="tw-rounded-2xl tw-w-full tw-overflow-hidden tw-shadow-lg"
          >
            <thead>
              <tr>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-pl-6 tw-pr-4 tw-whitespace-nowrap tw-text-left">
                  Token Name
                  <FiltersBlock
                    updateFilteringOptions={(filteringOptions: FilteringOptions) => {
                      filterTokens(filteringOptions);
                      setFilteringOptions(filteringOptions)
                    }}
                   />
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-whitespace-nowrap tw-text-left">
                  Balance
                  <Sorter
                    currentItems={currentItems}
                    currentSortStateOfProperty={balanceSortState}
                    setActiveSortProperty={setActiveSortProperty}
                    activeSortProperty={activeSortProperty}
                    setCurrentTokens={setCurrentItems}
                    setSortState={setBalanceSortState}
                    sortProperty={SORT_PROPERTIES.USD_BALANCE}
                  />
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-whitespace-nowrap" />
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-pr-6 tw-whitespace-nowrap tw-text-center">
                  Liquidity pool options
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2" />
              </tr>
            </thead>
            <tbody>{tableBody()}</tbody>
          </table>
        </div>
      </div>
      {!state.assets.syncing && (
        <Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
      )}
      <NewTriggerModal
        state={state}
        show={showNew}
        initialStage={Stage.SELECT_AGENT}
        triggerType={TriggerType.SWAP}
        allAgents={state.agents.list()}
        onDone={() => setShowNew(false)}
      />
    </>
  );
});
