import classnames from "classnames";
import React from "react";
import style from "./tooltip.module.scss";

interface tooltipProps {
  textClassName?: string;
  child: string | JSX.Element;
  tooltipTextClassName?: string;
  text: string;
  hideTooltip?: boolean;
}

export default class Tooltip extends React.Component<tooltipProps, {}> {
  render(): JSX.Element {
    const { textClassName, child, tooltipTextClassName } = this.props;
    return !this.props.hideTooltip ? (
      <>
        <div
          className={classnames(
            style.tooltip,
            textClassName !== undefined ? textClassName : "",
          )}
        >
          {child}
          <div
            className={classnames(
              style.tooltipText,
              tooltipTextClassName !== undefined ? tooltipTextClassName : ""
            )}
          >
            {this.props.text}
          </div>
        </div>
      </>
    ) : (
      <>{child}</>
    );
  }
}
