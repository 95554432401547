import { ENVIRONMENT, ENVIROMENT_TYPES } from "./../../configs";
import { TriggerType } from "../../state/triggers";
import { getFirebaseAnalytics } from "../../firebase";
interface TrackEvents {
  action: string;
  stage: string;
}

export const trackEvents = (
  name: string,
  triggertype: TriggerType,
  event: TrackEvents
) => {
  const eventName = `${ENVIRONMENT}_${TriggerType[
    triggertype
  ].toLowerCase()}_${name.toLowerCase()}`;
  getFirebaseAnalytics()?.logEvent(eventName, event);
};

export const eventAnalysis = (
  name: string,
  triggerType: TriggerType,
  message: string
) => {
  let eventName = "";
  const type =
    triggerType === TriggerType.SWAP
      ? "swap"
      : triggerType === TriggerType.TRADING_AUTOMATION
      ? "trad"
      : "wl";
  switch (ENVIRONMENT) {
    case ENVIROMENT_TYPES.PRODUCTION: {
      eventName = `prod_${type}_${name}`;
      break;
    }
    case ENVIROMENT_TYPES.PREPROD: {
      eventName = `p_prod_${type}_${name}`;
      break;
    }
    case ENVIROMENT_TYPES.STAGING: {
      eventName = `stage_${type}_${name}`;
      break;
    }
    case ENVIROMENT_TYPES.QA: {
      eventName = `qa_${type}_${name}`;
      break;
    }
    default: {
      eventName = `dev_${type}_${name}`;
      break;
    }
  }
  getFirebaseAnalytics()?.logEvent(eventName, { message });
};

export const eventNameAnalysis = (name: string, message: any) => {
  let eventName = "";
  switch (ENVIRONMENT) {
    case ENVIROMENT_TYPES.PRODUCTION: {
      eventName = `prod_${name}`;
      break;
    }
    case ENVIROMENT_TYPES.PREPROD: {
      eventName = `p_prod_${name}`;
      break;
    }
    case ENVIROMENT_TYPES.STAGING: {
      eventName = `stage_${name}`;
      break;
    }
    case ENVIROMENT_TYPES.QA: {
      eventName = `qa_${name}`;
      break;
    }
    default: {
      eventName = `dev_${name}`;
      break;
    }
  }
  getFirebaseAnalytics()?.logEvent(eventName, message);
};
