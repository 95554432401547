import { StateStore } from "../../state/state";
import { Trigger, TriggerState, TriggerType } from "../../state/triggers";
import _ from "lodash";
import { Pair } from "../../state/pairCache";
import { toJS } from "mobx";
import {
  BSC_MAIN_NET_CHAIN_ID,
  BSC_TEST_NET_CHAIN_ID,
  getNetworkDetails,
  MAINNET_CHAIN_ID,
  Network,
  RINKEBY_CHAIN_ID,
} from "../../configs";
import { Chain, HoldingToken } from "../../state/assets";
import { Costing } from "../../services/Costing";
import { isConnectNetwork } from "../../api/common";
import { anErr, anOk, isErr, isOk, Result } from "../../result";
import { findHoldingAssets } from "../../utils/misc/findHoldingAssets";
import { findToken2 } from "../../utils/misc/findToken2";
import { canUseMoreAgent } from "../../utils/misc/canUseMoreAgent";
import { eventAnalysis } from "../../utils/misc/trackEvent";

export enum errorMessages {
  WALLET_CONNECT = "Please connect to wallet",
  SERVICE_EXPIRED = "Service expired",
  AGENT_LIMIT_REACHED = "Max agent limit reached",
  OPPOSITE_NETWORK = "Not allow to add automation for opposite network",
  TRIGGER_ALREADY_REGISTERED = "Two or more triggers already registered to pool",
  WRONG_NETWORK_SELECTE = "Unavailable: can only create swap automation for assets on the network selected in MetaMask",
  INSUFFICIENT_ASSETS = "Needs another asset on network to provide liquidity",
  SYNCING = "Fetching info...",
}
/**
 * Get opposite chain ID eg for rinkeby opposite is bsc test net
 *
 * just used for display and doesn't include kovan
 */
export const oppositeChainId = (chaind: number): Result<number> => {
  let res;
  switch (chaind) {
    case MAINNET_CHAIN_ID:
      res = BSC_TEST_NET_CHAIN_ID;
      break;
    case RINKEBY_CHAIN_ID:
      res = BSC_TEST_NET_CHAIN_ID;
      break;
    case BSC_TEST_NET_CHAIN_ID:
      res = RINKEBY_CHAIN_ID;
      break;
    case BSC_MAIN_NET_CHAIN_ID:
      res = MAINNET_CHAIN_ID;
      break;
    default:
      return anErr("unsupported chain Id ");
  }

  return anOk(res);
};

export const getTokenErrorMessage = (
  state: StateStore,
  token: HoldingToken,
  requireToken2 = false
) => {
  if (!state.web3.isReady() || state.web3.chainId === undefined) {
    return errorMessages.WALLET_CONNECT;
  }
  if (Costing.hasServiceExpired(state)) {
    return errorMessages.SERVICE_EXPIRED;
  }
  if (state.agents.syncing) {
    return errorMessages.SYNCING;
  }
  if (!canUseMoreAgent(state)) {
    return errorMessages.AGENT_LIMIT_REACHED;
  }
  if (!isConnectNetwork(token.chain, state)) {
    const oppChainId = oppositeChainId(state.web3.chainId);

    const ErrorMessage = errorMessages.WRONG_NETWORK_SELECTE;

    if (isErr(oppChainId)) {
      return ErrorMessage;
    }

    const networkRes = getNetworkDetails(oppChainId.value);

    if (isOk(networkRes)) {
      return `Unavailable: requires ${networkRes.value.chainName} in MetaMask`;
    }

    return ErrorMessage;
  }

  if (requireToken2) {
    const holdingAssetRes = findHoldingAssets(state);

    if (
      isErr(holdingAssetRes) ||
      findToken2(token, holdingAssetRes.value) === undefined
    ) {
      return errorMessages.INSUFFICIENT_ASSETS;
    }
  }

  return "";
};

export const getPoolErrorMessage = (
  state: StateStore,
  chain: Chain,
  poolAddress: string
) => {
  if (!state.web3.isReady()) {
    return errorMessages.WALLET_CONNECT;
  }
  if (Costing.hasServiceExpired(state)) {
    return errorMessages.SERVICE_EXPIRED;
  }
  if (state.agents.syncing) {
    return errorMessages.SYNCING;
  }
  if (!canUseMoreAgent(state)) {
    return errorMessages.AGENT_LIMIT_REACHED;
  }
  if (!isConnectNetwork(chain, state)) {
    return errorMessages.OPPOSITE_NETWORK;
  }
  if (twoOrMoreTriggersOnPool(state, poolAddress)) {
    return errorMessages.TRIGGER_ALREADY_REGISTERED;
  }

  return "";
};

export const twoOrMoreTriggersOnPool = (
  state: StateStore,
  poolAddress: string
): boolean => {
  const triggers = state.triggers.list();
  return Boolean(
    triggers.filter((trigger: Trigger) => {
      return Boolean(
        trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
          ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
            trigger.state
          ) &&
          trigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY
      );
    }).length >= 2
  );
};

export const getPercentage = (num: string) => {
  return (parseFloat(num) * 100).toFixed(2);
};

export const swapTradErrorLogAnalysis = (
  token: HoldingToken,
  triggerType: TriggerType,
  state: StateStore
) => {
  switch (getTokenErrorMessage(state, token)) {
    case errorMessages.SERVICE_EXPIRED: {
      eventAnalysis(
        "service_exp_err",
        triggerType,
        getTokenErrorMessage(state, token)
      );
      break;
    }
    case errorMessages.AGENT_LIMIT_REACHED: {
      eventAnalysis(
        "agent_limit_reached_err",
        triggerType,
        getTokenErrorMessage(state, token)
      );
      break;
    }
  }
};
