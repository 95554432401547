import { Decimal } from "decimal.js";
import { equalsZero } from "../display/toNonCanonicalDisplay";
import { normalizeDecimalStringContainingE } from "../numeric/normalizeDecimalStringContainingE";

/**
 * converts from amount of currency represented by price to how many of the tokens there must be
 *
 * @param amount
 * @param price
 */

export const convertFromCurrency = (
  amount: string | undefined,
  price: string | undefined
): string => {
  if (amount === undefined || equalsZero(amount)) return "0";

  if (price === undefined || equalsZero(price)) return "";

  amount = normalizeDecimalStringContainingE(amount);
  price = normalizeDecimalStringContainingE(price);

  return new Decimal(amount).div(new Decimal(price)).toString();
};
