import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ModalWrapper } from "../../components/ModalWrapper";
import { PoolDetails, HoldingToken } from "../../state/assets";
import { StateContext, StateStore } from "../../state/state";
import { TriggerType } from "../../state/triggers";
import { eventAnalysis } from "../../utils/misc/trackEvent";
import { Tooltip } from "../../components/Tooltip";
import {
  getPoolErrorMessage,
  getTokenErrorMessage,
  swapTradErrorLogAnalysis,
} from "./utils";

const getPoolOptionErrorMessage = (
  pool: PoolDetails,
  state: StateStore,
  token: HoldingToken
) => {
  let errorMessage = getTokenErrorMessage(state, token, true);
  if (!errorMessage || errorMessage == "")
    errorMessage = getPoolErrorMessage(state, token.chain, pool.address);

  return errorMessage;
};

const getPercentage = (num: string) => {
  return (parseFloat(num) * 100).toFixed(2);
};

const PoolBtn = ({
  pool,
  state,
  token,
}: {
  pool: PoolDetails;
  state: StateStore;
  token: HoldingToken;
}) => {
  const [isTextCroped, setIsTextCroped] = useState(false);
  const history = useHistory();
  let btnRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (btnRef?.current) {
      if (btnRef.current.scrollWidth <= btnRef.current.offsetWidth) {
        setIsTextCroped(false);
      } else {
        setIsTextCroped(true);
      }
    }
  }, [btnRef.current?.scrollWidth, btnRef.current?.offsetWidth]);
  return (
    <Tooltip
      tooltipText={getPoolOptionErrorMessage(pool, state, token)}
      hide={getPoolOptionErrorMessage(pool, state, token) === ""}
    >
      <div
        className={`tw-whitespace-nowrap tw-bg-coolGray-50 hover:tw-bg-green-300 tw-flex tw-justify-center tw-px-6 tw-w-3/4 tw-py-3 tw-my-1 tw-mx-auto tw-rounded-full tw-text-coolGray-900 tw-text-base tw-font-semibold tw-shadow-base tw-border tw-border-coolGray-300 hover:tw-border-green-300
            ${getPoolOptionErrorMessage(pool, state, token) !== ""
            ? "tw-opacity-60"
            : "tw-cursor-pointer"
          } `}
        onClick={() => {
          if (getPoolOptionErrorMessage(pool, state, token) !== "") {
            swapTradErrorLogAnalysis(
              token,
              TriggerType.TRADING_AUTOMATION,
              state
            );
            return;
          }
          eventAnalysis(
            "provide_liquidity_click",
            TriggerType.TRADING_AUTOMATION,
            "Provide liquidity button clicked"
          );
          document.body.style.overflow = "unset";
          history.push(
            `/dashboard/tradingAutomation/${pool.token0_address}/${pool.token1_address}`
          );
        }}
      >
        <Tooltip
          id={`${pool.symbol}_${pool.fees_per_liquidity}`}
          tooltipText={pool.symbol}
          className="tw-flex"
          hide={
            getPoolOptionErrorMessage(pool, state, token) === ""
              ? !isTextCroped
              : true
          }
        >
          <div className="tw-relative tw-h-6">
            <div
              ref={btnRef}
              className="tw-inline-block tw-overflow-hidden tw-truncate tw-max-w-7rem "
            >
              {pool.symbol}
            </div>
          </div>
        </Tooltip>
        &nbsp;
        <span>/</span>
        &nbsp;
        {`${getPercentage(pool.fees_per_liquidity)} % APR`}
      </div>
    </Tooltip>
  );
};

export const PoolOptionModal = ({
  isHidden,
  onClose,
  pools,
  token,
}: {
  isHidden: boolean;
  onClose: Function;
  pools: PoolDetails[];
  token: HoldingToken;
}) => {
  const state = useContext(StateContext);

  return (
    <ModalWrapper
      title="Liquidity pool options"
      onClose={onClose}
      isHidden={isHidden}
    >
      <div className="tw-flex tw-flex-col tw-gap-3 tw-max-h-50v tw-overflow-y-auto">
        {pools.map((pool, index) => (
          <PoolBtn key={index} pool={pool} state={state} token={token} />
        ))}
      </div>
    </ModalWrapper>
  );
};
