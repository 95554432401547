import Decimal from "decimal.js";
import { isOk } from "../../result";
import Provider from "../../services/Provider";
import { Agent } from "../../state/agents";
import { StateStore } from "../../state/state";
import { NewTrigger, Trigger, TriggerBase } from "../../state/triggers";
import { getBalance } from "../../utils/networkRequests/getBalance";

function calcGasRequired(trigger: TriggerBase): Decimal {
  return new Decimal(trigger.gasPrice).mul(new Decimal(trigger.gasAmount));
}

export const amountSendToAgent = async (agent: Agent, state:StateStore, trigger:NewTrigger) => {
  const agentBalance = await getBalance(agent.agentAddress, Provider.web3);

  /**
   * We check if there is enough gas already on the agent for all the triggers and if this is true then we do not add further gas
   *
   * We go through all triggers and calculate the gas that they need and then compare this to the amount of gas on the agent.
   */
  if (isOk(agentBalance)) {
    const numAgentBalance = new Decimal(agentBalance.value);
    let totalGasRequired = new Decimal(0);

    // sum all the of gas requirements for all the existing triggers for this agents
    state.triggers
      .list()
      .filter((trigger: Trigger) => {
        return Boolean(trigger.agentUuid === agent.uuid);
      })
      .forEach((trigger: Trigger) => {
        totalGasRequired = totalGasRequired.add(calcGasRequired(trigger));
      });

    // add the gas required for this new trigger
    totalGasRequired = totalGasRequired.add(calcGasRequired(trigger));
    if (totalGasRequired.greaterThan(numAgentBalance)) {
      return totalGasRequired.sub(numAgentBalance);
    }
    return new Decimal("0")
  } else {
    // just send entire amount if an error getting balance
    return calcGasRequired(trigger);
  }
}
