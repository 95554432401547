import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Provider from "../services/Provider";
import style from "./NewAgentModal.module.scss";
import { AgentCapacityBar } from "./AgentCapacityBar";
import { AgentType } from "../state/agents";
import Decimal from "decimal.js";
import close from "../assets/close.svg";
import { KEY_EVENT_ENTER, KEY_EVENT_SPACE } from "../configs";
import { NetworkNameText } from "./NetworkNameText";

interface NewAgentModelProps {
  show: boolean;
  web3LoggedIn: boolean;
  web3Installed: boolean;
  web3Address: string;
  numAgents: number;
  maxAgents: number;
  balance: string;
  onSubmit: (type: AgentType) => void;
  onCancel: () => void;
  pending: boolean;
  estimatedMonthlyCost: string;
  chainId: number | undefined;
}

function formatAgentCapacity(numAgents: number, maxAgents: number): string {
  let pc: number;

  if (maxAgents === 0) {
    pc = 100;
  } else if (numAgents === 0) {
    pc = 0;
  } else {
    pc = (numAgents * 100) / maxAgents;
  }

  return `${numAgents} / ${maxAgents} (${pc.toFixed(1)}%)`;
}

export function NewAgentModal(props: NewAgentModelProps) {
  const numBalance = new Decimal(props.balance);
  const invalidBalance = numBalance.lessThanOrEqualTo(0);

  let content;
  if (!props.web3Installed) {
    content = <Button>Install MetaMask</Button>;
  } else if (!props.web3LoggedIn) {
    content = <Button onClick={Provider.openLogin}>unlock metamask</Button>;
  } else {
    content = (
      <Form>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label className={style.ctrlLableTextColor}>
              Network
            </Form.Label>
            <div className={style.inputBox}>
              <NetworkNameText chainId={props.chainId} />{" "}
            </div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label className={style.ctrlLableTextColor}>
              Your Ethereum Address
            </Form.Label>
            <div className={style.inputBox}>{props.web3Address}</div>
          </Form.Group>
        </Form.Group>

        <Form.Group
          controlId="exampleForm.ControlSelect1"
          className={style.formGroup}
        >
          <Form.Label className={style.ctrlLableTextColor}>
            Agent Capacity
            <div className={style.capacity}>
              {formatAgentCapacity(props.numAgents, props.maxAgents)}
            </div>
          </Form.Label>
          <AgentCapacityBar
            maxAgents={props.maxAgents}
            numAgents={props.numAgents}
            addAgent
          />
        </Form.Group>

        <Form.Group>
          <Form.Label className={style.ctrlLableTextColor}>
            Estimated Monthly Cost
          </Form.Label>
          <div className={style.inputBox}>{props.estimatedMonthlyCost}</div>
        </Form.Group>
        {invalidBalance && (
          <div className={style.errorMessage}>
            Looks like you don't have enough balance. Please go to the account
            section and make sure you have topped up you account with FET
          </div>
        )}
      </Form>
    );
  }

  return (
    <Modal show={props.show} className={style.modalContainer}>
      <Modal.Header onHide={props.onCancel} className={style.modalHeader}>
        <img
          data-testid="create-new-image"
          onClick={props.onCancel}
          src={close}
          alt="new logo"
          className={style.closeButton}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === KEY_EVENT_ENTER || e.key === KEY_EVENT_SPACE) {
              props.onCancel();
            }
          }}
        />
        <Modal.Title>Create New Agent</Modal.Title>
      </Modal.Header>

      <Modal.Body className={style.modalBody}>{content}</Modal.Body>

      <Modal.Footer className={style.modalFooter}>
        <Button
          variant="secondary"
          onClick={props.onCancel}
          className={`${style.secondaryButton} ${style.modalButton}`}
          style={{ marginRight: "auto" }}
        >
          Cancel
        </Button>
        <Button
          data-testid="create-new-agent"
          className={`${style.primaryButton} ${style.modalButton}`}
          variant="primary"
          onClick={() => props.onSubmit(AgentType.STOP_LOSS)}
          disabled={
            props.pending ||
            invalidBalance ||
            props.numAgents >= props.maxAgents
          }
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
