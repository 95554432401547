export const fetchTriggersQuery = `query Query {
  triggers {
    uuid
    agent
    triggerType
    userAddress
    pool {
      address
      price
      price0Avg
      price1Avg
      token0Sym
      token1Sym
      token0Address
      token1Address
      token0Decimals
      token1Decimals
      chain
      type
      updatedAt
    }
    token
    price0Min
    price0Max
    price1Min
    price1Max
    swapToken
    amount
    threshold
    gasPrice
    gasAmount
    state
    txHash
    slippage
    proxyAddress
    createdAt
  }
}`;

export const deleteTrigger = `mutation Mutation($deleteTriggerId: ID!) {
    deleteTrigger(id: $deleteTriggerId) {
      uuid
    }
  }`;

export const createTrigger = `mutation Mutation($input: NewTrigger!) {
    createTrigger(input: $input) {
      uuid
      agent
      triggerType
      userAddress
      pool {
        token1Address
        address
        price
        price0Avg
        token0Sym
        price1Avg
        token1Sym
        token0Address
        token0Decimals
        token1Decimals
        chain
        type
        updatedAt
      }
      token
      price0Min
      price0Max
      price1Min
      price1Max
      swapToken
      amount
      threshold
      gasPrice
      gasAmount
      state
      txHash
      slippage
      proxyAddress
      createdAt
    }
  }
  `;
