import React from "react";
import { ConfigState, ProgressIcon } from "../ProgressIcon";
import { Button } from "react-bootstrap";
import style from "./ProgressElement.module.scss";
import { EtherScanLink } from "../EtherScanLink";

interface ProgressElementProps {
  state: ConfigState;
  title: string;
  transactionHash?: string;
  errorMessage?: string;
  enableRetry?: boolean;
  onRetry?: () => void;
  chainId: number | undefined;
}

export function ProgressElement(props: ProgressElementProps) {
  const isComplete =
    props.state === ConfigState.ERROR || props.state === ConfigState.CONFIGURED;

  return (
    <div className={style.ProgressElement}>
      <ProgressIcon state={props.state} />

      <div className={style.ProgressContent}>
        <div>
          <div className={style.ProgressTitle}>{props.title}</div>
          {props.state === ConfigState.ERROR &&
            props.errorMessage !== undefined && (
              <div className={style.ProgressFeedback}>{props.errorMessage}</div>
            )}
          {props.transactionHash !== undefined && isComplete && (
            <EtherScanLink
              address={props.transactionHash}
              className={style.TransactionDetails}
              text="Link on Etherscan"
              chainId={props.chainId}
              tx
            />
          )}
        </div>
        <div>
          {props.state === ConfigState.ERROR && props.enableRetry && (
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                if (props.onRetry !== undefined) {
                  props.onRetry();
                }
              }}
            >
              Retry
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
