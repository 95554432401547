import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Pair } from "../../state/pairCache";
import {
  NewTrigger,
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerType,
} from "../../state/triggers";
import { Agent } from "../../state/agents";
import Decimal from "decimal.js";
import {
  GAS_PRICES_ETH,
  GAS_PRICES_BSC,
  Network,
  PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE,
} from "../../configs";
import style from "./SelectThreshold.module.scss";
import classnames from "classnames";
import { StateStore } from "../../state/state";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { gweiToWei } from "../../utils/currency/GweiToWei";
import { isInteger } from "../../utils/numeric/isInteger";
import { isNumeric } from "../../utils/numeric/isNumeric";
import { safeInv } from "../../utils/numeric/safeinv";
import { toDecimal } from "../../utils/numeric/toDecimal";
import { weiToGwei } from "../../utils/currency/WeiToGwei";
import { stripLeftwardsZeros } from "../../utils/display/stripLeftwardsZeros";

// initial threshold only exists if threshold is being recieved from a back navigation from the confirm threshold page
interface SelectThresholdProps {
  state: StateStore;
  pair: Pair;
  agent: Agent;
  onTriggerUpdate: (t: NewTrigger | undefined) => void;
  initialThreshold: string | undefined;
  initialGasPrice: string | undefined;
  triggerType: TriggerType;
  tokenIndex: TriggerChoices | undefined;
  approveAmount: string;
  testId?: string;
  selectedThresholdTokenIdx: number;
  setselectedThresholdTokenIdx: (num: number) => void;
}

export function FeeButton(props: {
  gasPriceButtonSelected: boolean;
  text: string;
  feeAmount: string;
  testId?: string;
  onClick: (gasPrice: string) => void;
}) {
  return (
    <Button
      data-testid={props.testId !== undefined ? props.testId : ""}
      className={classnames(
        style.feeButton,
        props.gasPriceButtonSelected ? style.selected : ""
      )}
      onClick={props.onClick.bind(null, props.feeAmount)}
    >
      <span> {props.text}</span>
      <br />
      <span className={style.feeDetail}>( {props.feeAmount} Gwei)</span>
    </Button>
  );
}

export const SelectThreshold = observer((props: SelectThresholdProps) => {
  const { state } = props;
  const initialthreshold =
    typeof props.initialThreshold === "string" ? props.initialThreshold : "";
  const initialGasPriceInGwei =
    typeof props.initialGasPrice === "string"
      ? weiToGwei(props.initialGasPrice)
      : "";
  const gasPrices =
    state.web3.networkId === Network.BSC ? GAS_PRICES_BSC : GAS_PRICES_ETH;
  const isCustomBtnSelected = Boolean(
    ![gasPrices.HIGH, gasPrices.LOW, gasPrices.MEDIUM].includes(
      initialGasPriceInGwei
    ) && initialGasPriceInGwei
  );
  const [thresholdText, setThresholdText] = useState<string>(initialthreshold);
  const [gasPrice, setGasPrice] = useState<string>(initialGasPriceInGwei);
  const [customGasPrice, setCustomGasPrice] =
    useState<boolean>(isCustomBtnSelected);
  const [gasPriceButtonSelected, setGasPriceButtonSelected] = useState<string>(
    initialGasPriceInGwei
  );
  const [gasPriceInvalidNumber, setGasPriceInvalidNumber] =
    useState<boolean>(false);
  const defaultSlippage = 2;
  const [slippage, setSlippage] = useState(defaultSlippage);

  const maxDecimalAllowed = 18;
  if (props.pair === undefined) {
    return <p>Error no pair selected</p>;
  }
  const threshold = toDecimal(thresholdText);
  const pairPrice = toDecimal(props.pair.price);

  const tokenNotAlreadySelectedRes = tokenNotAlreadySelected(
    props.pair.address,
    state.triggers.list(),
    props.pair
  );

  const [thresholdTokenIdx, setThresholdTokenIdx] = useState<number>(
    tokenNotAlreadySelectedRes !== false
      ? tokenIndex(tokenNotAlreadySelectedRes as string, props.pair)
      : props.selectedThresholdTokenIdx
  );

  const thresholdToken =
    thresholdTokenIdx === 0 ? props.pair.token0 : props.pair.token1;

  const nonThresholdToken =
    thresholdTokenIdx === 1 ? props.pair.token0 : props.pair.token1;

  const thresholdDisplayText = `${thresholdToken}/${nonThresholdToken}`;
  const roundDecimal = (text: string) => {
    const dotIndx = text.indexOf(".");
    return text.slice(0, dotIndx + maxDecimalAllowed + 1);
  };
  const currentPrice =
    thresholdTokenIdx === 1
      ? roundDecimal(formatBigNumber(pairPrice.toString()).full)
      : roundDecimal(formatBigNumber(safeInv(pairPrice).toString()).full);
  const isHigherThanCurrent = threshold.greaterThan(currentPrice);
  const isInvalidNumber = threshold.lessThanOrEqualTo(0);
  const numberGreaterThanTokenDecimal = tooManyDecimals(threshold.toString());
  const isInvalid =
    thresholdText !== "" &&
    (isInvalidNumber || isHigherThanCurrent || numberGreaterThanTokenDecimal);
  const minSlippage = 0.01;
  const maxSlippage = 100;
  const isValidSlippage = slippage <= maxSlippage && slippage >= minSlippage;
  const warningMessage = getWarningMessage(slippage);

  function tooManyDecimals(subject: string): boolean {
    if (subject === "") return false;

    const maxDecimalPlaces = maxDecimals();
    const decimalPlaces = new Decimal(subject).decimalPlaces();
    return decimalPlaces > maxDecimalPlaces;
  }

  function maxDecimals(): number {
    return thresholdTokenIdx === 0
      ? props.pair.token0Decimals
      : props.pair.token1Decimals;
  }

  function selectCustom() {
    setCustomGasPrice(true);
    setGasPrice("");
    setGasPriceButtonSelected("");
    setGasPriceInvalidNumber(true);
    props.onTriggerUpdate(undefined);
  }

  // Check decimalPart and fractionalPart count
  function validateDecimalCount(text: string, maxDecimalAllowed: number) {
    const textParts = text.split(".");
    const decimalPart = textParts[0];
    const fractionalPart = textParts[1];
    if (
      textParts.length > 2 ||
      decimalPart?.length > maxDecimalAllowed ||
      fractionalPart?.length > maxDecimalAllowed
    ) {
      return false;
    }
    return true;
  }

  function oppositeToken(token: string, pair: Pair): string {
    return token === pair.token0 ? pair.token1 : pair.token0;
  }

  function tokenIndex(token: string, pair: Pair): number {
    return token === pair.token0 ? 0 : 1;
  }

  function getWarningMessage(slippage: number) {
    if (slippage < 1) {
      return "Your transaction may fail";
    }
    if (slippage > 5) {
      return "Hopefully you know what you are doing";
    }
  }

  const triggerUpdate = (
    text: string,
    idx: number,
    gasPrice: string,
    slippage: number
  ) => {
    const threshold = toDecimal(text);
    // update the state
    setThresholdTokenIdx(idx);
    setThresholdText(text);
    setGasPrice(gasPrice);
    const validGasPrice = validateGasPrice(gasPrice);

    const validThreshold =
      isNumeric(text) &&
      !tooManyDecimals(text) &&
      !threshold.lessThanOrEqualTo(0);
    const isHigherThanCurrent = threshold.greaterThan(currentPrice);
    const isValidSlippage = slippage <= maxSlippage && slippage >= minSlippage;
    const haveTokenIndex =
      props.triggerType === TriggerType.WITHDRAW_LIQUIDITY
        ? true
        : props.tokenIndex !== undefined;
    if (
      !validGasPrice ||
      !validThreshold ||
      text === "" ||
      isHigherThanCurrent ||
      !isValidSlippage ||
      !haveTokenIndex
    ) {
      props.onTriggerUpdate(undefined);
    } else {
      props.onTriggerUpdate({
        network: state.web3.networkId,
        agentUuid: props.agent.uuid,
        pair: props.pair,
        threshold: text,
        price0Min: "",
        price0Max: "",
        price1Min: "",
        price1Max: "",
        token: thresholdTokenIdx === 0 ? 1 : 0,
        amount: props.approveAmount,
        gasPrice: gweiToWei(gasPrice),
        gasAmount: PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE.toString(),
        slippage,
        swap_token: props.tokenIndex,
      });
    }
  };

  const getSlippageErrorMessage = () => {
    if (slippage < minSlippage) {
      return "Slippage tolerance too low";
    }
    if (slippage > maxSlippage) {
      return "Slippage tolerance too high";
    }
    return "Not a valid slippage";
  };

  const handleSelectToken = (e: ChangeEvent<HTMLSelectElement>) => {
    props.setselectedThresholdTokenIdx(e.target.selectedIndex);
    triggerUpdate("", e.target.selectedIndex, gasPrice, slippage);
  };

  const selectGasPriceButton = (gasPrice: string) => {
    setCustomGasPrice(false);
    setGasPriceButtonSelected(gasPrice);
    setGasPrice("");
    triggerUpdate(thresholdText, thresholdTokenIdx, gasPrice, slippage);
  };

  const updateSlippage = (amount: string) => {
    setSlippage(parseFloat(amount));
    triggerUpdate(
      thresholdText,
      thresholdTokenIdx,
      gasPrice,
      parseFloat(amount)
    );
  };
  const handleThresholdTextUpdate = (text: string) => {
    text = text.trim();
    if (validateDecimalCount(text, maxDecimalAllowed) && !isNaN(Number(text))) {
      triggerUpdate(text, thresholdTokenIdx, gasPrice, slippage);
    }
  };

  const handleAutoClick = () => {
    setSlippage(defaultSlippage);
    triggerUpdate(thresholdText, thresholdTokenIdx, gasPrice, defaultSlippage);
  };

  const handleCustomGasPrice = (e: ChangeEvent<HTMLSelectElement>) => {
    const text = e.target.value;
    if (!isNaN(Number(text))) {
      triggerUpdate(thresholdText, thresholdTokenIdx, text, slippage);
    }
  };

  const currencyName = () => {
    return state.web3.networkId === Network.BSC ? "BSC" : "ETH";
  };

  /**
   * if one token has already been selected by an agent in a trigger then we cannot create a duplicate trigger
   * by allowing another trigger with that token so the other trigger must be on the other token.
   *
   */
  function tokenNotAlreadySelected(
    poolAddress: string,
    triggers: Array<Trigger>,
    pair: Pair
  ): false | string {
    const filtered = triggers.filter((trigger: Trigger) => {
      return Boolean(
        trigger.triggerType === props.triggerType &&
          trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
          ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
            trigger.state
          ) &&
          (props.triggerType === TriggerType.WITHDRAW_LIQUIDITY
            ? true
            : props.tokenIndex === trigger.swap_token)
      );
    });

    if (filtered.length > 1) {
      throw new Error(
        "We aleady have two triggers associated with this pool should not have been allowed to this stage"
      );
    }

    if (filtered.length === 0) {
      return false;
    }

    return filtered[0].token === 0 ? pair.token0 : pair.token1;
  }

  const showThresholdPriceErrors = () => {
    if (isHigherThanCurrent) {
      return (
        <Form.Control.Feedback type="invalid">
          Trigger price higher than the spot price, it would trigger immediately
        </Form.Control.Feedback>
      );
    }
    if (isInvalidNumber) {
      return (
        <Form.Control.Feedback type="invalid">
          Not a valid threshold
        </Form.Control.Feedback>
      );
    }
    if (numberGreaterThanTokenDecimal) {
      return (
        <Form.Control.Feedback type="invalid">
          Decimal places should not be greater than {thresholdToken} decimal
          limit
        </Form.Control.Feedback>
      );
    }
  };

  const validateGasPrice = (gasPrice: string): boolean => {
    const strippedZeros = stripLeftwardsZeros(gasPrice);

    if (
      !isNumeric(gasPrice) ||
      (!isInteger(gasPrice) && !isInteger(strippedZeros)) ||
      parseInt(gasPrice) <= 0
    ) {
      setGasPriceInvalidNumber(true);
      return false;
    }
    setGasPriceInvalidNumber(false);
    return true;
  };

  useEffect(() => {
    // reupdate trigger on component load
    triggerUpdate(thresholdText, thresholdTokenIdx, gasPrice, slippage);
  }, []);

  return (
    <>
      <div className={style.thresholdContainer}>
        <Form.Row>
          <Form.Label className={style.ctrlLableTextColor}>
            Trigger Price
          </Form.Label>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={9} className={style.largeCustomFlexWidth}>
            <Form.Control
              type="text"
              data-testid="threshold"
              placeholder="Amount"
              value={thresholdText}
              onChange={(e) => handleThresholdTextUpdate(e.target.value)}
              isInvalid={isInvalid}
              className={`${style.input} ${
                (isInvalidNumber ||
                  isHigherThanCurrent ||
                  numberGreaterThanTokenDecimal) &&
                style.errorInput
              }`}
            />

            {showThresholdPriceErrors()}
          </Form.Group>
          <Form.Group as={Col} xs={3} className={style.smallCustomFlexWidth}>
            <Form.Control
              as="select"
              onChange={handleSelectToken}
              className={classnames(
                style.ctrlSelect,
                tokenNotAlreadySelectedRes !== false ? style.noBackground : ""
              )}
              disabled={tokenNotAlreadySelectedRes !== false}
            >
              {tokenNotAlreadySelectedRes === false ? (
                <>
                  <option selected={thresholdTokenIdx === 0} value={0}>
                    {props.pair.token0}/{props.pair.token1}
                  </option>
                  <option selected={thresholdTokenIdx === 1} value={1}>
                    {props.pair.token1}/{props.pair.token0}
                  </option>{" "}
                </>
              ) : (
                <option
                  selected
                  value={tokenIndex(
                    tokenNotAlreadySelectedRes as string,
                    props.pair
                  )}
                >
                  {tokenNotAlreadySelectedRes}/
                  {oppositeToken(
                    tokenNotAlreadySelectedRes as string,
                    props.pair
                  )}
                </option>
              )}
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Label className={style.ctrlLableTextColor}>
            Spot Price
          </Form.Label>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={9} className={style.largeCustomFlexWidth}>
            <Form.Control
              type="text"
              value={roundDecimal(currentPrice.toString())}
              readOnly
              className={style.input}
            />
            <Form.Control.Feedback type="invalid">
              Unknown contract address
            </Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Unknown contract address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs={3} className={style.smallCustomFlexWidth}>
            <Form.Control
              readOnly
              value={thresholdDisplayText}
              className={style.input}
            />
          </Form.Group>
        </Form.Row>
        {props.triggerType === TriggerType.SWAP && (
          <>
            <Form.Row>
              <Form.Group as={Col} xs={9} className={style.slippageHeading}>
                <Form.Label className={style.ctrlLableTextColor}>
                  Slippage tolerance %
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-top">
                        Your transaction will revert if the price changes
                        unfavorably by more than this percentage
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className={style.icon}
                    />
                  </OverlayTrigger>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={3} />
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={9}>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  value={slippage}
                  isInvalid={!isValidSlippage}
                  onChange={(e) => updateSlippage(e.target.value)}
                  className={`${style.input} ${
                    warningMessage && isValidSlippage ? style.warning : null
                  }`}
                />

                {!isValidSlippage ? (
                  <Form.Control.Feedback type="invalid">
                    {getSlippageErrorMessage()}
                  </Form.Control.Feedback>
                ) : (
                  warningMessage && (
                    <p className={style.warningText}>{warningMessage}</p>
                  )
                )}
              </Form.Group>
              <Form.Group as={Col} xs={3} className={style.autoBtnContainer}>
                <Button
                  className={`${style.autoBtn} ${
                    slippage === defaultSlippage && style.activeAutoBtn
                  }`}
                  onClick={handleAutoClick}
                >
                  AUTO
                </Button>
              </Form.Group>
            </Form.Row>
          </>
        )}
        <Form.Row>
          <Form.Label className={style.ctrlLableTextColor}>
            Trigger Gas Price
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-top">
                  This is the gas price that will used for the transaction when
                  the trigger is executed. The selected value helps to fund your
                  selected agent with enough {currencyName()} to do a
                  transaction. A high value ensures that the transaction does
                  not fail due to chain congestion. You can always withdraw the
                  extra funds if the gas price during trigger execution is less
                  than the configured value now.
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} className={style.icon} />
            </OverlayTrigger>
          </Form.Label>
        </Form.Row>
        <div className={style.GasFeeGroup}>
          <FeeButton
            gasPriceButtonSelected={gasPriceButtonSelected === gasPrices.LOW}
            feeAmount={gasPrices.LOW}
            text="SLOW"
            onClick={selectGasPriceButton.bind(null, gasPrices.LOW)}
          />
          <FeeButton
            testId="medium-gas"
            gasPriceButtonSelected={gasPriceButtonSelected === gasPrices.MEDIUM}
            feeAmount={gasPrices.MEDIUM}
            text="MEDIUM"
            onClick={selectGasPriceButton.bind(null, gasPrices.MEDIUM)}
          />
          <FeeButton
            gasPriceButtonSelected={gasPriceButtonSelected === gasPrices.HIGH}
            feeAmount={gasPrices.HIGH}
            text="FAST"
            onClick={selectGasPriceButton.bind(null, gasPrices.HIGH)}
          />
          <Button
            className={classnames(
              style.feeButton,
              customGasPrice ? style.selected : ""
            )}
            onClick={selectCustom}
          >
            <span>CUSTOM </span> <br />
            <span
              className={classnames(style.feeDetail, style.customFeeDetail)}
            >
              Custom gas price
            </span>
          </Button>
        </div>
        {customGasPrice ? (
          <Form.Control
            type="text"
            className={style.input}
            placeholder="gas price in gwei"
            value={gasPrice}
            onChange={handleCustomGasPrice}
            isInvalid={gasPrice !== "" && gasPriceInvalidNumber}
          />
        ) : (
          ""
        )}
        {gasPrice !== "" && gasPriceInvalidNumber && (
          <Form.Control.Feedback type="invalid">
            Not a valid gas price
          </Form.Control.Feedback>
        )}
      </div>
    </>
  );
});
