import style from "./EthOrBSCSlider.module.scss";
import React from "react";
import classNames from "classnames";
import Provider from "../services/Provider";
import { ENVIRONMENT_CONFIG, mapChainId, Network } from "../configs";
import { eventNameAnalysis } from "../utils/misc/trackEvent";

interface EthOrBSCSliderProps {
  address: string;
  chainId: number;
  web3Unlocked: boolean;
  web3Installed: boolean;
}

export const NetworkSlider = (props: EthOrBSCSliderProps) => {
  const network = mapChainId(props.chainId);

  const selectBscNetwork = async () => {
    try {
      if (network !== Network.BSC) {
        await Provider.addChain(ENVIRONMENT_CONFIG.bscChainId);
        eventNameAnalysis("network_switch_to_bsc_success", {
          message: "Network switched to bsc successful",
        });
      }
    } catch (error: any) {
      eventNameAnalysis("network_switch_to_bsc_err", {
        message: `Network switched to bsc failed: ${error.message}`,
      });
    }
  };

  const selectEthNetwork = async () => {
    try {
      if (network !== Network.ETH) {
        await Provider.switchChain(ENVIRONMENT_CONFIG.ethChainId);
        eventNameAnalysis("network_switch_to_eth_success", {
          message: "Network switched to eth",
        });
      }
    } catch (error: any) {
      eventNameAnalysis("newtwork_switch_to_eth_err", {
        message: `Network switched to eth failed: ${error.message}`,
      });
    }
  };

  const switchNetwork = async (event: any) => {
    if (event.target.id === "circle") {
      // only oval part we switch network not circle part
      return;
      // event was triggered on the body
    }

    if (network === Network.ETH) {
      await selectBscNetwork();
    } else {
      await selectEthNetwork();
    }
  };

  let buttonStyles;
  switch (network) {
    case Network.ETH:
      buttonStyles = classNames(style.circle, style.right);
      break;
    case Network.BSC:
      buttonStyles = classNames(style.circle);
      break;
    case Network.UNKNOWN:
      buttonStyles = classNames(style.circle, style.hidden);
      break;
  }

  return (
    <div className={style.EthOrBSCSlider}>
      <div
        data-testid="bscSelected"
        className={classNames(
          style.bsc,
          network === Network.BSC ? style.selected : style.clickable
        )}
        onClick={selectBscNetwork}
      >
        BSC
      </div>
      <div>
        <div className={style.network}>Network</div>
        <div
          className={style.oval}
          onClick={switchNetwork}
          data-testid="switchNetwork"
        >
          <div
            data-testid="buttonStyles"
            className={buttonStyles}
            id="circle"
          />
        </div>
      </div>{" "}
      <div
        data-testid="ethSelected"
        className={classNames(
          style.eth,
          network === Network.ETH ? style.selected : style.clickable
        )}
        onClick={selectEthNetwork}
      >
        ETH
      </div>
    </div>
  );
};
