/* eslint-disable no-shadow */
import { API_URL } from "../configs";
import { AccessToken } from "../state/token";
import { anErr, anOk, Result } from "../result";
import { gql } from "@apollo/client";
import {
  confirmChallenge,
  createChallenge,
  refreshToken,
} from "./AuthenticationQueries";
import client from "./client";

export enum LoginErrors {
  INVALID_PASSWORD,
  NETWORK_ERROR,
}

export const LoginFailureErrorMessage =
  "No active account found with the given credentials";

export class AuthenticationAPI {
  static async registerAccount(
    email: string,
    firstName: string,
    lastName: string,
    token: string
  ): Promise<boolean> {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },

      body: JSON.stringify({
        email,
        first_name: firstName,
        last_name: lastName,
        notify_trigger_status: true,
        notify_low_balance: true,
        notify_service_expiry: true,
      }),
    };

    let response;
    try {
      response = await fetch(`${API_URL}/account/register/`, config);
    } catch {
      return false;
    }

    return response.status >= 200 && response.status < 300;
  }

  static async confirmAccount(email: string, uuid: string): Promise<boolean> {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify({
        email,
        uuid,
      }),
    };

    let response;
    try {
      response = await fetch(`${API_URL}/account/register/confirm/`, config);
    } catch {
      return false;
    }

    return response.status >= 200 && response.status < 300;
  }

  static async verifyToken(token: string): Promise<boolean> {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      body: JSON.stringify({ token }),
    };

    let response;
    try {
      response = await fetch(`${API_URL}/api/token/verify/`, config);
    } catch {
      return false;
    }

    return response.status >= 200 && response.status < 300;
  }

  static async login(
    username: string,
    password: string
  ): Promise<AccessToken | LoginErrors> {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ username, password }),
    };

    try {
      const response = await fetch(`${API_URL}/api/token/`, config);
      const data = await response.json();
      if (response.status === 200) {
        return new AccessToken(data.access, data.refresh);
      }
      if (data?.detail === LoginFailureErrorMessage) {
        return LoginErrors.INVALID_PASSWORD;
      }
    } catch (err) {
      console.log("Authentication error:", err);
    }
    return LoginErrors.NETWORK_ERROR;
  }

  static async getRefreshToken(
    token: AccessToken
  ): Promise<AccessToken | undefined> {
    try {
      const { data } = await client.mutate({
        mutation: gql(refreshToken),
        variables: {
          req: {
            refresh: token.refreshToken,
          },
        },
      });
      const {
        refresh: { access, refresh },
      } = data;
      return new AccessToken(access, refresh);
    } catch (e) {
      console.log("Authentication refresh error:", e);
    }

    return undefined;
  }

  static async web3RequestAccountChallenge(
    address: string,
    createIfNeeded: boolean
  ): Promise<Result<string>> {
    try {
      const { data } = await client.mutate({
        mutation: gql(createChallenge),
        variables: {
          req: {
            address,
            createIfNeeded,
          },
        },
      });
      const {
        createChallenge: { challenge },
      } = data;
      return anOk(challenge);
    } catch (e) {
      return anErr("Unable to get a challenge from the backend");
    }
  }

  static async web3SubmitAccountSignature(
    address: string,
    signature: string
  ): Promise<Result<AccessToken>> {
    try {
      const { data } = await client.mutate({
        mutation: gql(confirmChallenge),
        variables: {
          req: {
            address,
            signature,
          },
        },
      });
      const {
        confirmChallenge: { access, refresh },
      } = data;
      return anOk(new AccessToken(access, refresh));
    } catch (e) {
      return anErr("Unable to get a challenge from the backend");
    }
  }
}
