import { createContext } from "react";
import { AgentStore } from "./agents";
import { TriggerStore } from "./triggers";
import { AccountStore } from "./account";
import { Web3Store } from "./metamask";
import { PairCache } from "./pairCache";
import { DashboardStore } from "./dashboard";
import { Eth } from "./eth";
import { FeatureFlags } from "./features";
import { LocalStorage } from "./localStorage";
import { Preferences } from "./preferences";
import { AssetsStore } from "./assets";

const localStorage = new LocalStorage();

const account = new AccountStore(localStorage);

const agents = new AgentStore(localStorage);
const triggers = new TriggerStore(localStorage);
const assets = new AssetsStore(localStorage);

export class StateStore {
  localStorage = localStorage;

  preferences = new Preferences();

  web3 = new Web3Store(localStorage, () => {
    agents.clear();
    triggers.clear();
  });
  account = account;

  agents = agents;

  triggers = triggers;

  assets = assets;

  pairCache = new PairCache();

  dashboard = new DashboardStore();

  eth = new Eth();

  features = new FeatureFlags();
}

export const StateContext = createContext<StateStore>(new StateStore());
