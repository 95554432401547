import { Alert, Modal } from "react-bootstrap";
import { ProgressElement } from "../NewTriggerModal/ProgressElement";
import { ConfigState } from "../ProgressIcon";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { StateContext } from "../../state/state";
import ERC20Contract from "../../services/contracts/ERC20Contract";
import { isErr } from "../../result";
import Decimal from "decimal.js";
import VaultContract from "../../services/contracts/VaultContract";
import { AccountsAPI } from "../../api/AccountsAPI";
import { getNetworkDetails } from "../../configs";
import { gweiToWei } from "../../utils/currency/GweiToWei";
import { fetchCorrectTokenContractAddress } from "../../utils/configs/fetchCorrectTokenContractAddress";

interface PerformTopUpProps {
  amount: string;
  isAddressRegistered: boolean;
  onComplete: (txHash: string) => void;
  setAllowToCloseModal: (val: boolean) => void;
}

export const PerformTopUp = observer((props: PerformTopUpProps) => {
  const numAmount = new Decimal(props.amount);
  const state = useContext(StateContext);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [approvalState, setApprovalState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [sendFundsState, setSendFundsState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [transactionHash, setTransactionHash] = useState<string>("");

  const gasPriceInWei = gweiToWei(
    state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
  );

  const runCreation = async () => {
    if (state.web3.chainId === undefined) {
      setSendFundsState(ConfigState.ERROR);
      setErrorMessage("No chain selected");
      return;
    }

    const { chainId } = state.web3;
    if (state.web3.networkId === undefined) {
      setSendFundsState(ConfigState.ERROR);
      setErrorMessage("Top Up not available on this network");
    }

    // Step 1. Do the approval
    if (approvalState === ConfigState.PENDING) {
      setApprovalState(ConfigState.IN_PROGRESS);
      setErrorMessage("");

      if (state.web3.address === undefined) {
        setApprovalState(ConfigState.ERROR);
        setErrorMessage("No web3 address");
        return;
      }

      if (state.account.user === undefined) {
        setApprovalState(ConfigState.ERROR);
        setErrorMessage("No user account");
        return;
      }

      if (!props.isAddressRegistered) {
        const register = await AccountsAPI.registerEthereumAddress(
          state.web3.address,
          state.account.user.token
        );

        if (isErr(register)) {
          setApprovalState(ConfigState.ERROR);
          setErrorMessage(register.error.message);
          return;
        }
      }

      const networkDetails = getNetworkDetails(chainId);

      if (isErr(networkDetails)) {
        setApprovalState(ConfigState.ERROR);
        setErrorMessage(networkDetails.error.message);
        return;
      }

      const fetchTokenContract = fetchCorrectTokenContractAddress(chainId);

      const allowanceResult = await ERC20Contract.getAllowance(
        fetchTokenContract,
        state.web3.address,
        networkDetails.value.vaultContractAddress as string
      );

      if (isErr(allowanceResult)) {
        setApprovalState(ConfigState.ERROR);
        setErrorMessage(allowanceResult.error.message);
        return;
      }
      const allowance = new Decimal(allowanceResult.value);

      // check to see if an approval is needed
      if (allowance.greaterThanOrEqualTo(numAmount)) {
        setApprovalState(ConfigState.CONFIGURED);
        setErrorMessage("");
        return;
      }

      const result = await ERC20Contract.approve(
        fetchTokenContract,
        state.web3.address,
        networkDetails.value.vaultContractAddress as string,
        props.amount,
        gasPriceInWei
      );

      if (isErr(result)) {
        setApprovalState(ConfigState.ERROR);
        setErrorMessage(result.error.message);
        return;
      }

      setApprovalState(ConfigState.CONFIGURED);
      setErrorMessage("");
      return;
    }

    // Step 2. Send the funds to the vault
    if (
      approvalState === ConfigState.CONFIGURED &&
      sendFundsState === ConfigState.PENDING
    ) {
      setSendFundsState(ConfigState.IN_PROGRESS);
      setErrorMessage("");

      if (state.web3.address === undefined) {
        setSendFundsState(ConfigState.ERROR);
        setErrorMessage("No web3 address");
        return;
      }

      const vaultContract = new VaultContract(chainId);
      const result = await vaultContract.deposit(
        state.web3.address,
        props.amount,
        gasPriceInWei
      );

      if (isErr(result)) {
        setSendFundsState(ConfigState.ERROR);
        setErrorMessage(result.error.message);
        return;
      }

      setTransactionHash(result.value);
      setSendFundsState(ConfigState.CONFIGURED);
      setErrorMessage("");
      return;
    }

    if (
      approvalState === ConfigState.CONFIGURED &&
      sendFundsState === ConfigState.CONFIGURED
    ) {
      props.onComplete(transactionHash);
    }
  };

  useEffect(() => {
    const allowCancle = ![approvalState, sendFundsState].includes(
      ConfigState.IN_PROGRESS
    );
    props.setAllowToCloseModal(allowCancle);

    if (inProgress) {
      return;
    }

    setInProgress(true);
    runCreation()
      .then(() => {})
      .catch((err) => {
        console.log("Error", err);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [approvalState, sendFundsState, inProgress]);

  return (
    <Modal.Body style={{ padding: "20px 28px 28px" }}>
      {errorMessage.length > 0 && (
        <Alert variant="warning">{errorMessage}</Alert>
      )}
      <ProgressElement
        state={approvalState}
        chainId={state.web3.chainId}
        title="Approve Token Transfer"
        enableRetry
        onRetry={() => setApprovalState(ConfigState.PENDING)}
      />
      <ProgressElement
        state={sendFundsState}
        chainId={state.web3.chainId}
        title="Send Tokens to Account"
        enableRetry
        onRetry={() => setSendFundsState(ConfigState.PENDING)}
      />
    </Modal.Body>
  );
});
