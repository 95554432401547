// if on kovan show kovan balance on all other networks we use mainnet ethereum balance.
import {
  MAINNET_CHAIN_ID,
  getNetworkDetails,
  NetworkParameters,
} from "../../configs";
import { isErr, OkResult } from "../../result";

/**
 * If we are on Kovan we look at the fetch token contract on Kovan else in all other circumstances we look at contract on mainnet
 *
 */
export function fetchCorrectTokenContractAddress(
  chainId: number | undefined
): string {
  const id = chainId === undefined ? MAINNET_CHAIN_ID : chainId;
  const networkDetails = getNetworkDetails(id);

  if (isErr(networkDetails)) {
    return (getNetworkDetails(MAINNET_CHAIN_ID) as OkResult<NetworkParameters>)
      .value.fetchTokenContract as string;
  }

  return networkDetails.value.fetchTokenContract === undefined
    ? ((getNetworkDetails(MAINNET_CHAIN_ID) as OkResult<NetworkParameters>)
        .value.fetchTokenContract as string)
    : (networkDetails.value.fetchTokenContract as string);
}
