import { CryptoToken, HoldingToken } from "../../state/assets";
import { NATIVE_TOKEN_ADDRESS } from "../../api/common";

export const isTokenSame = (
  token1: HoldingToken | CryptoToken,
  token2: CryptoToken
): boolean => {
  if (token1.address == token2.address) {
    if (token1.address == NATIVE_TOKEN_ADDRESS) {
      if (
        token1.chain === token2.chain &&
        token1.symbol.toLowerCase() == token2.symbol.toLowerCase()
      ) {
        return true;
      }
    } else return true;
  }
  return false;
};
