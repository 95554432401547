import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import style from "./DeleteAgentModal.module.scss";
import thresholdStyle from "../NewTriggerModal/SelectThreshold.module.scss";
import close from "../../assets/close.svg";
import {
  BSCInfo,
  EthereumInfo,
  GAS_PRICES_ETH,
  GAS_PRICES_BSC,
  GasPrices,
  KEY_EVENT_ENTER,
  KEY_EVENT_SPACE,
  Network,
} from "../../configs";
import { isErr } from "../../result";
import { ConfigState } from "../ProgressIcon";
import { StateStore } from "../../state/state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import classnames from "classnames";
import { AccountsAPI } from "../../api/AccountsAPI";
import { Agent } from "../../state/agents";
import { AgentsAPI } from "../../api/AgentsAPI";
import { FeeButton } from "../NewTriggerModal/SelectThreshold";
import BN from "bn.js";
import { updateAgents } from "../../api/Sync";
import { gweiToWei } from "../../utils/currency/GweiToWei";
import { isInteger } from "../../utils/numeric/isInteger";
import { isNumeric } from "../../utils/numeric/isNumeric";
import {
  getMainUnit,
  toNonCanonicalDisplay,
} from "../../utils/display/toNonCanonicalDisplay";
import { snooze } from "../../utils/misc/snooze";
import { eventNameAnalysis } from "../../utils/misc/trackEvent";

enum GasPriceButtons {
  LOW,
  MEDIUM,
  HIGH,
  CUSTOM,
}

interface DeleteAgentModalProps {
  show: boolean;
  agent: Agent;
  onCancel: () => void;
  web3LoggedIn: boolean;
  state: StateStore;
}

export function DeleteAgentModal(props: DeleteAgentModalProps) {
  const { state } = props;
  const [gasPrice, setGasPrice] = useState<string>("0");
  const [customGasPrice, setCustomGasPrice] = useState<boolean>(false);
  const [gasPricesFromApi, setGasPricesFromApi] =
    useState<GasPrices>(GAS_PRICES_ETH);

  const [gasPriceButtonSelected, setGasPriceButtonSelected] = useState<
    GasPriceButtons | undefined
  >(undefined);

  const [gasPriceInvalidNumber, setGasPriceInvalidNumber] =
    useState<boolean>(false);

  const [DeleteAgentState, setDeleteAgentState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const cancel = () => {
    setErrorMessage("");
    props.onCancel();
    // otherwise the modal quickly flashes the non successfull state at the end.
    setTimeout(() => {
      setDeleteAgentState(ConfigState.PENDING);
    }, 1000);
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // just fetch them once on network change
    setGasPricesFromApi(
      state.web3.networkId === Network.BSC
        ? GAS_PRICES_BSC
        : state.eth.gasPrices
    );
  }, [state.web3.networkId]);

  function selectCustom() {
    setCustomGasPrice(true);
    setGasPrice("");
    setGasPriceButtonSelected(undefined);
    setGasPriceInvalidNumber(true);
  }

  const agentDelete = async () => {
    setDeleteAgentState(ConfigState.IN_PROGRESS);

    // similarly if we have a token but it has expired then we also need to trigger a logout event
    if (
      state.account.user === undefined ||
      state.account.user.token === undefined ||
      state.account.user.token.hasExpired() ||
      state.web3.address === undefined
    ) {
      setErrorMessage("error encountered");
      setDeleteAgentState(ConfigState.ERROR);
      return;
    }

    const agentBalanceValue = new BN(props.agent.balance ?? "0");

    // only attempt to withdraw ETH if the agent actually has a non-zero balance
    if (!agentBalanceValue.isZero()) {
      // convert gasPrice into wei
      const gasPriceValue = new BN(gweiToWei(gasPrice));

      // ensure that a gas price is selected
      if (gasPriceValue.isZero()) {
        setErrorMessage("Please select a gas price for this withdrawal");
        setDeleteAgentState(ConfigState.ERROR);
        return;
      }

      // compute the current total fees that would be required in order to make the withdrawal

      const transferGasCost = 21000;
      const totalFees = new BN(transferGasCost).mul(gasPriceValue);

      if (totalFees.gte(agentBalanceValue)) {
        setErrorMessage("Gas fees too high, no ETH would be returned");
        setDeleteAgentState(ConfigState.ERROR);
        return;
      }

      // compute the final amount to be transferred back to the user
      const transferAmount = agentBalanceValue.sub(totalFees).toString();

      const withdrawResult = await AccountsAPI.withdraw({
        token: state.account.user.token,
        agentUuid: props.agent.uuid,
        amount: transferAmount.toString(),
        address: state.web3.address,
        gasPrice: gasPriceValue.toString(),
        deleteOnWithdraw: true,
      });

      if (isErr(withdrawResult)) {
        setDeleteAgentState(ConfigState.ERROR);
        setErrorMessage(withdrawResult.error.message);
        return;
      }

      await updateAgents(state.account.user.token, state.agents);
      setErrorMessage("");
      setDeleteAgentState(ConfigState.CONFIGURED);
      cancel();

      eventNameAnalysis("agent_deleted", { type: "stop-loss" });
    } else {
      // used just to help give better error message
      try {
        const agentCount = state.agents.list().length;

        await AgentsAPI.deleteAgent(state.account.user.token, props.agent.uuid);

        let agentCountPostDeletion = state.agents.list().length;

        const timeout = 10000;

        let keepGoing = true;
        setTimeout(function () {
          keepGoing = false;
        }, timeout); // 1 min in milliseconds
        await updateAgents(state.account.user.token, state.agents);

        // we wait for the agent to actually be removed from state (and api responses) before closing modal
        while (agentCountPostDeletion === agentCount && keepGoing) {
          agentCountPostDeletion = state.agents.list().length;

          // if the token is expired and being updated then we just give them a single chance to renew it else we display an error
          await snooze(400);
          await updateAgents(state.account.user.token, state.agents);
        }
        // success, ensure that the error message has been removed
        setErrorMessage("");
        setDeleteAgentState(ConfigState.CONFIGURED);
        cancel();
      } catch (err) {
        setDeleteAgentState(ConfigState.ERROR);
        setErrorMessage("Could not delete, please retry");
      }
    }
  };

  const selectGasPriceButton = (
    gasPrice: string,
    gasPriceButton: GasPriceButtons
  ) => {
    setCustomGasPrice(false);
    setGasPriceButtonSelected(gasPriceButton);
    setGasPrice(gasPrice);
  };

  const validateGasPrice = (gasPrice: string): boolean => {
    if (
      !isNumeric(gasPrice) ||
      !isInteger(gasPrice) ||
      parseInt(gasPrice) <= 0
    ) {
      setGasPriceInvalidNumber(true);
      return false;
    }
    setGasPriceInvalidNumber(false);
    return true;
  };

  const handleCustomGasPrice = (e: ChangeEvent<HTMLSelectElement>) => {
    const price = e.target.value.trim();
    if (!isNaN(Number(price))) {
      validateGasPrice(e.target.value);
      setGasPrice(e.target.value);
    }
  };

  const successBody = () => {
    return (
      <div className={style.success}>
        <p>Agent {props.agent.name} has been successfully deleted</p>
        {props.agent.balance !== undefined && props.agent.balance !== "0" ? (
          <p>
            The system is currently transferring your agent funds back to you.
            Depending on network load this might take up to 30 minutes to
            complete. Please contact support if you run into any issues.
          </p>
        ) : (
          ""
        )}
      </div>
    );
  };

  const confirmBody = () => {
    return (
      <>
        {(props.agent.balance ?? "0") !== "0" && (
          <>
            <Form.Group data-testid="delete-agent-modal">
              <Form.Label className={style.ctrlLableTextColor}>
                Balance of agent
              </Form.Label>
              <Form.Control
                type="text"
                readOnly
                isInvalid={DeleteAgentState === ConfigState.ERROR}
                isValid={DeleteAgentState === ConfigState.CONFIGURED}
                value={toNonCanonicalDisplay(
                  props.agent.balance,
                  state.web3.networkId === Network.ETH ? EthereumInfo : BSCInfo
                )}
                disabled
                className={style.input}
              />
              Agent you are trying to delete has surplus{" "}
              {getMainUnit(
                state.web3.networkId === Network.ETH ? EthereumInfo : BSCInfo
              )}{" "}
              balance, deleting the agent also withdraws this surplus{" "}
              {getMainUnit(
                state.web3.networkId === Network.ETH ? EthereumInfo : BSCInfo
              )}{" "}
              funds and deposits it to your account. <br /> <br />
              Please select a gas price for this withdraw transaction:
              <Form.Control.Feedback type="invalid" className={style.center}>
                {errorMessage}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid" className={style.center}>
                success
              </Form.Control.Feedback>
            </Form.Group>
            <div className={style.GasFeeGroup}>
              <FeeButton
                testId="delete-low-fee"
                gasPriceButtonSelected={
                  gasPriceButtonSelected === GasPriceButtons.LOW
                }
                feeAmount={gasPricesFromApi.LOW}
                text="Safe Low"
                onClick={() => {
                  selectGasPriceButton(
                    gasPricesFromApi.LOW,
                    GasPriceButtons.LOW
                  );
                }}
              />
              <FeeButton
                testId="delete-low-medium"
                gasPriceButtonSelected={
                  gasPriceButtonSelected === GasPriceButtons.MEDIUM
                }
                feeAmount={gasPricesFromApi.MEDIUM}
                text="Average"
                onClick={() => {
                  selectGasPriceButton(
                    gasPricesFromApi.MEDIUM,
                    GasPriceButtons.MEDIUM
                  );
                }}
              />
              <FeeButton
                testId="delete-low-high"
                gasPriceButtonSelected={
                  gasPriceButtonSelected === GasPriceButtons.HIGH
                }
                feeAmount={gasPricesFromApi.HIGH}
                text="Fast"
                onClick={() => {
                  selectGasPriceButton(
                    gasPricesFromApi.HIGH,
                    GasPriceButtons.HIGH
                  );
                }}
              />
              <Button
                data-testid="delete-custom-fee"
                className={classnames(
                  thresholdStyle.feeButton,
                  customGasPrice ? thresholdStyle.selected : ""
                )}
                onClick={selectCustom}
              >
                <span>Custom </span> <br />
                <span
                  className={classnames(
                    style.marginTop,
                    thresholdStyle.feeDetail,
                    thresholdStyle.customFeeDetail
                  )}
                >
                  custom gas price (Gwei)
                </span>
              </Button>
            </div>
            {customGasPrice ? (
              <Form.Control
                data-testid="delete-custom-fee-input"
                type="text"
                className={style.gasInput}
                placeholder="gas price in gwei"
                value={gasPrice}
                onChange={handleCustomGasPrice}
                isInvalid={gasPrice !== "" && gasPriceInvalidNumber}
              />
            ) : (
              ""
            )}
            {gasPrice !== "" && gasPriceInvalidNumber && (
              <Form.Control.Feedback type="invalid">
                Not a valid gas price
              </Form.Control.Feedback>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Modal show={props.show} className={style.modalContainer}>
      <Modal.Header onHide={cancel} className={style.modalHeader}>
        {DeleteAgentState !== ConfigState.IN_PROGRESS && (
          <img
            onClick={cancel}
            src={close}
            alt="close"
            className={style.closeButton}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === KEY_EVENT_ENTER || e.key === KEY_EVENT_SPACE) {
                cancel();
              }
            }}
          />
        )}
        <Modal.Title>
          {ConfigState.CONFIGURED === DeleteAgentState
            ? "Agent Deleted"
            : "Delete Agent"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={style.modalBody}>
        {ConfigState.CONFIGURED === DeleteAgentState
          ? successBody()
          : confirmBody()}
      </Modal.Body>
      <Modal.Footer className={style.modalFooter}>
        {ConfigState.CONFIGURED !== DeleteAgentState ? (
          <Button
            data-testid="delete-agent-submit"
            className={`${style.primaryButton} ${style.modalButton}`}
            variant="primary"
            onClick={() => {
              agentDelete();
            }}
            disabled={DeleteAgentState === ConfigState.IN_PROGRESS}
          >
            {DeleteAgentState === ConfigState.IN_PROGRESS ? (
              <div className={style.progress}>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  size="2x"
                  className={classnames(style.ProgressIcon)}
                />{" "}
              </div>
            ) : (
              "Confirm Delete"
            )}
          </Button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
}
