export const fetchAllTokens = `query AllTokens($perPage: Int, $page: Int) {
  tokens(perPage: $perPage, page: $page) {
    total
    data {
      id
      address
      chain
      name
      symbol
      price
      market_cap
      logo_url
      decimals
    }
    per_page
    total_pages
    page
  }
}
`;

export const fetchHoldingTokens = `query Tokens($address: String!) {
  accountAsset(address: $address) {
    address
    eth_refreshed_assets {
      address
      balance
      is_uni_v2_lp
      is_cake_v2_lp
    }
    bsc_refreshed_assets {
      address
      balance
      is_uni_v2_lp
      is_cake_v2_lp
    }
  }
}

`;

export const fetchTokenPools = `query Query($tokens: [String]) {
  getChainTokenPools(tokens: $tokens)
}`

export const fetchPoolInfoFromTokenId = `query Query($ids: [String]) {
  getChainPools(ids: $ids) {
    data {
      address
      symbol
      chain
      token0_address
      token1_address
      volume_day_usd
      fees_day_usd
      fees_per_liquidity
      total_supply
      updated_at
      id
    }
  }
}`