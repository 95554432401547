import { ReactNode } from "react";
import batIcon from "../../assets/bat.png";
import collaborativeBot from "../../assets/collaborative-bot.svg";
import daiIcon from "../../assets/dai.png";
import ethIcon from "../../assets/eth.png";
import laptopImg from "../../assets/laptop.svg";
import stakingEth from "../../assets/staking-eth.svg";
import uniIcon from "../../assets/uni.png";

interface CardData {
  id: number;
  title: ReactNode;
  img: string;
}

export interface WalletData {
  title: string;
  text: string;
  img: string;
}

interface TokenData {
  symbol: string;
  chain: string;
  swapType: string;
  img: string;
}

interface PoolData {
  token1: string;
  token2: string;
  chain: string;
  swapType: string;
  token1Img: string;
  token2Img: string;
  gains: string;
  marketValue: string;
}

export const Cards: CardData[] = [
  {
    id: 0,
    title: (
      <>
        <span className="text-green-300">12,000</span> Triggers
      </>
    ),
    img: laptopImg,
  },
  {
    id: 1,
    title: (
      <>
        <span className="text-green-300">70,000</span> Users
      </>
    ),
    img: collaborativeBot,
  },
  {
    id: 2,
    title: (
      <>
        <span className="text-green-300">$25m</span> Total Value <br /> Locked
      </>
    ),
    img: stakingEth,
  },
];

export const Tokens: TokenData[] = [
  {
    symbol: "ETH",
    chain: "Ethereum",
    swapType: "Sushi swap",
    img: ethIcon,
  },
  {
    symbol: "UNI",
    chain: "Ethereum",
    swapType: "Uniswap",
    img: uniIcon,
  },
  {
    symbol: "DAI",
    chain: "Ethereum",
    swapType: "Uniswap",
    img: daiIcon,
  },
  {
    symbol: "BAT",
    chain: "Ethereum",
    swapType: "Sushi swap",
    img: batIcon,
  },
  {
    symbol: "ETH",
    chain: "Ethereum",
    swapType: "Sushi swap",
    img: ethIcon,
  },
  {
    symbol: "UNI",
    chain: "Ethereum",
    swapType: "Uniswap",
    img: uniIcon,
  },
  {
    symbol: "DAI",
    chain: "Ethereum",
    swapType: "Uniswap",
    img: daiIcon,
  },
  {
    symbol: "BAT",
    chain: "Ethereum",
    swapType: "Sushi swap",
    img: batIcon,
  },
  {
    symbol: "ETH",
    chain: "Ethereum",
    swapType: "Sushi swap",
    img: ethIcon,
  },
];

export const Pools: PoolData[] = [
  {
    token1: "ETH",
    token2: "WBTC",
    chain: "Ethereum",
    swapType: "Uniswap",
    token1Img: ethIcon,
    token2Img: uniIcon,
    gains: "+$400.00",
    marketValue: "$400.00",
  },
  {
    token1: "ETH",
    token2: "WBTC",
    chain: "Ethereum",
    swapType: "Uniswap",
    token1Img: ethIcon,
    token2Img: uniIcon,
    gains: "+$400.00",
    marketValue: "$400.00",
  },
  {
    token1: "ETH",
    token2: "WBTC",
    chain: "Ethereum",
    swapType: "Uniswap",
    token1Img: ethIcon,
    token2Img: uniIcon,
    gains: "+$400.00",
    marketValue: "$400.00",
  },
  {
    token1: "ETH",
    token2: "WBTC",
    chain: "Ethereum",
    swapType: "Uniswap",
    token1Img: ethIcon,
    token2Img: uniIcon,
    gains: "+$400.00",
    marketValue: "$400.00",
  },
];
