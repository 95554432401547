import React, {
  ChangeEvent,
  useRef,
  useState,
  useContext,
  useEffect,
} from "react";
import { Form } from "react-bootstrap";
import style from "./InputDropdown.module.scss";
import Web3 from "web3";
import { Validation } from "../../validation";
import { SwapToken } from "../../state/triggers";
import { StateContext } from "../../state/state";
import { isErr } from "../../result";
import ERC20Contract from "../../services/contracts/ERC20Contract";
import {
  InputValidation,
  InvalidReason,
  SwapInputType,
} from "./SelectTokenAndPool";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import BN from "bn.js";
import { toDecimalWithPrecision } from "../../services/contracts/PairContract";
import TooltipBox from "../../utils/tooltip/tooltip";
import { getNetworkDetails, mapChainId, Network } from "../../configs";
import Provider from "../../services/Provider";
import InfiniteScroll from "react-infinite-scroll-component";
import { UniswapERC20 } from "../../services/contracts/UniswapERC20";
import { format } from "../../utils/display/format";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";

function getTokenValidationMessage(
  validation: InputValidation
): string | undefined {
  if (validation.validity !== Validation.INVALID) {
    return;
  }
  switch (validation.reason) {
    case InvalidReason.INVALID_ADDRESS:
      return "Invalid pool address";
    case InvalidReason.UNSUPPORTED_NETWORK:
      return "Unsupported network: change network in MetaMask";
    case InvalidReason.NETWORK_ERROR:
      return "Network issues, please refresh";
    case InvalidReason.INVALID_TOKEN_ADDRESS:
      return "Invalid token address";
    case InvalidReason.NO_TOKENS:
      return "Given token symbol not found. Please paste token address";
    case InvalidReason.ZERO_PAIR_PRICE:
      return "Pair don't have sufficient reserve";
    default:
      return "Unknown error";
  }
}
interface DropdownSearchProps {
  tokenList: SwapToken[];
  setTokenList: (token: SwapToken[]) => void;
  selectedToken: SwapToken | undefined;
  setSelectedToken: (token: SwapToken | undefined) => void;
  filteredHoldingTokens: SwapToken[];
  setFilteredHoldingTokens: (token: SwapToken[]) => void;
  allHoldingToken?: SwapToken[];
  balance?: string;
  setBalance?: (bal: string) => void;
  noBalanceCheck?: boolean;
  onTokenSelect: (token: SwapToken) => void;
  inputText: string;
  setInputText: (text: string) => void;
  isTokenFocus: boolean;
  setIsTokenFocus: (isfocus: boolean) => void;
  showError?: InputValidation;
  setSearchAddress: (hasAddress: boolean) => void;
  isAddressPasted: boolean;
  queryingPool: boolean;
  name?: number;
}

export const TokenInputDropdown = ({
  tokenList,
  setTokenList,
  selectedToken,
  setSelectedToken,
  filteredHoldingTokens,
  setFilteredHoldingTokens,
  allHoldingToken,
  balance,
  setBalance,
  noBalanceCheck,
  onTokenSelect,
  inputText,
  setInputText,
  isTokenFocus,
  setIsTokenFocus,
  showError,
  setSearchAddress,
  isAddressPasted,
  queryingPool,
  name,
}: DropdownSearchProps) => {
  const [filteredData, setFilteredData] = useState<SwapToken[]>([]);
  const [isTokenAddressInvalid, setTokeAddressInvalid] = useState(false);
  const [fetchingTokenSymbol, setFetchingTokenSymbol] = useState(false);

  const [tokenValidation, setTokenValidation] = useState<InputValidation>({
    validity: Validation.UNKNOWN,
  });

  const inputTokenRef = useRef(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const beginningAddressLength = 12;
  const numberOfDots = 4;
  const endingAddressLength = 7;

  useEffect(() => {
    if (selectedToken && selectedToken.address) {
      if (!balance && !noBalanceCheck) {
        fetchTokenBalance(selectedToken.address, selectedToken.isNative);
      }
    } else {
      if (
        tokenList.length === 0 &&
        inputText.length > 0 &&
        (name === SwapInputType.FROM
          ? filteredHoldingTokens?.length === 0
          : !isAddressPasted)
      ) {
        setTokenValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.NO_TOKENS,
        });
      }
      setFilteredData(tokenList);
    }
  }, [tokenList, filteredHoldingTokens, inputText]);

  const state = useContext(StateContext);

  const fetchTokenBalance = async (tokenAddress: string, isNative = false) => {
    // Only do if balance check is true and necessary parameters are provided
    if (!setBalance || noBalanceCheck) {
      return;
    }

    setBalance("");

    if (state.web3.address === undefined || state.web3.chainId === undefined) {
      setTokenValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.NETWORK_ERROR,
      });
      return;
    }

    const balance = isNative
      ? await Provider.getBalance(state.web3.address)
      : await ERC20Contract.getBalance(state.web3.address, tokenAddress);

    if (isErr(balance) || new BN(balance.value).lte(new BN(0))) {
      return;
    }

    const networkDetails = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetails)) {
      setTokenValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.NO_BALANCE,
      });
      return;
    }

    let { decimals } = networkDetails.value.nativeCurrency;

    if (!isNative) {
      const decimalsRes = await ERC20Contract.getDecimals(tokenAddress);
      if (isErr(decimalsRes)) {
        setTokenValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.NO_BALANCE,
        });
        return;
      }

      decimals = parseInt(decimalsRes.value);
    }

    const decimalBalance = toDecimalWithPrecision(balance.value, decimals);

    setBalance(formatBigNumber(decimalBalance).full);
  };

  const updateTokenDropdown = async (e: ChangeEvent<HTMLSelectElement>) => {
    if (dropdownRef.current) {
      // On type scroll to top
      dropdownRef.current.scrollTo(0, 0);
    }
    setSelectedToken(undefined);
    setSearchAddress(false);
    if (setBalance) setBalance("");
    setTokenValidation({ validity: Validation.UNKNOWN });
    if (state.web3.networkId === Network.UNKNOWN) {
      setTokenValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }
    setInputText(e.target.value);
    if (e.target.value === "") {
      setFilteredData(tokenList);
      setFilteredHoldingTokens(tokenList);
      setTokeAddressInvalid(false);
      setSelectedToken(undefined);
    } else if (Web3.utils.isAddress(e.target.value)) {
      setFetchingTokenSymbol(true);
      setSearchAddress(true);
      if (state.web3.chainId) {
        const data = await UniswapERC20.getTokenData(
          e.target.value,
          state.web3.chainId
        );
        if (data.success) {
          let fetchedTokenBal = "0";
          if (state.web3.address) {
            const fetchedTokenBalRes = await ERC20Contract.getBalance(
              state.web3.address,
              e.target.value
            );
            if (fetchedTokenBalRes.success) {
              fetchedTokenBal = toDecimalWithPrecision(
                fetchedTokenBalRes.value,
                data.value.decimals
              );
            }
          }
          setFilteredHoldingTokens([
            {
              symbol: data.value.symbol,
              address: data.value.address,
              chain: mapChainId(state.web3.chainId ? state.web3.chainId : 0),
              users: 0,
              isNative: false,
              balance: fetchedTokenBal,
            },
          ]);
          setSearchAddress(true);
          setIsTokenFocus(true);
          setTokenValidation({ validity: Validation.UNKNOWN });
        } else {
          setTokenValidation({
            validity: Validation.INVALID,
            reason: InvalidReason.INVALID_ADDRESS,
          });
          setSearchAddress(false);
          setIsTokenFocus(false);
        }
      }
      setFetchingTokenSymbol(false);
    } else {
      const filteredToken = tokenList.filter((token) =>
        token.symbol
          .trim()
          .toUpperCase()
          .includes(e.target.value.trim().toUpperCase())
      );
      if (filteredToken.length === 0) {
        setTokenValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.NO_TOKENS,
        });
      }
      setFilteredHoldingTokens(filteredToken);
    }
  };

  const processSelectedToken = (item: SwapToken) => {
    setTokenValidation({ validity: Validation.UNKNOWN });
    setSelectedToken(item);
    setFilteredData([]);
    setTokeAddressInvalid(false);
    setIsTokenFocus(false);
    setInputText(item.symbol);
    fetchTokenBalance(item.address, item.isNative);
    onTokenSelect(item);
  };

  return (
    <>
      <div
        style={{ position: "relative" }}
        className={style.dropdownSearchContainer}
      >
        <Form.Control
          type="text"
          value={inputText}
          className={style.input}
          placeholder="Select token or paste token address"
          onChange={updateTokenDropdown}
          ref={inputTokenRef}
          isInvalid={Boolean(
            tokenValidation.validity !== Validation.UNKNOWN &&
              tokenValidation.reason &&
              getTokenValidationMessage(tokenValidation)
          )}
          onFocus={() => !isTokenFocus && setIsTokenFocus(true)}
          onBlur={() =>
            isTokenFocus && setTimeout(() => setIsTokenFocus(false), 500)
          }
        />
        {filteredHoldingTokens.length > 0 && (
          <div
            className={style.inputIcon}
            onClick={() => {
              setIsTokenFocus(!isTokenFocus);
            }}
          >
            <FontAwesomeIcon icon={faAngleDown} className={style.icon} />
          </div>
        )}

        <div
          id={`scrollableDiv-${name}`}
          ref={dropdownRef}
          className={`${style.dropdownContainer} ${
            isTokenFocus && filteredHoldingTokens.length > 0
              ? style.activeDropdown
              : null
          }`}
        >
          {isTokenFocus &&
            filteredHoldingTokens.length > 0 &&
            filteredHoldingTokens.map((item, index) => {
              if (item.balance) {
                return (
                  <span
                    key={index}
                    className={`${style.dropdown} ${style.holdingToken}`}
                    onClick={() => processSelectedToken(item)}
                  >
                    <div className={style.tokenName}>
                      {item.symbol}
                      <div className={style.holdingTokenBalance}>
                        {item.balance}
                      </div>
                    </div>
                    <div className={style.poolAddress}>
                      {!item.isNative &&
                        format(
                          item.address,
                          beginningAddressLength,
                          endingAddressLength,
                          numberOfDots
                        )}
                    </div>
                  </span>
                );
              } else {
                return (
                  <div
                    key={index}
                    className={style.dropdown}
                    onClick={() => processSelectedToken(item)}
                  >
                    <div className={style.tokenName}>{item.symbol}</div>
                    {!item.isNative && (
                      <div className={style.poolAddress}>
                        {format(
                          item.address,
                          beginningAddressLength,
                          endingAddressLength,
                          numberOfDots
                        )}
                      </div>
                    )}
                  </div>
                );
              }
            })}
          {isTokenFocus &&
            !isAddressPasted &&
            filteredHoldingTokens.length > 0 && (
              <p className={style.bottomText}>
                Can't find your token? Paste a token address
              </p>
            )}
        </div>
        {queryingPool && (
          <div className={`${style.loaderContainer} tw-mt-3`}>
            <FontAwesomeIcon icon={faSpinner} spin />{" "}
            <span className={style.loaderText}>
              Fetching token infomation...
            </span>
          </div>
        )}
        <Form.Control.Feedback type="invalid">
          {tokenValidation.validity !== Validation.UNKNOWN &&
            tokenValidation.reason &&
            getTokenValidationMessage(tokenValidation)}
        </Form.Control.Feedback>
      </div>
    </>
  );
};
