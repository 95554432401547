import Decimal from "decimal.js";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  AccountEventAPIResponseItem,
  AccountsAPI,
} from "../../api/AccountsAPI";
import { EthereumAddressTable } from "../../components/EthereumAddressTable";
import { FreeAccountPage } from "../../components/FreeAccountPage";
import TryBotswap from "../../components/TryBotswap";
import { ENABLE_ETH_ADDRESS_DISPLAY } from "../../configs";
import { isOk } from "../../result";
import { Costing } from "../../services/Costing";
import { PlanDetails } from "../../state/account";
import { StateContext } from "../../state/state";
import { equalsZero } from "../../utils/display/toNonCanonicalDisplay";
import { isAddressRegistered } from "../../utils/store/isAddressRegistered";
import style from "./AccountsPage.module.scss";

export enum AccountEventTypes {
  DEPOSIT = "deposit",
  CHARGE = "charge",
  INFORMATION = "information",
  REFUND = "refund",
  BONUS = "bonus",
}

interface AccountEventBase {
  event: AccountEventTypes;
  amount: string;
  details: string;
  tx: null | string;
  timestamp: Date;
}

export type AccountEvent = Overwrite<
  Omit<AccountEventAPIResponseItem, "id">,
  AccountEventBase
>;

const EVENTS_DISPLAY_PER_PAGE = 10;

function computeDailyUsage(totalAgents: number, details?: PlanDetails): string {
  if (details === undefined) {
    return "0";
  }

  const agentSecondRate = new Decimal(details.agentSecondPrice);
  const totalAgentDaySeconds = totalAgents * 24 * 60 * 60; // 24h * 60m * 60s

  return agentSecondRate.mul(totalAgentDaySeconds).toFixed(0);
}

export const AccountsPage = observer(() => {
  const state = useContext(StateContext);

  const [events, setEvents] = useState<AccountEvent[]>([]);
  const [queryInProgress, setQueryInProgress] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const queryAccountEvents = async (pageNumber: number) => {
    if (state.account.user === undefined) {
      return;
    }

    // only show the spinner if there is nothing in the list
    setQueryInProgress(true);

    const currentPageEvents = await AccountsAPI.fetchEvents(
      state.account.user.token,
      {
        page: pageNumber,
        pageSize: EVENTS_DISPLAY_PER_PAGE,
      }
    );

    let totalPages = 1;
    let nextEvents: AccountEvent[] = [];
    if (isOk(currentPageEvents)) {
      // we do not show events with 0 charge
      const filtered = currentPageEvents.value.results.filter(
        (event: AccountEvent) => !equalsZero(event.amount)
      );

      totalPages = Math.ceil(
        currentPageEvents.value.count / EVENTS_DISPLAY_PER_PAGE
      );
      nextEvents = filtered;
    }

    setQueryInProgress(false);
    setEvents(nextEvents);
    setPageCount(totalPages);
  };

  useEffect(() => {
    setTimeout(() => queryAccountEvents(currentPage));
  }, [currentPage]);

  const addressRegistered = isAddressRegistered(state.web3.address, state);

  return (
    <>
      <div className="tw-max-w-full tw-px-4 sm:tw-px-6 md:tw-px-8">
        <div className="PageHeader">
          {ENABLE_ETH_ADDRESS_DISPLAY && (
            <h1 className="PageTitle">Ethereum Addresses</h1>
          )}
        </div>
        <EthereumAddressTable addresses={state.account.addresses} />

        <FreeAccountPage
          network={state.web3.networkId}
          agentLimit={state.account.accountDetails?.agentLimit}
          balance={state.account.accountDetails?.balance}
          dailyUsage={computeDailyUsage(
            state.agents.length,
            state.account.planDetails
          )}
          numAgents={state.agents.length}
          fetBalance={state.account.fetBalance}
          isAddressRegistered={addressRegistered}
          estimatedServiceEnd={Costing.estimatedServiceEnd(state)}
        />
        <div className={style.eventContainer}>
          <TryBotswap />
          {false && pageCount !== 1 && (
            <div className={style.paginationContainer}>
              <ReactPaginate
                previousLabel={currentPage === 1 ? "" : "Previous"}
                nextLabel={
                  currentPage === pageCount || events.length === 0 ? "" : "Next"
                }
                pageCount={pageCount}
                marginPagesDisplayed={pageCount === 12 ? 6 : 5}
                pageRangeDisplayed={pageCount === 12 ? 6 : 5}
                onPageChange={(item) => {
                  setCurrentPage(item.selected + 1);
                }}
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
});
