import { anErr, anOk, Result } from "../../result";

/**
 * gets Ethereum balance of address on network the web3 param is pointing too
 *
 * @param address
 * @param web3
 */
export const getBalance = async (
  address: string,
  web3: any
): Promise<Result<string>> => {
  try {
    const balance: string = await web3.eth.getBalance(address);
    return anOk(balance);
  } catch (error) {
    // @ts-ignore
    return anErr(error.toString());
  }
};
