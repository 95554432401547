import { StateStore } from "../../state/state";
import { LocalStorage } from "../../state/localStorage";
import { AgentStore } from "../../state/agents";
import { TriggerStore } from "../../state/triggers";
import { Web3Store } from "../../state/metamask";
import { AccountStore } from "../../state/account";
import { PairCache } from "../../state/pairCache";
import { DashboardStore } from "../../state/dashboard";
import { Eth } from "../../state/eth";
import { FeatureFlags } from "../../state/features";

export const logout = (state: StateStore) => {
  const storage = new LocalStorage();
  const agents = new AgentStore(storage);
  const triggers = new TriggerStore(storage);

  localStorage.removeItem("login");
  state.account.logout();
  state.agents = agents;
  state.triggers = triggers;
  state.localStorage = storage;
  state.web3 = new Web3Store(storage, () => {
    agents.clear();
    triggers.clear();
  });
  state.account = new AccountStore(storage);

  state.pairCache = new PairCache();
  state.dashboard = new DashboardStore();
  state.eth = new Eth();
  state.features = new FeatureFlags();

  return null;
};
