/**
 * https://stackoverflow.com/questions/14283124/navigator-online-not-always-working
 *
 * navigator property doesn't work correctly so this gets around this online offline detection properly.
 */
export const isOnline = (): Promise<boolean> => {
    return new Promise((resolve)  => {
    // @ts-ignore
    var xhr = XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHttp');
    xhr.onload = function(){
            resolve(true);
    }
    xhr.onerror = function(){
            resolve(false);
    }
    xhr.open("GET","anypage.php",true);
    xhr.send();
})
}