import React, { ChangeEvent, useContext, useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Provider from "../../services/Provider";
import style from "./AgentFundsModal.module.scss";
import close from "../../assets/close.svg";
import {
  EthereumInfo,
  BSCInfo,
  GAS_PRICES_ETH,
  GasPrices,
  Network,
  GAS_PRICES_BSC,
} from "../../configs";
import { isErr } from "../../result";
import { ConfigState } from "../ProgressIcon";
import { StateContext } from "../../state/state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import classnames from "classnames";
import { AccountsAPI } from "../../api/AccountsAPI";
import thresholdStyle from "../NewTriggerModal/SelectThreshold.module.scss";
import { FeeButton } from "../NewTriggerModal/SelectThreshold";
import BN from "bn.js";
import { updateAgents } from "../../api/Sync";
import { gweiToWei } from "../../utils/currency/GweiToWei";
import { isInteger } from "../../utils/numeric/isInteger";
import { isNumeric } from "../../utils/numeric/isNumeric";
import { toNonCanonicalDisplay } from "../../utils/display/toNonCanonicalDisplay";
import { stripLeftwardsZeros } from "../../utils/display/stripLeftwardsZeros";

export enum MODAL_TYPE {
  WITHDRAW,
  TOPUP,
}

enum GasPriceButtons {
  LOW,
  MEDIUM,
  HIGH,
  CUSTOM,
}

export interface AgentFundsModalProps {
  /**
   * Withdraw or top up flag used to switch type
   */
  type: MODAL_TYPE.WITHDRAW | MODAL_TYPE.TOPUP;
  show: boolean;
  amount: string;
  agentAddress: string;
  agentUUID: string;
  web3LoggedIn: boolean;
  onCancel: () => void;
  pending: boolean;
}

export function AgentFundsModal(props: AgentFundsModalProps) {
  const state = useContext(StateContext);

  const [gasPrice, setGasPrice] = useState<string>("");
  const [customGasPrice, setCustomGasPrice] = useState<boolean>(false);
  const [gasPriceButtonSelected, setGasPriceButtonSelected] = useState<
    GasPriceButtons | undefined
  >(undefined);
  const [gasPriceInvalidNumber, setGasPriceInvalidNumber] =
    useState<boolean>(false);
  const [gasPricesFromApi, setGasPricesFromApi] =
    useState<GasPrices>(GAS_PRICES_ETH);

  const validateGasPrice = (gasPrice: string): boolean => {
    const strippedZeros = stripLeftwardsZeros(gasPrice);
    if (
      !isNumeric(gasPrice) ||
      !isInteger(strippedZeros) ||
      parseInt(gasPrice) <= 0
    ) {
      setGasPriceInvalidNumber(true);
      return false;
    }
    setGasPriceInvalidNumber(false);
    return true;
  };

  const selectGasPriceButton = (
    gasPrice: string,
    gasPriceButton: GasPriceButtons
  ) => {
    setCustomGasPrice(false);
    setGasPriceButtonSelected(gasPriceButton);
    setGasPrice(gasPrice);
  };

  const handleCustomGasPrice = (e: ChangeEvent<HTMLSelectElement>) => {
    const price = e.target.value.trim();
    if (!isNaN(Number(price))) {
      validateGasPrice(e.target.value);
      setGasPrice(e.target.value);
    }
  };

  const [AgentFundsState, setAgentFundsState] = useState<ConfigState>(
    ConfigState.PENDING
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const cancel = () => {
    setAgentFundsState(ConfigState.PENDING);
    setErrorMessage("");
    props.onCancel();
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // just fetch them once on network change
    setGasPricesFromApi(
      state.web3.networkId === Network.BSC
        ? GAS_PRICES_BSC
        : state.eth.gasPrices
    );
  }, [state.web3.networkId]);

  function selectCustom() {
    setCustomGasPrice(true);
    setGasPrice("");
    setGasPriceButtonSelected(undefined);
    setGasPriceInvalidNumber(true);
  }

  const withdraw = async () => {
    setAgentFundsState(ConfigState.IN_PROGRESS);

    let result;

    // similarly if we have a token but it has expired then we also need to trigger a logout event
    if (
      state.account.user === undefined ||
      state.account.user.token === undefined ||
      state.account.user.token.hasExpired() ||
      state.account.fetBalance === undefined ||
      state.web3.address === undefined
    ) {
      setErrorMessage("error encountered");
      setAgentFundsState(ConfigState.ERROR);
      return;
    }

    // convert gasPrice into wei
    const gasPriceValue = new BN(gweiToWei(gasPrice));

    // ensure that a gas price is selected
    if (gasPriceValue.isZero()) {
      setErrorMessage("Please select a gas price for this withdrawal");
      setAgentFundsState(ConfigState.ERROR);
      return;
    }

    // compute the current total fees that would be required in order to make the withdrawal

    const transferGasCost = 21000;
    const totalFees = new BN(transferGasCost).mul(gasPriceValue);

    const excessFundsValue = new BN(props.amount);

    if (totalFees.gte(excessFundsValue)) {
      setErrorMessage("Gas fees too high, no ETH would be returned");
      setAgentFundsState(ConfigState.ERROR);
      return;
    }

    // compute the final amount to be transferred back to the user
    const transferAmount = excessFundsValue.sub(totalFees).toString();

    result = await AccountsAPI.withdraw({
      token: state.account.user.token,
      agentUuid: props.agentUUID,
      amount: transferAmount.toString(),
      address: state.web3.address,
      gasPrice: gasPriceValue.toString(),
    });

    if (isErr(result)) {
      setAgentFundsState(ConfigState.ERROR);
      setErrorMessage("Request failed, please try again");
    } else {
      await updateAgents(state.account.user.token, state.agents);
      setErrorMessage("");
      setAgentFundsState(ConfigState.CONFIGURED);
      cancel();
    }
  };

  const topup = async () => {
    setAgentFundsState(ConfigState.IN_PROGRESS);
    const gasPriceInWei = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );

    const result = await Provider.sendFunds(
      state.web3.address as string,
      props.agentAddress,
      props.amount,
      gasPriceInWei
    );

    if (isErr(result)) {
      setAgentFundsState(ConfigState.ERROR);
      setErrorMessage(result.error.toString());
    } else {
      setAgentFundsState(ConfigState.CONFIGURED);
      setErrorMessage("");
      setTimeout(() => {
        cancel();
      }, 5000);
    }
  };

  return (
    <Modal show={props.show} className={style.modalContainer}>
      <Modal.Header onHide={cancel} className={style.modalHeader}>
        {/* Modal cannot be closed if in progress */}
        {AgentFundsState !== ConfigState.IN_PROGRESS && (
          <img
            onClick={cancel}
            src={close}
            alt="close"
            className={style.closeButton}
          />
        )}
        <Modal.Title>
          {props.type === MODAL_TYPE.TOPUP
            ? "Top Up Agent"
            : "Withdraw from Agent"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={style.modalBody}>
        {!props.web3LoggedIn && props.type === MODAL_TYPE.TOPUP ? (
          <Button onClick={Provider.openLogin}>unlock metamask</Button>
        ) : (
          <Form.Group>
            <Form.Label className={style.ctrlLableTextColor}>
              {props.type === MODAL_TYPE.TOPUP
                ? " shortfall of ETH to send to agent"
                : "Excess to withdraw from agent"}
            </Form.Label>
            <Form.Control
              type="text"
              readOnly
              isInvalid={AgentFundsState === ConfigState.ERROR}
              isValid={AgentFundsState === ConfigState.CONFIGURED}
              value={toNonCanonicalDisplay(
                props.amount,
                state.web3.networkId === Network.ETH ? EthereumInfo : BSCInfo
              )}
              disabled
              className={style.input}
            />
            <Form.Control.Feedback type="invalid" className={style.center}>
              {errorMessage}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" className={style.center}>
              {props.type === MODAL_TYPE.WITHDRAW
                ? "Request to withdraw excess agent funds submitted"
                : "Funds deposited"}
            </Form.Control.Feedback>

            {props.type === MODAL_TYPE.WITHDRAW ? (
              <Form.Group>
                <Form.Row>
                  <Form.Label>Gas Price</Form.Label>
                </Form.Row>
                <div className={style.GasFeeGroup}>
                  <FeeButton
                    gasPriceButtonSelected={
                      gasPriceButtonSelected === GasPriceButtons.LOW
                    }
                    feeAmount={gasPricesFromApi.LOW}
                    text="SLOW"
                    onClick={() => {
                      selectGasPriceButton(
                        gasPricesFromApi.LOW,
                        GasPriceButtons.LOW
                      );
                    }}
                  />
                  <FeeButton
                    gasPriceButtonSelected={
                      gasPriceButtonSelected === GasPriceButtons.MEDIUM
                    }
                    feeAmount={gasPricesFromApi.MEDIUM}
                    text="MEDIUM"
                    onClick={() => {
                      selectGasPriceButton(
                        gasPricesFromApi.MEDIUM,
                        GasPriceButtons.MEDIUM
                      );
                    }}
                  />
                  <FeeButton
                    gasPriceButtonSelected={
                      gasPriceButtonSelected === GasPriceButtons.HIGH
                    }
                    feeAmount={gasPricesFromApi.HIGH}
                    text="FAST"
                    onClick={() => {
                      selectGasPriceButton(
                        gasPricesFromApi.HIGH,
                        GasPriceButtons.HIGH
                      );
                    }}
                  />
                  <Button
                    className={classnames(
                      thresholdStyle.feeButton,
                      customGasPrice ? thresholdStyle.selected : ""
                    )}
                    onClick={selectCustom}
                  >
                    <span>Custom </span> <br />
                    <span
                      className={classnames(
                        style.marginTop,
                        thresholdStyle.feeDetail,
                        thresholdStyle.customFeeDetail
                      )}
                    >
                      custom gas price
                    </span>
                  </Button>
                </div>
                {customGasPrice ? (
                  <Form.Control
                    type="text"
                    className={style.input}
                    placeholder="gas price in gwei"
                    value={gasPrice}
                    onChange={handleCustomGasPrice}
                    isInvalid={gasPrice !== "" && gasPriceInvalidNumber}
                  />
                ) : (
                  ""
                )}
                {gasPrice !== "" && gasPriceInvalidNumber && (
                  <Form.Control.Feedback type="invalid">
                    Not a valid integer
                  </Form.Control.Feedback>
                )}{" "}
              </Form.Group>
            ) : (
              ""
            )}

            {AgentFundsState === ConfigState.IN_PROGRESS ? (
              <div className={style.progress}>
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin
                  size="2x"
                  className={classnames(style.ProgressIcon)}
                />{" "}
              </div>
            ) : (
              ""
            )}
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer className={style.modalFooter}>
        <Button
          className={`${style.primaryButton} ${style.modalButton}`}
          variant="primary"
          onClick={() => {
            if (props.type === MODAL_TYPE.TOPUP) {
              topup();
            } else {
              withdraw();
            }
          }}
          disabled={
            props.pending || AgentFundsState === ConfigState.IN_PROGRESS
          }
        >
          {props.type === MODAL_TYPE.TOPUP
            ? "Top Up Agent"
            : "Withdraw from Agent"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
