import Decimal from "decimal.js";
import {
  FRACTIONAL_DIGIT_COUNT,
  INTEGRAL_DIGIT_COUNT,
  LAST_INTEGRAL_DIGIT_COUNT,
} from "../../configs";
import { addCommas } from "../display/addCommas";
import { Agent } from "../../state/agents";
import { StateStore } from "../../state/state";

export const DOTS = "...";

// abbreviations for shortened numbers eg 2.3M for 2.3 million
const NUMBER_NAMES_ABBREVIATIONS = ["K", "M", "B", "T"];

/**
 * strips out excess dots as side effect
 *
 * @param s
 */
const toTwoDecimalPlaces = (s: string): string => {
  if (s.includes(DOTS)) {
    s = s.replace(DOTS, "");
  }

  // replace any abbreviations placed in.
  NUMBER_NAMES_ABBREVIATIONS.forEach((el) => {
    if (s.includes(el)) {
      s = s.replace(el, "");
    }
  });

  return new Decimal(s.trim()).toFixed(2);
};

/**
 *
 * Insert some abbreviatons into large strings
 *
 *  @see {@link https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn}
 *
 * @param num
 * @param fixed
 */
const abbreviateNumber = function (num: number, fixed: number) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split("e"), // get power
    k =
      b.length === 1
        ? 0
        : Math.floor(Math.min(parseFloat(b[1].slice(1)), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    e = parseFloat(c) + ["", ...NUMBER_NAMES_ABBREVIATIONS][k]; // append power
  return e;
};

interface BigNumberFormatted {
  full: string;
  fullCommas: string;
  trunc: string;
  same: boolean;
  same2DP: boolean;
}

export const formatBigNumber = (
  number: string,
  maxDecimal = 50
): BigNumberFormatted => {
  // number of decimals if number is big number truncated with symbol eg 22M or 8B
  const BIGNUMBERDECIMALS = 2;

  const fullNumber = new Decimal(number)
    .toFixed(maxDecimal)
    .replace(/(\.0+|0+)$/, "");

  let [numberPart, decimalPart] = fullNumber.split(".");
  const isLong = numberPart.length > 5;
  let truncInteger = "";
  if (
    decimalPart &&
    new Decimal(decimalPart).gt("0") &&
    new Decimal(numberPart).eq("0")
  ) {
    const decimalPartArray = decimalPart.split("");
    const firstNonZeroNumberIndex = decimalPartArray.findIndex(
      (number) => Number(number).valueOf() > 0
    );
    if (firstNonZeroNumberIndex > 3) {
      truncInteger = `${numberPart}.00${DOTS}${decimalPartArray[firstNonZeroNumberIndex]}`;
    } else {
      truncInteger = `${numberPart}.${decimalPart.slice(
        0,
        FRACTIONAL_DIGIT_COUNT
      )}`.replace(/(\.0+|0+)$/, "");
    }
  } else {
    if (isLong) {
      truncInteger = abbreviateNumber(
        parseFloat(numberPart),
        BIGNUMBERDECIMALS
      ) as string;
    } else if (decimalPart) {
      truncInteger = `${numberPart}.${decimalPart.slice(
        0,
        FRACTIONAL_DIGIT_COUNT
      )}`.replace(/(\.0+|0+)$/, "");
    } else {
      truncInteger = numberPart;
    }
  }

  /**
   * we look at if full number is same as triucated but since with "abbreviated" numbers eg 12B they displayed output is not the same as full but there is no point showing tooltip
   * since 12B loses no precision to user with fulll number of 12,000,000,000 we look at if abbreviation would have lost precision also.
   *
   * @param fullNum
   * @param truncInteger
   * @param isLong
   */
  function same(fullNum: string, truncInteger: string, isLong: boolean) {
    let [numberPart] = fullNumber.split(".");

    if (isLong) {
      let xx = parseFloat(numberPart).toFixed(BIGNUMBERDECIMALS);
      return new Decimal(xx).equals(new Decimal(fullNum));
    }

    return fullNumber === truncInteger;
  }

  return {
    full: fullNumber,
    fullCommas: fullNumber,
    trunc: truncInteger,
    same: Boolean(same(fullNumber, truncInteger, isLong)),
    same2DP: Boolean(
      toTwoDecimalPlaces(fullNumber) === toTwoDecimalPlaces(truncInteger)
    ),
  };
};
