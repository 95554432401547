import Provider from "../Provider";
import UniswapERC20ABI from "../../abis/UniswapERC20.json";
import { asAbi } from "./utils";
import { anErr, anOk, Result } from "../../result";
import { wrappedNameConversion } from "../../utils/display/wrappedNameConversion";

export interface TokenData {
  symbol: string;
  decimals: number;
  address: string;
}

export class UniswapERC20 {
  /**
   * We query the token contract and find the symbol for the token eg WETH
   *
   * @param tokenAddress
   */
  public static async getTokenData(
    tokenAddress: string,
    chainId: number | undefined
  ): Promise<Result<TokenData>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapERC20ABI),
      tokenAddress
    );

    try {
      const symbol = await contract.methods.symbol().call();
      const decimals: string = await contract.methods.decimals().call();

      return anOk({
        symbol: wrappedNameConversion(chainId, symbol, tokenAddress) as string,
        decimals: parseInt(decimals),
        address: tokenAddress,
      });
    } catch (error) {
      return anErr("Error trying to retrieve token data", error);
    }
  }
}
