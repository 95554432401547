import React from "react";
import { useHistory } from "react-router-dom";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { Tooltip } from "../Tooltip";

export enum progressStatus {
  UNKNOWN,
  PENDING,
  RUNNING,
  SUCCESS,
  FAILED,
}
export interface ProgressModalData {
  title: string;
  statusRunningText: string;
  statusSuccessText: string;
  status: progressStatus;
  onRetry: () => void;
}

interface ProgressModalProps {
  data: ProgressModalData[];
  isCompleted: boolean;
  errorMessage: string;
  setOpen: (val: boolean) => void;
  completedMessaage: string;
}

export const ProgressModal = React.memo(({
  data,
  isCompleted,
  errorMessage,
  setOpen,
  completedMessaage,
}: ProgressModalProps) => {
  const history = useHistory();

  const Index = ({
    index,
    status,
  }: {
    index: number;
    status: progressStatus;
  }) => {
    const getProgressIndexCss = () => {
      if (
        status === progressStatus.RUNNING ||
        status === progressStatus.SUCCESS
      ) {
        return "tw-bg-green-300 tw-text-coolGray-900";
      }
      if (
        status === progressStatus.PENDING ||
        status === progressStatus.UNKNOWN
      ) {
        return "tw-bg-coolGray-200 tw-text-coolGray-400";
      }
      if (status === progressStatus.FAILED) {
        return "tw-bg-red-400";
      }
      return "";
    };

    const getIndexContent = () => {
      if (status === progressStatus.SUCCESS) {
        return <CheckIcon className="tw-w-11" />;
      }
      if (status === progressStatus.FAILED) {
        return <XIcon className="tw-w-11" />;
      }
      return index;
    };
    return (
      <div
        className={`tw-w-20 tw-h-20 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-3xl tw-font-bold ${getProgressIndexCss()}`}
      >
        {getIndexContent()}
      </div>
    );
  };

  const Title = ({
    title,
    status,
  }: {
    title: string;
    status: progressStatus;
  }) => {
    return (
      <div
        className={`tw-text-xl tw-font-bold tw-whitespace-wrap tw-text-center tw-mx-3 tw-pt-2 tw-h-14 tw-leading-6 ${status === progressStatus.PENDING || status === progressStatus.UNKNOWN
          ? "tw-text-coolGray-400"
          : "tw-text-coolGray-900"
          }`}
      >
        {title}
      </div>
    );
  };

  const ProgressStatus = ({
    statusRunningText,
    statusSuccessText,
    status,
    onRetry,
  }: {
    statusRunningText: string;
    statusSuccessText: string;
    status: progressStatus;
    onRetry: () => void;
  }) => {
    if (status === progressStatus.FAILED) {
      return (
        <div
          className="tw-cursor-pointer mb-2 tw-text-base tw-font-semibold tw-text-coolGray-500 tw-shadow-base tw-border tw-border-coolGray-300 tw-rounded-full tw-py-1 tw-px-7"
          onClick={() => onRetry()}
        >
          Retry
        </div>
      );
    }
    return (
      <div
        className={`tw-text-base tw-font-bold tw-py-2 tw-px-7 tw-mb-2 ${status === progressStatus.PENDING || status === progressStatus.UNKNOWN
          ? "tw-text-coolGray-400"
          : "tw-text-coolGray-500"
          }`}
      >
        {status === progressStatus.SUCCESS
          ? statusSuccessText
          : statusRunningText}
      </div>
    );
  };

  const getProgressDotCss = (status: progressStatus) => {
    if (status === progressStatus.FAILED) {
      return "tw-bg-red-400";
    }
    if (status === progressStatus.RUNNING) {
      return "tw-bg-green-400";
    }
    return "tw-bg-blueGray-300";
  };

  const getItemWidthClass = (count: number) => {
    if (count <= 3) {
      return `tw-w-1/${count}`
    }
    if (count <= 4) {
      return "tw-justify-between tw-flex-nowrap";
    }
    return count > 6 ? "tw-w-1/4" : "tw-w-1/3";
  };

  const disableCancleBtn = () => {
    return (
      data.some((item) => item.status === progressStatus.RUNNING) &&
      !isCompleted
    );
  };

  return (
    <div className="tw-flex tw-justify-between tw-flex-col">
      <div className="tw-flex tw-justify-between tw-mt-5 tw-mx-6">
        <div>
          {errorMessage && (
            <div className="tw-text-xs tw-font-normal tw-text-red-800 tw-bg-red-200 tw-rounded-full tw-px-4 py-1">
              {errorMessage}
            </div>
          )}
        </div>
        <Tooltip
          tooltipText="Transaction in Progress"
          hide={!disableCancleBtn()}
        >
          <div>
            <XIcon
              onClick={() => !disableCancleBtn() && setOpen(false)}
              className={`tw-w-7 tw-cursor-pointer ${disableCancleBtn() &&
                "tw-text-coolGray-300 tw-cursor-not-allowed"
                }`}
            />
          </div>
        </Tooltip>
      </div>
      {isCompleted ? (
        <>
          <div className="tw-flex tw-flex-col tw-items-center">
            <div className="tw-w-16 tw-h-16 tw-rounded-full tw-bg-green-300 tw-flex tw-justify-center tw-items-center">
              <CheckIcon className="tw-w-9" />
            </div>
            <div className="tw-text-3xl tw-font-bold tw-text-coolGray-900 tw-mt-4">
              {completedMessaage}
            </div>
            {/* Hide for now */}
            {/* <div className="tw-text-base tw-font-semibold tw-text-coolGray-500">
              View on{" "}
              <a
                href="https://etherscan.io/tx/TX_HASH"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="tw-text-coolGray-500 tw-underline"
              >
                Etherscan
              </a>
            </div> */}
          </div>
          <div className="tw-text-center tw-mt-6">
            <div
              onClick={() => history.push("/automations")}
              className="tw-text-base tw-font-semibold tw-text-coolGray-500 tw-rounded-full tw-shadow-base tw-bg-blueGray-50 tw-border tw-border-coolGray-300 tw-inline-block tw-px-8 tw-py-2 tw-mb-10 tw-cursor-pointer"
            >
              Go back to Automations
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-overflow-y-auto  ${data.length <= 4
              ? "tw-justify-between tw-flex-nowrap tw-mx-10"
              : null
              }`}
          >
            {data.map((item, index) => (
              <div
                key={index}
                className={`tw-mt-4 tw-flex tw-flex-col tw-items-center ${getItemWidthClass(
                  data.length
                )}`}
              >
                <Index index={index + 1} status={item.status} />
                <Title title={item.title} status={item.status} />
                <ProgressStatus
                  statusRunningText={item.statusRunningText}
                  statusSuccessText={item.statusSuccessText}
                  status={item.status}
                  onRetry={item.onRetry}
                />
              </div>
            ))}
          </div>
          <div className="tw-flex tw-justify-center tw-my-8">
            {data.map((item, index) => (
              <div
                key={index}
                className={`tw-w-3 tw-h-3 tw-mx-2 tw-rounded-full  ${getProgressDotCss(
                  item.status
                )}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
})
