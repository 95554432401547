import React, { ReactNode, useRef } from "react";
import ReactTooltip from "react-tooltip";

interface TooltipProps {
  id?: string;
  tooltipText: string;
  hide?: boolean;
  children: ReactNode;
  className?: string;
}

export const Tooltip = ({
  id = "main",
  tooltipText,
  hide,
  children,
  className,
}: TooltipProps) => {
  let tooltipRef = useRef<HTMLDivElement>(null);

  if (hide) {
    return <div className={`${className ? className : "tw-inline-block"}`}>{children}</div>;
  } else {
    return (
      <>
        <div
          ref={tooltipRef}
          data-for={id}
          data-tip={tooltipText}
          data-iscapture="true"
          className={`${className ? className : "tw-inline-block"}`}
        >
          {children}
        </div>
        <ReactTooltip
          id={id}
          place={"bottom"}
          type={"info"}
          effect={"solid"}
          multiline={true}
          className={"customeTooltip"}
        />
      </>
    );
  }
};
