import Provider from "../Provider";
import { asAbi } from "./utils";
import VAULTABI from "../../abis/vault.json";
import { getNetworkDetails } from "../../configs";
import { anErr, anOk, isErr, Result } from "../../result";

/**
 * @classdesc Logic to make calls to contract with each public method being the same as a method name on the staking contract
 *
 */
export default class VaultContract {
  private contract: any | undefined;

  constructor(chainId: number) {
    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      this.contract = undefined;
    } else {
      this.contract = new Provider._web3.eth.Contract(
        asAbi(VAULTABI),
        networkDetails.value.vaultContractAddress
      );
    }
  }

  /**
   * Call approve method of contract. Approve this amount of funds to be sent to vault contract
   *
   * @param ethereumAddress subjects ethereum address
   * @param canonicalAmount canonical amount of fet to approve
   * @returns  Error object if there is error or void is a success
   */
  public async deposit(
    ethereumAddress: string,
    canonicalAmount: string,
    gasPrice: string
  ): Promise<Result<string>> {
    try {
      const result = await this.contract.methods
        .deposit(canonicalAmount)
        .send({ from: ethereumAddress, gasPrice });

      return anOk(result.transactionHash);
    } catch (e) {
      return anErr("Unable to deposit funds to account", e);
    }
  }
}
