import { StateStore } from "../../state/state";
import { PoolDetails } from "../../state/assets";
import Tooltip from "../../utils/tooltip/tooltip";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { capitalizeFirstLetter } from "../../utils/display/capitalizeFirstLetter";
import { getTokenErrorMessage } from "./utils";
import { PoolOptionBtns } from "./PoolOptionBtn";
import React from "react";

export function TokenTableRow(props: {
  token: any;
  onError: ({ currentTarget }: { currentTarget: any }) => void;
  state: StateStore;
  onClick: () => void;
  index: any;
  poolSuggestionBtnData: any[] | PoolDetails[];
}) {
  return (
    <tr className="tw-border-b tw-border-coolGray-200">
      <td>
        <div className="tw-flex tw-items-center tw-ml-6 tw-py-7 tw-mr-14 2xl:tw-mr-0">
          <img
            className="tw-mr-4 lg:tw-mr-8 tw-w-10 tw-rounded-full"
            src={props.token.logo_url}
            alt={props.token.symbol.toUpperCase()}
            onError={props.onError}
          />
          <div className="tw-flex tw-flex-col">
            <div className="tw-text-xl tw-font-bold tw-text-coolGray-900">
              {props.token.symbol.toUpperCase()}
            </div>
            <div className="tw-text-base tw-font-normal tw-text-coolGray-500">
              {props.token.name}
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="tw-text-center lg:tw-text-left">
          <div className="tw-font-semibold tw-whitespace-nowrap">
            <Tooltip
              child={` ${
                formatBigNumber(props.token.balance).trunc
              } ${props.token.symbol.toUpperCase()} `}
              tooltipTextClassName="tool-tip-center"
              text={`${
                formatBigNumber(
                  props.token.balance.length ? props.token.balance : "0.00"
                ).fullCommas
              } ${capitalizeFirstLetter(props.token.symbol)}`}
              hideTooltip={formatBigNumber(props.token.balance).same}
            />
          </div>
          <div className="tw-text-sm tw-font-normal tw-text-coolGray-900">
            <Tooltip
              child={` ${formatBigNumber(props.token.balanceUsd).trunc} $`}
              tooltipTextClassName="tool-tip-center"
              text={`${
                formatBigNumber(
                  props.token.balanceUsd.length
                    ? props.token.balanceUsd
                    : "0.00"
                ).fullCommas
              } $`}
              hideTooltip={formatBigNumber(props.token.balanceUsd).same}
            />
          </div>
        </div>
      </td>
      <td align="center">
        <Tooltip
          child={
            <div
              className={`tw-whitespace-nowrap tw-bg-green-300 tw-inline-block tw-mx-2  tw-w-fit tw-px-9 tw-py-3 tw-rounded-full tw-text-coolGray-900 tw-text-base tw-font-semibold tw-shadow-base 
                            ${
                              getTokenErrorMessage(props.state, props.token) !==
                              ""
                                ? "tw-opacity-60"
                                : "tw-cursor-pointer"
                            } `}
              onClick={props.onClick}
              id={`${props.index === 0 ? "tour_swap_btn" : ""}`}
            >
              Swap Automation
            </div>
          }
          tooltipTextClassName="tool-tip-center"
          text={getTokenErrorMessage(props.state, props.token)}
          hideTooltip={getTokenErrorMessage(props.state, props.token) === ""}
        />
      </td>
      <td className="tw-w-20 tw-pr-3 tw-relative">
        <PoolOptionBtns
          token={props.token}
          pools={props.poolSuggestionBtnData}
        />
      </td>
    </tr>
  );
}
