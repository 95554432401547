import { action, makeObservable } from "mobx";
import { Network } from "../configs";
import { AccessToken } from "./token";
import { updateAgents } from "../api/Sync";
import { CollectionStore } from "./utils/collectionStore";
import { LocalStorage } from "./localStorage";

export enum AgentType {
  STOP_LOSS = 1,
}

export interface Agent {
  uuid: string;
  agentAddress: string;
  type: AgentType;
  maxTriggers: number;
  network: Network;
  createdAt: Date;
  balance?: string;
  userAddress: string;
  name: string;
  withdrawPending: boolean;
  deletePending: boolean;
}

export class AgentStore extends CollectionStore<Agent> {
  get hasEverHadAgent(): boolean {
    return this._hasEverHadAgent;
  }

  set hasEverHadAgent(value: boolean) {
    this._hasEverHadAgent = value;
  }

  public syncing = true;

  private localStorage: LocalStorage;

  private _hasEverHadAgent: boolean;

  constructor(localStorage: LocalStorage) {
    super("uuid");
    this.localStorage = localStorage;
    makeObservable(this, {
      update: action,
      updateName: action,
      chainSwitched: action,
    });
    this._hasEverHadAgent = Boolean(this.localStorage.hasEverHadAgent);
  }

  update(agent: Agent): boolean {
    const currentAgent = this.items.get(agent.uuid);
    if (currentAgent === undefined) {
      return false;
    }

    // copy all the details except the name of the agent
    const nextAgent: Agent = {
      agentAddress: agent.agentAddress,
      balance: agent.balance,
      createdAt: agent.createdAt,
      maxTriggers: agent.maxTriggers,
      network: currentAgent.network, // never allow the network to change
      name: currentAgent.name !== "" ? currentAgent.name : agent.name, // the UI might be changing the agent name
      type: agent.type,
      userAddress: agent.userAddress,
      uuid: currentAgent.uuid, // never allow the uuid to change
      withdrawPending: agent.withdrawPending,
      deletePending: agent.deletePending,
    };

    this.items.set(agent.uuid, nextAgent);
    return true;
  }

  updateName(uuid: string, name: string): boolean {
    let success = false;

    const agent = this.items.get(uuid);
    if (agent !== undefined) {
      agent.name = name;
      this.items.set(uuid, agent);
      success = true;
    }

    return success;
  }

  async chainSwitched(token: AccessToken): Promise<void> {
    await updateAgents(token, this);
  }

  add(item: Agent): boolean {
    if (this._hasEverHadAgent === false) {
      this._hasEverHadAgent = true;
      this.localStorage.hasEverHadAgent = true;
    }

    return super.add(item);
  }

  overWrite(items: Array<Agent>) {
    super.overWrite(items);

    if (items.length && this._hasEverHadAgent === false) {
      this._hasEverHadAgent = true;
      this.localStorage.hasEverHadAgent = true;
    }
  }
}
