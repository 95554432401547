export const fetchPoolQuery = `query Query($address: String!) {
  pool(address: $address) {
    price
    price0Avg
    address
    price1Avg
    token0Sym
    token1Sym
    token0Address
    token1Address
    token0Decimals
    token1Decimals
    chain
    type
    updatedAt
  }
}`;
