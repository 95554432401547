import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { NATIVE_TOKEN_ADDRESS } from "../../api/common";
import HelpIcon from "../../assets/help-icon.png";
import { ThresholdToken } from "../../pages/automations/swapAutomation";
import { isOk } from "../../result";
import ERC20Contract from "../../services/contracts/ERC20Contract";
import { CryptoToken } from "../../state/assets";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import Tooltip from "../../utils/tooltip/tooltip";
import { Dropdown } from "../Dropdown";
import { PricePercentageBox } from "../PricePercentageBox";
import { PricePercentageInput } from "../PricePercentageInput";
import { SlippageInput } from "../SlippageInput";
import { Tooltip as HelpToolTip } from "../Tooltip";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum Automation {
  TRADING_AUTOMATION,
  SWAP_AUTOMATION,
}
const pricePercentagesSwap = [
  {
    percentage: -25,
    value: 0.75,
  },
  {
    percentage: -10,
    value: 0.9,
  },
  {
    percentage: 10,
    value: 1.1,
  },
  {
    percentage: 25,
    value: 1.25,
  },
];
const pricePercentagesTrading = [
  {
    percentage: -50,
    value: 0.5,
  },
  {
    percentage: -35,
    value: 0.65,
  },
  {
    percentage: -25,
    value: 0.75,
  },
  {
    percentage: -10,
    value: 0.9,
  },
];

export const TradingStrategy = ({
  automation,
  tokens,
  threshold,
  setThreshold,
  slippage,
  setSlippage,
  thresholdToken,
  setThresholdToken,
  thresholdMsg,
  setThresholdMsg,
  slippageMsgType,
  setSlippageMsgType,
  selectedPercentage,
  setSelectedPercentage,
  inputPercentage,
  setInputPercentage,
}: {
  automation: Automation;
  tokens: ThresholdToken[];
  threshold: number | undefined;
  setThreshold: Function;
  slippage: number;
  setSlippage: Function;
  thresholdToken: ThresholdToken | undefined;
  setThresholdToken: Function;
  thresholdMsg: string;
  setThresholdMsg: Function;
  slippageMsgType: string;
  setSlippageMsgType: Function;
  selectedPercentage: number;
  setSelectedPercentage: Function;
  inputPercentage: number | undefined;
  setInputPercentage: Function;
}) => {
  const [otherToken, setOtherToken] = useState<ThresholdToken>(tokens[1]);
  const [decimals, setDecimals] = useState<number>(6);

  // Threshold error msg
  useEffect(() => {
    if (threshold === undefined || thresholdToken?.price === undefined) setThresholdMsg("");
    else if (threshold <= 0) setThresholdMsg(`Not Valid Threshold`);
    else if (tooManyDecimals(threshold.toString()))
      setThresholdMsg(
        `Decimal places should not be greater than ${otherToken?.symbol.toUpperCase()} decimal limit`
      );
    else setThresholdMsg("");
  }, [threshold]);

  // update thresholdToken on dropdown token changes
  useEffect(() => {
    const thresholdTkn = tokens[0]?.validThresholdToken ? tokens[0] : tokens[1];
    setThresholdToken(thresholdTkn);
  }, [tokens]);

  // update otherToken on thresholdToken changes
  useEffect(() => {
    const getdecimals = async (otherToken: ThresholdToken) => {
      let decimals = 18;
      if (otherToken && otherToken.address !== NATIVE_TOKEN_ADDRESS) {
        const resultDecimals = await ERC20Contract.getDecimals(
          otherToken.address
        );
        if (isOk(resultDecimals)) decimals = parseInt(resultDecimals.value);
      }
      setDecimals(decimals);
    };

    const otherToken = tokens[0]?.address.toUpperCase() === thresholdToken?.address?.toUpperCase() ? tokens[1] : tokens[0]
    setOtherToken(otherToken);
    getdecimals(otherToken);
  }, [...tokens, thresholdToken]);

  // reset percentage and threshold on otherToken changes
  useEffect(() => {
    handleSelectedPercentage(-25);
  }, [otherToken]);

  const calcSpotPrice = (percentageValue: number | undefined) => {
    if (percentageValue === undefined) {
      return { spotPrice: 0, usdPrice: 0 };
    }

    const multiplier = (100 + percentageValue) / 100;

    let spotPrice, usdPrice;

    if (!thresholdToken) {
      spotPrice = "0";
      usdPrice = 0;
    } else {
      usdPrice = parseFloat(thresholdToken?.price) * multiplier;
      spotPrice = (usdPrice / parseFloat(otherToken?.price)).toFixed(
        decimals
      );
    }
    return { spotPrice: parseFloat(spotPrice), usdPrice };
  };
  const tooManyDecimals = (subject: string): boolean => {
    if (subject === "") return false;
    const decimalPlaces = new Decimal(subject).decimalPlaces();
    return decimalPlaces > decimals;
  };

  const handleSelected = (token: CryptoToken) => {
    setThresholdToken(token);
  };

  const handleSelectedPercentage = (value: number) => {
    setSelectedPercentage(value);
    setInputPercentage(undefined);
    const threshold = calcSpotPrice(value);

    if (threshold.spotPrice === 0) {
      setThreshold(0);
    } else {
      setThreshold(threshold.spotPrice);
    }
  };

  const handleInputPercentage = (value: number) => {
    if (value == 0 || !value) {
      handleSelectedPercentage(-25);
    } else if (value >= -100 && value <= 100) {
      setSelectedPercentage(0);
      const val =
        automation === Automation.TRADING_AUTOMATION ? -Math.abs(value) : value;
      setInputPercentage(val);
      const threshold = calcSpotPrice(val);
      setThreshold(threshold.spotPrice);
    }
  };

  return (
    <div className="tw-shadow-lg tw-rounded-xl tw-overflow-hidden tw-flex tw-flex-col lg:tw-col-span-6">
      <div className="tw-bg-coolGray-200 tw-px-7 tw-py-2 tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-text-coolGray-900 tw-text-xl tw-font-medium tw-items-center">
          Trading strategy&nbsp;
          <HelpToolTip
            id="help-txn-speed"
            tooltipText={`The trading strategy lets you define rules for when the ${automation === Automation.SWAP_AUTOMATION
              ? "swap"
              : "withdraw liquidity"
              } action should trigger.`}
          >
            <img src={HelpIcon} width="20px" />
          </HelpToolTip>
        </div>
        <div className="tw-text-base tw-font-normal tw-text-coolGray-900 tw-whitespace-nowrap tw-ml-1">
          Spot price: &nbsp;<wbr/>
          {
            thresholdToken?.price !== undefined ? (
              <>
                1 {thresholdToken?.symbol?.toUpperCase()} =&nbsp;
                <Tooltip
                  child={`${formatBigNumber(calcSpotPrice(0).spotPrice.toString()).trunc
                    }`}
                  tooltipTextClassName="tool-tip-center"
                  text={`${formatBigNumber(calcSpotPrice(0).spotPrice.toString()).full
                    }`}
                  hideTooltip={
                    formatBigNumber(calcSpotPrice(0).spotPrice.toString()).same
                  }
                />&nbsp;
                {otherToken?.symbol?.toUpperCase()} (
                <Tooltip
                  child={`$${formatBigNumber(calcSpotPrice(0).usdPrice.toString()).trunc
                    }`}
                  tooltipTextClassName="tool-tip-center"
                  text={`$${formatBigNumber(calcSpotPrice(0).usdPrice.toString()).full
                    }`}
                  hideTooltip={
                    formatBigNumber(calcSpotPrice(0).usdPrice.toString()).same
                  }
                />
                )
              </>
            ) :
              <span className="tw-w-40 tw-inline">
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </span>
          }

        </div>
      </div>
      <div className="tw-p-7 tw-bg-white tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-pb-0">
        <div className="tw-text-xl tw-font-medium tw-text-coolGray-900 tw-flex tw-items-center tw-mb-4 lg:tw-mb-0">
          {automation === Automation.SWAP_AUTOMATION
            ? "Execute swap "
            : "Remove liquidity "}
          if
          <span className="mx-2">
            <Dropdown
              selected={thresholdToken}
              handleSelected={handleSelected}
              tokens={tokens}
            />
          </span>
          changes by more than:
        </div>
        <div className="tw-grid tw-grid-cols-6 tw-gap-7 tw-mt-4">
          <PricePercentageInput
            inputPercentage={inputPercentage}
            handleInputPercentage={handleInputPercentage}
            price={calcSpotPrice(inputPercentage)}
          />
          {automation === Automation.SWAP_AUTOMATION &&
            pricePercentagesSwap.map(({ percentage }, index) => (
              <PricePercentageBox
                key={index}
                otherTokenSymbol={otherToken?.symbol?.toUpperCase()}
                thresholdTokenSymbol={thresholdToken?.symbol?.toUpperCase()}
                percentage={percentage}
                price={calcSpotPrice(percentage)}
                spotPriceValue={calcSpotPrice(0)}
                isSelected={selectedPercentage === percentage}
                onClick={handleSelectedPercentage}
              />
            ))}
          {automation === Automation.TRADING_AUTOMATION &&
            pricePercentagesTrading.map(({ percentage }, index) => (
              <PricePercentageBox
                key={index}
                otherTokenSymbol={otherToken?.symbol?.toUpperCase()}
                thresholdTokenSymbol={thresholdToken?.symbol?.toUpperCase()}
                percentage={percentage}
                price={calcSpotPrice(percentage)}
                spotPriceValue={calcSpotPrice(0)}
                isSelected={selectedPercentage === percentage}
                onClick={handleSelectedPercentage}
              />
            ))}
        </div>
        <span className="tw-w-full tw-mt-1 tw-text-sm tw-text-red-600">
          {thresholdToken !== undefined ? thresholdMsg : ""}
        </span>
        <SlippageInput
          slippage={slippage}
          setSlippage={setSlippage}
          slippageMsgType={slippageMsgType}
          setSlippageMsgType={setSlippageMsgType}
        />
      </div>
    </div>
  );
};
