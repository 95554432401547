import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import "normalize.css";
import "./App.scss";
import Provider from "./services/Provider";
import { StateContext, StateStore } from "./state/state";
import { Web3Store } from "./state/metamask";
import { AccountStore, loadAccessToken } from "./state/account";
import {
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerStore,
  TriggerType,
} from "./state/triggers";
import { FeatureFlags } from "./state/features";
import { Eth } from "./state/eth";
import { DashboardStore } from "./state/dashboard";
import { PairCache } from "./state/pairCache";
import { AgentStore } from "./state/agents";
import { LocalStorage } from "./state/localStorage";
import {
  clearState,
  fastLaneStateSync,
  fetchHoldingAssets,
  slowLaneStateSync,
} from "./api/Sync";
import { ConfirmPage } from "./pages/ConfirmPage";
import { TriggersPage } from "./pages/TriggersPage";
import { AgentsPage } from "./pages/AgentsPage";
import { ProfilePage } from "./pages/ProfilePage";
import { SettingsPage } from "./pages/SettingsPage";
import { AccountsPage } from "./pages/accounts/AccountsPage";
import { DashboardPage } from "./pages/dashboard/dashBoardPage";
import { TradingAutomation } from "./pages/automations/tradingAutomation";
import { SwapAutomation } from "./pages/automations/swapAutomation";
import { AutomationSetup } from "./pages/automations/automationSetup";
import { TopNavbar } from "./components/Navbars/TopNavbar";
import { SideNavBar } from "./components/Navbars/SideNavBar";
import { PageTracker } from "./components/GoogleAnalytics";
import { FeedbackBanner } from "./components/FeedbackBanner/FeedbackBanner";
import { getFirebaseAnalytics } from "./firebase";
import {
  ENABLE_SYNC_LOGGING,
  MAIN_WEBSITE_URI,
  Network,
  VERSION,
} from "./configs";
import { TokenAPI } from "./api/Tokens";
import { isOk } from "./result";
import Decimal from "decimal.js";
import { stat } from "fs";
import { IntroPage } from "./pages/introPage/IntroPage";
import { scrollToTop } from "./utils/display/scrollToTop";

// create your forceUpdate hook
function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const AppContent = observer(() => {
  const state = useContext(StateContext);

  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location]);

  const Logout = () => {
    state.account.logout();

    const storage = new LocalStorage();
    const accountStore = new AccountStore(storage);

    const agents = new AgentStore(storage);
    const triggers = new TriggerStore(storage);

    state.localStorage = storage;
    state.web3 = new Web3Store(storage, () => {
      agents.clear();
      triggers.clear();
    });
    state.account = accountStore;
    state.agents = agents;
    state.triggers = triggers;
    state.pairCache = new PairCache();
    state.dashboard = new DashboardStore();
    state.eth = new Eth();
    state.features = new FeatureFlags();
    return null;
  };

  const fetchTokens = async () => {
    if (state.account.user && state.web3.address) {
      // calling one api to get 1000 token
      state.assets.allTokens = [];
      await Promise.all(
        [...Array(1).keys()].map((page) => {
          if (state.account.user)
            return TokenAPI.fetchAllTokens(
              state.account.user.token.accessToken,
              page + 1
            )
              .then((data) => {
                if (isOk(data)) {
                  state.assets.allTokens.push(...data.value);
                }
              })
              .finally(() => (state.assets.allTokenSyncing = false));
        })
      );
    }
  };

  useEffect(() => {
    // track user id changes
    if (state.web3.address !== undefined) {
      getFirebaseAnalytics()?.setUserId(state.web3.address);
    }

    (async () => {
      // When address change clear the holding assests.
      state.assets.holdingTokensBSC = [];
      state.assets.holdingTokensETH = [];
      state.assets.holdingPoolsUniswap = [];
      state.assets.holdingPoolCake = [];
      // fetch top 1000 tokens
      await fetchTokens();
    })();
  }, [state.web3.address]);

  useEffect(() => {
    state.assets.syncing = true;
    if (!state.assets.allTokenSyncing) {
      fetchHoldingAssets(state, () => {
        state.assets.syncing = false;
        // get suggested pools
        const holdingTokens =
          state.web3.networkId === Network.ETH
            ? state.assets.holdingTokensETH
            : state.assets.holdingTokensBSC;
        state.assets.fetchSuggestedPools(holdingTokens, state);
      });
    }
  }, [
    state.web3.networkId,
    state.assets.allTokenSyncing,
    state.assets.holdingTokensETH.length,
    state.assets.holdingTokensBSC.length,
  ]);

  return (
    <>
      <div className="App">
        <FeedbackBanner />

        <SideNavBar navbar={
          <TopNavbar
            address={state.web3.address ?? ""}
            chainId={state.web3.chainId ?? -1}
            network={state.web3.networkId}
            web3Installed={Boolean(state.web3.address !== undefined)}
            web3Unlocked={state.web3.loggedIn}
          />
        }>
        <PageTracker />

        {/* <Container fluid className={"container"}> */}
        <main className="tw-flex-1 tw-min-h-screen  tw-bg-coolGray-50">
            <div className="tw-max-w-full tw-px-0 2xl:tw-px-6">
              <Switch>
                <Route exact path="/dashboard">
                  <DashboardPage />
                </Route>
                <Route
                  exact
                  path="/dashboard/tradingAutomation/:token1/:token2"
                >
                  <TradingAutomation />
                </Route>
                <Route exact path="/dashboard/swapAutomation/:id">
                  <SwapAutomation />
                </Route>
                <Route exact path="/dashboard/automationSetup/:id">
                  <AutomationSetup />
                </Route>
                <Route exact path="/automations">
                  <TriggersPage state={state} />
                </Route>
                <Route exact path="/agents">
                  <AgentsPage state={state} />
                </Route>
                <Route exact path="/account">
                  <AccountsPage />
                </Route>
                <Route exact path="/profile">
                  <ProfilePage />
                </Route>
                <Route exact path="/settings">
                  <SettingsPage />
                </Route>
                {/* <Route exact path="/confirm">
              <ConfirmPage />
            </Route> */}
                <Route exact path="/logout">
                  <Logout />
                  <Redirect to="/" />
                </Route>
                <Route path="*">
                  <Redirect to="/dashboard" />
                </Route>
              </Switch>
            </div>
        </main>
        {/* </Container> */}
        </SideNavBar >
      </div>
      <div className="footer">
        <a
          href={MAIN_WEBSITE_URI}
          rel="noopener noreferrer"
          className="versionTag"
        >
          Fetch.ai {new Date().getFullYear()}
        </a>
        <div className="versionTag">{VERSION}</div>
      </div>
    </>
  );
});

const AppWrapper = observer(() => {
  const state: StateStore = useContext(StateContext);

  // call your hook here
  const forceUpdate = useForceUpdate();

  const performFastLaneSync = async () => {
    const start = new Date().getTime();

    await fastLaneStateSync(state);

    const finish = new Date().getTime();
    if (ENABLE_SYNC_LOGGING) {
      console.log(
        `Periodic sync (fast lane) complete (duration: ${finish - start} ms)`
      );
    }
  };

  const performSlowLaneSync = async () => {
    const start = new Date().getTime();

    await slowLaneStateSync(state);

    const finish = new Date().getTime();
    if (ENABLE_SYNC_LOGGING) {
      console.log(
        `Periodic sync (slow lane) complete (duration: ${finish - start} ms)`
      );
    }
  };

  useEffect(() => {
    const wrapper = async () => {
      await Provider.init();
      await performFastLaneSync();
      await performSlowLaneSync();
    };
    wrapper();
  }, []);

  useEffect(() => {
    const checkIfLoggedInViaStorage = async () => {
      if (
        !state.web3.loggedIn &&
        (state.account.user === undefined ||
          state.account.user?.address !== state.web3.address)
      ) {
        await Provider.init();
        state.localStorage.loadLoggedIn();
        state.localStorage.loadUserDetails();

        if (state.localStorage.loggedIn) {
          const token = loadAccessToken(state.localStorage);
          let address;
          try {
            address = ((await Provider.fetchAccounts()) as any)[0];
          } catch (e) {
            console.log(e);
          }
          // const address = ((await Provider.fetchAccounts()) as any)[0];
          if (token !== undefined && typeof address === "string") {
            state.account = new AccountStore(state.localStorage);
            state.localStorage.init();
            state.web3.setLoggedIn(true, clearState.bind(null, state));
            state.account.login(address as string, token.token);
            await fastLaneStateSync(state);
            forceUpdate();
          }
        }
      }
    };
    try {
      checkIfLoggedInViaStorage();
    } catch (e) {
      console.log(e);
    }
  }, [state.account.user, state.account]);

  useEffect(() => {
    const fastLaneInterval = setInterval(performFastLaneSync, 5000);
    const slowLaneInterval = setInterval(performSlowLaneSync, 5000 * 10);
    return () => {
      clearInterval(fastLaneInterval);
      clearInterval(slowLaneInterval);
    };
  }, [state]);

  let web3Address = state.web3?.address;

  if (typeof web3Address === "string") {
    web3Address = web3Address.toUpperCase();
  }

  let accountAddress = state.account.user?.address;

  if (typeof accountAddress === "string") {
    accountAddress = accountAddress.toUpperCase();
  }

  if (state.account.user !== undefined && web3Address === accountAddress) {
    return <AppContent />;
  }
  return <IntroPage />;
});

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/confirm">
          <ConfirmPage />
        </Route>
        <AppWrapper />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
