import { action, computed, makeAutoObservable } from "mobx";
import {
  BASIC_STORAGE_KEYS,
  INDEXED_STORAGE_KEYS,
  storageItem,
} from "../tests/services/localStorage/types";

import { anErr, anOk, isErr, isOk, OkResult, Result } from "../result";
import { TokenStorageObject } from "./account";

function fetchFromStorage(key: string) {
  const item = localStorage.getItem(key);

  if (item === null) return anErr("none existant");

  try {
    return anOk(JSON.parse(item));
  } catch (error) {
    return anErr("invalid json");
  }
}

/**
 * error if not found
 *
 * if passing in an address then will filter by address
 *
 * @param address
 * @param key
 */
function getIndexedStorageValue(
  address: string,
  key: INDEXED_STORAGE_KEYS
): Result<any> {
  const result = fetchFromStorage(key);

  if (isErr(result) || result === undefined || !Array.isArray(result)) {
    return anErr("not array");
  }

  const filtered = (result as OkResult<storageItem[]>).value.filter(
    (el: storageItem) => {
      return Boolean(el.address === address);
    }
  );

  return anOk(filtered[0]);
}
/**
 * error if not found
 *
 *
 * @param address
 * @param key
 */
function getSimpleStorageValue(key: BASIC_STORAGE_KEYS): Result<any> {
  const parsed = fetchFromStorage(key);

  if (isErr(parsed) || Array.isArray(parsed)) {
    anErr("not array");
  }

  return anOk(parsed);
}

function updateSimpleStorageValue(value: any, key: BASIC_STORAGE_KEYS) {
  localStorage.setItem(key, JSON.stringify(value));
}

function updateIndexedStorageValue(
  address: string,
  value: any,
  key: INDEXED_STORAGE_KEYS
) {
  const stored = fetchFromStorage(key);

  let updated;

  if (isErr(stored) || !stored.value.length) {
    updated = [
      {
        address,
        value,
      },
    ];
  } else {
    updated = stored.value;
  }

  let found = false;

  if (updated.length) {
    // update the value for our address
    for (let i = 0; i < updated.length; i++) {
      if (updated[i].address === address) {
        updated[i].value = value;
        found = true;
      }
    }
  }

  if (!found) {
    updated.push({
      address,
      value,
    });
  }

  localStorage.setItem(key, JSON.stringify(updated));
}

// wraps local storage data into seperate store class which also allows us not to need to keep loading from local storage every single time we need the data
export class LocalStorage {
  private initialised = false;

  @action
  set hasEverHadTrigger(value: boolean | undefined) {
    if (this._tokenDetails === undefined || value === undefined) return;

    if (this._hasEverHadTrigger === value) return;

    this._hasEverHadTrigger = value;

    updateIndexedStorageValue(
      this._tokenDetails.address,
      value,
      INDEXED_STORAGE_KEYS.HAS_EVER_HAD_TRIGGER
    );
  }

  @action
  setLoggedIn(value: boolean | undefined) {
    if (this._loggedIn === value) return;
    this._loggedIn = value;
    updateSimpleStorageValue(value, BASIC_STORAGE_KEYS.LOGGED_IN);
  }

  get hasEverHadTrigger(): boolean | undefined {
    return this._hasEverHadTrigger;
  }

  @computed
  get loggedIn(): boolean | undefined {
    return this._loggedIn;
  }

  set hasEverHadAgent(value: boolean | undefined) {
    if (this._address === undefined || value === undefined) return;

    if (this._hasEverHadAgent === value) return;

    this._hasEverHadAgent = value;

    updateIndexedStorageValue(
      this._address,
      value,
      INDEXED_STORAGE_KEYS.HAS_EVER_HAD_AGENT
    );
  }

  @action
  set tokenDetails(value: TokenStorageObject | undefined) {
    if (value === undefined) return;
    this._tokenDetails = value;

    updateSimpleStorageValue(value, BASIC_STORAGE_KEYS.TOKEN_KEY);
  }

  get tokenDetails(): TokenStorageObject | undefined {
    return this._tokenDetails;
  }

  get hasEverHadAgent(): boolean | undefined {
    return this._hasEverHadAgent;
  }

  private _address?: string;

  private _hasEverHadTrigger?: boolean;

  private _hasEverHadAgent?: boolean;

  private _tokenDetails?: TokenStorageObject;

  private _loggedIn?: boolean;

  @action
  loadHasEverHadTrigger(): boolean | undefined {
    if (this._tokenDetails === undefined) return;

    const result = getIndexedStorageValue(
      this._tokenDetails.address,
      INDEXED_STORAGE_KEYS.HAS_EVER_HAD_TRIGGER
    );

    if (isOk(result)) {
      this._hasEverHadTrigger = result.value;
    }
  }

  @action
  loadUserDetails() {
    const result = getSimpleStorageValue(BASIC_STORAGE_KEYS.TOKEN_KEY);
    if (isOk(result)) {
      this._tokenDetails = result.value.value;
    }
  }

  @action
  loadLoggedIn(): boolean | undefined {
    const result = getSimpleStorageValue(BASIC_STORAGE_KEYS.LOGGED_IN);

    if (isOk(result)) {
      this._loggedIn = result.value.value;
    }

    return this._loggedIn;
  }

  @action
  loadHasEverHadAgent(): boolean | undefined {
    if (this._tokenDetails?.address === undefined) return;

    const result = getIndexedStorageValue(
      this._tokenDetails.address,
      INDEXED_STORAGE_KEYS.HAS_EVER_HAD_AGENT
    );

    if (isOk(result)) {
      this._hasEverHadAgent = result.value;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }

  @action
  init() {
    if (this.initialised) {
      return;
    }

    this.loadHasEverHadTrigger();
    this.loadHasEverHadAgent();
    this.loadUserDetails();
    this.loadLoggedIn();
    this.initialised = true;
  }
}
