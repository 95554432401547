import React, { useEffect, useState } from "react";

import ethIcon from "../../assets/eth.png";
import daiIcon from "../../assets/dai.png";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { CryptoToken } from "../../state/assets";
import { OutsideClickHandler } from "../OutsideClickHandler";
import { ThresholdToken } from "../../pages/automations/swapAutomation";
import cryptocurrencyBackup from "../../assets/cryptocurrency.svg";

interface Data {
  val: string;
  image: string;
  title: string;
}

const data: Data[] = [
  {
    val: "eth",
    image: ethIcon,
    title: "ETH",
  },
  {
    val: "dai",
    image: daiIcon,
    title: "DAI",
  },
  {
    val: "eth/dai",
    image: ethIcon,
    title: "ETH/DAI",
  },
];

export const Dropdown = ({
  tokens,
  selected,
  handleSelected,
}: {
  tokens: ThresholdToken[];
  selected: CryptoToken | undefined;
  handleSelected: Function;
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const haveMoreThanOneValidThresholdToken = () => {
    return tokens.filter(tkn => tkn.validThresholdToken).length > 1
  }

  return (
    <div className="tw-inline-block tw-min-w-10 tw-w-auto">
      <OutsideClickHandler onOutsideClick={() => setIsHidden(true)}>
        <div className="tw-relative">
          <div
            onClick={() => haveMoreThanOneValidThresholdToken() && setIsHidden(!isHidden)}
            className={`${isHidden ? "tw-rounded-full" : "tw-rounded-t"
              } ${haveMoreThanOneValidThresholdToken() ? "tw-cursor-pointer" : null} tw-h-10  tw-bg-coolGray-100 tw-border tw-border-coolGray-300 tw-px-2 tw-py-1 tw-flex tw-justify-between tw-items-center`}
          >
            {selected && (
              <div className="tw-flex tw-items-center">
                <img
                  className="tw-w-6 tw-mr-2"
                  src={selected.logo_url}
                  alt={selected?.symbol?.toUpperCase()}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = cryptocurrencyBackup;
                  }}
                />{" "}
                <div>{selected?.symbol?.toUpperCase()}</div>
              </div>)
            }
            {
              haveMoreThanOneValidThresholdToken() &&
              < ArrowIcon className="tw-transform tw--rotate-90 tw-ml-4 tw-mr-2 tw-w-2.5" />
            }

          </div>
          <div
            className={`tw-absolute tw-z-10 tw-top-full tw-min-w-10 tw-w-auto tw-rounded-b ${isHidden ? "tw-hidden " : null
              }`}
          >
            {tokens?.filter(tkn => tkn.validThresholdToken)?.map((val, index) => (
              <div
                key={index}
                onClick={() => {
                  handleSelected(val);
                  setIsHidden(true);
                }}
                className="last:tw-rounded-b tw-cursor-pointer tw-border tw-border-t-0 tw-border-coolGray-300 tw-bg-white tw-py-2 tw-px-2 tw-flex tw-items-center"
              >
                <div className="tw-flex tw-items-center">
                  <img
                    className="tw-w-6 tw-mr-2"
                    src={val.logo_url}
                    alt="token"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = cryptocurrencyBackup;
                    }}
                  />{" "}
                  <div>{val.symbol.toUpperCase()}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
