import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";
import { CryptoToken, HoldingToken } from "../../state/assets";
import "./style.scss";
import classNames from "classnames";
import { isFirefox } from "../../utils/isFirefox";
interface TokenDropdownProps {
  selected: HoldingToken | CryptoToken;
  otherToken: HoldingToken | CryptoToken | undefined;
  tokenList: HoldingToken[] | CryptoToken[];
  setSelected: Function;
  isFromToken?: boolean;
}

import cryptocurrencyBackup from "../../assets/cryptocurrency.svg";
import { ModalWrapper } from "../ModalWrapper";

export const TokenDropdown = ({
  tokenList,
  otherToken,
  selected,
  setSelected,
  isFromToken = false,
}: TokenDropdownProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [searchToken, setSearchToken] = useState<string>("");
  const [list, setList] = useState<HoldingToken[] | CryptoToken[]>(tokenList);

  /**
   *  we remove needle from haystack because we do not user to have same token at either side of trade
   *
   * @param needle
   * @param list
   */
  function removeTokenFromList(
    needle: HoldingToken | CryptoToken | undefined,
    list: HoldingToken[] | CryptoToken[]
  ): HoldingToken[] | CryptoToken[] {
    if (needle === undefined) return list;

    return list.filter((el: HoldingToken | CryptoToken) => {
      return Boolean(el.address.toUpperCase() !== needle.address.toUpperCase() );
    });
  }

  useEffect(() => {
    const searchCriteria = (token: HoldingToken | CryptoToken) => {
      return (
        token.name.toLowerCase().includes(searchToken.toLowerCase()) ||
        token.address.toLowerCase().includes(searchToken.toLowerCase()) ||
        token.symbol.toLowerCase().includes(searchToken.toLowerCase())
      );
    };
    let filteredList = tokenList.filter((token) => searchCriteria(token));

    filteredList = removeTokenFromList(otherToken, filteredList);

    if (otherToken) {
      setList([otherToken]);
    } else if (filteredList.length) {
      setList(filteredList);
    } else setList([]);
  }, [searchToken]);

  useEffect(() => {
    if (!searchToken.length) {
      const filteredList = removeTokenFromList(otherToken, tokenList);
      setList(filteredList);
    }
  }, [tokenList]);

  function showAlternativeFireFoxVersion() {
    return Boolean(isFirefox() && isFromToken);
  }

  return (
    <>
      <ModalWrapper
        title="Select a token"
        onClose={() => {
          setSearchToken("");
          setIsHidden(true);
        }}
        isHidden={isHidden}
      >
        <input
          value={searchToken}
          onChange={(evt) => setSearchToken(evt.target.value)}
          className="token-search-text tw-w-full"
          aria-label="Default"
          placeholder="Enter name, symbol or address"
        />

        <div className="token-list">
          {list.length ? (
            list.map((token, i) => {
              return (
                <div
                  key={token.address}
                  className="tw-flex tw-items-center token-row"
                  onClick={() => {
                    setSelected(token);
                    setSearchToken("");
                    setIsHidden(true);
                    document.body.style.overflow = "unset";
                  }}
                >
                  <img
                    className="tw-w-6"
                    src={token.logo_url}
                    alt={token.symbol}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = cryptocurrencyBackup;
                    }}
                    width="32"
                    height="32"
                  />
                  <div className="token-name tw-gray-900 tw-text-xl tw-font-medium">
                    {token.symbol.toUpperCase()}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="tw-text-center">
              <div className="tw-gray-900 tw-text-xl tw-font-medium">
                No Token Found
              </div>
            </div>
          )}
        </div>
      </ModalWrapper>
      <div className="tw-bg-coolGray-200 tw-rounded tw-py-2 tw-w-max">
        <div className="tw-relative">
          <div
            onClick={() => {
              document.body.style.overflow = "hidden";
              setIsHidden(!isHidden);
            }}
            className={`${
              isHidden ? "tw-rounded-full" : "tw-rounded-t"
            } tw-cursor-pointer tw-px-2 tw-py-1 tw-flex tw-justify-between tw-items-center`}
          >
            <div className="tw-flex tw-px-4 tw-min-w-max tw-w-auto">
              <img
                className="tw-w-8 tw-h-8 tw-mr-2"
                src={selected?.logo_url}
                alt={selected?.symbol.toUpperCase()}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = cryptocurrencyBackup;
                }}
              />{" "}
              <div className="tw-text-coolGray-900 tw-text-xl tw-font-medium">
                {selected?.symbol.toUpperCase()}
              </div>
              {showAlternativeFireFoxVersion() ? (
                <ArrowIcon className="tw-transform tw--rotate-90 tw-ml-4 tw-mr-6 tw-w-2.5 tw-mt-2" />
              ) : (
                ""
              )}
            </div>

            {!showAlternativeFireFoxVersion() ? (
              <ArrowIcon className="tw-transform tw--rotate-90 tw-ml-4 tw-mr-2 tw-w-2.5" />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
