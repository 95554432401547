import { ArrowLeftIcon } from "@heroicons/react/outline";
import { Decimal } from "decimal.js";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NATIVE_TOKEN_ADDRESS } from "../../../api/common";
import { Costs } from "../../../components/Costs";
import Modal from "../../../components/modal";
import {
  InputValidation,
  InvalidReason,
} from "../../../components/NewTriggerModal/SelectTokenAndPool";
import { TokenDropdown } from "../../../components/TokenDropdown/index";
import { Tooltip } from "../../../components/Tooltip";
import { Automation, TradingStrategy } from "../../../components/TradingStrategy";
import {
  getNetworkDetails,
  Network,
  PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE,
  ZERO_PAIR_ADDRESS,
} from "../../../configs";
import { isErr, isOk } from "../../../result";
import { FactoryContract } from "../../../services/contracts/Factory";
import { PairContract } from "../../../services/contracts/PairContract";
import { Agent } from "../../../state/agents";
import { HoldingToken } from "../../../state/assets";
import { Pair } from "../../../state/pairCache";
import { StateContext } from "../../../state/state";
import {
  NewTrigger,
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerType,
} from "../../../state/triggers";
import { gweiToWei } from "../../../utils/currency/GweiToWei";
import { calcAvailableAgents } from "../../../utils/misc/calcAvailableAgents";
import {
  calcTotalGasCosts,
  isNativeTokenSufficient,
} from "../../../utils/misc/calcTotalGasCost";
import { findHoldingAssets } from "../../../utils/misc/findHoldingAssets";
import { isTokenSame } from "../../../utils/misc/isTokenSame";
import { eventAnalysis } from "../../../utils/misc/trackEvent";
import { formatBigNumber } from "../../../utils/numeric/formatBigNumber";
import { hasTooManyDecimalPlaces } from "../../../utils/numeric/hasTooManyDecimalPlaces";
import { isNumeric } from "../../../utils/numeric/isNumeric";
import { isPercentageNegative } from "../../../utils/numeric/isPercentageNegative";
import { StripRightwardZerosFromDecimalComponent } from "../../../utils/numeric/StripRightwardZerosFromDecimalComponent";
import { truncateToDecimalPlaces } from "../../../utils/numeric/truncateToDecimalPlaces";
import { Validation } from "../../../validation";
import { ThresholdToken } from "../swapAutomation";
import { TradingAutomationProgress } from "./tradingAutomationProgress";
import { changeURLWithoutReload } from "../../../utils/display/changeUrlWithoutReload";
import { isInvalidPool } from "../../../utils/networkRequests/isInvalidPool";
import {PairCache, PairInterfacesTypes} from "../../../state/pairCache";

interface TokenLiquidityBoxProps {
  token: HoldingToken | undefined;
  setToken: Function;
  otherToken: HoldingToken | undefined;
  amount: number | undefined;
  setAmount: Function;
  tokenList: HoldingToken[];
  decimalsExceeded: boolean;
  setDecimalsExceeded: Function;
  inputError: boolean;
  setInputError: Function;
  poolError: InputValidation;
  setOtherTokenAmount: Function;
  handleAmountChange: Function;
  handleOtherAmountChange: Function;
}

function getPoolValidationMessage(
  validation: InputValidation,
  token1Symbol: string,
  token2Symbol: string
): string | undefined {
  if (validation.validity !== Validation.INVALID) {
    return;
  }
  const namePrefix = `${token1Symbol.toLowerCase()}_${token2Symbol.toLowerCase()}`;
  switch (validation.reason) {
    case InvalidReason.INVALID_POOL: {
      eventAnalysis(
        `${namePrefix}_indirect_pair_err`,
        TriggerType.TRADING_AUTOMATION,
        "There is no pair for selected tokens. Indirect swaps are currently unsupported"
      );
      return "There is no pair for selected tokens. Indirect swaps are currently unsupported";
    }
    case InvalidReason.UNABLE_TO_FETCH_PAIR: {
      eventAnalysis(
        `${namePrefix}_fetch_pair_err`,
        TriggerType.TRADING_AUTOMATION,
        "Not able to fetch pair, please close and try again"
      );
      return "Not able to fetch pair, please close and try again";
    }
    case InvalidReason.TWO_TRIGGERS_ON_POOL: {
      eventAnalysis(
        `${namePrefix}_already_two_trigger_err`,
        TriggerType.TRADING_AUTOMATION,
        "You already have two triggers for the above from token - to token combination"
      );
      return "You already have two triggers for the above from token - to token combination";
    }
    case InvalidReason.UNSUPPORTED_NETWORK:
      return "Network or chain connection error, please refresh and try again";

    default:
      return "Unknown error";
  }
}

const balanceTooltip = (id: string, balance: string | undefined) => {
  if (formatBigNumber(balance || "0.00").same)
    return formatBigNumber(balance || "0.00").trunc;

  return (
    <Tooltip id={id} tooltipText={`${formatBigNumber(balance || "0.00").full}`}>
      {formatBigNumber(balance || "0.00").trunc}
    </Tooltip>
  );
};

const amountTooltip = (id: string, amount: string | undefined) => {
  if (formatBigNumber(amount || "0.00").same)
    return "$ " + formatBigNumber(amount || "0.00").trunc;

  return (
    <Tooltip
      id={id}
      tooltipText={`$ ${formatBigNumber(amount ? amount : "0.00").full}`}
    >
      $ {formatBigNumber(amount || "0.00").trunc}
    </Tooltip>
  );
};

const TokenLiquidityBox = ({
  token,
  setToken,
  amount,
  setAmount,
  otherToken,
  tokenList,
  decimalsExceeded,
  setDecimalsExceeded,
  inputError,
  setInputError,
  poolError,
  handleAmountChange,
  handleOtherAmountChange,
}: TokenLiquidityBoxProps) => {
  const [tokenUSD, setTokenUSD] = useState<string>("");
  useEffect(() => {
    if (token) {
      const price = parseFloat(token.price);
      const calcResult =
        amount === undefined ? "" : (amount * price).toString();
      setTokenUSD(calcResult);
    }
  }, [amount, token]);

  const calcPrice = (price: string, maxAmount: string): number => {
    return parseFloat(maxAmount) * parseFloat(price);
  };

  const calcOtherAmount = (
    otherToken: HoldingToken,
    amountUSD: number
  ): string => {
    return (amountUSD / parseFloat(otherToken.price)).toFixed(
      otherToken.decimal
    );
  };

  /**
   * Only gets error message around decimals. If decimal places not supported by currency show different error.
   *
   * @param decimalsExceeded
   * @param maxDecimals
   */
  const getDecimalsErrorMessage = (
    input: number | undefined,
    decimalsExceeded: boolean,
    maxDecimals: number | undefined
  ) => {
    maxDecimals = maxDecimals === undefined ? 0 : maxDecimals;
    if (!decimalsExceeded || input === undefined) {
      return "";
    }

    const WHOLE_NUMBER_ERROR_MESSAGE = "requires whole number";

    if (maxDecimals === undefined || maxDecimals <= 0) {
      return WHOLE_NUMBER_ERROR_MESSAGE;
    }

    return "Max decimals exceeded";
  };

  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-coolGray-300 tw-bg-coolGray-50 tw-p-3 tw-rounded-2xl">
      <div className="tw-flex-1 tw-flex tw-items-center">
        <div className="tw-flex tw-flex-col tw-rounded-2xl tw-w-full">
          {token && (
            <div className="tw-flex tw-justify-end">
              Current balance:&nbsp;
              {balanceTooltip("token1-amount", token.balance)}&nbsp;
              {token.symbol.toUpperCase()}
              <span
                className="tw-bg-green-300 tw-rounded tw-text-coolGray-900 tw-text-xs tw-font-medium tw-px-3 tw-py-1 tw-ml-7 tw-cursor-pointer"
                onClick={() => {
                  let maxAmount;

                  setInputError(false);
                  setDecimalsExceeded(false);

                  if (!token || !otherToken) {
                    return;
                  }

                  if (
                    otherToken.balance === undefined ||
                    token.balance === undefined
                  ) {
                    maxAmount = "0";
                  } else {
                    maxAmount = token.balance;
                  }

                  let amountUSD = calcPrice(token.price, maxAmount);
                  let otherAmount = calcOtherAmount(otherToken, amountUSD);
                  let decimals = token.decimal;
                  let otherDecimals = otherToken.decimal;

                  if (
                    new Decimal(otherAmount).greaterThan(
                      new Decimal(otherToken.balance)
                    )
                  ) {
                    otherAmount = otherToken.balance;
                    let otherAmountUSD = calcPrice(
                      otherToken.price,
                      otherAmount
                    );
                    maxAmount = calcOtherAmount(token, otherAmountUSD);
                    decimals = otherToken.decimal;
                    otherDecimals = token.decimal;
                  }

                  if (hasTooManyDecimalPlaces(otherAmount, decimals)) {
                    otherAmount = truncateToDecimalPlaces(
                      otherAmount,
                      decimals
                    );
                  }

                  if (hasTooManyDecimalPlaces(maxAmount, otherDecimals)) {
                    maxAmount = truncateToDecimalPlaces(maxAmount, decimals);
                  }

                  otherAmount =
                    StripRightwardZerosFromDecimalComponent(otherAmount);

                  maxAmount =
                    StripRightwardZerosFromDecimalComponent(maxAmount);

                  handleOtherAmountChange(otherAmount);
                  setAmount(maxAmount);
                }}
              >
                {" "}
                Add max{" "}
              </span>
            </div>
          )}

          <div className="tw-flex tw-items-center tw-justify-between">
            {token ? (
              <TokenDropdown
                otherToken={otherToken}
                tokenList={tokenList}
                selected={token}
                setSelected={setToken}
                isFromToken={true}
              />
            ) : (
              <div>Loading...</div>
            )}
            <div className="tw-w-full tw-ml-5">
              <input
                className="tw-w-full tw-bg-white tw-h-12 tw-rounded tw-text-xl tw-font-medium tw-text-coolGray-900 tw-text-right tw-border tw-border-coolGray-300 tw-my-2 tw-px-3 focus:tw-ring-2 focus:tw-ring-blue-600 "
                type="text"
                value={amount}
                onChange={(e) => {
                  if (!token) return;
                  const val = e.target.value;

                  const res = handleAmountChange(val);
                  if (res && token && otherToken) {
                    const price1 = parseFloat(token.price);
                    const price2 = parseFloat(otherToken.price);
                    const amountUSD =
                      val === undefined ? 0 : parseFloat(val) * price1;
                    let otherAmount = (amountUSD / price2).toFixed(
                      otherToken.decimal
                    );
                    otherAmount =
                      StripRightwardZerosFromDecimalComponent(otherAmount);
                    handleOtherAmountChange(otherAmount);
                  }
                }}
                placeholder="00.00"
              />
            </div>
          </div>
          <div className="tw-flex tw-justify-end tw-mb-2">
            <div className="tw-text-red-700 tw-mr-3">
              {inputError ? "Invalid input" : ""}
            </div>
            <div className="tw-text-red-700 tw-mr-3">
              {getDecimalsErrorMessage(
                amount,
                decimalsExceeded,
                token === undefined ? undefined : token.decimal
              )}
            </div>
            <div className="tw-flex tw-justify-end">
              {amountTooltip("token-amount-usd", tokenUSD)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getTokenIndex = (token1: string, selectedPair: Pair) => {
  return selectedPair.token0Address.toUpperCase() === token1.toUpperCase()
    ? 0
    : 1;
};
const getThresholdTokenIndex = (tokenIndex: 0 | 1) =>
  tokenIndex === 0 ? 1 : 0;

export const TradingAutomation = () => {
  let params = useParams<{ token1: string; token2: string }>();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const state = useContext(StateContext);

  const [tokenPair, setTokenPair] = useState<ThresholdToken[]>([]);

  const [token1, setToken1] = useState<HoldingToken>();
  const [token1Amount, setToken1Amount] = useState<number | undefined>(
    undefined
  );
  const [holdingAssets, setHoldingAssets] = useState<HoldingToken[]>([]);

  const [tokenList1, setTokenList1] = useState<HoldingToken[]>([]);

  const [tokenList2, setTokenList2] = useState<HoldingToken[]>([]);

  const [token2, setToken2] = useState<HoldingToken>();
  const [token2Amount, setToken2Amount] = useState<number | undefined>(
    undefined
  );

  //Error states
  const [inputError1, setInputError1] = useState<boolean>(false);
  const [inputError2, setInputError2] = useState<boolean>(false);
  const [decimalsExceeded1, setDecimalsExceeded1] = useState<boolean>(false);
  const [decimalsExceeded2, setDecimalsExceeded2] = useState<boolean>(false);
  const [thresholdMsg, setThresholdMsg] = useState<string>("");
  const [slippageMsgType, setSlippageMsgType] = useState<string>("");

  //Data we need for API
  const [thresholdToken, setThresholdToken] = useState<ThresholdToken>();
  const [threshold, setThreshold] = useState<number>(0);
  const [selectedPercentage, setSelectedPercentage] = useState(-25);
  const [inputPercentage, setInputPercentage] = useState<number | undefined>(
    undefined
  );
  const [slippage, setSlippage] = useState<number>(2);
  const [txnSpeed, setTxnSpeed] = useState<number>(0);
  const [pair, setPair] = useState<Pair>();
  const [triggerPairData, setTriggerPairData] = useState<Pair>();
  const [agent, setAgent] = useState<Agent | undefined>();
  const [trigger, setTrigger] = useState<NewTrigger | undefined>();
  const [emptyCustom, setEmptyCustom] = useState<boolean>(false);
  const [poolValidation, setPoolValidation] = useState<InputValidation>({
    validity: Validation.UNKNOWN,
  });

  /**
   * this goes through all holding assets and then looks at the token trie to find a pair for it.
   *
   * The pair cache is seached first, and is also updated.
   */
  const resetList = async (
    whichList: 1 | 2,
    token: HoldingToken,
    holdingAssetsParam?: Array<HoldingToken>
  ) => {
    // also we must update list when this changes
    const list: Array<HoldingToken> = [];

    const assets =
      holdingAssetsParam !== undefined ? holdingAssetsParam : holdingAssets;

    await Promise.all(
      assets.map(async (holdingToken: HoldingToken) => {
        {
          const found = state.pairCache.findByTokens(
            token.address,
            holdingToken.address,
            state.web3.networkId
          );

          if (found === null) {
            if (state.web3.chainId === undefined) return;

            const pairAddress = await FactoryContract.fetchPair(
              token.address,
              holdingToken.address,
              state.web3.chainId
            );

            if (isErr(pairAddress)) {
              return;
            }

            if (await isInvalidPool(pairAddress)) {
              state.pairCache.add({
                network: state.web3.networkId,
                token0: token.address,
                token1: holdingToken.address,
                type: PairInterfacesTypes.NON_EXISTANT_PAIR,
              });
              return;
            }

            let poolInformation = await PairContract.fetchPoolInformation(
              pairAddress.value,
              state.web3.networkId,
              state.web3.chainId
            );

            if (isErr(poolInformation)) {
              //todo unsure if the values here are correct please can remind to reviewer to check before deleting.
              state.pairCache.add({
                network: state.web3.networkId,
                token0: token.address,
                token1: holdingToken.address,
                type: PairInterfacesTypes.PARTIAL_PAIR,
              });
              list.push(holdingToken);
              return;
            }

            state.pairCache.add({
              ...poolInformation.value,
              token0Address: poolInformation.value.token1Address,
              token0: poolInformation.value.token1,
              token0Decimals: poolInformation.value.token1Decimals,
              token1Address: poolInformation.value.token0Address,
              token1: poolInformation.value.token0,
              token1Decimals: poolInformation.value.token0Decimals,
            });

            return;
          } else if (
            PairCache.PairType(found) !== PairInterfacesTypes.NON_EXISTANT_PAIR
          ) {
            list.push(holdingToken);
          }
        }
      })
    );

    if (whichList === 1) {
      setTokenList1(list);
    } else {
      setTokenList2(list);
    }
  };

  const setToken1Wrapper = async (token1: HoldingToken) => {
    if (params.token1 === token1.address.toUpperCase()) {
      return;
    }
    changeURLWithoutReload(
      `/dashboard/tradingAutomation/${token1.address}/${params.token2}`
    );
    setToken1(token1);
    await resetList(1, token1);

    if (token2 !== undefined) {
      await resetList(1, token2);
    }
  };

  const setToken2Wrapper = async (token2: HoldingToken) => {
    if (params.token1 === token2.address.toUpperCase()) {
      return;
    }
    changeURLWithoutReload(
      `/dashboard/tradingAutomation/${params.token1}/${token2.address}`
    );
    setToken2(token2);
    await resetList(2, token2);

    if (token1 !== undefined) {
      await resetList(1, token1);
    }
  };

  const resetPage = () => {
    setPoolValidation({
      validity: Validation.UNKNOWN,
    });
    setInputError1(false);
    setInputError2(false);
    setToken1Amount(0);
    setToken2Amount(0);
    setDecimalsExceeded1(false);
    setDecimalsExceeded2(false);
    setThresholdMsg("");
    setSlippageMsgType("");
    setThresholdToken(undefined);
    setThreshold(0);
    setSlippage(2);
    setTxnSpeed(100);
    setAgent(undefined);
    fetchHoldingAssets();
    setTimeout(fetchHoldingAssets, 3000);
  };

  const handleToken1AmountChange = (val: any) => {
    if (!token1) return;

    if (val === "" || val.substring(0, 1) === "-") {
      setInputError1(false);
      setDecimalsExceeded1(false);
      setToken1Amount(undefined);
      setToken2Amount(undefined);
      return;
    }

    if (!isNumeric(val) && val !== ".") {
      setInputError1(true);
      setDecimalsExceeded1(false);
      return;
    }

    if (new Decimal(val).gt(new Decimal(token1.balance))) {
      setInputError1(true);
      setDecimalsExceeded1(false);
      return;
    }

    if (new Decimal(val).lt(new Decimal(0))) {
      setInputError1(false);
      setDecimalsExceeded1(false);
      setToken1Amount(undefined);
      setToken2Amount(undefined);
      return;
    }

    if (hasTooManyDecimalPlaces(val, token1.decimal)) {
      setInputError1(false);
      setDecimalsExceeded1(true);
      return;
    }

    setInputError1(false);
    setDecimalsExceeded1(false);
    setToken1Amount(val);
    return true;
  };

  const handleToken2AmountChange = (val: any) => {
    if (!token2) return;

    if (val === "" || val.substring(0, 1) === "-") {
      setInputError2(false);
      setDecimalsExceeded2(false);
      setToken1Amount(undefined);
      setToken2Amount(undefined);
      return;
    }

    if (!isNumeric(val) && val !== ".") {
      setInputError2(true);
      setDecimalsExceeded2(false);
      return;
    }

    if (new Decimal(val).gt(new Decimal(token2.balance))) {
      setInputError2(true);
      setDecimalsExceeded2(false);
      return;
    }

    if (new Decimal(val).lt(new Decimal(0))) {
      setInputError2(false);
      setDecimalsExceeded2(false);
      setToken1Amount(undefined);
      setToken2Amount(undefined);
      return;
    }

    if (hasTooManyDecimalPlaces(val, token2.decimal)) {
      setInputError2(false);
      setDecimalsExceeded2(true);
      return;
    }

    setInputError2(false);
    setDecimalsExceeded2(false);
    setToken2Amount(val);
    return true;
  };

  const checkDisableAddLiquidity = () => {
    if (
      inputError1 ||
      inputError2 ||
      decimalsExceeded1 ||
      decimalsExceeded2 ||
      thresholdMsg ||
      emptyCustom ||
      slippageMsgType === "error" ||
      poolValidation.validity === Validation.INVALID ||
      token1Amount == undefined ||
      token1Amount <= 0 ||
      !pair ||
      !isNativeBalanceSufficient
    ) {
      return true;
    }
    return false;
  };

  const getAvailableAgents = () => {
    return calcAvailableAgents(
      state.agents.list(),
      state.web3.networkId,
      state.web3.address,
      // @ts-ignore
      state.triggers.agentNonCompletedTriggersCount.bind(state.triggers)
    );
  };

  const handleProceedClick = () => {
    eventAnalysis(
      `proceed_button_clicked`,
      TriggerType.TRADING_AUTOMATION,
      "trading automation proceed button clicked"
    );
    // Get available agent
    const availableAgents = getAvailableAgents();
    if (availableAgents.length) {
      setAgent(() => availableAgents[0]);
    }

    if (!thresholdToken || !triggerPairData || !pair) {
      return;
    }
    const thresholdIndex = getTokenIndex(thresholdToken.address, pair);
    const isNegative = isPercentageNegative(
      inputPercentage,
      selectedPercentage
    );
    const tokenIndex = getThresholdTokenIndex(thresholdIndex);

    const triggerData = {
      network: state.web3.networkId,
      agentUuid: availableAgents[0]?.uuid || "",
      pair: triggerPairData,
      threshold: threshold.toString(),
      price0Min:
        thresholdIndex === TriggerChoices.TOKEN0 && isNegative
          ? threshold.toString()
          : "0",
      price0Max:
        thresholdIndex === TriggerChoices.TOKEN0 && !isNegative
          ? threshold.toString()
          : "0",
      price1Min:
        thresholdIndex === TriggerChoices.TOKEN1 && isNegative
          ? threshold.toString()
          : "0",
      price1Max:
        thresholdIndex === TriggerChoices.TOKEN1 && !isNegative
          ? threshold.toString()
          : "0",
      token: thresholdIndex,
      gasPrice: gweiToWei(txnSpeed.toString()),
      gasAmount: PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE.toString(),
      slippage: slippage,
      amount: "0",
      // amount: amount === undefined ? "" : amount.toString(), this will be set when liquidity added
    };
    setTrigger(triggerData);
    setOpen(true);
  };

  function tokenNotAlreadySelected(
    poolAddress: string,
    triggers: Array<Trigger>,
    pair: Pair
  ): false | string {
    const filtered = triggers.filter((trigger: Trigger) => {
      return Boolean(
        trigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY &&
        trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
        ![TriggerState.COMPLETED, TriggerState.FAILED].includes(trigger.state)
      );
    });

    if (filtered.length === 0) {
      return false;
    }

    return filtered[0].token === 0 ? pair.token1Address : pair.token0Address;
  }

  const fetchPair = async (token1: string, token2: string) => {
    setPair(undefined);
    setPoolValidation({ validity: Validation.UNKNOWN });

    if (state.web3.networkId === Network.UNKNOWN) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }

    const chainId = state.web3.chainId;
    if (!chainId) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }
    const networkDetails = getNetworkDetails(chainId);
    let wrappedContractAddress;
    if (isOk(networkDetails)) {
      wrappedContractAddress = networkDetails.value.wrappedNativeTokenAddress;
    }
    if (token1 === NATIVE_TOKEN_ADDRESS && wrappedContractAddress) {
      token1 = wrappedContractAddress;
    }
    const pairAddress = await FactoryContract.fetchPair(
      token1,
      token2,
      chainId
    );
    if (pairAddress.success) {
      if (pairAddress.value === ZERO_PAIR_ADDRESS) {
        setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.INVALID_POOL,
        });
        return;
      }

      let poolInformation = await PairContract.fetchPoolInformation(
        pairAddress.value,
        state.web3.networkId,
        chainId
      );
      if (poolInformation.success) {
        if (
          poolInformation.value.token0Address.toUpperCase() !==
          token1.toUpperCase()
        ) {
          //  shuffle tokens when token1 address is not match with pair token0 address
          setTriggerPairData({
            ...poolInformation.value,
            token0Address: poolInformation.value.token1Address,
            token0: poolInformation.value.token1,
            token0Decimals: poolInformation.value.token1Decimals,
            token1Address: poolInformation.value.token0Address,
            token1: poolInformation.value.token0,
            token1Decimals: poolInformation.value.token0Decimals,
          });
        } else setTriggerPairData(poolInformation.value);

        setPair(poolInformation.value);
        if (
          getArmedTriggersForTokenAndPair(token1, poolInformation.value) >= 2
        ) {
          setPoolValidation({
            validity: Validation.INVALID,
            reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
          });
        }
      }
    } else {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNABLE_TO_FETCH_PAIR,
      });
    }
  };

  const getArmedTriggersForTokenAndPair = (
    token1: string,
    selectedPair: Pair
  ): number => {
    const triggers = state.triggers.list();
    return triggers.filter((trigger: Trigger) => {
      const swap_token =
        trigger.swap_token !== undefined ? trigger.swap_token : trigger.token;
      return Boolean(
        trigger.pair.address.toUpperCase() ===
        selectedPair.address.toUpperCase() &&
        getTokenIndex(token1, selectedPair) === swap_token &&
        ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
          trigger.state
        ) &&
        trigger.triggerType === TriggerType.SWAP
      );
    }).length;
  };

  async function fetchHoldingAssets() {
    if (!state.web3.chainId) return; //handle error here

    const holdingAssetRes = findHoldingAssets(state);

    if (isErr(holdingAssetRes)) return;

    setHoldingAssets([...holdingAssetRes.value]);

    const fromTkn =
      holdingAssetRes.value.find(
        (tkn) => tkn.address.toUpperCase() === params.token1.toUpperCase()
      ) || holdingAssets[0];
    const toTkn =
      holdingAssetRes.value.find(
        (tkn) => tkn.address.toUpperCase() === params.token2.toUpperCase()
      ) || holdingAssets[1];

    await resetList(1, fromTkn, holdingAssetRes.value);
    await resetList(2, toTkn, holdingAssetRes.value);

    setToken1(fromTkn);
    setToken2(toTkn);
  }

  const [totalCost, setTotalCost] = useState<number>(0);
  const [isNativeBalanceSufficient, setIsNativeBalanceSufficient] =
    useState<boolean>(true);

  useEffect(() => {
    if (state.web3.chainId) {
      const totalCost = calcTotalGasCosts(
        state.assets.allTokens,
        state.web3.chainId,
        txnSpeed,
        state.account.gasPrice
      );
      setTotalCost(totalCost);
    }
  }, [
    state.assets.allTokens.length,
    state.web3.chainId,
    state.account.gasPrice,
    txnSpeed,
  ]);

  useEffect(() => {
    let tokenAmount = "0";
    if (token1?.isNative) tokenAmount = `${token1Amount}` || "0";
    if (token2?.isNative) tokenAmount = `${token2Amount}` || "0";
    setIsNativeBalanceSufficient(
      isNativeTokenSufficient(totalCost, holdingAssets, tokenAmount)
    );
  }, [
    totalCost,
    token1,
    token2,
    holdingAssets.length,
    token1Amount,
    token2Amount,
  ]);

  useEffect(() => {
    state.web3.networkId === Network.BSC ? setTxnSpeed(10) : setTxnSpeed(100);
  }, [state.web3.networkId]);

  // Responsible for updating from token
  useEffect(() => {
    fetchHoldingAssets();
  }, [
    state.assets.holdingTokensETH.length,
    state.assets.holdingTokensBSC.length,
  ]);

  useEffect(() => {
    if (token1 && token2) {
      const namePrefix = `${token1.symbol.toLowerCase()}_${token2.symbol.toLowerCase()}`;
      eventAnalysis(
        `${namePrefix}_pair`,
        TriggerType.TRADING_AUTOMATION,
        `Selected token pair ${namePrefix}`
      );
      if (token1.address.toUpperCase() !== token2.address.toUpperCase()) {
        fetchPair(token1.address, token2.address);
      }
    }
  }, [token1, token2]);

  useEffect(() => {
    if (!token1 || !token2) return;
    if (pair) {
      let tokenPair;
      let notSelectedToken = tokenNotAlreadySelected(
        pair.address,
        state.triggers.list(),
        pair
      );
      if (notSelectedToken) {
        if (notSelectedToken.toUpperCase() === token1.address.toUpperCase()) {
          // from-token is not selected in previous triggers as a threshold token
          tokenPair = [
            { ...token1, validThresholdToken: true },
            { ...token2, validThresholdToken: false },
          ];
        } else {
          // to-token is not selected in previous triggers as a threshold token
          tokenPair = [
            { ...token1, validThresholdToken: false },
            { ...token2, validThresholdToken: true },
          ];
        }
      } else {
        // Both From and To tokens are not selected in previous triggers as a threshold token
        tokenPair = [
          { ...token1, validThresholdToken: true },
          { ...token2, validThresholdToken: true },
        ];
      }
      setTokenPair(tokenPair);
    } else {
      setTokenPair([
        { ...token1, validThresholdToken: true },
        { ...token2, validThresholdToken: false },
      ]);
    }
  }, [pair]);

  useEffect(() => {
    const thresholdTkn = tokenPair[0]?.validThresholdToken
      ? tokenPair[0]
      : tokenPair[1];
    setThresholdToken({ ...thresholdTkn, validThresholdToken: true });
  }, [tokenPair, token1, token2]);

  useEffect(() => {
    if (inputPercentage) {
      if (inputPercentage > 0) {
        eventAnalysis(
          `positive_threshold_%`,
          TriggerType.TRADING_AUTOMATION,
          inputPercentage.toString()
        );
      } else {
        eventAnalysis(
          `negative_threshold_%`,
          TriggerType.TRADING_AUTOMATION,
          inputPercentage.toString()
        );
      }
    } else {
      if (selectedPercentage > 0) {
        eventAnalysis(
          `positive_threshold_%`,
          TriggerType.TRADING_AUTOMATION,
          selectedPercentage.toString()
        );
      } else {
        eventAnalysis(
          `negative_threshold_%`,
          TriggerType.TRADING_AUTOMATION,
          selectedPercentage.toString()
        );
      }
    }
  }, [selectedPercentage, inputPercentage]);

  return (
    <div className="tw-px-6 2xl:tw-px-20">
      <h2 className="tw-font-normal tw-text-4xl tw-flex tw-items-center tw-relative">
        <ArrowLeftIcon
          className="tw-w-7 tw-cursor-pointer 2xl:tw-absolute tw-mr-6 tw--left-16"
          onClick={() => history.goBack()}
        />
        Add trading automation
      </h2>
      <div className="tw-text-xl tw-font-semibold tw-my-4">Add liquidity</div>
      <div className="tw-shadow-lg tw-rounded-xl tw-overflow-hidden">
        <div className="tw-bg-coolGray-200 tw-text-coolGray-900 tw-text-xl tw-font-medium tw-pl-7 tw-py-2">
          Pool
        </div>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-20 tw-p-7 tw-bg-white">
          {token2 && (
            <TokenLiquidityBox
              token={token1}
              setToken={setToken1Wrapper}
              otherToken={token2}
              amount={token1Amount}
              setAmount={setToken1Amount}
              setOtherTokenAmount={setToken2Amount}
              tokenList={tokenList1.filter(
                (token) => !isTokenSame(token2, token)
              )}
              decimalsExceeded={decimalsExceeded1}
              setDecimalsExceeded={setDecimalsExceeded1}
              inputError={inputError1}
              setInputError={setInputError1}
              poolError={poolValidation}
              handleAmountChange={handleToken1AmountChange}
              handleOtherAmountChange={handleToken2AmountChange}
            />
          )}
          {token1 && (
            <TokenLiquidityBox
              token={token2}
              setToken={setToken2Wrapper}
              amount={token2Amount}
              setAmount={setToken2Amount}
              otherToken={token1}
              setOtherTokenAmount={setToken1Amount}
              tokenList={tokenList2.filter(
                (token) => !isTokenSame(token1, token)
              )}
              decimalsExceeded={decimalsExceeded2}
              setDecimalsExceeded={setDecimalsExceeded2}
              inputError={inputError2}
              setInputError={setInputError2}
              poolError={poolValidation}
              handleAmountChange={handleToken2AmountChange}
              handleOtherAmountChange={handleToken1AmountChange}
            />
          )}
        </div>
      </div>
      <div className="tw-text-xl tw-font-semibold tw-mb-4 tw-mt-11">
        Add automation
      </div>
      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-11 tw-gap-12">
        <TradingStrategy
          automation={Automation.TRADING_AUTOMATION}
          tokens={tokenPair}
          threshold={threshold}
          thresholdToken={thresholdToken}
          setThresholdToken={setThresholdToken}
          setThreshold={setThreshold}
          slippage={slippage}
          setSlippage={setSlippage}
          thresholdMsg={thresholdMsg}
          setThresholdMsg={setThresholdMsg}
          slippageMsgType={slippageMsgType}
          setSlippageMsgType={setSlippageMsgType}
          selectedPercentage={selectedPercentage}
          setSelectedPercentage={setSelectedPercentage}
          inputPercentage={inputPercentage}
          setInputPercentage={setInputPercentage}
        />
        <Costs
          txnSpeed={txnSpeed}
          setTxnSpeed={setTxnSpeed}
          setEmptyCustom={setEmptyCustom}
        />
      </div>
      <div className="tw-mt-7 tw-flex tw-justify-center">
        <Tooltip
          children={
            <div
              className={`tw-bg-green-300 tw-text-xl tw-font-semibold tw-rounded tw-px-16 tw-py-4 tw-inline-block tw-cursor-pointer ${
                checkDisableAddLiquidity() ? "tw-opacity-60" : null
              }`}
              onClick={() =>
                !checkDisableAddLiquidity() && handleProceedClick()
              }
            >
              Proceed
            </div>
          }
          tooltipText={`Insufficient ${state.web3.networkId === Network.BSC ? "BSC" : "ETH"
            } balance to create Automation`}
          hide={isNativeBalanceSufficient}
        />
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        widthClass="6xl"
        closeOnOutsideClick={false}
      >
        {trigger && token1 && token2 && (
          <TradingAutomationProgress
            onComplete={() => {
              resetPage();
            }}
            setOpen={setOpen}
            trigger={trigger}
            agent={agent}
            state={state}
            token1={token1}
            token2={token2}
            token1Amount={token1Amount?.toString() || "0"}
            token2Amount={token2Amount?.toString() || "0"}
          />
        )}
      </Modal>
    </div>
  );
};
