import React, { useContext, useState, useEffect } from "react";
import {
  faAngleDown,
  faGasPump,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./SelectGasPrice.module.scss";
import { StateContext } from "../../state/state";
import { observer } from "mobx-react-lite";
import { OutsideClickHandler } from "../OutsideClickHandler";
import { isOk } from "../../result";
import { GasPrices } from "../../configs";
import { getGasPrices } from "../../utils/networkRequests/getGasPrice";

enum GasType {
  SLOW,
  MEDIUM,
  FAST,
}
export interface GasPriceInfo {
  type: GasType;
  price: string;
}

export const SelectGasPrice = observer(() => {
  const state = useContext(StateContext);

  const [showGasPrices, setShowGasPrices] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState<GasPriceInfo>({
    type: GasType.MEDIUM,
    price: state.eth.gasPrices.MEDIUM,
  }); // Standard is default Medium

  const onSelectGasPrice = (price: string, type: GasType) => {
    setSelectedPrice({ type, price });
    setShowGasPrices(false);
  };

  const isGasPriceLoaded = () => {
    return (
      state.eth.gasPrices.LOW !== "" &&
      state.eth.gasPrices.MEDIUM !== "" &&
      state.eth.gasPrices.HIGH !== ""
    );
  };

  const updateSelectedGasPrice = (gasPrices: GasPrices) => {
    state.eth.setGasPrices(gasPrices);

    switch (selectedPrice.type) {
      case GasType.SLOW:
        if (gasPrices.LOW !== selectedPrice.price) {
          state.account.updateGasPrice({
            type: GasType.SLOW,
            price: gasPrices.LOW,
          });
          setSelectedPrice({
            type: GasType.SLOW,
            price: gasPrices.LOW,
          });
        }
        break;
      case GasType.MEDIUM:
        if (gasPrices.MEDIUM !== selectedPrice.price) {
          state.account.updateGasPrice({
            type: GasType.MEDIUM,
            price: gasPrices.MEDIUM,
          });
          setSelectedPrice({
            type: GasType.MEDIUM,
            price: gasPrices.MEDIUM,
          });
        }
        break;
      case GasType.FAST:
        if (gasPrices.HIGH !== selectedPrice.price) {
          state.account.updateGasPrice({
            type: GasType.FAST,
            price: gasPrices.HIGH,
          });
          setSelectedPrice({
            type: GasType.FAST,
            price: gasPrices.HIGH,
          });
        }
    }
  };

  const fetchGasPrice = async () => {
    const gasPrices = await getGasPrices(state.web3.networkId);
    if (isOk(gasPrices)) {
      updateSelectedGasPrice(gasPrices.value);
    }
  };

  useEffect(() => {
    // Here has price will update when Sync calls
    updateSelectedGasPrice(state.eth.gasPrices);
  }, [state.eth.gasPrices]);

  useEffect(() => {
    // Here gas price will update when user will change network
    fetchGasPrice();
  }, [state.web3.networkId]);

  const GasPriceBox = (props: { price: string; type: GasType }) => {
    return (
      <div
        onClick={() => onSelectGasPrice(props.price, props.type)}
        className={`${style.gasPrice} ${
          selectedPrice.type === props.type && style.selected
        }`}
      >
        <div className={style.title}>{GasType[props.type]}</div>
        <div className={style.price}>{`${props.price} Gwei`}</div>
      </div>
    );
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setShowGasPrices(false)}>
      <div className={style.gasEstimateContainer}>
        <div
          onClick={() => isGasPriceLoaded() && setShowGasPrices(!showGasPrices)}
          className={style.selectedPriceContainer}
        >
          <FontAwesomeIcon icon={faGasPump} className={style.gasIcon} />
          {isGasPriceLoaded() ? (
            <>
              <div className={style.selectedGasPrice}>
                {selectedPrice.price} gwei
              </div>
              <FontAwesomeIcon icon={faAngleDown} className={style.downIcon} />
            </>
          ) : (
            <FontAwesomeIcon icon={faSpinner} spin className={style.gasIcon} />
          )}
        </div>

        {showGasPrices && (
          <div className={style.gasEstimatePrices}>
            <p>Select Gas Setting</p>
            <div className={style.pricesContainer}>
              <GasPriceBox
                price={state.eth.gasPrices.LOW}
                type={GasType.SLOW}
              />
              <GasPriceBox
                price={state.eth.gasPrices.MEDIUM}
                type={GasType.MEDIUM}
              />
              <GasPriceBox
                price={state.eth.gasPrices.HIGH}
                type={GasType.FAST}
              />
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
});
