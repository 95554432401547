/**
 * Normally changing URL causes page to reload.
 *
 * This method changes browsers current URL or at least part past beyond host without reloading page.
 *
 * @see {@link https://stackoverflow.com/questions/3338642/updating-address-bar-with-new-url-without-hash-or-reloading-the-page}
 *
 * @param newURL url to load int ocurrent page
 */
export const changeURLWithoutReload = (newURLPath: string): void  =>
{
    const newurl = window.location.protocol + "//" + window.location.host + newURLPath;
    window.history.replaceState({path: newurl}, '', newurl);
}