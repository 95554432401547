import { Fragment, ReactNode, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/react/outline'

import sidebarLogo from "../../assets/sidebarLogo.svg";
import dashNav from "../../assets/dashboard-nav-icon.svg"
import agentNav from "../../assets/agent-nav-icon.svg"
import autoNav from "../../assets/trigger-nav-icon.svg"
import accountNav from "../../assets/account-nav-icon.svg"

import { Link, useLocation } from "react-router-dom";
import { MAIN_WEBSITE_URI } from "../../configs";

interface LinkItem {
  text: string;
  url: string;
  screenName: string;
  icon: string;
}

export const links: LinkItem[] = [
  {
    text: "Dashboard",
    url: "/dashboard",
    screenName: "Dashboard",
    icon: dashNav,
  },
  {
    text: "Agents",
    url: "/agents",
    screenName: "Agents",
    icon: agentNav,
  },
  {
    text: "Automations",
    url: "/automations",
    screenName: "Automations",
    icon: autoNav,
  },
  {
    text: "Account",
    url: "/account",
    screenName: "Account",
    icon: accountNav,
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

function buildNavItemsClasses(url: string, currentUrl: string) {
  if (url === currentUrl) {
    return classNames("tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-medium tw-rounded-xl", "tw-bg-green-400 tw-text-white hover:tw-text-white");
  }
  return classNames("tw-group tw-flex tw-items-center tw-px-2 tw-py-2 tw-text-base tw-font-medium tw-rounded-xl", "tw-text-white hover:tw-bg-green-400 hover:tw-text-white");
}

export function SideNavBar({navbar ,children}:{navbar:ReactNode,children:ReactNode}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { pathname } = useLocation();

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="tw-relative tw-z-40 lg:tw-hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="tw-transition-opacity tw-ease-linear tw-duration-300"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition-opacity tw-ease-linear tw-duration-300"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75" />
            </Transition.Child>

            <div className="tw-fixed tw-inset-0 tw-z-40 tw-flex">
              <Transition.Child
                as={Fragment}
                enter="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
                enterFrom="tw--translate-x-full"
                enterTo="tw-translate-x-0"
                leave="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
                leaveFrom="tw-translate-x-0"
                leaveTo="tw--translate-x-full"
              >
                <Dialog.Panel className="tw-relative tw-max-w-xs tw-w-full tw-bg-white tw-flex-1 tw-flex tw-flex-col">
                  <Transition.Child
                    as={Fragment}
                    enter="tw-ease-in-out tw-duration-300"
                    enterFrom="tw-opacity-0"
                    enterTo="tw-opacity-100"
                    leave="tw-ease-in-out tw-duration-300"
                    leaveFrom="tw-opacity-100"
                    leaveTo="tw-opacity-0"
                  >
                    <div className="tw-absolute tw-top-0 tw-right-0 tw--mr-12 tw-pt-2">
                      <button
                        type="button"
                        className="tw-ml-1 tw-flex tw-items-center tw-justify-center tw-h-10 tw-w-10 tw-rounded-full focus:tw-outline-none "
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="tw-sr-only">Close sidebar</span>
                        <XIcon className="tw-h-6 tw-w-6 tw-text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="tw-flex-1 tw-flex tw-flex-col tw-min-h-0 tw-bg-coolGray-900">
                    <div className="tw-flex-1 tw-flex tw-flex-col tw-pt-5 tw-pb-4 tw-overflow-y-auto">
                      <div className="tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center tw-px-4 tw-mt-4 tw-mb-6">
                        <a href={MAIN_WEBSITE_URI} target="_blank" rel="noopener noreferrer">
                        <img
                          className="tw-h-16 tw-w-auto"
                          src={sidebarLogo}
                          alt="Workflow"
                        />
                        </a>
                      </div>
                      <nav className="tw-mt-5 tw-flex-1 tw-px-6 tw-space-y-1">
                        {links.map((l, i) => (
                          <Link
                            to={l.url}
                            className={buildNavItemsClasses(l.url, pathname)}
                            key={i}
                          >
                            <img src={l.icon} alt={l.text} />
                            <span className="tw-ml-4 tw-font-redHat tw-font-medium">{l.text}</span>
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="tw-tw-flex-shrink-0 tw-tw-w-14">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="tw-hidden lg:tw-flex lg:tw-w-64 lg:tw-flex-col lg:tw-fixed lg:tw-inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="tw-flex-1 tw-flex tw-flex-col tw-min-h-0 tw-bg-coolGray-900">
            <div className="tw-flex-1 tw-flex tw-flex-col tw-pt-5 tw-pb-4 tw-overflow-y-auto">
              <div className="tw-flex tw-items-center tw-flex-shrink-0">
                <a href={MAIN_WEBSITE_URI} target="_blank" rel="noopener noreferrer">
                <img
                  className="tw-ml-4 tw-mb-8 tw-mt-4"
                  src={sidebarLogo}
                  alt="Workflow"
                />
                </a>
              </div>
              <nav className="tw-mt-5 tw-flex-1 tw-px-6 tw-space-y-1">
                {links.map((l, i) => (
                  <Link
                    to={l.url}
                    className={buildNavItemsClasses(l.url, pathname)}
                    key={i}
                  >
                    <img src={l.icon} alt={l.text} />
                    <span className="tw-ml-4 tw-font-redHat tw-font-medium">{l.text}</span>
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="lg:tw-pl-64 tw-mt-6">
            <div className=" tw-flex-shrink-0 tw-flex">
              <button
                type="button"
                className="tw-ml-6 tw-px-4 tw-text-gray-500 focus:tw-outline-none lg:tw-hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="tw-sr-only">Open sidebar</span>
                <MenuAlt2Icon className="tw-h-6 tw-w-6" aria-hidden="true" />
              </button>
              <div className="tw-flex tw-justify-end tw-w-full tw-items-center">
                {
                  navbar
                }
              </div>
            </div>
            {
              children
            }
        </div>
      </div>
    </>
  )
}
