import { Network } from "../configs";
import { anErr, anOk, Result } from "../result";
import { Chain } from "../state/assets";
import { StateStore } from "../state/state";

const CHAIN_ID_ETH = 1;
const CHAIN_ID_BSC = 2;
const CHAIN_ETH = "ETH";
const CHAIN_ID_RINKEBY = 4;
const CHAIN_BSC = "BSC";

export const ETH_ID = "native_1";
export const BNB_ID = "native_2";
export const NATIVE_TOKEN_ADDRESS = "native";
/**
 * we have the metamask given chain ids which are different to the apis chain id.
 *
 * We look at the chain id return the different chain id required for our API
 * @param network
 */
export function networkToApiChainId(network: Network): Result<number> {
  switch (network) {
    case Network.ETH:
      return anOk(CHAIN_ID_ETH);
    case Network.BSC:
      return anOk(CHAIN_ID_BSC);
    default:

      return anErr("Unable to map unknown network");
  }
}

export function apiChainIdToNetwork(apiChainId: number): Network {
  if (apiChainId === CHAIN_ID_ETH) return Network.ETH;
  if (apiChainId === CHAIN_ID_BSC) return Network.BSC;
  if (apiChainId === CHAIN_ID_RINKEBY) return Network.RINKEBY;
  return Network.UNKNOWN;
}

export function networkToApiChain(network: Network): string {
  switch (network) {
    case Network.ETH:
      return CHAIN_ETH;
    case Network.BSC:
      return CHAIN_BSC;
    case Network.UNKNOWN:
      throw new Error("Unable to map unknown network");
  }
  return "";
}

export function apiChainToNetwork(apiChain: string): Network {
  if (apiChain === CHAIN_ETH) return Network.ETH;
  if (apiChain === CHAIN_BSC) return Network.BSC;
  return Network.UNKNOWN;
}

export function apiChainToChainID(apiChain: string): Network {
  if (apiChain === CHAIN_ETH) return CHAIN_ID_ETH;
  if (apiChain === CHAIN_BSC) return CHAIN_ID_BSC;
  return 99;
}

export function enumToString(enumType: any, value: any): any {
  let k;
  for (k in enumType) if (enumType[k] === value) return k;
  return null;
}

export const isConnectNetwork = (chain: Chain, state: StateStore) => {
  return chain === enumToString(Chain, state.web3.networkId);
};
