/**
 * A function that is for display purposes across automation pages.
 *
 */

export const isPercentageNegative = (
  inputPercentage: number | undefined,
  selectedPercentage: number
) => {
  if (inputPercentage === 0 || inputPercentage === undefined) {
    if (selectedPercentage < 0) {
      return true;
    }
    return false;
  }
  if (inputPercentage < 0) {
    return true;
  } else {
    return false;
  }
};
