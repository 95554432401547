import { action, makeAutoObservable, makeObservable, observable } from "mobx";

import { CollectionStore } from "./utils/collectionStore";
import { LocalStorage } from "./localStorage";
import { TokenAPI } from "../api/Tokens";
import { StateStore } from "./state";
import { anErr, anOk, isOk } from "../result";
import { FilteringOptions } from "../pages/dashboard/FiltersBlock";

export enum Chain {
  ETH,
  BSC,
}

export interface CryptoToken {
  address: string;
  chain: Chain;
  id: string;
  logo_url: string;
  market_cap: string;
  name: string;
  price: string;
  symbol: string;
  decimals: string;
}

export interface HoldingToken extends CryptoToken {
  balance: string;
  balanceUsd: string;
  isNative: boolean;
  decimal: number;
}

export interface HoldingPool {
  address: string;
  token1: string;
  token1Address: string;
  token1LogoUrl: string;
  token1marketCap: string;
  token1Decimal: string;
  token0: string;
  token0Address: string;
  token0LogoUrl: string;
  token0marketCap: string;
  token0Decimal: string;
  network: Chain;
  balance: string;
  symbol: string;
  decimal: string;
  priceUSD: string;
  swapType: number;
}
export interface TokenPoolsResponse {
  [key: string]: string[];
}

export interface SuggestedPool {
  [key: string]: PoolDetails[];
}

export interface PoolDetails {
  address: string;
  symbol: string;
  chain: string;
  token0_address: string;
  token1_address: string;
  volume_day_usd: string;
  fees_day_usd: string;
  fees_per_liquidity: string;
  total_supply: string;
  updated_at: string;
  id: string;
}

export class AssetsStore {
  get localStorage(): LocalStorage {
    return this._localStorage;
  }

  @action
  set localStorage(value: LocalStorage) {
    this._localStorage = value;
  }
  get syncing(): boolean {
    return this._syncing;
  }
  @action
  set syncing(value: boolean) {
    this._syncing = value;
  }
  get assetsProtected(): string {
    return this._assetsProtected;
  }

  set assetsProtected(value: string) {
    this._assetsProtected = value;
  }
  get assetsCurrentlyMonitored(): string {
    return this._assetsCurrentlyMonitored;
  }
  @action
  set assetsCurrentlyMonitored(value: string) {
    this._assetsCurrentlyMonitored = value;
  }
  get currentMarketValueUSD(): string {
    return this._currentMarketValueUSD;
  }
  @action
  set currentMarketValueUSD(value: string) {
    this._currentMarketValueUSD = value;
  }
  get holdingPoolCake(): HoldingPool[] {
    return this._holdingPoolCake;
  }
  @action
  set holdingPoolCake(value: HoldingPool[]) {
    this._holdingPoolCake = value;
  }
  get holdingPoolsUniswap(): HoldingPool[] {
    return this._holdingPoolsUniswap;
  }
  @action
  set holdingPoolsUniswap(value: HoldingPool[]) {
    this._holdingPoolsUniswap = value;
  }
  get holdingTokensETH(): HoldingToken[] {
    return this._holdingTokensETH;
  }
  @action
  set holdingTokensETH(value: HoldingToken[]) {
    this._holdingTokensETH = value;
  }
  get holdingTokensBSC(): HoldingToken[] {
    return this._holdingTokensBSC;
  }
  @action
  set holdingTokensBSC(value: HoldingToken[]) {
    this._holdingTokensBSC = value;
  }
  get allTokens(): CryptoToken[] {
    return this._allTokens;
  }
  @action
  set allTokens(value: CryptoToken[]) {
    this._allTokens = value;
  }
  get suggestedPools(): PoolDetails[] | undefined {
    return this._suggestedPools;
  }
  @action
  set suggestedPools(value: PoolDetails[] | undefined) {
    this._suggestedPools = value;
  }
  get suggestedPoolSyncing(): boolean {
    return this._suggestedPoolSyncing;
  }
  @action
  set suggestedPoolSyncing(value: boolean) {
    this._suggestedPoolSyncing = value;
  }
  get allTokenSyncing(): boolean {
    return this._allTokensSyncing;
  }
  @action
  set allTokenSyncing(value: boolean) {
    this._allTokensSyncing = value;
  }

  get filteringOptions(): FilteringOptions {
    return this._filteringOptions;
  }
  @action
  set filteringOptions(value: FilteringOptions) {
    this._filteringOptions = value;
  }

  private _localStorage: LocalStorage;

  private _allTokens: CryptoToken[];

  private _holdingTokensBSC: HoldingToken[];

  private _holdingTokensETH: HoldingToken[];

  private _holdingPoolsUniswap: HoldingPool[];

  private _holdingPoolCake: HoldingPool[];

  private _currentMarketValueUSD: string;

  private _assetsCurrentlyMonitored: string;

  private _assetsProtected: string;

  private _suggestedPools: PoolDetails[] | undefined;

  private _suggestedPoolSyncing: boolean;

  private _allTokensSyncing: boolean;

  private _syncing: boolean;

  private _filteringOptions: FilteringOptions;

  constructor(localStorage: LocalStorage) {
    this._allTokens = [];
    this._localStorage = localStorage;
    this._holdingTokensBSC = [];
    this._holdingTokensETH = [];
    this._holdingPoolsUniswap = [];
    this._holdingPoolCake = [];
    this._currentMarketValueUSD = "";
    this._assetsCurrentlyMonitored = "";
    this._assetsProtected = "";
    this._syncing = true;
    this._suggestedPools = undefined;
    this._suggestedPoolSyncing = true;
    this._allTokensSyncing = true;
    this._filteringOptions = {
      query: "",
      ordering: null,
      chain: null,
    };

    makeAutoObservable(this);
  }

  getPoolIds(tokenPools: TokenPoolsResponse) {
    let poolIds: string[] = [];
    for (const ids of Object.values(tokenPools)) {
      poolIds = [...poolIds, ...ids];
    }
    return poolIds;
  }

  fetchSuggestedPools = async (
    holdingTokens: HoldingToken[],
    state: StateStore
  ) => {
    if (!state?.account?.user?.token.accessToken)
      return anErr("Unable to get user");
    this._suggestedPoolSyncing = true;
    try {
      const tokensAdressess = holdingTokens.map((token) =>
        token.address.toLocaleLowerCase()
      );
      if (!tokensAdressess.length) return anErr("no tokens to fetch details");
      // fetch pool Ids with tokens
      const tokensWithPoolIds = await TokenAPI.fetchSuggestedPoodIds(
        state.account.user.token.accessToken,
        tokensAdressess
      );
      if (isOk(tokensWithPoolIds)) {
        // filter all pool ids
        const poolIds = this.getPoolIds(tokensWithPoolIds.value);
        if (poolIds.length) {
          // fetch pool details from pools ids
          let poolsDetails = await TokenAPI.fetchtPoolInfoFromPoolIds(
            state.account.user.token.accessToken,
            poolIds
          );
          if (isOk(poolsDetails)) {
            this._suggestedPools = poolsDetails.value;
            return anOk(true);
          }
        }
        return anErr("Unable to get suggested pools");
      }
    } catch (err: any) {
      return anErr(`Unable to get suggested pools ${err.message}`);
    } finally {
      this._suggestedPoolSyncing = false;
    }
  };
}
