import Provider from "../Provider";
import { asAbi } from "./utils";
import ERC20ABI from "../../abis/ERC20.json";
import { getNetworkDetails } from "../../configs";
import { anErr, anOk, isErr, Result } from "../../result";
import { TransactionOptions } from "./types";
import { ApprovedBalance } from "../../state/triggers";
import BN from "bn.js";

/**
 * @classdesc Logic to make calls to contract with each public method being the same as a method name on the staking contract
 *
 */
export default class ERC20Contract {
  private contract: any;

  constructor(address: string) {
    // @ts-ignore
    this.contract = new Provider._web3.eth.Contract(asAbi(ERC20ABI), address);
  }

  public static async getAllowance(
    erc20Address: string,
    owner: string,
    spender: string
  ): Promise<Result<string>> {
    try {
      const erc20Contract = new ERC20Contract(erc20Address);
      const allowance = await erc20Contract.contract.methods
        .allowance(owner, spender)
        .call();

      return anOk(allowance);
    } catch (e) {
      return anErr(`Unable to lookup contract allowance ${erc20Address}`, e);
    }
  }

  public static async getApprovedAmount(
    erc20Address: string,
    userAddress: string,
    proxyAddress: string,
    actualApprovedAmount: string
  ): Promise<Result<ApprovedBalance>> {
    const erc20Contract = new ERC20Contract(erc20Address);

    let finalBalance;
    let symbol;
    let decimal;
    try {
      symbol = await erc20Contract.contract.methods.symbol().call();
      decimal = await erc20Contract.contract.methods.decimals().call();
      // temporarily commented
      // const allowance = await erc20Contract.contract.methods
      //   .allowance(userAddress, proxyAddress)
      //   .call();

      // const balance = await erc20Contract.contract.methods
      //   .balanceOf(userAddress)
      //   .call();

      // finalBalance = new BN(balance).lt(new BN(allowance))
      //   ? balance
      //   : allowance;
    } catch (err) {
      return anErr("Failed to get balance");
    }

    // const approvedAmount =
    //   actualApprovedAmount !== "" &&
    //   new BN(actualApprovedAmount).lte(new BN(finalBalance))
    //     ? actualApprovedAmount
    //     : finalBalance;

    return anOk({
      balance: actualApprovedAmount,
      symbol: symbol,
      decimal: decimal,
    } as ApprovedBalance);
  }

  public static async getSymbol(ERC20Address: string): Promise<Result<string>> {
    const erc20Contract = new ERC20Contract(ERC20Address);
    let symbol;
    try {
      symbol = await erc20Contract.contract.methods.symbol().call();
    } catch (err) {
      return anErr("Failed to get symbol");
    }
    return anOk(symbol);
  }

  /**
   * Gets canonical FET balance of user by Ethereum Address
   *
   * @param ethereumAddress of user to test
   * @returns {Promise<unknown> string of canonical FET user has in contract
   */
  public static async getBalance(
    ethereumAddress: string,
    ERC20Address: string
  ): Promise<Result<string>> {
    const erc20Contract = new ERC20Contract(ERC20Address);

    if (!ethereumAddress) {
      return anErr("no address");
    }

    let balance;
    try {
      balance = await erc20Contract.contract.methods
        .balanceOf(ethereumAddress)
        .call();
    } catch (err) {
      return anErr("Failed to get balance");
    }

    return anOk(balance);
  }

  public static async getDecimals(
    ERC20Address: string
  ): Promise<Result<string>> {
    const erc20Contract = new ERC20Contract(ERC20Address);

    let decimals: string;
    try {
      decimals = await erc20Contract.contract.methods.decimals().call();
    } catch (err) {
      return anErr(`Failed to get decimals`);
    }
    return anOk(decimals);
  }

  /**
   * Call approve method of contract. Approve this amount of funds to be sent to vault contract
   *
   * @param ethereumAddress subjects ethereum address
   * @param spenderAddress
   * @param canonicalAmount canonical amount of fet to approve
   * @returns  Error object if there is error or void is a success
   */
  public static async approve(
    ERC20Address: string,
    ethereumAddress: string,
    spenderAddress: string,
    canonicalAmount: string,
    gasPrice: string
  ): Promise<Result<void>> {
    try {
      const erc20Contract = new ERC20Contract(ERC20Address);
      await erc20Contract.contract.methods
        .approve(spenderAddress, canonicalAmount)
        .send({ from: ethereumAddress, gasPrice });
      return anOk(undefined);
    } catch (err) {
      return anErr(`Unable to perform approval`, err);
    }
  }

  /**
   * Estimate Gas cost for the approve method
   *
   * @param ethereumAddress address of user looking to approve
   * @param canonicalAmount of fet that you wish to approve
   */
  public async estimateApproveGas({
    ethereumAddress,
    canonicalAmount,
    chainId,
  }: Omit<TransactionOptions, "statusCallback">): Promise<Result<string>> {
    let estimate;

    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      return networkDetails;
    }

    try {
      estimate = await this.contract.methods
        .approve(networkDetails.value.vaultContractAddress, canonicalAmount)
        .estimateGas({ from: ethereumAddress });
    } catch (err: any) {
      return anErr(err.message);
    }

    return anOk(estimate);
  }
}
