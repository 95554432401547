import style from "../../pages/AgentsPage.module.scss";
import classnames from "classnames";
import { AgentNameEditBox } from "../AgentNameEditBox/AgentNameEditBox";
import { Agent } from "../../state/agents";
import dateFormat from "dateformat";
import { BSCInfo, EthereumInfo, Network } from "../../configs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse } from "react-bootstrap";
import { Trigger } from "../../state/triggers";
import BN from "bn.js";
import React, { useState } from "react";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NetworkTypePill } from "../NetworkTypePill";
import { StateStore } from "../../state/state";
import { Error } from "../../pages/AgentsPage";
import { format } from "../../utils/display/format";
import { toNonCanonicalDisplay } from "../../utils/display/toNonCanonicalDisplay";
import { Tooltip } from "../Tooltip";
import { EnumType } from "typescript";

export type AgentFundStatus = "surplus" | "shortage" | "correct";

interface AgentRowProps {
  network: Network;
  agent: Agent;
  agentTriggers: Trigger[];
  isMobile: boolean;
  expand: boolean;
  onToggleExpand: () => void;
  onDelete: () => void;
  onTopUp: () => void;
  onWithdraw: () => void;
  isOwner: boolean;
  agentFundStatus: AgentFundStatus;
  agentFundOutstandingAmount: string;
  state: StateStore;
  setErrorMessage: (error: Error) => void;
}

function formatMobileDate(date: Date): string {
  return `${dateFormat(date, "shortDate")} ${dateFormat(date, "shortTime")}`;
}

function absoluteDecimalString(decimalString: string): string {
  return new BN(decimalString).abs().toString();
}



export const AgentRow = (props: AgentRowProps) => {
  const { state } = props;
  const [isOtherEditable, setOtherEditable] = useState(false);

  const currencyName = () => {
    return props.agent.network === Network.BSC ? "BNB" : "ETH";
  };

  const deleteAgent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.blur();
    props.onDelete();
  };

  const disabledButton = () => {
    if (props.agent.network !== props.network) {
      return "tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-pr-3.5 tw-mr-3.5 tw-px-3.5 tw-opacity-60";
    }
    else {
      return "tw-cursor-pointer tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-px-3.5 tw-mr-3.5";
    }
  };

  const disabledDelete = () => {
    if (props.agent.network !== props.network || props.agent.withdrawPending || props.agent.deletePending) {
      return "tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-px-3.5 tw-opacity-60";
    }
    else {
      return "tw-cursor-pointer tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-px-3.5";
    }
  };

  const agentDeleteMessage = () => {
    if (props.agent.withdrawPending) {
      return "Withdraw in progress";
    }
    if (props.agent.deletePending) {
      return "Delete in progress";
    }
    return "";
  };

  return (
    <>
      <tr role="agent-page-table-row">
        <td className="tw-px-3.5 tw-flex tw-justify-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          <div className="tw-text-right">
          <AgentNameEditBox
            name={props.agent.name}
            uuid={props.agent.uuid}
            isOtherEditable={isOtherEditable}
            setOtherEditable={setOtherEditable}
            pendingDelete={props.agent.deletePending}
            state={props.state}
            setErrorMessage={props.setErrorMessage}
          />
          </div>
        </td>
        <td className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          <NetworkTypePill network={props.agent.network} />
        </td>
        <td className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          {props.isMobile
            ? formatMobileDate(props.agent.createdAt)
            : dateFormat(new Date(props.agent.createdAt))}
        </td>
        <td className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          {props.agent.network === props.network ? (
            <Tooltip
              id={`trigger-agent`}
              tooltipText={`Your agent has ${toNonCanonicalDisplay(
                props.agent.balance,
                props.agent.network === Network.BSC ? BSCInfo : EthereumInfo
              )} to send transactions on your behalf`}
            >
              {toNonCanonicalDisplay(
                props.agent.balance,
                props.agent.network === Network.BSC ? BSCInfo : EthereumInfo
              )}
            </Tooltip>
          ) : (
            "-"
          )}
        </td>
        <td className="tw-px-3.5 tw-flex tw-flex-row tw-justify-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-text-coolGray-900">
          {props.agentFundStatus === "surplus" && (
            <>
              {props.agent.withdrawPending ? (
                <>
                  <Tooltip id={'trigger-withdraw-more-funds'} tooltipText={"Your agent currently has more funds assigned to it than it needs. You have already initiated a withdraw."}>
                  <div className="tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-pr-3.5 tw-mr-3.5 tw-px-3.5 tw-opacity-60">
                    Funds withdraw in progress
                  </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip id={'trigger-withdraw'} className={'tw-mr-5'} tooltipText={"Your agent currently has more funds assigned to it than it needs. If you wish you can withdraw these back to your account."}>
                  <div className={disabledButton()}  onClick={() => {
                    if (props.agent.network !== props.network) {
                      return;
                    }
                    else {
                      props.onWithdraw() 
                    };
                    }
                  }>
                    Withdraw
                  </div>
                  </Tooltip>
                </>
              )}
            </>
          )}
          {props.agentFundStatus === "shortage" && (
            <>
              {props.agent.withdrawPending ? (
                <>
                  <Tooltip id={'trigger-withdraw-progress'} tooltipText={"You have already initiated a withdraw.You have already initiated a withdraw."}>
                  <div className="tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-pr-3.5 tw-mr-3.5 tw-px-3.5 tw-opacity-60">
                    Funds withdraw in progress
                  </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip id={'trigger-topup-less-funds'} tooltipText={"Your agent currently has less funds assigned to it than it needs. If you wish you can top up your funds."}>
                  <div className={disabledButton()} onClick={() => {
                    if (props.agent.network !== props.network) {
                      return;
                    }
                    else {
                      props.onTopUp()
                    };
                    }
                  }>
                    Topup funds
                  </div>
                  </Tooltip>
                </>
              )}
            </>
          )}
          {props.agentFundStatus === "correct" && (
            <>
              <Tooltip id={'trigger-topup-equal-funds'} tooltipText={"Your agent currently has sufficient balance"}>
              <div className="tw-rounded-full tw-shadow-base tw-text-base tw-font-semibold tw-bg-coolGray-50 tw-border tw-border-coolGray-300 tw-py-1.5 tw-pr-3.5 tw-mr-3.5 tw-px-3.5 tw-opacity-60">
                Topup funds
              </div>
              </Tooltip>
            </>
          )}
          {!props.isMobile && (
            <Tooltip id={`trigger-delete`} tooltipText={agentDeleteMessage()}>
              <button
              data-testid="agent-delete-button"
              className={disabledDelete()}
              onClick={(event) => {
                if (props.agent.network !== props.network || props.agent.withdrawPending || props.agent.deletePending) {
                  return;
                }
                  deleteAgent(event)
              }
              }>
              Delete
              </button>
            </Tooltip>
          )}
        </td>
      </tr>
    </>
  );
};
