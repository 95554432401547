import { ArrowLeftIcon } from "@heroicons/react/outline";
import Decimal from "decimal.js";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  BNB_ID,
  ETH_ID,
  isConnectNetwork,
  NATIVE_TOKEN_ADDRESS,
} from "../../../api/common";
import { AutomationStrategy } from "../../../components/AutomationStrategy";
import { Costs } from "../../../components/Costs";
import Modal from "../../../components/modal";
import { Tooltip } from "../../../components/Tooltip";
import {
  getNetworkDetails,
  mapChainId,
  Network,
  PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE,
} from "../../../configs";
import { isOk } from "../../../result";
import { Agent } from "../../../state/agents";
import { CryptoToken, HoldingPool } from "../../../state/assets";
import { Pair } from "../../../state/pairCache";
import { StateContext } from "../../../state/state";
import {
  NewTrigger,
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerType,
} from "../../../state/triggers";
import { gweiToWei } from "../../../utils/currency/GweiToWei";
import { calcAvailableAgents } from "../../../utils/misc/calcAvailableAgents";
import {
  calcTotalGasCosts,
  isNativeTokenSufficient,
} from "../../../utils/misc/calcTotalGasCost";
import { eventAnalysis } from "../../../utils/misc/trackEvent";
import { isPercentageNegative } from "../../../utils/numeric/isPercentageNegative";
import { ThresholdToken } from "../swapAutomation";
import { AutomationSetupProgress } from "./automationSetupProgress";
import PoolAutomation from "./poolAutomation";

const getTokenIndex = (fromToken: string, selectedPair: Pair) => {
  return selectedPair.token0Address.toUpperCase() === fromToken.toUpperCase()
    ? 0
    : 1;
};
const getThresholdTokenIndex = (tokenIndex: 0 | 1) => tokenIndex;

export const AutomationSetup = () => {
  let params = useParams<{ id: string }>();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const state = useContext(StateContext);

  const [holdingPools, setHoldingPools] = useState<HoldingPool[]>([]);
  const [selectedPool, setSelectedPool] = useState<HoldingPool>();
  const [tokenPair, setTokenPair] = useState<ThresholdToken[]>([]);
  const [amount, setAmount] = useState<string | undefined>();
  const [thresholdToken, setThresholdToken] = useState<CryptoToken>();

  //Error states
  const [inputError, setInputError] = useState<boolean>(false);
  const [decimalsExceeded, setDecimalsExceeded] = useState<boolean>(false);
  const [thresholdMsg, setThresholdMsg] = useState<string>("");

  //Data we need for API
  const [threshold, setThreshold] = useState<number>(0);
  const [selectedPercentage, setSelectedPercentage] = useState(-25);
  const [inputPercentage, setInputPercentage] = useState(0);

  const [txnSpeed, setTxnSpeed] = useState<number>(0);
  const [agent, setAgent] = useState<Agent | undefined>();
  const [trigger, setTrigger] = useState<NewTrigger | undefined>();
  // refers to IF custom selected and it is empty then we can use to disable next step.
  const [emptyCustom, setEmptyCustom] = useState<boolean>(false);

  const checkDisableProceed = () => {
    if (
      inputError ||
      decimalsExceeded ||
      thresholdMsg ||
      emptyCustom ||
      !selectedPool ||
      !amount ||
      !isNativeBalanceSufficient ||
      new Decimal(amount).lessThanOrEqualTo(new Decimal("0"))
    ) {
      return true;
    }
    return false;
  };
  const getAvailableAgents = () => {
    return calcAvailableAgents(
      state.agents.list(),
      state.web3.networkId,
      state.web3.address,
      // @ts-ignore
      state.triggers.agentNonCompletedTriggersCount.bind(state.triggers)
    );
  };

  const parseHoldingPoolToPair = (holdingPool: HoldingPool): Pair => {
    return {
      token0: holdingPool.token0, // the name of token 0
      token1: holdingPool.token1, // the name of token 1
      token0Decimals: Number(holdingPool.token0Decimal), // the number of decimals of token 0
      token1Decimals: Number(holdingPool.token1Decimal), // the number of decimals of token 1
      token0Address: holdingPool.token0Address, // the address of token 0
      token1Address: holdingPool.token1Address, // the address of token 1
      address: holdingPool.address, // pool address
      price: holdingPool.balance,
      network: state.web3.networkId,
    };
  };

  const handleProceedClick = () => {
    eventAnalysis(
      `proceed_button_clicked`,
      TriggerType.WITHDRAW_LIQUIDITY,
      "withdraw liquidity proceed button clicked"
    );
    // Get available agent
    const availableAgents = getAvailableAgents();
    if (availableAgents.length) {
      setAgent(() => availableAgents[0]);
    }

    if (!thresholdToken || !selectedPool) {
      return;
    }
    const thresholdIndex = getTokenIndex(
      thresholdToken.address,
      parseHoldingPoolToPair(selectedPool)
    );

    const isNegative = isPercentageNegative(
      inputPercentage,
      selectedPercentage
    );
    const tokenIndex = getThresholdTokenIndex(thresholdIndex);

    const triggerData = {
      network: state.web3.networkId,
      agentUuid: availableAgents[0]?.uuid || "",
      pair: parseHoldingPoolToPair(selectedPool),
      threshold: "",
      price0Min:
        tokenIndex === TriggerChoices.TOKEN0 && isNegative
          ? threshold.toString()
          : "0",
      price0Max:
        tokenIndex === TriggerChoices.TOKEN0 && !isNegative
          ? threshold.toString()
          : "0",
      price1Min:
        tokenIndex === TriggerChoices.TOKEN1 && isNegative
          ? threshold.toString()
          : "0",
      price1Max:
        tokenIndex === TriggerChoices.TOKEN1 && !isNegative
          ? threshold.toString()
          : "0",
      token: getThresholdTokenIndex(thresholdIndex),
      gasPrice: gweiToWei(txnSpeed.toString()),
      gasAmount: PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE.toString(),
      slippage: 2,
      amount: !amount ? "" : amount,
    };
    setTrigger(triggerData);
    setOpen(true);
  };

  function tokenNotAlreadySelected(
    poolAddress: string,
    triggers: Array<Trigger>,
    pair: Pair
  ): false | string {
    const filtered = triggers.filter((trigger: Trigger) => {
      return Boolean(
        trigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY &&
        trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
        ![TriggerState.COMPLETED, TriggerState.FAILED].includes(trigger.state)
      );
    });

    if (filtered.length === 0) {
      return false;
    }

    return filtered[0].token === 0 ? pair.token1Address : pair.token0Address;
  }

  const addWrappedAddress = (token: CryptoToken | undefined) => {
    if (
      !state.web3.chainId ||
      !token ||
      token.address !== NATIVE_TOKEN_ADDRESS
    ) {
      return token;
    }
    const networkDetails = getNetworkDetails(state.web3.chainId);
    if (isOk(networkDetails)) {
      return {
        ...token,
        address: networkDetails.value.wrappedNativeTokenAddress,
      };
    }
    return token;
  };

  const getToken = (tokenName: string, tokenAddress: string) => {
    let token = state.assets.allTokens.find((tkn) => {
      if (tkn.id === ETH_ID || tkn.id === BNB_ID) {
        return tkn.symbol.toLowerCase() === tokenName.toLowerCase(); // If token is native token
      }
      if (isConnectNetwork(tkn.chain, state)) {
        // Check is tkn network is same as connected network
        return tkn.address.toLowerCase() === tokenAddress.toLowerCase();
      }
    });
    // Add wrapped address if token is native tokan
    return addWrappedAddress(token);
  };

  const [totalCost, setTotalCost] = useState<number>(0);
  const [isNativeBalanceSufficient, setIsNativeBalanceSufficient] =
    useState<boolean>(true);

  useEffect(() => {
    if (state.web3.chainId) {
      const totalCost = calcTotalGasCosts(
        state.assets.allTokens,
        state.web3.chainId,
        txnSpeed,
        state.account.gasPrice
      );
      setTotalCost(totalCost);
    }
  }, [
    state.assets.allTokens.length,
    state.web3.chainId,
    state.account.gasPrice,
    txnSpeed,
  ]);

  useEffect(() => {
    let tokenAmount = "0";
    let holdingAssets =
      Network.ETH == mapChainId(state.web3.chainId || 2)
        ? state.assets.holdingTokensETH
        : state.assets.holdingTokensBSC;
    setIsNativeBalanceSufficient(
      isNativeTokenSufficient(totalCost, holdingAssets, tokenAmount)
    );
  }, [totalCost, state.web3.chainId]);

  useEffect(() => {
    state.web3.networkId === Network.BSC ? setTxnSpeed(10) : setTxnSpeed(100);
  }, [state.web3.networkId]);

  //Responsible for updating holding assets and from token
  useEffect(() => {
    const holdingPools =
      Network.ETH == mapChainId(state.web3.chainId || 2)
        ? state.assets.holdingPoolsUniswap
        : state.assets.holdingPoolCake;

    if (!holdingPools.length) {
      history.push("/dashboard");
    }

    setHoldingPools([...holdingPools]);
    const selectedPool = holdingPools.find(
      (pool) => pool.address.toUpperCase() === params.id.toUpperCase()
    );
    setSelectedPool(selectedPool);
  }, [
    state.assets.holdingPoolsUniswap.length,
    state.assets.holdingPoolCake.length,
  ]);

  useEffect(() => {
    if (selectedPool) {
      const namePrefix = `${selectedPool.token0.toLowerCase()}_${selectedPool.token1.toLowerCase()}`;
      eventAnalysis(
        `${namePrefix}_pair`,
        TriggerType.WITHDRAW_LIQUIDITY,
        `Selected pair ${namePrefix}`
      );
    }

    const token0 = selectedPool
      ? getToken(selectedPool.token0, selectedPool.token0Address)
      : undefined;
    const token1 = selectedPool
      ? getToken(selectedPool.token1, selectedPool.token1Address)
      : undefined;

    if (token0 && token1) {
      if (selectedPool) {
        let tokenPair;
        let notSelectedToken = tokenNotAlreadySelected(
          selectedPool.address,
          state.triggers.list(),
          parseHoldingPoolToPair(selectedPool)
        );
        if (notSelectedToken) {
          if (notSelectedToken.toUpperCase() === token0.address.toUpperCase()) {
            // token0 is not selected in previous triggers
            tokenPair = [
              { ...token0, validThresholdToken: true },
              { ...token1, validThresholdToken: false },
            ];
          } else {
            // token1 is not selected in previous triggers
            tokenPair = [
              { ...token0, validThresholdToken: false },
              { ...token1, validThresholdToken: true },
            ];
          }
        } else {
          // Both token0 and token1 are not selected in previous triggers
          tokenPair = [
            { ...token0, validThresholdToken: true },
            { ...token1, validThresholdToken: true },
          ];
        }
        setTokenPair(tokenPair);
      } else {
        setTokenPair([
          { ...token0, validThresholdToken: true },
          { ...token1, validThresholdToken: true },
        ]);
      }
    }

    setThresholdToken(token0);
  }, [selectedPool]);

  useEffect(() => {
    if (selectedPercentage > 0 || inputPercentage > 0) {
      const value =
        inputPercentage !== 0 ? inputPercentage : selectedPercentage;
      eventAnalysis(
        "positive_threshold_%",
        TriggerType.WITHDRAW_LIQUIDITY,
        value.toString()
      );
    } else {
      const value =
        inputPercentage !== 0 ? inputPercentage : selectedPercentage;
      eventAnalysis(
        "negative_threshold_%",
        TriggerType.WITHDRAW_LIQUIDITY,
        value.toString()
      );
    }
  }, [selectedPercentage, inputPercentage]);

  return (
    <div className="tw-px-6 2xl:tw-px-20">
      <h2 className="tw-font-normal tw-text-4xl tw-flex tw-items-center tw-relative tw-mb-8">
        <ArrowLeftIcon
          className="tw-w-7 tw-cursor-pointer 2xl:tw-absolute tw-mr-6 tw--left-16"
          onClick={() => history.goBack()}
        />
        Add automation setup
      </h2>
      {selectedPool && (
        <PoolAutomation
          poolList={holdingPools}
          selected={selectedPool}
          setSelected={setSelectedPool}
          amount={amount}
          setAmount={setAmount}
          inputError={inputError}
          setInputError={setInputError}
          decimalsExceeded={decimalsExceeded}
          setDecimalsExceeded={setDecimalsExceeded}
        />
      )}

      <div className="tw-text-xl tw-font-semibold tw-mb-4 tw-mt-11">
        Add automation
      </div>
      <div className="tw-grid tw-grid-cols-1 tw-gap-12 lg:tw-grid-cols-11">
        {selectedPool && thresholdToken && (
          <AutomationStrategy
            tokens={tokenPair}
            thresholdToken={thresholdToken}
            setThresholdToken={setThresholdToken}
            threshold={threshold}
            setThreshold={setThreshold}
            thresholdMsg={thresholdMsg}
            setThresholdMsg={setThresholdMsg}
            selectedPercentage={selectedPercentage}
            setSelectedPercentage={setSelectedPercentage}
            inputPercentage={inputPercentage}
            setInputPercentage={setInputPercentage}
          />
        )}
        <Costs
          txnSpeed={txnSpeed}
          setTxnSpeed={setTxnSpeed}
          setEmptyCustom={setEmptyCustom}
        />
      </div>
      <div className="tw-mt-11 tw-flex tw-justify-center">
        <Tooltip
          children={
            <div
              className={`tw-bg-green-300 tw-text-xl tw-font-semibold tw-rounded tw-px-16 tw-py-4 tw-inline-block tw-cursor-pointer  ${
                checkDisableProceed() ? "tw-opacity-60" : null
              }`}
              onClick={() => !checkDisableProceed() && handleProceedClick()}
            >
              Proceed
            </div>
          }
          tooltipText={`Insufficient ${state.web3.networkId === Network.BSC ? "BSC" : "ETH"
            } balance to create Automation`}
          hide={isNativeBalanceSufficient}
        />
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        widthClass="6xl"
        closeOnOutsideClick={false}
      >
        {trigger && (
          <AutomationSetupProgress
            setOpen={setOpen}
            trigger={trigger}
            agent={agent}
            state={state}
          />
        )}
      </Modal>
    </div>
  );
};
