import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import BotswapFree from "../../components/BotswapFree";
import { TopUpVaultModal } from "../../components/TopUpVaultModal";
import { FetInfo, Network } from "../../configs";
import { toDisplayString } from "../../services/BinanceAPI";
import { StateContext, StateStore } from "../../state/state";
import { convertCurrency } from "../../utils/currency/convertCurrency";
import {
  equalsZero,
  toNonCanonicalDisplay,
} from "../../utils/display/toNonCanonicalDisplay";
import { divideByDecimals } from "../../utils/numeric/divideByDecimals";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { isAddressRegistered } from "../../utils/store/isAddressRegistered";
import Tooltip from "../../utils/tooltip/tooltip";
import { PoolsTable } from "./PoolsTable";
import { TokenTable } from "./TokenTable";

export function allowTopup(
  walletBalance: string | undefined,
  state: StateStore
) {
  if (!state.web3.loggedIn) {
    return {
      allowTopup: false,
      error: "",
    };
  }
  if (state.web3.networkId === Network.UNKNOWN) {
    return {
      allowTopup: false,
      error: "Connect to supported network to topup wallet",
    };
  }
  if (walletBalance === undefined || equalsZero(walletBalance as string)) {
    return {
      allowTopup: false,
      error: "No balance on wallet, top up wallet first",
    };
  }
  return {
    allowTopup: true,
    error: "",
  };
}

export const DashboardPage = observer(() => {
  const state = useContext(StateContext);
  const [showVaultModal, setShowVaultModal] = useState(false);

  const addressRegistered = isAddressRegistered(state.web3.address, state);
  const canonicalWalletBalance = state.account.fetBalance;
  const canonicalAppBalance = state.account.accountDetails?.balance;

  let walletNonCanonicalFetDisplay: string | undefined;

  if (canonicalWalletBalance !== undefined) {
    walletNonCanonicalFetDisplay = toNonCanonicalDisplay(
      canonicalWalletBalance,
      FetInfo
    );
  }

  let appBalanceNonCanonicaFetDisplay: string | undefined;

  if (canonicalAppBalance !== undefined) {
    appBalanceNonCanonicaFetDisplay = toNonCanonicalDisplay(
      canonicalAppBalance,
      FetInfo
    );
  }

  let walletBalanceDollars: string | undefined;

  if (
    canonicalWalletBalance !== undefined &&
    state.account.fetPrice !== undefined
  ) {
    const convertedCurrency = convertCurrency(
      divideByDecimals(canonicalWalletBalance as string, FetInfo.decimals),
      state.account.fetPrice
    );
    walletBalanceDollars = toDisplayString(
      formatBigNumber(convertedCurrency, 2).full
    );
  }

  let appBalanceDollars: string | undefined;

  if (
    canonicalAppBalance !== undefined &&
    state.account.fetPrice !== undefined
  ) {
    const convertedCurrency = convertCurrency(
      divideByDecimals(canonicalAppBalance as string, FetInfo.decimals),
      state.account.fetPrice
    );
    appBalanceDollars = toDisplayString(
      formatBigNumber(convertedCurrency, 2).full
    );
  }

  function walletBalance() {
    if (!state.web3.loggedIn || walletBalanceDollars === undefined) {
      return "-";
    }

    return walletBalanceDollars;
  }

  function hasWalletBalance(): boolean {
    return !equalsZero(walletBalance());
  }

  function appBalance() {
    if (!state.web3.loggedIn || appBalanceDollars === undefined) {
      return "-";
    }

    return appBalanceDollars;
  }

  const formatBalance = (
    balanceStr: string
  ): { value: string; isTruncated: boolean } => {
    // this fails sometimes when no balance
    let [balance, symbol] = balanceStr.trim().split(" ");

    balance = balance.replaceAll(",", "");

    if (symbol === undefined) {
      symbol = "";
    }

    let [decimalPart, fractionalPart] = balance.split(".");
    const maxNoOfDegit = 17;

    decimalPart = decimalPart ?? "";
    fractionalPart = fractionalPart ?? "";

    if (decimalPart.length > maxNoOfDegit) {
      const initialPart = decimalPart.slice(0, 4);
      const endPart = decimalPart.slice(-2);
      decimalPart = `${initialPart}...${endPart}`;
      let number;

      if (fractionalPart.length > 0) {
        number = `${decimalPart}.${fractionalPart}`;
      } else {
        number = decimalPart;
      }

      return {
        value: `${number} ${symbol}`,
        isTruncated: true,
      };
    }
    return {
      value: balanceStr,
      isTruncated: false,
    };
  };

  const PriceBox = ({
    title,
    value,
    syncing,
  }: {
    title: string;
    value: string;
    syncing: boolean;
  }) => {
    return (
      <div className="tw-shadow-lg tw-p-6 tw-text-center tw-rounded-2xl tw-flex tw-flex-col tw-justify-center tw-bg-white">
        <h3 className="tw-text-xl tw-font-normal tw-text-coolGray-500">
          {title}
        </h3>
        <div className="tw-text-3xl tw-font-bold tw-text-coolGray-900">
          {!syncing ? (
            <Tooltip
              child={`$ ${
                formatBigNumber(value.length ? value : "0.00").trunc
              }`}
              tooltipTextClassName="tool-tip-center"
              text={`$ ${
                formatBigNumber(value.length ? value : "0.00").fullCommas
              }`}
              hideTooltip={formatBigNumber(value.length ? value : "0.00").same}
            />
          ) : (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              style={{ marginRight: 10, fontSize: "30px" }}
            />
          )}
        </div>
      </div>
    );
  };
  const [startTour, setStartTour] = useState(false);
  const [hasTokens, sethasTokens] = useState(false);
  const [hasPool, sethasPool] = useState(false);
  const [hasPoolOptionBtn, setHasPoolOptionBtn] = useState(false);
  const [hasNoLpBtn, setHasNoLpBtn] = useState(false);

  const handleTourClick = () => {
    if (!state.assets.syncing && !state.assets.suggestedPoolSyncing) {
      if (
        [...state.assets.holdingTokensETH, ...state.assets.holdingTokensBSC]
          .length
      ) {
        sethasTokens(true);
        if (document.getElementById("tour_pool_option_btn")) {
          setHasPoolOptionBtn(true);
        } else {
          setHasPoolOptionBtn(false);
        }
        if (document.getElementById("tour_no_lp_option")) {
          setHasNoLpBtn(true);
        } else {
          setHasNoLpBtn(false);
        }
      } else {
        sethasTokens(false);
      }
      if (
        [...state.assets.holdingPoolsUniswap, ...state.assets.holdingPoolCake]
          .length
      ) {
        sethasPool(true);
      } else {
        sethasPool(false);
      }
      setStartTour(true);
    }
  };

  return (
    <div className="tw-max-w-full tw-px-4 sm:tw-px-6 md:tw-px-8">
      <BotswapFree />
      {/* 
        <div className="tw-flex tw-flex-col xl:tw-flex-row tw-justify-between tw-gap-y-7 xl:tw-gap-7 tw-px-3 tw-mt-6">
          <div className="tw-flex-1 tw-bg-white tw-rounded-2xl tw-shadow-lg tw-p-8 tw-flex tw-flex-nowrap ">
            <div className="tw-flex tw-w-full tw-flex-wrap tw-justify-between">
              <div className="tw-flex-1 tw-mx-auto tw-flex tw-flex-col tw-justify-between tw-mr-6">
                <h3 className="tw-text-xl tw-font-medium tw-text-coolGray-900">
                  Wallet Balance:
                </h3>
                {walletNonCanonicalFetDisplay ? (
                  <div className="tw-text-5xl tw-font-bold tw-text-coolGray-900 tw-whitespace-nowrap">
                    <Tooltip
                      child={formatBalance(walletNonCanonicalFetDisplay).value}
                      tooltipTextClassName="tool-tip-agent-name"
                      text={walletNonCanonicalFetDisplay}
                      hideTooltip={
                        !formatBalance(walletNonCanonicalFetDisplay).isTruncated
                      }
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: 45, fontSize: "30px" }}
                  />
                )}
                <div className="tw-text-xl tw-font-semibold tw-text-coolGray-500 tw-pl-1">
                  <Tooltip
                    child={formatBalance(walletBalance()).value}
                    tooltipTextClassName="tool-tip-agent-name"
                    text={walletBalance()}
                    hideTooltip={!formatBalance(walletBalance()).isTruncated}
                  />
                </div>
              </div>
              <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-mr-6">
                <h3 className="tw-text-xl tw-font-medium tw-text-coolGray-900">
                  App Balance:
                </h3>
                {appBalanceNonCanonicaFetDisplay ? (
                  <div className="tw-text-5xl tw-font-bold tw-text-coolGray-900 tw-whitespace-nowrap">
                    <Tooltip
                      child={
                        formatBalance(appBalanceNonCanonicaFetDisplay).value
                      }
                      tooltipTextClassName="tool-tip-agent-name"
                      text={appBalanceNonCanonicaFetDisplay}
                      hideTooltip={
                        !formatBalance(appBalanceNonCanonicaFetDisplay)
                          .isTruncated
                      }
                    />
                  </div>
                ) : (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    style={{ marginLeft: 40, fontSize: "30px" }}
                  />
                )}
                <div className="tw-text-xl tw-font-semibold tw-text-coolGray-500 tw-pl-1">
                  <Tooltip
                    child={formatBalance(appBalance()).value}
                    tooltipTextClassName="tool-tip-agent-name"
                    text={appBalance()}
                    hideTooltip={!formatBalance(appBalance()).isTruncated}
                  />
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-justify-center tw-gap-6 tw-ml-auto">
              {Costing.hasServiceExpired(state) && !hasWalletBalance() ? (
                <Tooltip
                  child={
                    <div className="tw-cursor-not-allowed tw-text-base tw-font-semibold tw-border tw-border-coolGray-300 tw-text-coolGray-500 tw-bg-blueGray-50 tw-shadow-sm tw-px-7 tw-py-3 tw-rounded tw-w-40 tw-text-center">
                      Add funds
                    </div>
                  }
                  tooltipTextClassName={"tool-tip-trigger-button"}
                  text={"Service expired"}
                />
              ) : (
                <div
                  className="tw-text-base tw-font-semibold tw-bg-green-300 tw-text-coolGray-900 tw-cursor-pointer tw-shadow-sm tw-px-7 tw-py-3 tw-rounded tw-w-40 tw-text-center"
                  onClick={() => setShowVaultModal(true)}
                >
                  Add funds
                </div>
              )

              <HelpToolTip tooltipText="Withdrawal is currently disabled">
                <div
                  className="tw-cursor-not-allowed tw-text-base tw-font-semibold tw-border tw-border-coolGray-300 tw-text-coolGray-500 tw-bg-blueGray-50 tw-shadow-sm tw-px-7 tw-py-3 tw-rounded tw-w-40 tw-text-center"
                  onClick={() => {}}
                >
                  Withdraw
                </div>
              </HelpToolTip>
            </div>
          </div>

          <div className="xl:tw-w-1/4 tw-border-blue-700 tw-rounded-2xl tw-p-8 tw-bg-coolGray-900 tw-grid tw-grid-cols-5 ">
            <div className="tw-col-span-3 tw-flex tw-flex-col tw-justify-around">
              <h3 className="tw-text-3xl tw-font-semibold tw-text-white">
                Need help?
              </h3>
              <div
                className={`tw-text-base tw-font-semibold tw-bg-green-300 tw-text-coolGray-900 tw-cursor-pointer tw-shadow-sm tw-px-7 tw-py-3 tw-rounded-full tw-w-40 tw-text-center ${
                  state.assets.syncing || state.assets.suggestedPoolSyncing
                    ? "tw-opacity-80 tw-cursor-not-allowed"
                    : ""
                }`}
                onClick={handleTourClick}
              >
                Start tour
              </div>
            </div>
            <div className="tw-col-span-2 tw-flex tw-items-center">
              <img
                className="tw-object-contain"
                src={pointerGear}
                alt="start tour"
              />
            </div>
          </div>
          {startTour && (
            <Tour
              startTour={startTour}
              setStartTour={setStartTour}
              hasTokens={hasTokens}
              hasPool={hasPool}
              hasPoolOptionBtn={hasPoolOptionBtn}
              hasNoLpBtn={hasNoLpBtn}
            />
          )}
        </div>
      )} */}
      {/* Price Container  */}
      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-4 xl:tw-gap-8 2xl:tw-gap-20 tw-mt-14 tw-px-3">
        <PriceBox
          title="Current market value (USD)"
          value={state.assets.currentMarketValueUSD}
          syncing={state.assets.syncing}
        />
        <PriceBox
          title="Assets currently monitored"
          value={state.assets.assetsCurrentlyMonitored}
          syncing={state.triggers.syncing}
        />
        <PriceBox
          title="Assets protected for you"
          value={state.assets.assetsProtected}
          syncing={state.triggers.syncing}
        />
      </div>

      {/* Tokens Table */}
      <TokenTable />

      {/* Pool Table */}
      <PoolsTable />
      <TopUpVaultModal
        show={showVaultModal}
        fetBalance={canonicalWalletBalance}
        close={() => setShowVaultModal(false)}
        isAddressRegistered={addressRegistered}
      />
    </div>
  );
});
