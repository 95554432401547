import React, {useEffect, useState} from "react";
import {Decimal} from "decimal.js";
import {Validation} from "../../../validation";
import {TokenDropdown} from "../../../components/TokenDropdown";
import {hasTooManyDecimalPlaces} from "../../../utils/numeric/hasTooManyDecimalPlaces";
import {isNumeric} from "../../../utils/numeric/isNumeric";
import {amountTooltip, calcAmount} from "./utils";
import {truncateToDecimalPlaces} from "../../../utils/numeric/truncateToDecimalPlaces";
import {SwapTokenWrapper} from "./index";
import {CryptoToken, HoldingToken} from "../../../state/assets";
import {InputValidation, InvalidReason} from "../../../components/NewTriggerModal/SelectTokenAndPool";
import {eventAnalysis} from "../../../utils/misc/trackEvent";
import {TriggerType} from "../../../state/triggers";


interface ToTokenInterface {
  toToken: CryptoToken | undefined;
  fromToken: HoldingToken | undefined;
  fromTokenSymbol: string;
  tokenList: CryptoToken[];
  setToToken: Function;
  setFromAmount: (s: string) => void;
  setToAmount: (s: string) => void;
  setInputToTokenError: (s: boolean) => void;
  setDecimalsToTokenExceeded: (s: boolean) => void;
  inputToTokenError: boolean;
  decimalsToTokenExceeded: boolean;
  toAmount: string | undefined;
  fromAmount: string | undefined;
  error: InputValidation;
}

function getPoolValidationMessage(
  validation: InputValidation,
  toTokenSymbol: string,
  fromTokenSymbol: string
): string | undefined {
  if (validation.validity !== Validation.INVALID) {
    return;
  }
  const namePrefix = `${fromTokenSymbol.toLowerCase()}_${toTokenSymbol.toLowerCase()}`;
  switch (validation.reason) {
    case InvalidReason.INVALID_POOL: {
      eventAnalysis(
        `${namePrefix}_indirect_swap_err`,
        TriggerType.SWAP,
        "There is no pair for selected tokens. Indirect swaps are currently unsupported"
      );
      return "There is no pair for selected tokens. Indirect swaps are currently unsupported";
    }
    case InvalidReason.UNABLE_TO_FETCH_PAIR: {
      eventAnalysis(
        `${namePrefix}_fetch_pair_err`,
        TriggerType.SWAP,
        "Not able to fetch pair, please close and try again"
      );
      return "Not able to fetch pair, please close and try again";
    }
    case InvalidReason.TWO_TRIGGERS_ON_POOL: {
      eventAnalysis(
        `${namePrefix}_already_two_trigger_err`,
        TriggerType.SWAP,
        "You already have two triggers for the above from token - to token combination"
      );
      return "You already have two triggers for the above from token - to token combination";
    }
    case InvalidReason.UNSUPPORTED_NETWORK: {
      return "Network or chain connection error, please refresh and try again";
    }
    default: {
      return "Unknown error";
    }
  }
}


export const ToTokenBox = ({
                               toToken,
                               fromToken,
                               fromTokenSymbol,
                               toAmount,
                               tokenList,
                               setFromAmount,
                               setToAmount,
                               setInputToTokenError,
                               setDecimalsToTokenExceeded,
                               inputToTokenError,
                               decimalsToTokenExceeded,
                               setToToken,

                               error,
                           }: ToTokenInterface) => {
    const [toTokenUSD, setToTokenUSD] = useState<string>("");

    useEffect(() => {
        if (!toToken) return;

        if (!toToken.price) {
            setToTokenUSD("0");
            return;
        }

        const price = parseFloat(toToken.price);
        const calcResult = !toAmount
            ? ""
            : new Decimal(toAmount).mul(new Decimal(price)).toString();
        setToTokenUSD(calcResult);
    }, [toAmount, toToken]);
    return (
        <SwapTokenWrapper title="To">
            <div
                className={`tw-p-7 ${
                    error.validity === Validation.INVALID ? "tw-pb-1" : "tw-pb-7"
                }  tw-bg-white tw-flex-1 tw-flex tw-items-center`}
            >
                <div
                    className="tw-w-full tw-flex tw-justify-between tw-items-center tw-border tw-border-coolGray-300 tw-bg-coolGray-50 tw-px-6 tw-py-3 tw-rounded-2xl">
                    {toToken ? (
                        <TokenDropdown
                            otherToken={undefined}
                            tokenList={tokenList}
                            selected={toToken}
                            setSelected={setToToken}
                        />
                    ) : (
                        <div>Loading...</div>
                    )}
                    <div>
                        <div className="tw-text-sm tw-font-normal tw-text-coolGray-500 tw-text-right">
                            Based on current rates:
                        </div>
                        <div>
                            <input
                                className="tw-w-full tw-bg-white tw-h-12 tw-rounded tw-text-xl tw-font-medium tw-text-coolGray-900 tw-text-right tw-border tw-border-coolGray-300 tw-my-2 tw-px-3 focus:tw-ring-2 focus:tw-ring-blue-600 "
                                type="text"
                                value={toAmount}
                                onChange={(e) => {
                                    if (!fromToken || !toToken) return;
                                    const val = e.target.value;

                                    if (val === "" || val.substring(0, 1) === "-") {
                                        setInputToTokenError(false);
                                        setDecimalsToTokenExceeded(false);
                                        setFromAmount("");
                                        setToAmount("");
                                        return;
                                    }

                                    if (
                                        hasTooManyDecimalPlaces(val, parseInt(toToken.decimals))
                                    ) {
                                        setDecimalsToTokenExceeded(true);
                                        setInputToTokenError(false);
                                        return;
                                    }

                                    if (!isNumeric(val) && val !== ".") {
                                        setInputToTokenError(true);
                                        setDecimalsToTokenExceeded(false);
                                        return;
                                    }

                                    let nextFromAmount = calcAmount(val, toToken, fromToken);

                                    if (nextFromAmount === undefined) {
                                        return;
                                    }

                                    if (
                                        new Decimal(nextFromAmount).gt(
                                            new Decimal(fromToken.balance)
                                        )
                                    ) {
                                        setInputToTokenError(true);
                                        setDecimalsToTokenExceeded(false);
                                        return;
                                    }

                                    if (new Decimal(val).lt(new Decimal(0))) {
                                        setInputToTokenError(false);
                                        setDecimalsToTokenExceeded(false);
                                        setFromAmount("");
                                        setToAmount("");
                                        return;
                                    }

                                    if (
                                        hasTooManyDecimalPlaces(nextFromAmount, fromToken.decimal)
                                    ) {
                                        setInputToTokenError(false);
                                        // setDecimalsToTokenExceeded(true)
                                        nextFromAmount = truncateToDecimalPlaces(
                                            nextFromAmount,
                                            fromToken.decimal
                                        );
                                    }

                                    setInputToTokenError(false);
                                    setDecimalsToTokenExceeded(false);
                                    setFromAmount(nextFromAmount);
                                    setToAmount(val);
                                }}
                                placeholder="00.00"
                            />
                        </div>

                        <div className="tw-flex tw-justify-end tw-mb-2">
                            <div className="tw-text-red-700 tw-mr-3">
                                {inputToTokenError ? "Invalid input" : ""}
                            </div>
                            <div className="tw-text-red-700 tw-mr-3">
                                {decimalsToTokenExceeded ? "Max decimals exceeded" : ""}
                            </div>
                            {amountTooltip("to-amount-usd", toTokenUSD)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-text-sm tw-text-red-600 tw-px-7 pb-1">
                {error.validity === Validation.INVALID &&
                toToken &&
                getPoolValidationMessage(error, toToken.symbol, fromTokenSymbol)}
            </div>
        </SwapTokenWrapper>
    );
};