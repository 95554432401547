import { asAbi } from "./utils";
import Provider from "../Provider";
import { anErr, anOk, isErr, Result } from "../../result";

import WethAbi from "../../abis/IWeth.json";
import { getNetworkDetails } from "../../configs";

export class Weth {
  /**
   * Fetch all info for the pool, but return null if any part of it fails including pool not existing
   *
   * @param poolAddress
   */
  public static async convert(
    fromAddress: string,
    canonicalAmount: string,
    chainId: number,
    gasPrice: string
  ): Promise<Result<string>> {
    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      return networkDetails;
    }

    const contract = new Provider._web3.eth.Contract(
      asAbi(WethAbi),
      networkDetails.value.wrappedNativeTokenAddress
    );

    try {
      await contract.methods
        .deposit()
        .send({ from: fromAddress, value: canonicalAmount, gasPrice });

      return anOk("Success");
    } catch (err) {
      return anErr("Error: Unable to perform conversion", err);
    }
  }
}
