import { Button, Modal, Form, Col } from "react-bootstrap";
import EmailValidator from "email-validator";

import React, { useState, useEffect } from "react";

import style from "./editEmailModal.module.scss";
import close from "../../assets/close.svg";

interface EditEmailModalProps {
  existingEmail?: string;
  confirmed: boolean;
  show: boolean;
  close: () => void;
  update: (regEmail: string) => void;
}

function isEmailInvalid(value: string): boolean {
  if (value === "") {
    return false;
  }

  return !EmailValidator.validate(value);
}

export function EditEmailModal(props: EditEmailModalProps) {
  const [email, setEmail] = useState(props.existingEmail ?? "");
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
  const [emailIsJustTurnedValid, setEmailIsJustTurnedValid] =
    useState<boolean>(false);

  let intervalId: any;

  useEffect(() => {
    setEmail(props.existingEmail ?? "");
  }, [props.existingEmail]);

  return (
    <Modal show={props.show}>
      <Modal.Header className={style.modalHeader}>
        <img
          onClick={props.close}
          src={close}
          alt="close"
          className={style.close}
          tabIndex={0}
          onKeyDown={(e) => {}}
        />
        <Modal.Title>Update Email</Modal.Title>
      </Modal.Header>
      <Modal.Body className={style.modalBody}>
        <Form.Row>
          <Form.Group as={Col} xs={9}>
            <Form.Control
              type="text"
              value={email}
              className={`${style.input}`}
              onChange={(e) => {
                clearTimeout(intervalId);

                if (isEmailInvalid(email) && !isEmailInvalid(e.target.value)) {
                  setEmailIsJustTurnedValid(true);
                  setTimeout(() => {
                    setEmailIsJustTurnedValid(false);
                  }, 3000);
                }

                //if email is invalid as it become valid we change
                if (emailInvalid && !isEmailInvalid(e.target.value)) {
                  setEmailInvalid(false);
                }

                setEmail(e.target.value);
              }}
              isValid={emailIsJustTurnedValid}
              isInvalid={emailInvalid}
            />
          </Form.Group>
          <Form.Group as={Col} xs={3} className={style.updateBtnContainer}>
            <div
              onMouseEnter={() => {
                if (isEmailInvalid(email)) {
                  setEmailInvalid(true);
                  intervalId = setTimeout(() => {
                    setEmailInvalid(false);
                  }, 1000);
                }
              }}
            >
              <Button
                disabled={
                  email === "" ||
                  email === props.existingEmail ||
                  isEmailInvalid(email)
                }
                onClick={() => props.update(email)}
                className={`${style.updateBtn} ${style.activeUpdateBtn}`}
              >
                Update
              </Button>
            </div>
          </Form.Group>
          {email !== "" && !props.confirmed && email === props.existingEmail && (
            <>
              <Button
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  props.update(email);
                }}
                className={style.link}
              >
                Resend
              </Button>
              confirmation link
            </>
          )}
        </Form.Row>
      </Modal.Body>
    </Modal>
  );
}
