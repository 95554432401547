import classNames from "classnames";

interface ToastModalProps {
  message: string;
  show: boolean;
  close: () => void;
}

export const ToastModal = ({ message, show, close }: ToastModalProps) => {
  return (
    <div
      className={classNames(
        "tw-flex",
        "tw-space-x-2",
        "tw-justify-center",
        "tw-mt-3",
        show ? "" : "tw-invisible"
      )}
    >
      <div
        className="tw-bg-white tw-shadow-lg tw-mx-auto tw-w-96 tw-max-w-full tw-text-sm tw-pointer-events-auto tw-bg-clip-padding tw-rounded-lg block"
        id="static-example"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        data-mdb-autohide="false"
      >
        <div className="tw-bg-white tw-flex tw-justify-between tw-items-center tw-py-2 tw-px-3 tw-bg-clip-padding tw-border-b tw-border-gray-200 tw-rounded-t-lg">
          <p className="tw-font-bold tw-text-gray-500">Error Encountered</p>
          <div className="tw-flex tw-items-center">
            <button
              type="button"
              className="tw-btn-close tw-box-content tw-w-4 tw-h-4 tw-ml-2 tw-text-black tw-border-none tw-rounded-none tw-opacity-50 focus:tw-shadow-none focus:tw-outline-none focus:tw-opacity-100 hover:tw-text-black hover:tw-opacity-75 hover:tw-no-underline"
              data-mdb-dismiss="toast"
              aria-label="Close"
              onClick={close}
            ></button>
          </div>
        </div>
        <div className="tw-p-3 tw-bg-white tw-rounded-b-lg tw-break-words tw-text-gray-700">
          {message}
        </div>
      </div>
    </div>
  );
};
