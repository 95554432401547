import { Trigger, TriggerType } from "../../state/triggers";
import { NetworkParameters } from "../../configs";

/**
 *
 * note: reason for long name is due to naming conflicts
 *
 * @param existingTrigger
 * @param networkDetails
 */
export const getProxyContractAddressForTrigger = (
  address: string | null | undefined,
  triggerType: number,
  networkDetails: NetworkParameters
): string => {
  return (
    address ??
    (triggerType === TriggerType.WITHDRAW_LIQUIDITY
      ? networkDetails.legacyWithdrawProxyContractAddress
      : networkDetails.legacySwapProxyContractAddress)
  );
};
