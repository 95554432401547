import { StateStore } from "../../state/state";
import { hasAvailableAgents } from "./hasAvailableAgents";

export const canUseMoreAgent = (state: StateStore) => {
  if (
    (state.account?.accountDetails?.agentLimit &&
      state.account.accountDetails.agentLimit > state.agents.length) ||
    hasAvailableAgents(state)
  ) {
    return true;
  }
  return false;
};
