/* eslint-disable class-methods-use-this */
import { getNetworkDetails, Network, protocols } from "../configs";
import {
  ApprovedBalance,
  NewTrigger,
  parseTriggerChoices,
  parseTriggerState,
  parseTriggerType,
  Trigger,
  TriggerChoices,
  TriggerType,
} from "../state/triggers";
import { AccessToken } from "../state/token";
import { Pair } from "../state/pairCache";
import { PairContract } from "../services/contracts/PairContract";
import { anErr, anOk, isErr, isOk, Result } from "../result";
import {
  apiChainToChainID,
  apiChainToNetwork,
  enumToString,
  networkToApiChain,
  networkToApiChainId,
} from "./common";
import { PoolsAPI } from "./PoolsAPI";
import ERC20Contract from "../services/contracts/ERC20Contract";
import { wrappedNameConversion } from "../utils/display/wrappedNameConversion";

import client from "./client";
import { gql } from "@apollo/client";
import {
  createTrigger,
  deleteTrigger,
  fetchTriggersQuery,
} from "./TriggersQueries";
/**
 * params expected by python to create trigger
 */
interface CreateTriggerGraphQLParams {
  agent: string;
  threshold: string | null;
  price0Min: string | null;
  price0Max: string | null;
  price1Min: string | null;
  price1Max: string | null;
  pool: string;
  token: string;
  swapToken?: string;
  chain: string;
  gasPrice: string;
  gasAmount: string;
  triggerType: string;
  type: string;
  amount: string;
  slippage?: number;
  proxyAddress: string;
}

function getProxyAddress(
  triggerType: TriggerType,
  chainId: number
): Result<string> {
  const networkDetails = getNetworkDetails(chainId);

  if (isErr(networkDetails)) {
    return networkDetails;
  }

  if (triggerType === TriggerType.SWAP) {
    return anOk(networkDetails.value.swapProxyContractAddress);
  }
  return anOk(networkDetails.value.withdrawLiquidityProxyContractAddress);
}
const getPrice = (price: string) => ((price === "0" || price === "") ? null : price);
/**
 * Converts between our trigger object and the required params of the trigger apis create functionality
 *
 * @param trigger
 * @param network
 */
function buildTriggerAPIParams(
  trigger: NewTrigger,
  network: Network,
  chainId: number,
  triggerType: TriggerType,
  protocol: number
): CreateTriggerGraphQLParams | undefined {
  const proxyAddress = getProxyAddress(triggerType, chainId);
  if (isErr(proxyAddress)) return;
  return {
    agent: trigger.agentUuid,
    threshold: getPrice(trigger.threshold),
    price0Min: getPrice(trigger.price0Min),
    price0Max: getPrice(trigger.price0Max),
    price1Min: getPrice(trigger.price1Min),
    price1Max: getPrice(trigger.price1Max),
    pool: trigger.pair.address,
    gasAmount: trigger.gasAmount,
    gasPrice: trigger.gasPrice,
    amount: trigger.amount,
    chain: networkToApiChain(network),
    proxyAddress: proxyAddress.value,
    triggerType: enumToString(TriggerType, triggerType),
    token: enumToString(TriggerChoices, trigger.token),
    swapToken: enumToString(TriggerChoices, trigger.swap_token),
    slippage:
      TriggerType.SWAP === triggerType && trigger.slippage
        ? trigger.slippage * 100 // convert percentage to plane number eg. 0.1 to 10
        : undefined,
    type: enumToString(protocols, protocol),
  };
}

const getTokenAddress = async (
  triggerType: TriggerType,
  pairAddress: string,
  token: TriggerChoices
): Promise<string | undefined> => {
  if (triggerType === TriggerType.WITHDRAW_LIQUIDITY) {
    return pairAddress;
  }
  const tokenAddresses = await PairContract.getTokenAddresses(pairAddress);
  if (isOk(tokenAddresses)) {
    return token === 0
      ? tokenAddresses.value.token0
      : tokenAddresses.value.token1;
  }
};

/**
 *
 *  maybe need to rethink this as it seems too complex.
 *
 * The complexity comes from reducing network requests by only searching for token info if not pre-existing
 *  trigger in which case we can find the token values from the list and build from them.
 *
 * @param triggerAPIResponse
 * @param pair
 */
async function buildTrigger(
  network: Network,
  triggerAPIResponse: any,
  pair: Pair
): Promise<Result<Trigger>> {
  let approveBalance;
  const triggerType = parseTriggerType(triggerAPIResponse.triggerType);
  const triggerState = parseTriggerState(triggerAPIResponse.state);
  const swapToken = parseTriggerChoices(triggerAPIResponse.swapToken);
  const token = parseTriggerChoices(triggerAPIResponse.token);
  const triggerChoice =
    triggerAPIResponse.swapToken !== undefined ? swapToken : token;
  const networkID = networkToApiChainId(network);
  if (
    isOk(networkID) &&
    networkID.value === apiChainToChainID(triggerAPIResponse.pool.chain)
  ) {
    const address = await getTokenAddress(
      triggerType,
      pair.address,
      triggerChoice
    );
    const approveBalRes = await ERC20Contract.getApprovedAmount(
      address as string,
      triggerAPIResponse.userAddress,
      triggerAPIResponse.proxyAddress,
      triggerAPIResponse.amount
    );
    approveBalance = approveBalRes.success
      ? approveBalRes.value
      : ({
          balance: "0",
          symbol: "",
          decimal: "18",
        } as ApprovedBalance);
  } else {
    approveBalance = {
      balance: "0",
      symbol: "",
      decimal: "18",
    } as ApprovedBalance;
  }

  return anOk({
    uuid: triggerAPIResponse.uuid,
    agentUuid: triggerAPIResponse.agent,
    pair,
    approveBalance,
    amount: triggerAPIResponse.amount,
    network: apiChainToNetwork(triggerAPIResponse.pool.chain),
    threshold: `${triggerAPIResponse.threshold}`,
    price0Min: `${triggerAPIResponse.price0Min}`,
    price0Max: `${triggerAPIResponse.price0Max}`,
    price1Min: `${triggerAPIResponse.price1Min}`,
    price1Max: `${triggerAPIResponse.price1Max}`,
    token,
    swap_token: triggerChoice,
    state: triggerState,
    gasPrice: triggerAPIResponse.gasPrice,
    gasAmount: triggerAPIResponse.gasAmount,
    createdAt: triggerAPIResponse.createdAt,
    triggerType,
    proxyAddress: triggerAPIResponse.proxyAddress,
    tx_hash: triggerAPIResponse.txHash,
  });
}

export interface TriggersAPIStructure {
  createTrigger: (
    token: AccessToken,
    trigger: NewTrigger,
    network: Network,
    chainId: number,
    triggerType: TriggerType,
    protocol: number
  ) => Promise<Result<Trigger>>;
  deleteTrigger: (token: AccessToken, uuid: string) => Promise<void>;
  fetchTriggers: (
    token: AccessToken,
    prexistingTriggers: Trigger[],
    network: Network
  ) => Promise<Result<Trigger[]>>;
}

export class TriggersAPI implements TriggersAPIStructure {
  async createTrigger(
    token: AccessToken,
    trigger: NewTrigger,
    network: Network,
    chainId: number,
    triggerType: TriggerType,
    protocol: number
  ): Promise<Result<Trigger>> {
    const apiParams = buildTriggerAPIParams(
      trigger,
      network,
      chainId,
      triggerType,
      protocol
    );
    try {
      const { data, errors } = await client.mutate({
        mutation: gql(createTrigger),
        variables: {
          input: apiParams,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });

      if (errors) {
        return anErr(errors[0].message ?? "Error creating new trigger");
      }
      const triggerData = data.createTrigger;
      return buildTrigger(network, triggerData, trigger.pair);
    } catch (e: any) {
      return anErr(`Unable to create user trigger: ${e.message}`);
    }
  }

  async deleteTrigger(token: AccessToken, uuid: string): Promise<void> {
    try {
      const { errors } = await client.mutate({
        mutation: gql(deleteTrigger),
        variables: {
          deleteTriggerId: uuid,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (!errors) return;
      throw new Error(`Unable to delete user trigger: API Error`);
    } catch (e) {
      throw new Error(`Unable to delete user trigger: ${uuid}`);
    }
  }

  async fetchTriggers(
    token: AccessToken,
    prexistingTriggers: Trigger[],
    network: Network
  ): Promise<Result<Trigger[]>> {
    const findPreExistingPair = (item: any): Pair | false => {
      const found = prexistingTriggers.filter((trigger: Trigger) => {
        return trigger.uuid === item.uuid;
      });

      return found.length ? found[0].pair : false;
    };
    try {
      const { data, errors } = await client.query({
        query: gql(fetchTriggersQuery),
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to fetch triggers: ${errors[0].message ?? "unknown error"}`
        );
      const { triggers } = data;
      let pair: Pair | false;
      let res: Result<Pair>;
      const triggersList: Trigger[] = [];
      for (const item of triggers) {
        const triggerNetwork = apiChainToNetwork(item.pool.chain);
        const chainID = apiChainToChainID(item.pool.chain);
        const result = await PoolsAPI.fetchPool(
          token.accessToken,
          item.pool.address
        );
        if (isOk(result)) {
          const trigger = await buildTrigger(network, item, result.value);
          if (isOk(trigger)) triggersList.push(trigger.value);
          continue;
        }
        if (triggerNetwork !== network) {
          continue; // for the moment skip non-native triggers
        }
        pair = findPreExistingPair(item);
        if (pair === false) {
          res = await PairContract.fetchPoolInformation(
            item.pool.address,
            triggerNetwork,
            chainID
          );
          if (isErr(res)) continue;
          else pair = res.value;
        } else {
          // we update prices now of preexisting triggers now in this method
          const price = await PairContract.fetchPairPrice(
            item.pool,
            pair.token0Decimals,
            pair.token1Decimals
          );
          if (isErr(price)) continue;
          else {
            pair.price = price.value.toString();
          }
        }
        pair.token1 = wrappedNameConversion(
          chainID,
          pair.token1,
          pair.address
        ) as string;

        pair.token0 = wrappedNameConversion(
          chainID,
          pair.token0,
          pair.address
        ) as string;
        const trigger = await buildTrigger(network, item, pair);
        if (isOk(trigger)) triggersList.push(trigger.value);
      }
      return anOk(triggersList);
    } catch (e) {
      return anErr(`Unable to fetch user triggers: GraphQL Error`);
    }
  }
}
