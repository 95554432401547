import BN from "bn.js";

/**
 *
 * @param wei
 * @return gwei
 */
export function weiToGwei(wei: string): string {
  const divisor = new BN(10).pow(new BN(9));
  // Convert wei to gwei
  const gweiAmount = new BN(wei).div(divisor);
  return gweiAmount.toString();
}
