import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { SelectAmount } from "./SelectAmount";
import { PerformTopUp } from "./PerformTopUp";
import style from "./index.module.scss";
import close from "../../assets/close.svg";
import { KEY_EVENT_ENTER, KEY_EVENT_SPACE } from "../../configs";
import { eventNameAnalysis } from "../../utils/misc/trackEvent";

enum Stage {
  SELECT_AMOUNT,
  SEND_FUNDS,
}

interface TopUpVaultModalProps {
  show: boolean;
  fetBalance: string | undefined;
  isAddressRegistered: boolean;
  close: () => void;
}

export function TopUpVaultModal(props: TopUpVaultModalProps) {
  const [stage, setStage] = useState<Stage>(Stage.SELECT_AMOUNT);
  const [transferAmount, setTransferAmount] = useState<string>("0");
  const [allowToCloseModal, setAllowToCloseModal] = useState<boolean>(true);

  const handleClose = () => {
    if (!allowToCloseModal) {
      return;
    }
    setStage(Stage.SELECT_AMOUNT);
    setTransferAmount("0");
    props.close();
  };

  let content = null;
  switch (stage) {
    case Stage.SELECT_AMOUNT:
      content = (
        <SelectAmount
          fetBalance={props.fetBalance}
          onComplete={(value) => {
            setStage(Stage.SEND_FUNDS);
            setTransferAmount(value);
          }}
        />
      );
      break;
    case Stage.SEND_FUNDS:
      content = (
        <PerformTopUp
          amount={transferAmount}
          onComplete={(txHash) => {
            eventNameAnalysis("purchase", {
              value: parseInt(transferAmount),
              transaction_id: txHash,
            });
            handleClose();
          }}
          isAddressRegistered={props.isAddressRegistered}
          setAllowToCloseModal={setAllowToCloseModal}
        />
      );
      break;
  }

  return (
    <Modal show={props.show} className={style.modalContainer}>
      <Modal.Header className={style.modalHeader}>
        <img
          onClick={handleClose}
          src={close}
          alt="close"
          className={style.closeButton}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === KEY_EVENT_ENTER || e.key === KEY_EVENT_SPACE) {
              handleClose();
            }
          }}
        />
        <Modal.Title>Top up your Account</Modal.Title>
      </Modal.Header>
      {content}
    </Modal>
  );
}
