import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TokenAPI } from "../../api/Tokens";
import cryptocurrencyBackup from "../../assets/cryptocurrency.svg";
import shieldCross from "../../assets/shield-cross.png";
import shieldLock from "../../assets/shield-lock.png";
import HelpIcon from "../../assets/help-icon.png";
import { NewTriggerModal, Stage } from "../../components/NewTriggerModal";
import Pagination from "../../components/pagination";
import { Tooltip as HelpToolTip, Tooltip } from "../../components/Tooltip";
import {Network, protocols} from "../../configs";
import { isOk } from "../../result";
import { Chain, HoldingPool, PoolDetails } from "../../state/assets";
import { StateContext, StateStore } from "../../state/state";
import { Trigger, TriggerType } from "../../state/triggers";
import { eventAnalysis } from "../../utils/misc/trackEvent";
import { Pools } from "./Dashboard.data";
import { errorMessages, getPercentage, getPoolErrorMessage } from "./utils";
import {
  SORT_PROPERTIES,
  SORT_STAGES,
  Sorter,
  sortPoolsOrTokens,
} from "../../components/Sorter";
import { PoolTableRow } from "./PoolTableRow";
import { FiltersBlock, FilteringOptions } from "./FiltersBlock";
import {formatPoolName} from "../../utils/display/formatPoolName";
import {formatBigNumber} from "../../utils/numeric/formatBigNumber";

export const PoolAPRCol = ({
  feesPerLiquidity,
  syncing,
}: {
  feesPerLiquidity: string | undefined;
  syncing: boolean;
}) => {
  if (syncing) {
    return <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />;
  }
  if (feesPerLiquidity === undefined) {
    return <>-</>;
  } else {
    const apr = getPercentage(feesPerLiquidity);
    return <div>{apr} %</div>;
  }
};

export const ShieldIcon = ({
  hasTrigger,
  poolName,
}: {
  hasTrigger: boolean;
  poolName: string;
}) => {
  const tooltiptext = hasTrigger
    ? `Automation is already created for ${poolName} pool`
    : `Automation is not created for ${poolName} pair`;
  return (
    <Tooltip id={`shield-${poolName}`} tooltipText={tooltiptext}>
      <img
        className="tw-h-6 tw-w-auto tw-ml-2"
        src={hasTrigger ? shieldLock : shieldCross}
      />
    </Tooltip>
  );
};

export let PoolsTable: React.FunctionComponent<object>;

PoolsTable = observer(() => {
  const state = useContext(StateContext) as StateStore;
  const [currentItems, setCurrentItems] = useState<HoldingPool[] | null>(null);
  const [balanceSortState, setBalanceSortState] = useState<SORT_STAGES>(
    SORT_STAGES.BOTH
  );
  const [marketValueSortState, setMarketValueSortState] = useState<SORT_STAGES>(
    SORT_STAGES.BOTH
  );
  const [poolNameSortState, setPoolNameSortState] = useState<SORT_STAGES>(
    SORT_STAGES.BOTH
  );
  const [activeSortProperty, setActiveSortProperty] = useState<SORT_PROPERTIES>(
    SORT_PROPERTIES.DEFAULT
  );
  const [poolPositionsDetail, setPoolPositionsDetail] = useState<PoolDetails[]>(
    []
  );
  const [syncingAPR, setSyncingAPR] = useState(true);
  const [allPools, setallPools] = useState<HoldingPool[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [showNew, setShowNew] = useState(false);
  const [filteringOptions, setFilteringOptions] = useState<FilteringOptions>({
    query: "",
    ordering: null,
    chain: null,
  });
  const history = useHistory();

  const itemsPerPage = 5;

  const withdrawErrorLogAnalysis = (chain: Chain, poolAddress: string) => {
    switch (getPoolErrorMessage(state, chain, poolAddress)) {
      case errorMessages.SERVICE_EXPIRED: {
        eventAnalysis(
          "service_exp_err",
          TriggerType.WITHDRAW_LIQUIDITY,
          getPoolErrorMessage(state, chain, poolAddress)
        );
        break;
      }
      case errorMessages.AGENT_LIMIT_REACHED: {
        eventAnalysis(
          "agent_limit_reached_err",
          TriggerType.WITHDRAW_LIQUIDITY,
          getPoolErrorMessage(state, chain, poolAddress)
        );
        break;
      }
      case errorMessages.OPPOSITE_NETWORK: {
        eventAnalysis(
          "not_allow_on_opp_network_err",
          TriggerType.WITHDRAW_LIQUIDITY,
          getPoolErrorMessage(state, chain, poolAddress)
        );
        break;
      }
      case errorMessages.TRIGGER_ALREADY_REGISTERED: {
        eventAnalysis(
          "trigger_limit_reached_err",
          TriggerType.WITHDRAW_LIQUIDITY,
          getPoolErrorMessage(state, chain, poolAddress)
        );
        break;
      }
    }
  };

  const getPoolDetilsFromPoolIds = async (poolIds: string[]) => {
    if (!state.account.user?.token) return [];
    const poolsDetails = await TokenAPI.fetchtPoolInfoFromPoolIds(
      state.account.user?.token.accessToken,
      poolIds
    );
    if (isOk(poolsDetails)) {
      setPoolPositionsDetail(poolsDetails.value);
      setSyncingAPR(false);
    }
  };
  const getPoolDetail = (poolAddress: string) => {
    if (poolPositionsDetail.length) {
      return poolPositionsDetail.find(
        (poolDetail) =>
          poolDetail.address.toUpperCase() === poolAddress.toUpperCase()
      );
    }
  };

  useEffect(() => {
    if (allPools.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      const unsortedCurrentPools = allPools.slice(itemOffset, endOffset);
      const sortedPools = sortPoolsOrTokens(
        unsortedCurrentPools,
        balanceSortState,
        activeSortProperty
      );
      setCurrentItems(sortedPools as HoldingPool[]);
      setPageCount(Math.ceil(allPools.length / itemsPerPage));
    } else {
      setCurrentItems([]);
      setPageCount(0);
    }
  }, [itemOffset, itemsPerPage, allPools]);

  
  
  function filterPools(filteringOpts: FilteringOptions) {
        let pools =
      state.web3.networkId === Network.ETH
        ? [...state.assets.holdingPoolsUniswap, ...state.assets.holdingPoolCake]
        : [
            ...state.assets.holdingPoolCake,
            ...state.assets.holdingPoolsUniswap,
          ];
    if (pools.length) {
      const poolIds = pools.map(
        (pool) =>
          `${pool.address}-${state.web3.networkId === Network.ETH ? 1 : 2}`
      );
      getPoolDetilsFromPoolIds(poolIds);
    }

    // Filter by chain (network)
    if (filteringOpts.chain) {
      pools = pools.filter((pool) => pool.network === filteringOpts.chain);
    }
    // Filter by search query
    pools = pools.filter(
      (pool) =>
        pool.token0.toLowerCase().includes(filteringOpts.query) ||
        pool.token1.toLowerCase().includes(filteringOpts.query) ||
        pool.symbol.toLowerCase().includes(filteringOpts.query)
    );
    // Sort
    if (filteringOpts.ordering === "chain") {
      pools = pools.sort((a, b) => a.network - b.network);
    }
    setallPools(pools);
  }
  
  useEffect(() => {
filterPools(filteringOptions)
  }, [
    state.assets.holdingPoolsUniswap,
    state.assets.holdingPoolCake,
    state.assets.syncing
  ]);

  const isPoolAutomationAlreadyCreated = (poolAddress: string) => {
    const triggers = state.triggers.list();
    return Boolean(
      triggers.filter((trigger: Trigger) => {
        return Boolean(
          trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
            trigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY
        );
      }).length >= 1
    );
  };

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % Pools.length;
    setItemOffset(newOffset);
  };

  const poolTable = () => {
    if (state.assets.syncing) {
      return (
        <tr>
          <td colSpan={6}>
            <div className="tw-py-7 tw-flex tw-justify-center tw-items-center">
              <span>Syncing...</span>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: 10 }}
              />
            </div>
          </td>
        </tr>
      );
    }

    if (state.web3.networkId === Network.UNKNOWN) {
      return (
        <tr>
          <td colSpan={6}>
            <div className="tw-w-full tw-text-center tw-text-xl tw-font-medium tw-text-coolGray-500 tw-py-5 tw-flex tw-justify-center">
              <div id="tour_no_pool_data" className="tw-inline-block">
                Unsupported Network
              </div>
            </div>
          </td>
        </tr>
      );
    }

    if (currentItems?.length) {
      return currentItems?.map((pool, index) => {
        return (
          <PoolTableRow
            key={pool.address}
            pool={pool}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = cryptocurrencyBackup;
            }}
            poolDetail={getPoolDetail(pool.address)}
            syncing={syncingAPR}
            hasTrigger={isPoolAutomationAlreadyCreated(pool.address)}
            state={state}
            onClick={() => {
              if (
                getPoolErrorMessage(state, pool.network, pool.address) !== ""
              ) {
                withdrawErrorLogAnalysis(pool.network, pool.address);
                return;
              }
              eventAnalysis(
                "add_automation_click",
                TriggerType.WITHDRAW_LIQUIDITY,
                "Add automation button clicked"
              );
              history.push(`/dashboard/automationSetup/${pool.address}`);
            }}
          />
        );
      });
    }

    return (
      <tr>
        <td colSpan={6}>
          <div className="tw-w-full tw-text-center tw-text-xl tw-font-medium tw-text-coolGray-500 tw-py-5 tw-flex tw-justify-center">
            <div id="tour_no_pool_data" className="tw-inline-block">
              No Data
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <div id="tour_pool_position">
        <div className="tw-flex tw-text-3xl tw-text-coolGray-900 tw-font-medium tw-mb-8 px-3 tw-items-center">
          Pool Positions&nbsp;
          <HelpToolTip
            id="help-txn-speed"
            tooltipText={`${
              state.web3.networkId === Network.ETH
                ? "Uniswap V2"
                : "PancakeSwap"
            } based pool positions in your wallet.<br/>If you don’t see your pool position yet, please refresh this page after about 10 minutes.`}
          >
            <img src={HelpIcon} width="24px" />
          </HelpToolTip>
        </div>
        <div className="tw-max-w-full tw-overflow-x-auto tw-px-3 tw-pb-5">
          <table className="tw-rounded-2xl tw-w-full tw-overflow-hidden tw-shadow-lg">
            <thead>
              <tr>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-pl-6 tw-text-left">
                  Pool Name
                  <Sorter
                    currentItems={currentItems}
                    currentSortStateOfProperty={poolNameSortState}
                    setActiveSortProperty={setActiveSortProperty}
                    activeSortProperty={activeSortProperty}
                    setCurrentPools={setCurrentItems}
                    setSortState={setPoolNameSortState}
                    sortProperty={SORT_PROPERTIES.POOL_NAME}
                  />
                  <FiltersBlock
                    updateFilteringOptions={(filteringOptions: FilteringOptions) => {
                      filterPools(filteringOptions);
                      setFilteringOptions(filteringOptions)
                    }}/>
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-text-left">
                  APR
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-text-left">
                  Balance
                  <Sorter
                    currentItems={currentItems}
                    currentSortStateOfProperty={balanceSortState}
                    setActiveSortProperty={setActiveSortProperty}
                    activeSortProperty={activeSortProperty}
                    setCurrentPools={setCurrentItems}
                    setSortState={setBalanceSortState}
                    sortProperty={SORT_PROPERTIES.BALANCE}
                  />
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-text-right">
                  Market value
                  <Sorter
                    currentItems={currentItems}
                    currentSortStateOfProperty={marketValueSortState}
                    setActiveSortProperty={setActiveSortProperty}
                    activeSortProperty={activeSortProperty}
                    setCurrentPools={setCurrentItems}
                    setSortState={setMarketValueSortState}
                    sortProperty={SORT_PROPERTIES.MARKET_VALUE}
                  />
                </th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-text-center"></th>
                <th className="tw-bg-coolGray-200 tw-text-coolGray-500 tw-text-xl tw-font-medium tw-py-2 tw-text-center">
                  Automation
                </th>
              </tr>
            </thead>
            <tbody>
              {state.assets.syncing ? (
                <tr>
                  <td colSpan={6}>
                    <div className="tw-py-7 tw-flex tw-justify-center tw-items-center">
                      <span>Syncing...</span>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        style={{ marginLeft: 10 }}
                      />
                    </div>
                  </td>
                </tr>
              ) : currentItems?.length ? (
                currentItems?.map((pool, index) => (
                  <tr
                    key={pool.address}
                    className="tw-border-b tw-border-coolGray-200"
                  >
                    <td className="tw-w-72 2xl:tw-w-96">
                      <div className="tw-flex tw-items-center tw-ml-6 tw-py-7">
                        <div className="tw-mr-8 tw-flex tw-w-24 md:tw-w-auto">
                          <img
                            src={pool.token0LogoUrl}
                            className="tw-w-10"
                            alt={pool.token0.toUpperCase()}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = cryptocurrencyBackup;
                            }}
                          />
                          <img
                            src={pool.token1LogoUrl}
                            alt={pool.token1.toUpperCase()}
                            className="tw-relative tw--ml-3 tw-mr-3 tw-w-10"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = cryptocurrencyBackup;
                            }}
                          />
                        </div>
                        <div className="tw-flex tw-flex-col">
                          <div className="tw-text-xl tw-font-bold tw-text-coolGray-900">
                            {formatPoolName(pool)}
                          </div>
                          <div className="tw-text-base tw-font-normal tw-text-coolGray-500">
                            {pool.swapType === protocols.TYPE_PANCAKESWAP
                              ? "Pancake Swap"
                              : "Uniswap"}{" "}
                            ({pool.network})
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </td>
                    <td>
                      <div className="tw-text-base tw-font-medium tw-text-blueGray-500">
                        <PoolAPRCol
                          feesPerLiquidity={
                            getPoolDetail(pool.address)?.fees_per_liquidity
                          }
                          syncing={syncingAPR}
                        />
                      </div>
                    </td>
                    <td className="tw-w-40">
                      <div className="tw-text-base tw-font-medium tw-text-green-400">
                        <Tooltip
                          id={`pool-balance`}
                          tooltipText={`${
                            formatBigNumber(
                              pool.balance.length ? pool.balance : "0.00"
                            ).fullCommas
                          } ${pool.symbol}`}
                        >
                          {` ${formatBigNumber(pool.balance).trunc} ${
                            pool.symbol
                          }`}
                        </Tooltip>
                      </div>
                    </td>
                    <td className="tw-w-40 tw-text-right">
                      <div className="tw-flex tw-justify-center tw-items-end tw-flex-col tw-mr-4">
                        <div className="tw-text-base tw-font-medium tw-text-blueGray-500 tw-flex tw-items-center">
                          <Tooltip
                            id={`pool-balance-usd`}
                            tooltipText={`${
                              formatBigNumber(
                                pool.priceUSD.length ? pool.priceUSD : "0.00"
                              ).fullCommas
                            } $`}
                          >
                            {` ${formatBigNumber(pool.priceUSD).trunc} $`}
                          </Tooltip>
                        </div>
                      </div>
                    </td>
                    <td>
                      <ShieldIcon
                        hasTrigger={isPoolAutomationAlreadyCreated(
                          pool.address
                        )}
                        poolName={`${pool.token0.toUpperCase()}-${pool.token1.toUpperCase()}`}
                      />
                    </td>
                    <td className="tw-w-40">
                      <div className="tw-flex tw-gap-3 tw-justify-center">
                        <Tooltip
                          id={`pool-add-automation`}
                          tooltipText={getPoolErrorMessage(
                            state,
                            pool.network,
                            pool.address
                          )}
                        >
                          <div
                            className={`tw-bg-green-300 tw-w-fit tw-px-9 tw-py-3 tw-rounded-full tw-text-base tw-font-semibold tw-shadow-base tw-cursor-pointer 
                          ${
                            getPoolErrorMessage(
                              state,
                              pool.network,
                              pool.address
                            ) !== ""
                              ? "tw-opacity-60"
                              : "tw-cursor-pointer"
                          } `}
                            onClick={() => {
                              if (
                                getPoolErrorMessage(
                                  state,
                                  pool.network,
                                  pool.address
                                ) !== ""
                              ) {
                                withdrawErrorLogAnalysis(
                                  pool.network,
                                  pool.address
                                );
                                return;
                              }
                              eventAnalysis(
                                "add_automation_click",
                                TriggerType.WITHDRAW_LIQUIDITY,
                                "Add automation button clicked"
                              );
                              history.push(
                                `/dashboard/automationSetup/${pool.address}`
                              );
                            }}
                          >
                            Add
                          </div>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="tw-w-full tw-text-center tw-text-xl tw-font-medium tw-text-coolGray-500 tw-py-5 tw-flex tw-justify-center">
                      <div id="tour_no_pool_data" className="tw-inline-block">
                        No Data
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {!state.assets.syncing && (
          <Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
        )}
        <NewTriggerModal
          state={state}
          show={showNew}
          initialStage={Stage.SELECT_AGENT}
          triggerType={TriggerType.WITHDRAW_LIQUIDITY}
          allAgents={state.agents.list()}
          onDone={() => setShowNew(false)}
        />
      </div>
    </>
  );
});
