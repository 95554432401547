import { useEffect, useState } from "react";

const minSlippage = 0.01;
const maxSlippage = 100;

function IsSlippageValid(slippage: number) {
  return slippage <= maxSlippage && slippage >= minSlippage;
}

function getWarningMessage(slippage: number) {
  if (slippage < 1) {
    return "Your transaction may fail";
  }
  if (slippage > 5) {
    return "Hopefully you know what you are doing";
  }
  return "";
}

function getSlippageErrorMessage(slippage: number) {
  if (slippage < minSlippage) {
    return "Slippage tolerance too low";
  }
  if (slippage > maxSlippage) {
    return "Slippage tolerance too high";
  }
  return "Not a valid slippage";
}

export const SlippageInput = ({
  slippage,
  setSlippage,
  slippageMsgType,
  setSlippageMsgType,
}: {
  slippage: number;
  setSlippage: Function;
  slippageMsgType: string;
  setSlippageMsgType: Function;
}) => {
  const [slippageMsg, setSlippageMsg] = useState<string>("");

  useEffect(() => {
    if (!IsSlippageValid(slippage)) {
      setSlippageMsg(getSlippageErrorMessage(slippage));
      setSlippageMsgType("error");
    } else {
      setSlippageMsg(getWarningMessage(slippage));
      setSlippageMsgType(getWarningMessage(slippage).length ? "warning" : "");
    }
  }, [slippage]);

  const handleSlippage = (value: string) => {
    const slippage = parseFloat(value);
    if (!slippage || IsSlippageValid(slippage)) setSlippage(slippage);
  };

  return (
    <>
      <div className="tw-flex tw-rounded-2xl tw-w-full tw-justify-between tw-items-center">
        <div className="tw-text-xl tw-font-medium tw-flex">
          Slippage Tolerance %
        </div>
        <div className="tw-flex tw-justify-end" onClick={() => setSlippage(2)}>
          <span className="tw-bg-green-300 tw-rounded tw-text-coolGray-900 tw-text-xs tw-font-medium tw-px-3 tw-py-1 tw-ml-7 tw-cursor-pointer">
            Auto
          </span>
        </div>
      </div>
      <div className="tw-w-full">
        <input
          className={`tw-text-left tw-w-full tw-bg-white tw-h-12 tw-rounded tw-text-xl tw-font-medium tw-text-coolGray-900 tw-text-left tw-border  tw-my-4 tw-px-3 tw-mb-0 focus:tw-outline-none ${
            slippageMsgType === "error"
              ? "tw-border-red-600"
              : slippageMsgType === "warning"
              ? "tw-border-yellow-600"
              : "tw-border-coolGray-300"
          }`}
          type="number"
          value={slippage}
          onChange={(event) => handleSlippage(event.target.value)}
          placeholder="00.00"
        />
        <div
          className={`tw-w-full tw-h-7 tw-mt-1 tw-text-sm ${
            slippageMsgType === "error"
              ? "tw-text-red-600"
              : "tw-text-yellow-600"
          }`}
        >
          {slippageMsg}
        </div>
      </div>
    </>
  );
};
