import React, { useEffect, useRef, ReactNode } from "react";

export const OutsideClickHandler = ({
  children,
  onOutsideClick,
}: {
  children: ReactNode;
  onOutsideClick: () => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return <div ref={wrapperRef}>{children}</div>;
};
