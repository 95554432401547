import React from "react";
import { Dropdown } from "react-bootstrap";
import style from "./Web3AddressNav.module.scss";
import { Link } from "react-router-dom";
import signOutAlt from "../assets/logout.svg";
import {
  BSC_MAIN_NET_CHAIN_ID,
  BSC_TEST_NET_CHAIN_ID,
  KOVAN_CHAIN_ID,
  MAINNET_CHAIN_ID,
  Network,
  RINKEBY_CHAIN_ID,
} from "../configs";
import Provider from "../services/Provider";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

function formatAddress(address: string): string {
  if (address === "") {
    return "";
  }

  return `${address.substr(0, 6)}...${address.substr(address.length - 6)}`;
}

function formatChainId(chainId: number): string {
  switch (chainId) {
    case MAINNET_CHAIN_ID:
      return "Mainnet";
    case KOVAN_CHAIN_ID:
      return "Kovan";
    case BSC_TEST_NET_CHAIN_ID:
      return "BSC Testnet";
    case BSC_MAIN_NET_CHAIN_ID:
      return "BSC Mainnet";
    case RINKEBY_CHAIN_ID:
      return "Rinkeby";
    default:
      return "Unknown";
  }
}

interface DropDownMenuProps {
  showUnlock?: boolean;
}

function DropDownMenu(props: DropDownMenuProps) {
  return (
    <Dropdown.Menu className={style.DropDownMenu}>
      {props.showUnlock && (
        <>
          <Dropdown.Item
            onClick={Provider.openLogin}
            className={classnames(style.dropDownItem)}
          >
            <img src={signOutAlt} className={style.DropDownIcon} alt="unlock" />
            Unlock
          </Dropdown.Item>
        </>
      )}

      <Link
        data-testid="logout-button"
        to="/logout"
        className={classnames(style.dropDownItem, "dropdown-item")}
      >
        <img src={signOutAlt} className={style.DropDownIcon} alt="logout" />
        Logout
      </Link>
    </Dropdown.Menu>
  );
}

interface ActiveButtonProps {
  address: string;
  chainId: number;
  network: Network;
}

function ActiveButton(props: ActiveButtonProps) {
  return (
    <Dropdown alignRight={false}>
      <Dropdown.Toggle
        data-testid="drop-down-toggle"
        variant="info"
        id="dropdown-basic"
        className={classnames(
          style.UserAccountButton,
          props.network === Network.UNKNOWN ? style.unsupportedColor : ""
        )}
      >
        <div className={style.UserAccountWrapper}>
          <div className={style.UserAddress}>
            {formatAddress(props.address)}
          </div>
        </div>
      </Dropdown.Toggle>

      <DropDownMenu />
    </Dropdown>
  );
}

function NotWeb3SupportButton() {
  return (
    <Dropdown alignRight={false}>
      <Dropdown.Toggle
        variant="default"
        id="dropdown-basic"
        className={style.UserAccountButton}
      >
        <div className={style.UserAccountWrapper}>
          <div className={style.UserAddress}>Link Wallet</div>
        </div>
      </Dropdown.Toggle>

      <DropDownMenu />
    </Dropdown>
  );
}

function LockedWeb3Support() {
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="warning"
        id="dropdown-basic"
        className={style.UserAccountButton}
      >
        <div className={style.UserAccountWrapper}>
          <div className={style.UserAddress}>Wallet is Locked</div>
        </div>
      </Dropdown.Toggle>

      <DropDownMenu showUnlock />
    </Dropdown>
  );
}

interface Web3AddressNavProps {
  address: string;
  chainId: number;
  network: Network;
  web3Unlocked: boolean;
  web3Installed: boolean;
  pathname: string;
}

export const Web3AddressNav = observer((props: Web3AddressNavProps) => {
  if (!props.web3Installed) {
    return <NotWeb3SupportButton />;
  }
  if (!props.web3Unlocked || props.address === "") {
    return <LockedWeb3Support />;
  }
  return (
    <ActiveButton
      address={props.address}
      chainId={props.chainId}
      network={props.network}
    />
  );
});
