/**
 * if string representing number contains e eg 1e5 then we turn it into string without e notation eg
 * input "1e5" returns "100000"
 *
 * @param s
 */
export const normalizeDecimalStringContainingE = (s: string): string => {
  if (s.includes("e")) {
    s = parseFloat(s).toLocaleString("fullwide", {
      useGrouping: false,
    });
  }
  return s;
};
