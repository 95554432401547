import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { default as classNames, default as classnames } from "classnames";
import Decimal from "decimal.js";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { TriggersAPI } from "../api/TriggersAPI";
import { NetworkTypePill } from "../components/NetworkTypePill";
import { NewTriggerModal, Stage } from "../components/NewTriggerModal";
import {
  BSC_MAIN_NET_CHAIN_ID,
  BSC_TEST_NET_CHAIN_ID,
  EthereumInfo,
  getNetworkDetails,
  KOVAN_CHAIN_ID,
  MAINNET_CHAIN_ID,
  Network,
  NetworkParameters,
  PANCAKE_POOLS,
  UNISWAP_POOLS,
} from "../configs";
import { isErr, OkResult } from "../result";
import { toDecimalWithPrecision } from "../services/contracts/PairContract";
import { Costing } from "../services/Costing";
import { Pair } from "../state/pairCache";
import { StateStore } from "../state/state";
import {
  ApprovedBalance,
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerType,
} from "../state/triggers";
import { gweiToWei } from "../utils/currency/GweiToWei";
import { isMobile } from "../utils/display/isMobile";
import { toNonCanonicalDisplay } from "../utils/display/toNonCanonicalDisplay";
import { calcAvailableAgents } from "../utils/misc/calcAvailableAgents";
import { isCurrentAgent } from "../utils/misc/isCurrentAgent";
import { eventNameAnalysis } from "../utils/misc/trackEvent";
import { formatBigNumber } from "../utils/numeric/formatBigNumber";
import Tooltip from "../utils/tooltip/tooltip";
import style from "./TriggersPage.module.scss";

interface TriggerPageProps {
  state: StateStore;
}

function isStateArmedCompletedOrFailed(trigger: Trigger): boolean {
  return (
    trigger.state === TriggerState.COMPLETED ||
    trigger.state === TriggerState.ARMED ||
    trigger.state === TriggerState.FAILED
  );
}

function _selectToken(token: 0 | 1, pair: Pair): [string, number] {
  const tokenName = token === 0 ? pair.token1 : pair.token0;
  const tokenDecimals = token === 0 ? pair.token0Decimals : pair.token1Decimals;
  return [tokenName, tokenDecimals];
}

function isLessThanFifteenSecondsAgo(isoStringTime: string): boolean {
  const FIFTEEN_SECONDS_IN_MILLISECONDS = 1000 * 15;
  const twoMinutesAgo = Date.now() - FIFTEEN_SECONDS_IN_MILLISECONDS;
  return new Date(isoStringTime).getTime() > twoMinutesAgo;
}

function orderTriggersByCurrentNetwork(
  network: Network,
  a: Trigger,
  b: Trigger
): 0 | 1 | -1 {
  const aNetwork = network === a.network;
  const bNetwork = network === b.network;

  if (aNetwork && !bNetwork) {
    return -1;
  }
  if (!aNetwork && bNetwork) {
    return 1;
  }
  return 0;
}

const mostRecentlyCreatedTrigger = (
  triggers: Array<Trigger>
): Trigger | undefined => {
  if (!triggers.length) return undefined;

  return triggers.reduce((prev: Trigger, current: Trigger) =>
    new Date(prev.createdAt).getTime() > new Date(current.createdAt).getTime()
      ? prev
      : current
  );
};

function formatThresholdPrice(
  price: string,
  price0Min: string,
  price0Max: string,
  price1Min: string,
  price1Max: string,
  token: 0 | 1,
  pair: Pair,
  full?: boolean
): string {
  const [name] = _selectToken(token, pair);
  let symbol = "<";
  if (token === TriggerChoices.TOKEN1) {
    if (price1Max !== "" && price1Max !== "null") {
      price = price1Max;
      symbol = ">";
    } else if (price1Min !== "" && price1Min !== "null") {
      price = price1Min;
      symbol = "<";
    }
  } else {
    if (price0Max !== "" && price0Max !== "null") {
      price = price0Max;
      symbol = ">";
    } else if (price0Min !== "" && price0Min !== "null") {
      price = price0Min;
      symbol = "<";
    }
  }
  const p = new Decimal(price);
  if (full) {
    const tokenName = token === 0 ? pair.token0 : pair.token1;
    return `1 ${tokenName} ${symbol}= ${
      formatBigNumber(p.toString()).full
    } ${name}`;
  }
  return `${symbol} ${formatBigNumber(p.toString()).trunc} ${name}`;
}

function formatPairPrice(
  token0Price: string,
  token: 0 | 1,
  pair: Pair,
  full?: boolean
): string {
  const MAX_DECIMAL_ALLOW = 18;
  const [name] = _selectToken(token, pair);
  // invert if necessary
  if (token0Price == "") {
    return "";
  }
  let price = new Decimal(token0Price);
  if (token === 1) {
    price = new Decimal(1).dividedBy(price);
  }

  if (full) {
    const tokenName = token === 0 ? pair.token0 : pair.token1;
    return `1 ${tokenName} = ${
      formatBigNumber(price.toString(), MAX_DECIMAL_ALLOW).full
    } ${name}`;
  }
  return `${formatBigNumber(price.toString()).trunc} ${name}`;
}

function formatPairName(
  pair: Pair,
  network: number,
  chainId: number | undefined
) {
  if (chainId === MAINNET_CHAIN_ID || chainId === BSC_MAIN_NET_CHAIN_ID) {
    const infoUrl = network === Network.ETH ? UNISWAP_POOLS : PANCAKE_POOLS;
    return (
      <a
        className="tw-whitespace-nowrap"
        rel="noreferrer"
        target="_blank"
        href={`${infoUrl}${pair.address}`}
      >
        {pair.token0}-{pair.token1}
      </a>
    );
  }
  return `${pair.token0}-${pair.token1}`;
}

function formatTxHashLink(
  txHash: string | null,
  stateChainId: number | undefined,
  network: Network
) {
  if (txHash === null || stateChainId === undefined) {
    return "";
  }

  let networkDetails;
  if ([MAINNET_CHAIN_ID, BSC_MAIN_NET_CHAIN_ID].includes(stateChainId)) {
    networkDetails =
      network === Network.ETH
        ? (getNetworkDetails(MAINNET_CHAIN_ID) as OkResult<NetworkParameters>)
            .value
        : (
            getNetworkDetails(
              BSC_MAIN_NET_CHAIN_ID
            ) as OkResult<NetworkParameters>
          ).value;
  } else {
    networkDetails =
      network === Network.ETH
        ? (getNetworkDetails(KOVAN_CHAIN_ID) as OkResult<NetworkParameters>)
            .value
        : (
            getNetworkDetails(
              BSC_TEST_NET_CHAIN_ID
            ) as OkResult<NetworkParameters>
          ).value;
  }

  return (
    <a
      href={`${networkDetails.blockExplorerUrls}/tx/${txHash}`}
      className="tw-pl-1"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon
        icon={faExternalLinkAlt}
        className="tw-text-coolGray-900"
      />
    </a>
  );
}

function formatTriggerState(state: TriggerState) {
  switch (state) {
    case TriggerState.DISABLED:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Disabled
        </span>
      );
    case TriggerState.ARMED:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Armed
        </span>
      );
    case TriggerState.TRIGGERING:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Triggering
        </span>
      );
    case TriggerState.COMPLETED:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Completed
        </span>
      );
    case TriggerState.FAILED:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Failed
        </span>
      );
    case TriggerState.PROCESSING:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Processing
        </span>
      );
    case TriggerState.UNKNOWN:
    default:
      return (
        <span className="tw-text-center tw-items-center tw-font-medium">
          Unknown
        </span>
      );
  }
}

function formatTriggerType(triggerType: TriggerType) {
  return (
    <>
      <span
        className={classNames(
          "tw-text-center tw-font-medium",
          triggerType === TriggerType.SWAP ? "Blue" : "Yellow"
        )}
      >
        {triggerType === TriggerType.WITHDRAW_LIQUIDITY ? "Withdraw" : "Swap"}
      </span>
    </>
  );
}

function formatApproveBal(
  approveBalance: ApprovedBalance,
  full?: boolean
): string {
  if (
    approveBalance?.balance === undefined ||
    approveBalance?.balance?.length === 0 ||
    new Decimal(approveBalance.balance).lte(0)
  )
    return "-";
  return full
    ? `${
        formatBigNumber(
          toDecimalWithPrecision(
            approveBalance.balance,
            approveBalance.decimal
          ).toString()
        ).full
      } ${approveBalance.symbol}`
    : `${
        formatBigNumber(
          toDecimalWithPrecision(
            approveBalance.balance,
            approveBalance.decimal
          ).toString()
        ).trunc
      } ${approveBalance.symbol}`;
}

const TriggerTableRow = React.memo(
  ({
    trigger,
    state,
    newTriggerUuidToHighlight,
    setErrorMessage,
  }: {
    trigger: Trigger;
    state: StateStore;
    newTriggerUuidToHighlight: string | undefined;
    setErrorMessage: (error: string) => void;
  }) => {
    const mobile: boolean = isMobile.any();

    const setErrorMessageWithTimeout = (s: string) => {
      setTimeout(() => setErrorMessage(""), 6000);
      setErrorMessage(s);
    };

    const onDelete = async (triggerUuid: string) => {
      if (state.account.user?.token === undefined) {
        setErrorMessageWithTimeout("No user information available");
        return;
      }

      const trigger = state.triggers.get(triggerUuid);
      if (trigger === undefined) {
        setErrorMessage("Unable to lookup trigger");
        return;
      }

      /// check the relavent properties required for deletion exist
      if (
        typeof state?.web3?.address !== "string" ||
        typeof state?.web3?.chainId !== "number"
      ) {
        setErrorMessageWithTimeout("Insufficient user information available");
        return;
      }

      const triggersAPI = new TriggersAPI();
      const gasPriceInWei = gweiToWei(
        state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
      );

      const deleteTriggerResponse = await state.triggers.delete({
        triggersAPI,
        address: state.web3.address,
        token: state.account.user.token,
        chainId: state.web3.chainId,
        uuid: triggerUuid,
        gasPrice: gasPriceInWei,
      });
      if (isErr(deleteTriggerResponse)) {
        setErrorMessageWithTimeout("Unable to delete trigger");
        return;
      }
      eventNameAnalysis("trigger_deleted", {});
      // clear the error message
      setErrorMessageWithTimeout("");
    };

    return (
      <tr
        role="trigger-page-table-row"
        className={classnames(
          newTriggerUuidToHighlight !== undefined &&
            newTriggerUuidToHighlight === trigger.uuid
            ? style.new
            : ""
        )}
      >
        <td className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          {formatTriggerType(trigger.triggerType)}
        </td>
        <td className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5">
          <NetworkTypePill network={trigger.network} />
        </td>
        <td className="tw-whitespace-nowrap tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          {trigger.pair.token0 === "" || trigger.pair.token1 === "" ? (
            <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 0 }} />
          ) : (
            formatPairName(trigger.pair, trigger.network, state.web3.chainId)
          )}
        </td>
        <td className="tw-px-3.5 tw-whitespace-nowrap tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          <Tooltip
            child={
              trigger.pair.price === "" ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ marginLeft: 0 }}
                />
              ) : (
                formatPairPrice(trigger.pair.price, trigger.token, trigger.pair)
              )
            }
            tooltipTextClassName="tool-tip-agent"
            text={formatPairPrice(
              trigger.pair.price,
              trigger.token,
              trigger.pair,
              true
            )}
            hideTooltip={trigger.pair.price === ""}
          />
        </td>
        <td className="tw-px-3.5 tw-whitespace-nowrap tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          <Tooltip
            textClassName="ethereumAddress"
            child={formatThresholdPrice(
              trigger.threshold || "",
              trigger.price0Min || "",
              trigger.price0Max || "",
              trigger.price1Min || "",
              trigger.price1Max || "",
              trigger.token,
              trigger.pair
            )}
            tooltipTextClassName="tool-tip-current-price"
            text={formatThresholdPrice(
              trigger.threshold || "",
              trigger.price0Min || "",
              trigger.price0Max || "",
              trigger.price1Min || "",
              trigger.price1Max || "",
              trigger.token,
              trigger.pair,
              true
            )}
          />
        </td>
        <td className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          <div className="tw-flex tw-items-center">
            {formatTriggerState(trigger.state)}
            {formatTxHashLink(
              trigger.tx_hash,
              state.web3.chainId,
              trigger.network
            )}
          </div>
        </td>
        <td className="tw-px-3.5 tw-whitespace-nowrap tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          {toNonCanonicalDisplay(trigger.gasPrice, EthereumInfo)}
        </td>
        <td className="tw-px-3.5 tw-whitespace-nowrap tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
          <Tooltip
            child={formatApproveBal(trigger.approveBalance)}
            tooltipTextClassName="tool-tip-agent"
            text={formatApproveBal(trigger.approveBalance, true)}
            hideTooltip={formatApproveBal(trigger.approveBalance) === "-"}
          />
        </td>
        <td
          style={{ paddingRight: "24px" }}
          className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900"
          align="left"
        >
          {state.triggers.list().some((t: Trigger) => {
            return Boolean(
              t.uuid === trigger.uuid && t.pendingDeletion === true
            );
          }) ? (
            <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 0 }} />
          ) : !mobile ? (
            <Button
              data-testid="delete-trigger-button"
              variant="info"
              onClick={() => onDelete(trigger.uuid)}
              disabled={
                trigger.pendingDeletion ||
                !state.web3.isReady() ||
                state.web3.networkId !== trigger.network ||
                !isCurrentAgent(
                  state.web3.address,
                  state.agents.get(trigger.agentUuid)
                ) ||
                !isStateArmedCompletedOrFailed(trigger)
              }
            >
              Delete
            </Button>
          ) : (
            ""
          )}
        </td>
      </tr>
    );
  }
);

export const TriggersPage = observer((props: TriggerPageProps) => {
  const { state } = props;
  const [showNew, setShowNew] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [hasAvailableAgents, setHasAvailableAgents] = useState<boolean>(false);
  const mobile: boolean = isMobile.any();

  // if a trigger is pending deletion its uuid is put in this array, and if not pending should be removed
  const [newTriggerUuidToHighlight, setNewTriggerUuidToHighlight] = useState<
    string | undefined
  >(undefined);

  const checkWhichTriggersAreNew = (triggers: Array<Trigger>) => {
    const mostRecent: Trigger | undefined =
      mostRecentlyCreatedTrigger(triggers);

    if (mostRecent === undefined) {
      setNewTriggerUuidToHighlight(undefined);
    } else if (isLessThanFifteenSecondsAgo(mostRecent.createdAt)) {
      setNewTriggerUuidToHighlight(mostRecent.uuid);
    } else {
      setNewTriggerUuidToHighlight(undefined);
    }
  };

  function calcHasAvailableAgents(): boolean {
    return Boolean(
      calcAvailableAgents(
        state.agents.list(),
        state.web3.networkId,
        state.web3.address,
        // @ts-ignore
        state.triggers.agentNonCompletedTriggersCount.bind(state.triggers)
      ).length > 0
    );
  }

  useEffect(() => {
    setHasAvailableAgents(calcHasAvailableAgents());
  }, [
    state.agents,
    state.web3.networkId,
    state.web3.address,
    // @ts-ignore
    state.triggers.agentNonCompletedTriggersCount.bind(this as any),
  ]);

  useEffect(() => {
    const interval = setInterval(
      () => checkWhichTriggersAreNew(state.triggers.list()),
      5000
    );
    return () => {
      clearInterval(interval);
    };
  }, [state.triggers]);

  let tableContents;
  if (state.triggers.syncing) {
    tableContents = (
      <tbody>
        <tr>
          <td
            colSpan={12}
            className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900"
            data-testid="trigger-page-syncing"
          >
            <span>Syncing...</span>
            <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />
          </td>
        </tr>
      </tbody>
    );
  } else {
    const orderTriggers = (a: Trigger, b: Trigger) =>
      orderTriggersByCurrentNetwork(state.web3.networkId, a, b);
    const triggerList = state.triggers.list().sort(orderTriggers);
    tableContents = (
      <tbody>
        {triggerList.length === 0 && (
          <tr>
            <td
              data-testid="empty-triggers-message"
              colSpan={12}
              className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-16 tw-py-2.5 tw-text-coolGray-900"
            >
              No Automations currently configured for this network
            </td>
          </tr>
        )}
        {triggerList.map((t: Trigger, i) => {
          return (
            <TriggerTableRow
              state={state}
              trigger={t}
              newTriggerUuidToHighlight={newTriggerUuidToHighlight}
              setErrorMessage={setErrorMessage}
              key={i}
            />
          );
        })}
      </tbody>
    );
  }

  function toolTipMessage(): string {
    if (!state.web3.isReady()) return "Web3 not ready";

    if (Costing.hasServiceExpired(state)) return "service expired";

    return hasAvailableAgents
      ? "Click here to create a new Trigger"
      : "No available Agents";
  }

  return (
    <>
      <div className="tw-max-w-full tw-px-4 sm:tw-px-6 md:tw-px-8">
        <div className="tw-text-coolGray-90 tw-text-center tw-mb-2.5 tw-flex tw-flex-row tw-justify-between tw-items-center">
          <h1 className="tw-text-5xl tw-leading-9 tw-mb-8 tw-font-Inter tw-font-bold">
            {" "}
            {/* <img className={style.icon} src={triggersLogo} alt="trigger" /> */}
            Automations
          </h1>

          {/* {isMobile.any() ? (
            <Button
              className={`createButton ${style.tiggerBtn}`}
              onClick={() => setShowNew(true)}
              variant="primary"
              disabled={state.web3.networkId === Network.UNKNOWN}
            >
              <img
                src={plus}
                style={{ marginRight: 5, marginBottom: 3 }}
                alt="plus"
              />
              New Trigger
            </Button>
          ) : (
            <Tooltip
              child={
                <Button
                  className="createButton"
                  onClick={() => setShowNew(true)}
                  variant="primary"
                  disabled={
                    !state.web3.isReady() ||
                    Costing.hasServiceExpired(state) ||
                    !hasAvailableAgents
                  }
                >
                  <img
                    src={plus}
                    style={{ marginRight: 5, marginBottom: 3 }}
                    alt="plus"
                  />
                  New Trigger
                </Button>
              }
              tooltipTextClassName="tool-tip-trigger-button"
              textClassName={style.btnContainer}
              text={toolTipMessage()}
            />
          )} */}

          <h1 className="tw-hidden tw-font-bold tw-text-xl tw-my-5">
            Automations
          </h1>
        </div>
        {errorMessage && <Alert variant="warning">{errorMessage}</Alert>}
        <div className="tw-overflow-x-auto tw-overflow-y-hidden">
          <table className="tw-bg-white tw-min-w-full tw-rounded-3xl tw-shadow-md tw-overflow-hidden tw-p-1.5 tw-mb-12">
            <thead>
              <tr className="tw-bg-coolGray-200">
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Type
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Network
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Pair
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Spot Price
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Trigger price
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Trigger state
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Gas Price
                </th>
                <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                  Approved Balance
                </th>
                {!mobile ? (
                  <th className="tw-px-3.5 tw-items-center tw-text-center tw-not-italic tw-font-medium tw-text-base tw-leading-5 tw-h-12 tw-py-2.5 tw-text-coolGray-900">
                    Actions
                  </th>
                ) : (
                  <th />
                )}
              </tr>
            </thead>
            {tableContents}
          </table>
        </div>
        <NewTriggerModal
          state={state}
          show={showNew}
          initialStage={Stage.TRIGGER_INFO}
          allAgents={state.agents.list()}
          onDone={() => setShowNew(false)}
        />
      </div>
    </>
  );
});
