import style from "./loginPage.module.scss";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function ConnectButton(props: {
  onClick: () => Promise<void>;
  inProgress: boolean;
  errorMessage?: string;
}) {
  return (
    <div className="tw-flex tw-justify-center">
      <button
        data-testid="login-button"
        className={`tw-bg-green-300 tw-text-blueGray-900 tw-px-8 tw-py-3 tw-text-xl tw-font-semibold tw-rounded tw-shadow-md tw-mx-auto tw-w-56`}
        onClick={props.onClick}
      >
        {props.inProgress ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <>Connect wallet</>
        )}
      </button>
    </div>
  );
}