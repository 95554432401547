import React from "react";
import { Button, Form } from "react-bootstrap";
import { TriggerType } from "../../state/triggers";
import classnames from "classnames";
import style from "./TriggerInfo.module.scss";

interface TriggerInfoProps {
  triggerType: TriggerType | undefined;
  setTriggerType: (type: TriggerType) => void;
  clearData: () => void;
}

export function TriggerInfo({
  triggerType,
  setTriggerType,
  clearData,
}: TriggerInfoProps) {
  const handleWithdrawClick = () => {
    clearData();
    setTriggerType(TriggerType.WITHDRAW_LIQUIDITY);
  };
  const handleSwapClick = () => {
    clearData();
    setTriggerType(TriggerType.SWAP);
  };
  return (
    <Form.Group>
      <div className={style.typebtncontainer}>
        <Button
          data-testid="withdraw-click-button"
          className={classnames(
            style.typeBtn,
            triggerType === TriggerType.WITHDRAW_LIQUIDITY && style.selected
          )}
          onClick={handleWithdrawClick}
        >
          Withdraw liquidity Trigger
        </Button>
        <Button
          data-testid="swap-click-button"
          className={classnames(
            style.typeBtn,
            triggerType === TriggerType.SWAP && style.selected
          )}
          onClick={handleSwapClick}
        >
          Swap Token Trigger
        </Button>
      </div>
      <div className={style.infoContainer}>
        {triggerType === TriggerType.WITHDRAW_LIQUIDITY && (
          <div>
            Adding a withdraw liquidity trigger instructs an agent to withdraw
            your liquidity pool tokens when the price of one of the tokens in
            the pair falls below the threshold you specify.
          </div>
        )}
        {triggerType === TriggerType.SWAP && (
          <div>
            Adding a swap token trigger instructs an agent to swap your tokens
            when the price of 'your token' or the 'token you want to swap for'
            falls below the threshold you specify.
          </div>
        )}
      </div>
    </Form.Group>
  );
}
