import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ArrowLeftIcon, ArrowRightIcon, SwitchHorizontalIcon,} from "@heroicons/react/outline";
import {Decimal} from "decimal.js";
import {observer} from "mobx-react-lite";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {enumToString, NATIVE_TOKEN_ADDRESS} from "../../../api/common";
import {Costs} from "../../../components/Costs";
import Modal from "../../../components/modal";
import {InputValidation, InvalidReason,} from "../../../components/NewTriggerModal/SelectTokenAndPool";
import {Tooltip} from "../../../components/Tooltip";
import {Automation, TradingStrategy,} from "../../../components/TradingStrategy";
import {getNetworkDetails, mapChainId, Network, PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE,} from "../../../configs";
import {isOk} from "../../../result";
import {FactoryContract} from "../../../services/contracts/Factory";
import {PairContract,} from "../../../services/contracts/PairContract";
import {Agent} from "../../../state/agents";
import {Chain, CryptoToken, HoldingToken} from "../../../state/assets";
import {Pair} from "../../../state/pairCache";
import {StateContext} from "../../../state/state";
import {NewTrigger, Trigger, TriggerChoices, TriggerState, TriggerType,} from "../../../state/triggers";
import {gweiToWei} from "../../../utils/currency/GweiToWei";
import {calcAvailableAgents} from "../../../utils/misc/calcAvailableAgents";
import {calcTotalGasCosts, isNativeTokenSufficient,} from "../../../utils/misc/calcTotalGasCost";
import {isTokenSame} from "../../../utils/misc/isTokenSame";
import {eventAnalysis} from "../../../utils/misc/trackEvent";
import {isPercentageNegative} from "../../../utils/numeric/isPercentageNegative";
import {Validation} from "../../../validation";
import {SwapProgress} from "./SwapProgress";
import {FromTokenBox} from "./fromtokenBox";
import {ToTokenBox} from "./toTokenBox";


export interface ThresholdToken extends CryptoToken {
  validThresholdToken: boolean;
}

const getTokenIndex = (fromToken: string, selectedPair: Pair) => {
  return selectedPair.token0Address.toUpperCase() === fromToken.toUpperCase()
    ? 0
    : 1;
};
const getThresholdTokenIndex = (tokenIndex: 0 | 1) => tokenIndex;



export const SwapTokenWrapper = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="lg:tw-col-span-5 tw-shadow-lg tw-rounded-xl tw-overflow-hidden tw-flex tw-flex-col">
      <div className="tw-bg-coolGray-200 tw-text-coolGray-900 tw-text-xl tw-font-medium tw-pl-7 tw-py-2">
        {title}
      </div>
      {children}
    </div>
  );
};






export const SwapAutomation = observer(() => {
  let params = useParams<{ id: string }>();
  const state = useContext(StateContext);

  const [open, setOpen] = useState(false);
  const [poolValidation, setPoolValidation] = useState<InputValidation>({
    validity: Validation.UNKNOWN,
  });
  const [queryingPool, setQueryingPool] = useState<boolean>(false);
  const history = useHistory();

  const [holdingAssets, setHoldingAssets] = useState<HoldingToken[]>([]);
  const [fromToken, setFromToken] = useState<HoldingToken>();
  const [fromAmount, setFromAmount] = useState<string | undefined>(undefined);

  const [allTokens, setAllTokens] = useState<CryptoToken[]>([]);
  const [toToken, setToToken] = useState<CryptoToken>();
  const [toAmount, setToAmount] = useState<string | undefined>(undefined);

  const [tokenPair, setTokenPair] = useState<ThresholdToken[]>([]);

  // Error states
  const [inputError, setInputError] = useState<boolean>(false);
  const [decimalsExceeded, setDecimalsExceeded] = useState<boolean>(false);

  const [inputToTokenError, setInputToTokenError] = useState<boolean>(false);
  const [decimalsToTokenExceeded, setDecimalsToTokenExceeded] =
    useState<boolean>(false);

  const [thresholdMsg, setThresholdMsg] = useState<string>("");
  const [slippageMsgType, setSlippageMsgType] = useState<string>("");

  // Data we need for API
  const [thresholdToken, setThresholdToken] = useState<ThresholdToken>();
  const [threshold, setThreshold] = useState<number | undefined>(undefined);
  const [selectedPercentage, setSelectedPercentage] = useState(-25);
  const [inputPercentage, setInputPercentage] = useState<number | undefined>(
    undefined
  );
  const [slippage, setSlippage] = useState<number>(2);
  const [txnSpeed, setTxnSpeed] = useState<number>(0);
  const [pair, setPair] = useState<Pair>();
  const [swapTokenIndex, setSwapTokenIndex] = useState<0 | 1 | undefined>();
  const [agent, setAgent] = useState<Agent | undefined>();
  // variable refers to IF custom selected and it is empty ( we can use to disable next step).
  const [emptyCustom, setEmptyCustom] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<NewTrigger | undefined>();

  const checkDisableSwap = () => {
    if (
      inputError ||
      decimalsExceeded ||
      thresholdMsg ||
      slippageMsgType === "error" ||
      poolValidation.validity === Validation.INVALID ||
      !fromAmount ||
      new Decimal(fromAmount).lessThanOrEqualTo(new Decimal("0")) ||
      emptyCustom ||
      !pair ||
      !isNativeBalanceSufficient
    ) {
      return true;
    }
    return false;
  };

  const resetPage = () => {
    setPoolValidation({
      validity: Validation.UNKNOWN,
    });
    setQueryingPool(false);
    setFromAmount("");
    setToAmount("");
    setInputError(false);
    setDecimalsExceeded(false);
    setThresholdMsg("");
    setSlippageMsgType("");
    setThresholdToken(undefined);
    setThreshold(0);
    setSlippage(2);
    setTxnSpeed(100);
    setAgent(undefined);
    fetchToAndFromTokens();
    getHoldingAssets();
    setTokens();
    // a hack since sometimes it needs to wait for other values to be loaded.
    setTimeout(getHoldingAssets, 3000);
  };

  const getAvailableAgents = () => {
    return calcAvailableAgents(
      state.agents.list(),
      state.web3.networkId,
      state.web3.address,
      // @ts-ignore
      state.triggers.agentNonCompletedTriggersCount.bind(state.triggers)
    );
  };

  const handleSwapClick = () => {
    // Get available agent
    eventAnalysis(`button_clicked`, TriggerType.SWAP, "swap button clicked");
    const availableAgents = getAvailableAgents();
    if (availableAgents.length) {
      setAgent(() => availableAgents[0]);
    }

    if (!thresholdToken || !pair) {
      return;
    }
    const thresholdIndex = getTokenIndex(thresholdToken.address, pair);
    const isNegative = isPercentageNegative(
      inputPercentage,
      selectedPercentage
    );
    const tokenIndex = getThresholdTokenIndex(thresholdIndex);

    function limit(threshold: number | undefined, isZero: boolean) {
      if (isZero || threshold === undefined) {
        return "0";
      }

      return threshold.toString();
    }

    const triggerData = {
      network: state.web3.networkId,
      agentUuid: availableAgents[0]?.uuid || "",
      pair: pair,
      threshold: "",
      price0Min: limit(
        threshold,
        Boolean(tokenIndex === TriggerChoices.TOKEN0 && isNegative)
      ),
      price0Max: limit(
        threshold,
        Boolean(tokenIndex === TriggerChoices.TOKEN0 && !isNegative)
      ),
      price1Min: limit(
        threshold,
        Boolean(tokenIndex === TriggerChoices.TOKEN1 && isNegative)
      ),
      price1Max: limit(
        threshold,
        Boolean(tokenIndex === TriggerChoices.TOKEN1 && !isNegative)
      ),
      token: tokenIndex,
      swap_token: swapTokenIndex,
      gasPrice: gweiToWei(txnSpeed.toString()),
      gasAmount: PROFILING_DERIVED_GAS_AMOUNT_ESTIMATE.toString(),
      slippage: slippage,
      amount: !fromAmount ? "0" : fromAmount.toString(),
    };
    setTrigger(triggerData);
    setOpen(true);
  };

  const getArmedTriggersForTokenAndPair = (
    fromToken: string,
    selectedPair: Pair
  ): number => {
    const triggers = state.triggers.list();
    return triggers.filter((trigger: Trigger) => {
      const swap_token =
        trigger.swap_token !== undefined ? trigger.swap_token : trigger.token;
      return Boolean(
        trigger.pair.address.toUpperCase() ===
          selectedPair.address.toUpperCase() &&
          getTokenIndex(fromToken, selectedPair) === swap_token &&
          ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
            trigger.state
          ) &&
          trigger.triggerType === TriggerType.SWAP
      );
    }).length;
  };

  function tokenNotAlreadySelected(
    poolAddress: string,
    triggers: Array<Trigger>,
    pair: Pair
  ): false | string {
    const filtered = triggers.filter((trigger: Trigger) => {
      return Boolean(
        trigger.triggerType === TriggerType.SWAP &&
          trigger.pair.address.toUpperCase() === poolAddress.toUpperCase() &&
          ![TriggerState.COMPLETED, TriggerState.FAILED].includes(
            trigger.state
          ) &&
          swapTokenIndex === trigger.swap_token
      );
    });

    if (filtered.length > 1) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
      });
      // throw new Error(
      //   "We aleady have two triggers associated with this pool should not have been allowed to this stage"
      // );
    }

    if (filtered.length === 0) {
      return false;
    }

    return filtered[0].token === 0 ? pair.token1Address : pair.token0Address;
  }

  const fetchPair = async (fromToken: string, toToken: string) => {
    setPair(undefined);
    setPoolValidation({ validity: Validation.UNKNOWN });

    if (state.web3.networkId === Network.UNKNOWN) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }

    setQueryingPool(true);

    const chainId = state.web3.chainId;
    if (!chainId) {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNSUPPORTED_NETWORK,
      });
      return;
    }
    const networkDetails = getNetworkDetails(chainId);
    let wrappedContractAddress;
    if (isOk(networkDetails)) {
      wrappedContractAddress = networkDetails.value.wrappedNativeTokenAddress;
    }
    if (fromToken === NATIVE_TOKEN_ADDRESS && wrappedContractAddress) {
      fromToken = wrappedContractAddress;
    }
    const pairAddress = await FactoryContract.fetchPair(
      fromToken,
      toToken,
      chainId
    );

    if (pairAddress.success) {
      if (pairAddress.value === "0x0000000000000000000000000000000000000000") {
        setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.INVALID_POOL,
        });
        setQueryingPool(false);
        return;
      }

      const poolInformation = await PairContract.fetchPoolInformation(
        pairAddress.value,
        state.web3.networkId,
        chainId
      );

      if (poolInformation.success) {
        const swapTokenIndex = getTokenIndex(fromToken, poolInformation.value);
        setSwapTokenIndex(swapTokenIndex);
        setPair(poolInformation.value);
        if (
          getArmedTriggersForTokenAndPair(fromToken, poolInformation.value) >= 2
        ) {
          setPoolValidation({
            validity: Validation.INVALID,
            reason: InvalidReason.TWO_TRIGGERS_ON_POOL,
          });
        }
      }
      setQueryingPool(false);
    } else {
      setPoolValidation({
        validity: Validation.INVALID,
        reason: InvalidReason.UNABLE_TO_FETCH_PAIR,
      });
      setQueryingPool(false);
    }
  };

  const [totalCost, setTotalCost] = useState<number>(0);
  const [isNativeBalanceSufficient, setIsNativeBalanceSufficient] =
    useState<boolean>(true);

  useEffect(() => {
    if (state.web3.chainId) {
      const totalCost = calcTotalGasCosts(
        state.assets.allTokens,
        state.web3.chainId,
        txnSpeed,
        state.account.gasPrice
      );
      setTotalCost(totalCost);
    }
  }, [
    state.assets.allTokens.length,
    state.web3.chainId,
    state.account.gasPrice,
    txnSpeed,
  ]);

  useEffect(() => {
    let tokenAmount = "0";
    if (fromToken?.isNative) tokenAmount = fromAmount || "0";
    setIsNativeBalanceSufficient(
      isNativeTokenSufficient(totalCost, holdingAssets, tokenAmount)
    );
  }, [totalCost, fromToken, holdingAssets.length, fromAmount]);

  useEffect(() => {
    state.web3.networkId === Network.BSC ? setTxnSpeed(10) : setTxnSpeed(100);
  }, [state.web3.networkId]);

  function getHoldingAssets() {
    if (!state.web3.chainId) return; //handle error here
    const networkDetails = getNetworkDetails(state.web3.chainId);
    let holdingAssets =
      Network.ETH == mapChainId(state.web3.chainId || 2)
        ? state.assets.holdingTokensETH
        : state.assets.holdingTokensBSC;
    holdingAssets = holdingAssets.map((token) => {
      if (token.address === NATIVE_TOKEN_ADDRESS && isOk(networkDetails)) {
        return {
          ...token,
          address: networkDetails.value.wrappedNativeTokenAddress,
        };
      }
      return token;
    });
    setHoldingAssets([...holdingAssets]);

    if (!holdingAssets.length) {
      history.push("/dashboard");
    }

    const fromTkn = holdingAssets.find(
      (tkn) => tkn.address.toUpperCase() === params.id.toUpperCase()
    );
    if (fromTkn) {
      setFromToken(fromTkn);
    } else {
      setFromToken(holdingAssets[0]);
    }
  }

  // Responsible for updating holding assets and from token
  useEffect(() => {
    getHoldingAssets();
  }, [
    state.assets.holdingTokensETH.length,
    state.assets.holdingTokensBSC.length,
  ]);

  function setTokens() {
    if (!state.web3.chainId) {
      return;
    }
    const wrappedAddress = getNetworkDetails(state.web3.chainId);
    const allAssets = state.assets.allTokens
      .filter(
        (token) =>
          token.chain ===
          enumToString(Chain, mapChainId(state.web3.chainId || 2))
      )
      .map((token) => {
        if (token.address === NATIVE_TOKEN_ADDRESS) {
          if (isOk(wrappedAddress)) {
            return {
              ...token,
              address: wrappedAddress.value.wrappedNativeTokenAddress,
            };
          }
        }
        return token;
      });
    setAllTokens(allAssets);

    if (fromToken && (!toToken || isTokenSame(fromToken, toToken))) {
      setToToken(allAssets.find((tkn) => !isTokenSame(fromToken, tkn)));
    }
  }

  // Responsible for updating to token list and to token
  useEffect(() => {
    setTokens();
  }, [state.assets.allTokens.length, fromToken]);

  // Responsible for updating amounts on change or toggle and for tokenpair for dropdown
  useEffect(() => {
    let toAmount: string = "0";
    if (fromAmount && fromToken && toToken) {
      const numerator = new Decimal(fromAmount).mul(
        new Decimal(fromToken.price)
      );
      toAmount = numerator.div(new Decimal(toToken.price)).toString();
    }
    setToAmount(toAmount);
  }, [fromToken, toToken]);

  function fetchToAndFromTokens() {
    if (fromToken && toToken) {
      const namePrefix = `${fromToken.symbol.toLowerCase()}_${toToken.symbol.toLowerCase()}`;
      eventAnalysis(
        `${namePrefix}_pair`,
        TriggerType.SWAP,
        `Selected token pair ${namePrefix}`
      );
      if (fromToken.address.toUpperCase() !== toToken.address.toUpperCase()) {
        fetchPair(fromToken.address, toToken.address);
      }
    }
  }

  useEffect(() => {
    fetchToAndFromTokens();
  }, [fromToken, toToken]);

  useEffect(() => {
    if (!fromToken || !toToken) return;
    if (pair) {
      let tokenPair;
      let notSelectedToken = tokenNotAlreadySelected(
        pair.address,
        state.triggers.list(),
        pair
      );
      if (notSelectedToken) {
        if (
          notSelectedToken.toUpperCase() === fromToken.address.toUpperCase()
        ) {
          // from-token is not selected in previous triggers as a threshold token
          tokenPair = [
            { ...fromToken, validThresholdToken: true },
            { ...toToken, validThresholdToken: false },
          ];
        } else {
          // to-token is not selected in previous triggers as a threshold token
          tokenPair = [
            { ...fromToken, validThresholdToken: false },
            { ...toToken, validThresholdToken: true },
          ];
        }
      } else {
        // Both From and To tokens are not selected in previous triggers as a threshold token
        tokenPair = [
          { ...fromToken, validThresholdToken: true },
          { ...toToken, validThresholdToken: true },
        ];
      }
      setTokenPair(tokenPair);
    } else {
      setTokenPair([
        { ...fromToken, validThresholdToken: true },
        { ...toToken, validThresholdToken: false },
      ]);
    }
  }, [pair]);

  useEffect(() => {
    const thresholdTkn = tokenPair[0]?.validThresholdToken
      ? tokenPair[0]
      : tokenPair[1];
    setThresholdToken({ ...thresholdTkn, validThresholdToken: true });
  }, [tokenPair]);

  useEffect(() => {
    if (inputPercentage) {
      if (inputPercentage > 0) {
        eventAnalysis(
          `positive_threshold_%`,
          TriggerType.SWAP,
          inputPercentage.toString()
        );
      } else {
        eventAnalysis(
          `negative_threshold_%`,
          TriggerType.SWAP,
          inputPercentage.toString()
        );
      }
    } else {
      if (selectedPercentage > 0) {
        eventAnalysis(
          `positive_threshold_%`,
          TriggerType.SWAP,
          selectedPercentage.toString()
        );
      } else {
        eventAnalysis(
          `negative_threshold_%`,
          TriggerType.SWAP,
          selectedPercentage.toString()
        );
      }
    }
  }, [selectedPercentage, inputPercentage]);

  const swapTokenValues = () => {
    const newToToken = allTokens.find(
      (token) => token.address === fromToken?.address
    );
    const newFromToken = holdingAssets.find(
      (token) => token.address === toToken?.address
    );
    if (newFromToken && newToToken) {
      setFromToken(newFromToken);
      setToToken(newToToken);
    }
  };

  return (
    <div className="tw-px-6 2xl:tw-px-20">
      <h2 className="tw-font-normal tw-text-4xl tw-flex tw-items-center tw-relative">
        <ArrowLeftIcon
          className="tw-w-7 tw-cursor-pointer 2xl:tw-absolute tw-mr-6 tw--left-16"
          onClick={() => history.goBack()}
        />
        Add swap automation
      </h2>
      <div className="tw-text-xl tw-font-semibold tw-mb-4 tw-mt-5">
        Enter an amount
      </div>
      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-11 tw-gap-9 lg:tw-gap-1">
        <FromTokenBox
          setInputToTokenError={setInputToTokenError}
          setDecimalsToTokenExceeded={setDecimalsToTokenExceeded}
          fromToken={fromToken}
          toToken={toToken}
          setToAmount={setToAmount}
          setFromToken={setFromToken}
          fromAmount={fromAmount}
          setFromAmount={setFromAmount}
          tokenList={holdingAssets}
          decimalsExceeded={decimalsExceeded}
          setDecimalsExceeded={setDecimalsExceeded}
          inputError={inputError}
          setInputError={setInputError}
        />
        <div className="lg:tw-col-span-1 tw-flex tw-items-center tw-justify-center">
          {queryingPool ? (
            <div className="tw-bg-green-300 tw-rounded-xl tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center ">
              <FontAwesomeIcon icon={faSpinner} spin={true} />
            </div>
          ) : holdingAssets.find(
              (token) =>
                token.address.toLowerCase() == toToken?.address.toLowerCase()
            ) ? (
            <div
              className="tw-bg-green-300 tw-rounded-xl tw-w-12 tw-h-12 tw-cursor-pointer"
              onClick={() => swapTokenValues()}
            >
              <SwitchHorizontalIcon className="tw-w-full tw-p-2 tw-text-coolGray-900 tw-transform tw-rotate-90 lg:tw-rotate-0" />
            </div>
          ) : (
            <div className="tw-bg-green-300 tw-rounded-xl tw-w-12 tw-h-12">
              <ArrowRightIcon className="tw-w-full tw-p-2 tw-text-coolGray-900 tw-transform tw-rotate-90 lg:tw-rotate-0" />
            </div>
          )}
        </div>
        {fromToken && toToken && (
          <ToTokenBox
            setFromAmount={setFromAmount}
            fromToken={fromToken}
            setToToken={setToToken}
            toToken={toToken}
            fromTokenSymbol={fromToken.symbol}
            toAmount={toAmount}
            tokenList={allTokens.filter(
              (token) =>
                !isTokenSame(fromToken, token) &&
                token.symbol.toLowerCase() != "weth"
            )}
            error={poolValidation}
            fromAmount={fromAmount}
            setToAmount={setToAmount}
            setInputToTokenError={setInputToTokenError}
            setDecimalsToTokenExceeded={setDecimalsToTokenExceeded}
            decimalsToTokenExceeded={decimalsToTokenExceeded}
            inputToTokenError={inputToTokenError}
          />
        )}
      </div>
      <div className="tw-text-xl tw-font-semibold tw-mb-4 tw-mt-11 ">
        Add automation
      </div>
      <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-11 tw-gap-12">
        <TradingStrategy
          automation={Automation.SWAP_AUTOMATION}
          tokens={tokenPair}
          threshold={threshold}
          thresholdToken={thresholdToken}
          setThresholdToken={setThresholdToken}
          setThreshold={setThreshold}
          slippage={slippage}
          setSlippage={setSlippage}
          thresholdMsg={thresholdMsg}
          setThresholdMsg={setThresholdMsg}
          slippageMsgType={slippageMsgType}
          setSlippageMsgType={setSlippageMsgType}
          selectedPercentage={selectedPercentage}
          setSelectedPercentage={setSelectedPercentage}
          inputPercentage={inputPercentage}
          setInputPercentage={setInputPercentage}
        />
        <Costs
          txnSpeed={txnSpeed}
          setTxnSpeed={setTxnSpeed}
          setEmptyCustom={setEmptyCustom}
        />
      </div>
      <div className="tw-mt-11 tw-flex tw-justify-center">
        <Tooltip
          children={
            <div
              className={`tw-bg-green-300 tw-text-xl tw-font-semibold tw-rounded tw-px-16 tw-py-4 tw-inline-block tw-cursor-pointer ${
                checkDisableSwap() ? "tw-opacity-60" : null
              }`}
              onClick={() => !checkDisableSwap() && handleSwapClick()}
            >
              Swap
            </div>
          }
          tooltipText={`Insufficient ${
            state.web3.networkId === Network.BSC ? "BSC" : "ETH"
          } balance to create Automation`}
          hide={isNativeBalanceSufficient}
        />
      </div>
      <Modal
        open={open}
        setOpen={setOpen}
        widthClass="7xl"
        closeOnOutsideClick={false}
      >
        {fromToken && trigger && (
          <SwapProgress
            setOpen={setOpen}
            trigger={trigger}
            fromToken={fromToken}
            agent={agent}
            state={state}
            onComplete={() => {
              resetPage();
            }}
          />
        )}
      </Modal>
    </div>
  );
});
