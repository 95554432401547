import detectEthereumProvider from "@metamask/detect-provider";
import Onboarding from "@metamask/onboarding";
import Web3 from "web3";
import { anErr, anOk, isErr, Result } from "../result";
import { TransactionReceipt } from "web3-core";
import {
  BITSKI_CALLBACK_URL,
  BITSKI_CLIENT_ID,
  getNetworkDetails,
  INFURA_ID,
  NetworkParameters,
} from "../configs";
import { omitObjectProperties } from "../utils/misc/omitObjectProperties";
import WalletConnect from "@walletconnect/web3-provider";
import Torus from "@toruslabs/torus-embed";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Authereum from "authereum/dist";
import { Bitski } from "bitski";

function getChainDataFromChainId(chainId: number): Result<ChainData> {
  const networkDetails = getNetworkDetails(chainId);

  if (isErr(networkDetails)) {
    return networkDetails;
  }

  return anOk(
    omitObjectProperties(
      networkDetails.value,
      "proxyContractAddress",
      "fetchTokenContract",
      "vaultContractAddress",
      "wrappedNativeTokenAddress",
      "withdrawLiquidityProxyContractAddress",
      "swapProxyContractAddress",
      "factoryContractAddress",
      "legacyWithdrawProxyContractAddress",
      "legacySwapProxyContractAddress",
      "routerV2ContractAddress"
    )
  );
}

interface SwitchEthereumChainParameter {
  chainId: string;
}

function toHex(s: string | number): string {
  if (typeof s === "number") {
    return `0x${s.toString(16)}`;
  }

  return `0x${parseInt(s).toString(16)}`;
}

interface ChainData
  extends Omit<
    NetworkParameters,
    | "proxyContractAddress"
    | "fetchTokenContract"
    | "vaultContractAddress"
    | "wrappedNativeTokenAddress"
    | "chainId"
    | "withdrawLiquidityProxyContractAddress"
    | "swapProxyContractAddress"
    | "factoryContractAddress"
  > {
  chainId: string;
}

/**
 * All the MetaMask api logic isolated into this class for ease of understanding/change
 *
 */
export default class Provider {
  static get web3(): Web3 {
    return this._web3;
  }

  public static get onboarding(): Onboarding {
    return this._onboarding;
  }

  public static getProviderOptions() {
    let providerOptions: any = {
      authereum: {
        package: Authereum, // required
      },
      // torus: {
      //   package: Torus,
      // },
    };

    if (BITSKI_CLIENT_ID !== null && BITSKI_CALLBACK_URL !== null) {
      providerOptions["bitski"] = {
        package: Bitski,
        options: {
          clientId: BITSKI_CLIENT_ID, // required
          callbackUrl: BITSKI_CALLBACK_URL, // required
        },
      };
    } else {
      console.warn(
        "Application requires both a Bitski client is and a bitski callback URL"
      );
    }

    if (INFURA_ID !== null) {
      providerOptions["coinbasewallet"] = {
        package: CoinbaseWalletSDK,
        options: {
          appName: "Defi Agents",
          infuraId: INFURA_ID,
        },
      };

      providerOptions["walletconnect"] = {
        package: WalletConnect,
        options: {
          infuraId: INFURA_ID,
        },
      };
    } else {
      console.warn("No infura id given to application");
    }

    return providerOptions;
  }

  /**
   * Fails if chain already default chain in metamask
   *
   * @param chainId
   */
  public static async addChain(chainId: number): Promise<Result<boolean>> {
    const chainDataResult = getChainDataFromChainId(chainId);

    if (isErr(chainDataResult)) {
      return chainDataResult;
    }

    const data = [chainDataResult.value];
    // to hex
    data[0].chainId = toHex(data[0].chainId);

    let hasError = false;
    const message = "";
    await Provider.provider
      .request({ method: "wallet_addEthereumChain", params: data })
      .catch(() => {
        hasError = true;
      });

    if (hasError) {
      return anOk(hasError);
    }
    return anErr(message);
  }

  /**
   * ensure chain already exists in wallet eg kovan or mainnet else will fail.
   *
   * @see {@link https://github.com/ethereum/EIPs/pull/3326/files}
   *
   * @param chainId
   */
  public static async switchChain(chainId: number): Promise<boolean> {
    const param: SwitchEthereumChainParameter = { chainId: toHex(chainId) };

    let hasError = false;
    await Provider.provider
      .request({ method: "wallet_switchEthereumChain", params: [param] })
      .catch(() => {
        hasError = true;
      });

    return hasError;
  }

  /**
   * Must be called before any of the methods in this class are called. Ensure it is very begiing of app initialization.
   *
   */
  public static async init(provider?: any) {
    if (Provider._provider !== null) {
      return;
    }

    if (provider !== undefined) {
      Provider._provider = provider;
    } else {
      try {
        Provider._provider = await detectEthereumProvider();
      } catch (e: any) {
        return;
      }
    }

    // @ts-ignore
    Provider._web3 = new Web3(Provider._provider);
    Provider._onboarding = new Onboarding();
  }

  static get provider() {
    return Provider._provider;
  }

  get onboardingInProgress(): boolean {
    return Provider._onboardingInProgress;
  }

  static _provider: any | null = null;

  static _web3: Web3;

  static _onboardingInProgress: boolean;

  private static _onboarding: Onboarding;

  public static async fetchAccounts() {
    return await Provider.provider.request({ method: "eth_requestAccounts" });
  }

  public static async getBalance(address: string): Promise<Result<string>> {
    try {
      return anOk(await Provider.web3.eth.getBalance(address));
    } catch (error) {
      return anErr("Unable to get balance", error);
    }
  }

  public static async fetchChainId() {
    return Provider.provider.request({ method: "eth_chainId" });
  }

  public static async sendFunds(
    from: string,
    to: string,
    amount: string,
    gasPrice: string
  ): Promise<Result<TransactionReceipt>> {
    try {
      return anOk(
        await Provider._web3.eth.sendTransaction({
          from,
          to,
          value: amount,
          gasPrice,
        })
      );
    } catch (error) {
      return anErr("Unable to send funds", error);
    }
  }

  public static async openLogin() {
    if (Provider._provider !== null) {
      await Provider.init();
    }

    Provider.provider.enable();
  }

  public static safeAddress(accounts: any): string | undefined {
    if (accounts.length > 0) {
      return this.web3.utils.toChecksumAddress(accounts[0] as string);
    }
    return undefined;
  }

  /**
   * Is web3 defined, imperfect proxy for checking if metamask unlocked
   *
   * https://ethereum.stackexchange.com/questions/27366/how-can-i-check-if-a-user-is-logged-in-to-metamask
   *
   * Wb3 is injected by Metamask, so if it is not
   * defined we take this to mean that metamask is not installed
   *
   * @returns {boolean}  is metamask unlocked?
   */
  public static async isMetaMaskUnlocked(): Promise<boolean> {
    if (Provider._provider === null) {
      return false;
    }
    const accounts = await Provider._web3.eth.getAccounts();
    return Boolean(Array.isArray(accounts) && accounts.length !== 0);
  }

  /**
   * Is MetaMask browser extension installed and enabled on client
   *
   * @returns {Promise<boolean>} is MetaMask installed and enabled on client
   */
  public static async isMetaMaskInstalled(): Promise<boolean> {
    let detected: boolean;
    try {
      // null we return as false
      detected = !!(await detectEthereumProvider());
    } catch (err) {
      detected = false;
    }

    return detected;
  }

  /**
   * Request MetaMask accounts and set callbacks for if accounts are added or removed via MetaMask.
   * The main initiator-type logic of connection.
   *
   * @returns {void} void
   * @param callback to execute when new account added
   */
  public static async addMetaMaskAccountEventListeners(
    callback: (newAccounts: Array<string>) => void
  ): Promise<void> {
    Provider.provider.on("accountsChanged", callback);
  }

  public static async addMetaMaskChainIdEventListeners(
    setChainIdCallback: (chainId: string) => void
  ): Promise<void> {
    const chainId = await Provider.provider.request({ method: "eth_chainId" });
    setChainIdCallback(chainId);
    Provider.provider.on("chainChanged", setChainIdCallback);
  }

  public static stopOnboarding(): void {
    Provider._onboarding.stopOnboarding();
  }
}
