import { Network } from "../configs";

import { Decimal } from "decimal.js";
import { CollectionStore } from "./utils/collectionStore";
import { makeObservable } from "mobx";

export interface Tokens {
  token0: string; // the name of token 0
  token1: string; // the name of token 1
  token0Decimals: number; // the number of decimals of token 0
  token1Decimals: number; // the number of decimals of token 1
  token0Address: string; // the address of token 0
  token1Address: string; // the address of token 1
}

export type DecimalStr = string;

export enum PairInterfacesTypes {
  PAIR = "PAIR",
  PARTIAL_PAIR = "PARTIAL_PAIR",
  NON_EXISTANT_PAIR = "NON_EXISTANT_PAIR",
}

export interface Pair extends Tokens {
  type?: PairInterfacesTypes;
  address: string; // the contract address
  price: DecimalStr;
  liquidity?: Decimal;
  network: Network;
}

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
/**
 * When we fetch a pair we can store here.
 *
 * If we do not find pair we can also save this data here to save checking again.
 */

interface NonExistantPair
  extends Pick<Pair, "token0" | "token1" | "network" | "address"> {
  type: PairInterfacesTypes.NON_EXISTANT_PAIR;
}

/**
 * this part of the data can be stored when pair is found but not pool information
 */
interface PartialPair
  extends Pick<Pair, "token0" | "token1" | "network" | "address"> {
  type: PairInterfacesTypes.PARTIAL_PAIR;
}

/**
 * non existant pair has no address so for this we add a work around consisting of the two tokens joined together.
 *
 * we can then use this to prove the negative ie to check if a pair is not worth looking for.
 *
 * to get a unique key we also add the network to the address or they may only be unique per network.
 */
function AddressFromTokens(token0: string, token1: string, network: Network) {
  return `${token0}${token1}${network}`;
}

export class PairCache extends CollectionStore<
  Pair | NonExistantPair | PartialPair
> {
  constructor() {
    super("address");
    makeObservable(this, {});
  }

  /**
   * allows caller to determine if result is a pair on nonexistantPair by this simple testing function.
   *
   * @param subject
   */
  static PairType(
    subject: PartialPair | Pair | NonExistantPair
  ): PairInterfacesTypes {
    if (
      !subject.hasOwnProperty.call(subject, "type") ||
      subject.type === PairInterfacesTypes.PAIR
    ) {
      return PairInterfacesTypes.PAIR;
    }

    if (subject.type === PairInterfacesTypes.PARTIAL_PAIR) {
      return PairInterfacesTypes.PARTIAL_PAIR;
    }

    // last option
    return PairInterfacesTypes.NON_EXISTANT_PAIR;
  }

  findByAddress(address: string): Pair | NonExistantPair | PartialPair | null {
    const found1 = this.items.get(address);

    if (found1) {
      return found1;
    }

    return null;
  }

  findByTokens(
    token0: string,
    token1: string,
    network: Network
  ): Pair | NonExistantPair | PartialPair | null {
    // we check with addresses in both orders.
    const address1 = AddressFromTokens(token0, token1, network);
    const address2 = AddressFromTokens(token1, token0, network);

    const found1 = this.items.get(address1);

    if (found1) {
      return found1;
    }

    const found2 = this.items.get(address2);

    if (found2) {
      return found2;
    }

    return null;
  }

  /**
   * Add pair to cache or overwrite if already existing in cache
   *
   * @param data
   */
  add(
    data:
      | Pair
      | PartialBy<NonExistantPair, "address">
      | PartialBy<PartialPair, "address">
  ): boolean {
    let success = false;

    let found;
    if (!data.address) {
      found = this.findByTokens(data.token0, data.token1, data.network);
      const address = AddressFromTokens(data.token0, data.token1, data.network);
      data.address = address;
    } else {
      found = this.findByAddress(data.address);
    }

    if (found !== undefined) {
      this.items.set(data.address, data as Pair | NonExistantPair);
      return true;
    } else {
      success = super.add(data as Pair | NonExistantPair);
    }
    return success;
  }
}
