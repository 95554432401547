export const confirmChallenge = `mutation ConfirmChallenge($req: ChallengeConfirmRequest!) {
    confirmChallenge(req: $req) {
      access
      refresh
    }
  }`;

export const createChallenge = `mutation CreateChallenge($req: ChallengeRequest!) {
    createChallenge(req: $req) {
    challenge
  }
  }`;

export const refreshToken = `mutation Refresh($req: RefreshRequest!) {
    refresh(req: $req) {
      access
      refresh
    }
  }`;
