import { StateStore } from "../state/state";
import { AuthenticationAPI } from "./AuthenticationAPI";
import { TriggersAPI } from "./TriggersAPI";
import { AccountsAPI } from "./AccountsAPI";
import { AgentAPIResult, AgentsAPI } from "./AgentsAPI";
import Provider from "../services/Provider";
import { anErr, isErr, isOk, OkResult, Result } from "../result";
import {
  Trigger,
  TriggerChoices,
  TriggerState,
  TriggerStore,
  TriggerType,
} from "../state/triggers";
import ERC20Contract from "../services/contracts/ERC20Contract";
import { AccessToken } from "../state/token";
import { AgentStore } from "../state/agents";
import { getNetworkDetails, Network, protocols } from "../configs";
import { AccountStore, UserDetails } from "../state/account";
import { getBalance } from "../utils/networkRequests/getBalance";
import { getGasPrices } from "../utils/networkRequests/getGasPrice";
import { logout } from "../utils/store/logout";
import { fetchCorrectTokenContractAddress } from "../utils/configs/fetchCorrectTokenContractAddress";
import { TokenAPI } from "./Tokens";
import Decimal from "decimal.js";
import { toDecimalWithPrecision } from "../services/contracts/PairContract";
import { apiChainIdToNetwork, BNB_ID, ETH_ID } from "./common";
import { CryptoToken } from "../state/assets";

interface AccountWithToken extends AccountStore {
  user: UserDetails;
}

export function clearState(store: StateStore) {
  if (store.account.user === undefined) {
    // as last resort we reload page
    window.location.reload();
    return;
  }

  store.agents.chainSwitched(store.account.user.token);
  store.triggers.chainSwitched(store.account.user.token, store.web3.networkId);
}

/**
 * main action of function is updating agents
 *
 * @param token
 * @param agents
 */
export async function updateAgents(
  token: AccessToken,
  agents: AgentStore,
  clearAgents = false
): Promise<Result<AgentAPIResult[]>> {
  const { web3 } = Provider;

  let buildAgent;
  const builtAgents = [];

  const fetchedAgents = await AgentsAPI.fetchAgents(token);

  if (isErr(fetchedAgents)) {
    return fetchedAgents;
  }

  for (const agent of (fetchedAgents as OkResult<AgentAPIResult[]>).value) {
    const balance = await getBalance(agent.agentAddress, web3);

    if (isErr(balance)) {
      agents.syncing = false;
      return anErr(balance.error.message);
    }

    buildAgent = AgentsAPI.buildAgent(agent, balance.value);
    builtAgents.push(buildAgent);
  }

  // the reason that this is here is that it is then immediately before setting new agents to stop delay awaiting network requests for balance
  if (clearAgents) {
    agents.clear();
  }

  agents.overWrite(builtAgents);

  return fetchedAgents;
}

/**
 * main action of function is updating agents
 *
 * @param token
 * @param agents
 */
export async function updateTriggers(
  token: AccessToken,
  triggerStore: TriggerStore,
  networkId: Network,
  clearTriggers = false
) {
  const triggers = triggerStore.list();

  const triggersAPI = new TriggersAPI();
  const fetchedTriggers = await triggersAPI.fetchTriggers(
    token,
    triggers,
    networkId
  );

  if (isErr(fetchedTriggers)) {
    return;
  }

  if (clearTriggers) {
    triggerStore.clear();
  }

  triggerStore.overWrite((fetchedTriggers as OkResult<Trigger[]>).value);

  if (triggerStore.syncing) {
    triggerStore.syncing = false;
  }
}

/**
 *
 *
 * @param account
 * @returns should we continue loop ( if false then it already logged us out)
 */
 async function tokenLogic(state: StateStore) {
  const account = state.account as AccountWithToken;

  // if we an account user but we do not have a token trigger a logout or if token expired
  if (
    account.user.token === undefined ||
    account.user.token.isAlmostExpired()
  ) {
    logout(state);
    return false;
  }

  if (
    account.user.token.isAlmostExpired() &&
    !account.user.token.isRenewable()
  ) {
    logout(state);
    return false;
  }

  if (account.user.token.isRenewable()) {
    const nextToken = await AuthenticationAPI.getRefreshToken(
      account.user.token
    );
    if (nextToken === undefined) {
      console.log("WARN: Unable to refresh authentication token");
      logout(state);
      return false;
    }

    account.updateAccessToken(nextToken);
  }

  return true;
}

export const fetchHoldingAssets = async (
  state: StateStore,
  callback: Function | undefined = undefined
) => {  
  // fetch holding token and pool details
  if (
    state.web3.address !== undefined &&
    state.account.user &&
    state.web3.chainId
  ) {
    const holdingAssets = await TokenAPI.fetchHoldingAssets(
      state.account.user.token.accessToken,
      state.web3.address,
      state.assets.allTokens,
      state.web3.chainId
    );
    if (isOk(holdingAssets)) {
      state.assets.holdingTokensBSC = holdingAssets.value.Tokens.BSC;
      state.assets.holdingTokensETH = holdingAssets.value.Tokens.ETH;
      state.assets.holdingPoolsUniswap = holdingAssets.value.Pairs.UNISWAP;
      state.assets.holdingPoolCake = holdingAssets.value.Pairs.CAKE;
      state.assets.currentMarketValueUSD =
        holdingAssets.value.currentMarketValueUSD;
      callback && callback();
    }
  }
};

const calculateSumOfTriggerBalUsd = async (
  triggers: Trigger[],
  state: StateStore,
  chainId: number | undefined,
  allTokens: CryptoToken[]
) => {
  let assetsCurrentlyMonitored = new Decimal("0");
  if (chainId === undefined) {
    return assetsCurrentlyMonitored.toString();
  }
  for (const trigger of triggers) {
    if (trigger.triggerType === TriggerType.SWAP) {
      // get swap token address from trigger
      const swapTokenAddress =
        trigger.swap_token === TriggerChoices.TOKEN0
          ? trigger.pair.token0Address
          : trigger.pair.token1Address;

      let swapToken;
      const wrappedTokenAddress = getNetworkDetails(chainId);
      if (isOk(wrappedTokenAddress)) {
        if (
          wrappedTokenAddress.value.wrappedNativeTokenAddress.toUpperCase() ===
          swapTokenAddress.toUpperCase()
        ) {
          const nativeAddress =
            apiChainIdToNetwork(chainId) === Network.ETH ? ETH_ID : BNB_ID;
          const nativeTokenDetails = allTokens.find(
            (token) => token.id === nativeAddress
          );
          if (nativeTokenDetails) {
            swapToken = {
              ...nativeTokenDetails,
              address: swapTokenAddress,
            };
          }
        } else {
          swapToken = allTokens.find(
            (token) =>
              token.address.toUpperCase() === swapTokenAddress.toUpperCase()
          );
        }
      } else {
        // find it on 1000 tokens to get usd price
        swapToken = state.assets.allTokens.find(
          (token) =>
            token.address.toUpperCase() === swapTokenAddress.toUpperCase()
        );
      }
      if (swapToken) {
        // amount*USD value of swapToken
        const amount = toDecimalWithPrecision(
          trigger.approveBalance.balance,
          Number(trigger.approveBalance.decimal)
        ).toString();
        const swapTokenBalanceUsd = new Decimal(amount).mul(swapToken.price);
        assetsCurrentlyMonitored =
          assetsCurrentlyMonitored.add(swapTokenBalanceUsd);
      }
    } else {
      const lpPoolAddress = trigger.pair.address;
      if (state.web3.address && state.web3.chainId) {
        const pool = await TokenAPI.getPoolDetails(
          lpPoolAddress,
          state.assets.allTokens,
          state.web3.address,
          state.web3.chainId,
          protocols.TYPE_UNISWAP
        );
        if (pool) {
          const amount = toDecimalWithPrecision(
            trigger.approveBalance.balance,
            Number(trigger.approveBalance.decimal)
          ).toString();
          const lpPoolBalanceUsd = new Decimal(amount).mul(pool.priceUSD);
          assetsCurrentlyMonitored =
            assetsCurrentlyMonitored.add(lpPoolBalanceUsd);
          // amount*lp_unit_price
        }
      }
    }
  }
  return assetsCurrentlyMonitored.toString();
};

const fetchAssetsCurrentlymonitored = async (state: StateStore) => {
  // filter triggers that are not failed, completed, deleted or disabled
  const filteredTriggers = state.triggers
    .list()
    .filter(
      (trigger) =>
        trigger.state !== TriggerState.COMPLETED &&
        trigger.state !== TriggerState.FAILED &&
        trigger.state !== TriggerState.DISABLED
    );

  const assetsCurrentlyMonitored = await calculateSumOfTriggerBalUsd(
    filteredTriggers,
    state,
    state.web3.chainId,
    state.assets.allTokens
  );
  return assetsCurrentlyMonitored;
};

const fetchAssetsProtected = async (state: StateStore) => {
  // filter triggers that are completed
  const filteredTriggers = state.triggers
    .list()
    .filter((trigger) => trigger.state === TriggerState.COMPLETED);
  const assetsProtected = await calculateSumOfTriggerBalUsd(
    filteredTriggers,
    state,
    state.web3.chainId,
    state.assets.allTokens
  );
  return assetsProtected;
};

/**
 * @returns is token good (possibly with a refresh)
 */
export async function slowLaneStateSync(state: StateStore): Promise<void> {
  if (state.account.user === undefined) {
    return;
  }

  if (!(await tokenLogic(state))) {
    return;
  }

  const { token } = state.account.user;

  if (typeof state.web3.address === "string") {
    const planDetails = await AccountsAPI.getPlanDetails(token);

    if (isOk(planDetails)) {
      state.account.setPlanDetails(planDetails.value);
    }
  }

  const userDetails = await AccountsAPI.accountDetails(token);

  if (isOk(userDetails)) {
    state.account.setUserDetails(userDetails.value);
  }
}

/**
 * This function is for state that needs to be synced more frequently
 *
 *  @param state
 */
export async function fastLaneStateSync(state: StateStore): Promise<void> {
  if (state.account.user === undefined) {
    return;
  }

  if (!(await tokenLogic(state))) {
    return;
  }

  // fetch holding assets
  await fetchHoldingAssets(state);
  // fetch holding assets Currently Monitored
  state.assets.assetsCurrentlyMonitored = await fetchAssetsCurrentlymonitored(
    state
  );
  // fetch holding assets Protected
  state.assets.assetsProtected = await fetchAssetsProtected(state);

  const fetPriceResult = await AccountsAPI.fetchFetPrice();

  if (isOk(fetPriceResult)) {
    state.account.fetPrice = `${fetPriceResult.value.price}`;
  }

  // update the metamask state (it is unlocked etc)
  const metaMaskUnlocked = await Provider.isMetaMaskUnlocked();
  const metaMaskInstalled = await Provider.isMetaMaskInstalled();

  if (state.web3.installed !== metaMaskInstalled) {
    state.web3.setInstalled(metaMaskInstalled);
  }

  if (state.web3.loggedIn !== metaMaskUnlocked) {
    await state.web3.setLoggedIn(
      metaMaskUnlocked,
      clearState.bind(null, state)
    );
  }

  // If we have got this far then we know that we have a valid user logged into the system and that we have an active
  // access token for the user.
  const { token } = state.account.user;

  // we check again the token status since it may have been deleted by this stage of the loop.
  if (
    state.account.user.token === undefined ||
    state.account.user.token.isAlmostExpired()
  ) {
    logout(state);
    return;
  }

  const preferences = await AccountsAPI.fetchPreferences(token);

  if (isOk(preferences)) {
    state.preferences.userPreferences = preferences.value;
  } else {
    state.preferences.userPreferences = undefined;
  }

  // update the user details
  const userDetails = await AccountsAPI.accountDetails(token);
  const addresses = await AccountsAPI.fetchAddresses(token);

  if (isOk(addresses)) {
    state.account.addresses = addresses.value;
  }

  if (isOk(userDetails)) {
    state.account.setUserDetails(userDetails.value);
  }

  if (typeof state.web3.address === "string") {
    const planDetails = await AccountsAPI.getPlanDetails(token);

    if (isOk(planDetails)) {
      state.account.setPlanDetails(planDetails.value);
    }
    const gasPricesRes = await getGasPrices(state.web3.networkId);

    if (isOk(gasPricesRes)) {
      state.eth.setGasPrices(gasPricesRes.value);
    }

    // early return. all operations below this point require web3 address else should be put above this unless they require a particular other reason to go below this point
    if (typeof state.web3.address !== "string") {
      return;
    }

    await updateTriggers(token, state.triggers, state.web3.networkId);

    const fetchedAgents = await updateAgents(token, state.agents);

    if (isOk(fetchedAgents)) {
      // check for deleted agents to remove from state if deleted outside of this front end.
      // if we don't get it in our request for all agents then it must have been deleted
      const agentsInState = state.agents.list();

      for (let i = 0; i < agentsInState.length; i++) {
        if (
          !fetchedAgents.value.some(
            (t: AgentAPIResult) => t.uuid === agentsInState[i].uuid
          )
        ) {
          // it must have been deleted in api
          state.agents.remove(agentsInState[i].uuid);
        }
      }

      if (state.agents.syncing) {
        state.agents.syncing = false;
      }
    }

    const fetchTokenContract = fetchCorrectTokenContractAddress(
      state.web3.chainId
    );

    const balance = await ERC20Contract.getBalance(
      state.web3.address,
      fetchTokenContract
    );

    if (isOk(balance)) {
      state.account.fetBalance = balance.value;
    }
  }
}
