import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { ENVIRONMENT_CONFIG, Network } from "../configs";
import { LocalStorage } from "./localStorage";
import Provider from "../services/Provider";

export class Web3Store {
  localStorage: LocalStorage;

  private clear: () => void;

  get installed(): boolean {
    return this._installed;
  }

  get chainId(): number | undefined {
    return this._chainId;
  }

  get networkId(): Network {
    return this._networkId;
  }

  @action
  setInstalled(value: boolean) {
    this._installed = value;
  }

  get loggedIn(): boolean {
    return this._setLoggedIn;
  }

  @computed
  isReady(): boolean {
    return (
      this.installed &&
      this.loggedIn &&
      this.address !== undefined &&
      this._chainId !== undefined
    );
  }

  @action
  async setLoggedIn(value: boolean, chainChanged: () => void) {
    // if we are just being logged in then we set some event listeners on metamask and associated data
    if (!this._setLoggedIn && value) {
      const accounts = await Provider.fetchAccounts();
      const address = Provider.safeAddress(accounts);
      const chainId = await Provider.fetchChainId();

      runInAction(() => {
        this._address = address;
        this.setChainId(chainId, chainChanged);
      });

      await Provider.addMetaMaskAccountEventListeners(
        (newAccounts: Array<string>) => {
          runInAction(() => this.setAddress(newAccounts));
          this.clear();
        }
      );

      await Provider.addMetaMaskChainIdEventListeners((chainId: string) => {
        runInAction(() => this.setChainId(chainId, chainChanged));
        this.clear();
      });
    }

    runInAction(() => {
      this._setLoggedIn = value;
    });
  }

  get address(): string | undefined {
    return this._address;
  }

  @action
  setAddress(accounts: Array<string>) {
    this._address = Provider.safeAddress(accounts);
  }

  @action
  setChainId(value: string, chainChanged: () => void) {
    const nextChainId = parseInt(value);
    const hasChainChanged =
      this._chainId !== undefined && this._chainId !== nextChainId;

    this._chainId = nextChainId;
    if (nextChainId === ENVIRONMENT_CONFIG.bscChainId) {
      this._networkId = Network.BSC;
    } else if (nextChainId === ENVIRONMENT_CONFIG.ethChainId) {
      this._networkId = Network.ETH;
    } else {
      this._networkId = Network.UNKNOWN;
    }

    if (hasChainChanged) {
      chainChanged();
    }
  }

  initialised = false;

  // is metamask browser extension logged in
  private _setLoggedIn = false;

  // is metamask browser extension installed
  private _installed = false;

  private _address?: string;

  private _chainId?: number;

  private _networkId: Network = Network.UNKNOWN;

  constructor(localStorage: LocalStorage, clear: () => void) {
    makeAutoObservable(this);
    this.localStorage = localStorage;
    this.clear = clear;
  }
}
