import { getNetworkDetails, mapChainId, Network } from "../../configs";
import { NATIVE_TOKEN_ADDRESS } from "../../api/common";
import { anErr, anOk, isOk, Result } from "../../result";
import { HoldingToken } from "../../state/assets";
import { StateStore } from "../../state/state";

export const findHoldingAssets = (
  state: StateStore
): Result<Array<HoldingToken>> => {
  if (!state.web3.chainId) {
    return anErr("no chain id");
  }

  const networkDetails = getNetworkDetails(state.web3.chainId);

  let holdingAssets =
    Network.ETH == mapChainId(state.web3.chainId || 2)
      ? state.assets.holdingTokensETH
      : state.assets.holdingTokensBSC;
  holdingAssets = holdingAssets.map((token) => {
    if (token.address === NATIVE_TOKEN_ADDRESS && isOk(networkDetails)) {
      return {
        ...token,
        address: networkDetails.value.wrappedNativeTokenAddress,
      };
    }
    return token;
  });
  return anOk(holdingAssets);
};
