import React, { ReactElement } from "react";
import { getNetworkDetails } from "../configs";
import { isErr } from "../result";

interface EtherScanLinkProps {
  address: string;
  text?: string;
  className?: string;
  tx?: boolean;
  chainId: number | undefined;
}

export function EtherScanLink(props: EtherScanLinkProps): ReactElement {
  if (props.chainId === undefined) {
    return <>{props.address}</>;
  }

  const networkDetails = getNetworkDetails(props.chainId);

  if (isErr(networkDetails) || !networkDetails.value.blockExplorerUrls.length) {
    return <>{props.address}</>;
  }

  return (
    <a
      href={`${networkDetails.value.blockExplorerUrls[0]}/${
        props.tx ? "tx" : "address"
      }/${props.address}`}
      className={props.className}
      target="_blank"
      rel="noopener noreferrer"
      data-testid="etherscanLink"
    >
      {props.text ?? props.address}
    </a>
  );
}
