import { API_URL } from "../configs";
import { AccessToken } from "../state/token";
import { anErr, anOk, Result } from "../result";
import { AccountDetails, PlanDetails } from "../state/account";
import {
  AccountEvent,
  AccountEventTypes,
} from "../pages/accounts/AccountsPage";
import { gql } from "@apollo/client";
import client from "./client";
import {
  fundsWithdraw,
  accountDetailsQuery,
  accountEventsQuery,
  addressesQuery,
  planDetails,
} from "./AccountsQueries";

 type ISOString = string;

export interface AccountEventAPIResponseItem {
  id: 3;
  event: AccountEventTypes;
  amount: string | number | null;
  details: string;
  tx: string;
  timestamp: ISOString;
}

 interface AccountEventAPIResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<AccountEventAPIResponseItem>;
}

type AccountEventData = Overwrite<
  AccountEventAPIResponse,
  {
    results: Array<AccountEvent>;
  }
>;

 interface AccountDetailsAPIResponse {
  agentLimit: number;
  balance: string;
  createdAt: string;
}

export interface PlanDetailsApiResponse {
  name: string;
  agentSecondRate: string;
}

interface FetPriceApiResponse {
  price: number;
}

interface PaginationParams {
  page: number;
  pageSize: number;
}

interface AddressItem {
  address: string;
}

 interface WithdrawParams {
  token: AccessToken;
  agentUuid: string;
  amount: string;
  address: string;
  gasPrice: string;
  deleteOnWithdraw?: boolean;
}

export interface UserPreferences {
  email: string;
  notify_low_balance: boolean;
  notify_service_expiry: boolean;
  notify_trigger_status: boolean;
  email_confirmed: boolean;
}

export class AccountsAPI {
  private static buildPlanDetails(
    response: PlanDetailsApiResponse
  ): PlanDetails {
    return {
      agentSecondPrice: response.agentSecondRate,
      name: response.name,
    };
  }

  private static buildAccountDetails(
    accountDetailsAPIResponse: AccountDetailsAPIResponse
  ): AccountDetails {
    return {
      balance: accountDetailsAPIResponse.balance,
      agentLimit: accountDetailsAPIResponse.agentLimit,
    };
  }

  private static buildEvent(event: AccountEventAPIResponseItem): AccountEvent {
    // they are almost the same.
    return {
      amount: event.amount === null ? "0" : event.amount.toString(),
      details: event.details,
      event: event.event,
      timestamp: new Date(event.timestamp),
      tx: event.tx,
    };
  }

  static async withdraw({
    token,
    agentUuid,
    amount,
    address,
    gasPrice,
    deleteOnWithdraw,
  }: WithdrawParams): Promise<Result<string>> {
    try {
      const { data, errors } = await client.mutate({
        mutation: gql(fundsWithdraw),
        variables: {
          agentFundsWithdrawId: agentUuid,
          input: {
            amount,
            address,
            gasPrice,
            deleteAgent: deleteOnWithdraw === true,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (!errors) return anOk(agentUuid);
      const createAccountError = errors ? errors[0].message : "unknown error";
      return anErr("Failed to withdraw with error: ", createAccountError);
    } catch (e) {
      return anErr("Unable to withdraw: GraphQL Error");
    }
  }

  static async registerEthereumAddress(
    address: string,
    token: AccessToken
  ): Promise<Result<void>> {
    const config = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
      body: JSON.stringify({
        address,
      }),
    };

    try {
      const response = await fetch(`${API_URL}/account/address/`, config);
      if (response.status !== 201) {
        return anErr("Unable to register user address");
      }
      return anOk(undefined);
    } catch (e) {
      return anErr("Failed to register user address", e);
    }
  }

  static async accountDetails(
    token: AccessToken
  ): Promise<Result<AccountDetails>> {
    try {
      const { data, errors } = await client.query({
        query: gql(accountDetailsQuery),
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to query account details: ${
            errors[0].message ?? "unknown error"
          }`
        );

      const { account } = data;
      return anOk(AccountsAPI.buildAccountDetails(account));
    } catch (e) {
      return anErr(`Unable to query account details: API Error`);
    }
  }

  /**
   *
   * @param token
   */
  static async getPlanDetails(
    token: AccessToken
  ): Promise<Result<PlanDetails>> {
    try {
      const { data, errors } = await client.query({
        query: gql(planDetails),
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to query plan details: ${
            errors[0].message ?? "unknown error"
          }`
        );

      const { plan } = data;
      return anOk(AccountsAPI.buildPlanDetails(plan));
    } catch (e) {
      return anErr(`Unable to query account plan details: API Error`);
    }
  }

  static async fetchEvents(
    token: AccessToken,
    paginationParams: PaginationParams
  ): Promise<Result<AccountEventData>> {
    try {
      const { data, errors } = await client.query({
        query: gql(accountEventsQuery),
        fetchPolicy: "no-cache",
        variables: {
          params: {
            page: paginationParams.page,
            pageSize: paginationParams.pageSize,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to query account Events: ${
            errors[0].message ?? "unknown error"
          }`
        );

      const { accountEvents } = data;
      return anOk({
        ...accountEvents,
        results: accountEvents.results.map(
          (event: AccountEventAPIResponseItem) => AccountsAPI.buildEvent(event)
        ),
      });
    } catch (e) {
      return anErr(`Unable to query account Events: GraphQL Error`);
    }
  }

  static async fetchFetPrice(): Promise<Result<FetPriceApiResponse>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(`${API_URL}/fetch/price/`, config);

      if (response.status !== 200) {
        return anErr("Unable to fetch FET price: API Error");
      }
      const data = await response.json();
      return anOk(data);
    } catch (e) {
      return anErr("Unable to fetch FET price: API Error", e);
    }
  }

  static async fetchAddresses(token: AccessToken): Promise<Result<string[]>> {
    try {
      const { data, errors } = await client.query({
        query: gql(addressesQuery),
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to query plan details: ${
            errors[0].message ?? "unknown error"
          }`
        );

      const { accountAddress } = data;
      return anOk(
        accountAddress.map((addressItem: AddressItem) => addressItem.address)
      );
    } catch (e) {
      return anErr(`Unable to query addresses: API Error`);
    }
  }

  static async fetchPreferences(
    token: AccessToken
  ): Promise<Result<UserPreferences>> {
    const config = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
    };

    let error = false;

    const response = await fetch(
      `${API_URL}/account/preferences/`,
      config
    ).catch(() => {
      error = true;
    });

    if (error) {
      return anErr(`Unable to query user preferences: API Error`);
    }

    const data = await (response as Response).json();
    if ((response as Response).status !== 200) {
      return anErr(`Unable to query user preferences: API Error`);
    }
    return anOk(data);
  }

  static async updatePreferences(
    email: string,
    notify_trigger_status: boolean,
    notify_low_balance: boolean,
    notify_service_expiry: boolean,
    email_confirmed: boolean,
    token: string
  ): Promise<Result<UserPreferences>> {
    const config = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email,
        notify_trigger_status,
        notify_low_balance,
        notify_service_expiry,
        email_confirmed,
      }),
    };

    let error = false;

    const response = await fetch(
      `${API_URL}/account/preferences/`,
      config
    ).catch(() => {
      error = true;
    });

    if (error) {
      return anErr(`Unable to query user preferences: API Error`);
    }

    const data = await (response as Response).json();
    if ((response as Response).status !== 200) {
      return anErr(`Unable to query user preferences: API Error`);
    }
    return anOk(data);
  }
}
