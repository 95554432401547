import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import style from "./DropdownSearchPool.module.scss";
import { PoolData } from "./DropdownSearchPool.data";
import Web3 from "web3";
import { Validation } from "../../validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Pair } from "../../state/pairCache";
import { PairContract } from "../../services/contracts/PairContract";
import { StateStore } from "../../state/state";
import { isErr } from "../../result";
import { InvalidReason, PoolValidation } from "../NewTriggerModal";
import { format } from "../../utils/display/format";
import { HoldingPool } from "../../state/assets";

interface DropdownSearchProps {
  filteredHoldingTokens: HoldingPool[];
  allHoldingToken: HoldingPool[];
  setFilteredHoldingTokens: (tokens: HoldingPool[]) => void;
  onPoolSelected: (address: string | undefined) => void;
  poolValidation: PoolValidation;
  setPoolValidation: (text: PoolValidation) => void;
  inputText: string;
  setInputText: (text: string) => void;
  isFocus: boolean;
  setIsFocus: (focus: boolean) => void;
  pair: Pair | undefined;
  state: StateStore;
  setIsAddressPaste: (address: boolean) => void;
  isAddressPaste: boolean;
  setPair: (pair: Pair | undefined) => void;
}

function getPoolValidationMessage(
  validation: PoolValidation
): string | undefined {
  if (validation.validity !== Validation.INVALID) {
    return;
  }

  switch (validation.reason) {
    case InvalidReason.NO_BALANCE:
      return "No balance";
    case InvalidReason.ALREADY_REGISTERED:
      return "Already registered to a pool";
    case InvalidReason.NETWORK_ERROR:
      return "Unable to lookup pool details";
    case InvalidReason.INVALID_ADDRESS:
      return "Invalid pool address";
    case InvalidReason.INVALID_POOL:
      return "The address does not appear to be a Uniswap pool";
    case InvalidReason.POOL_NOT_PANCAKE_SWAP:
      return "Requires a Pancake Swap pool";
    case InvalidReason.POOL_NOT_ON_NETWORK:
      return "Pool not found on network";
    case InvalidReason.UNSUPPORTED_NETWORK:
      return "Unsupported network: change network in MetaMask";
    case InvalidReason.POOL_NOT_v2:
      return "Requires a V2 pool";
    case InvalidReason.TWO_TRIGGERS_ON_POOL:
      return "You have two or more triggers already registered to this pool";
    case InvalidReason.NO_POOL_DATA:
      return "Given pool symbol not found, please paste pool address ";
    case InvalidReason.POOL_PRICE_NOT_FOUND:
      return "Given pool price not found"
    default:
      return "Unknown error";
  }
}

export const DropdownSearch = ({
  filteredHoldingTokens,
  setFilteredHoldingTokens,
  poolValidation,
  setPoolValidation,
  onPoolSelected,
  allHoldingToken,
  inputText,
  setInputText,
  isFocus,
  setIsFocus,
  pair,
  state,
  setIsAddressPaste,
  isAddressPaste,
  setPair,
}: DropdownSearchProps) => {
  const [poolAddressInvalid, setPoolAddressInvalid] = useState<boolean>(false);
  const [filterdData, setFilteredData] = useState<PoolData[]>([]);
  const inputRef = useRef(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [fetchingPool, setFetchingPool] = useState<boolean>(false);

  const beginningAddressLength = 12;
  const numberOfDots = 4;
  const endingAddressLength = 7;

  const updateDropdown = async (e: ChangeEvent<HTMLSelectElement>) => {
    if (dropdownRef.current) {
      // scroll to top on text change
      dropdownRef.current.scrollTo(0, 0);
    }
    setIsAddressPaste(false);
    setFilteredData([]);
    setPoolValidation({ validity: Validation.UNKNOWN });
    if (e.target.value === "") {
      setFilteredHoldingTokens(allHoldingToken);
      setInputText(e.target.value);
      setPoolAddressInvalid(false);
      onPoolSelected(undefined);
    } else if (Web3.utils.isAddress(e.target.value)) {
      setPair(undefined);
      setInputText(e.target.value);
      setFetchingPool(true);
      setIsFocus(true);
      setIsAddressPaste(true);
      const result = await PairContract.fetchPoolInformation(
        e.target.value,
        state.web3.networkId,
        state.web3.chainId
      );
      if (isErr(result)) {
        setFetchingPool(false);
        setFilteredData([]);
        setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.INVALID_POOL,
        });
        return;
      }
      if (allHoldingToken) {
        const comparator = e.target.value.trim().toLowerCase();
        const filterHoldingToken = allHoldingToken.filter((token) =>
          token.address.trim().toLowerCase().includes(comparator)
        );
        if (filterHoldingToken.length > 0) {
          setFilteredHoldingTokens(filterHoldingToken);
          setFilteredData([]);
          setIsFocus(true);
          setFetchingPool(false);
          setPoolValidation({ validity: Validation.UNKNOWN });
          return;
        }
        setFilteredHoldingTokens([]);
      }
      pair = result.value;
      setFilteredData([
        {
          name: `${pair.token0}-${pair.token1}`,
          address: pair.address,
          chainId: state.web3.chainId ?? 0,
        },
      ]);
      setPoolAddressInvalid(false);
      setFetchingPool(false);
    } else {
      const comparator = e.target.value.trim().toLowerCase();
      const filteredTokens = allHoldingToken.filter(
        (token: HoldingPool) =>
          token.token0.toLowerCase().includes(comparator) ||
          token.token1.toLowerCase().includes(comparator)
      );
      setFilteredHoldingTokens(filteredTokens);
      setInputText(e.target.value);
      onPoolSelected(undefined);
    }
  };

  const handleDropdownClick = (address: string, name: string) => {
    setInputText(name);
    onPoolSelected(address);
    setPoolAddressInvalid(false);
    setIsFocus(false);
  };

  useEffect(() => {
    // Show dropdown with all data when input box is empty and focus
    if (inputText !== "") {
      if (
        filteredHoldingTokens.length === 0 &&
        !isAddressPaste &&
        inputText !== pair?.address
      ) {
        setPoolValidation({
          validity: Validation.INVALID,
          reason: InvalidReason.NO_POOL_DATA,
        });
        return;
      }
    }
  }, [inputText, isFocus, filteredHoldingTokens]);

  return (
    <>
      <div
        style={{ position: "relative" }}
        className={style.dropdownSearchContainer}
      >
        <Form.Control
          type="text"
          data-testid="pair-input"
          value={inputText}
          className={style.input}
          placeholder="Select pair or paste pair address"
          onChange={updateDropdown}
          ref={inputRef}
          isInvalid={
            poolValidation.validity === Validation.INVALID || poolAddressInvalid
          }
          onFocus={() => !isFocus && setIsFocus(true)}
          onBlur={() => isFocus && setTimeout(() => setIsFocus(false), 500)}
        />
        {poolValidation.validity !== Validation.INVALID &&
          !poolAddressInvalid &&
          filteredHoldingTokens.length !== 0 && (
            <div
              className={style.downIcon}
              onClick={() => {
                setIsFocus(!isFocus);
              }}
            >
              <FontAwesomeIcon icon={faAngleDown} />
            </div>
          )}

        <div
          id="scrollableWithdrawDiv"
          ref={dropdownRef}
          className={`${style.dropdownContainer} ${isFocus &&
              (filterdData.length > 0 ||
                (filteredHoldingTokens && filteredHoldingTokens?.length > 0))
              ? style.activeDropdown
              : null
            }`}
        >
          {fetchingPool ? (
            <div className={style.loaderContainer}>
              <FontAwesomeIcon icon={faSpinner} spin />{" "}
              <span className={style.loaderText}>Fetching tokens...</span>
            </div>
          ) : (
            isFocus &&
            (filterdData.length > 0 ||
              (filteredHoldingTokens && filteredHoldingTokens?.length > 0)) && (
              <>
                {filteredHoldingTokens?.map((item, index) => (
                  <span
                    key={index}
                    className={`${style.dropdown} ${style.holdingPool}`}
                    onClick={() =>
                      handleDropdownClick(
                        item.address,
                        `${item.token0}/${item.token1}`
                      )
                    }
                  >
                    <div className={style.poolName}>
                      {`${item.token0}/${item.token1}`}
                      <div className={style.holdingTokenBalance}>
                        {item.balance}
                      </div>
                    </div>
                    <div className={style.poolAddress}>
                      {format(
                        item.address,
                        beginningAddressLength,
                        endingAddressLength,
                        numberOfDots
                      )}
                    </div>
                  </span>
                ))}
                {filterdData.map((item, index) => (
                  <div
                    key={index}
                    className={style.dropdown}
                    onClick={() => handleDropdownClick(item.address, item.name)}
                  >
                    <div className={style.poolName}>{item.name}</div>
                    <div className={style.poolAddress}>
                      {format(
                        item.address,
                        beginningAddressLength,
                        endingAddressLength,
                        numberOfDots
                      )}
                    </div>
                  </div>
                ))}
                {!isAddressPaste && (
                  <p className={style.bottomText}>
                    Can't find your token? Paste a token address
                  </p>
                )}
              </>
            )
          )}
        </div>
        <Form.Control.Feedback type="invalid">
          {getPoolValidationMessage(poolValidation) ?? "Invalid Pool Address"}
        </Form.Control.Feedback>
      </div>
    </>
  );
};
