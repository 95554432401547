import { StateStore } from "../../state/state";

/**
 * check if ethereum address is registered with our api for this account
 *
 * @param address
 * @param state
 */
export const isAddressRegistered = (
  address: string | undefined,
  state: StateStore
) => {
  let ret = false;
  if (address !== undefined) {
    ret = state.account.addresses.indexOf(address) >= 0;
  }
  return ret;
};
