import { networkToApiChainId } from "../../api/common";
import { GasPrices, Network, API_URL } from "../../configs";
import { anOk, isErr, Result } from "../../result";

export const getGasPrices = async (
  network: Network
): Promise<Result<GasPrices>> => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const chainId = networkToApiChainId(network);

  if (isErr(chainId)) {
    return anOk({
      LOW: "10",
      MEDIUM: "10",
      HIGH: "10",
    });
  }

  try {
    const response = await fetch(
      `${API_URL}/gas/prices?chain=${chainId.value}`,
      config
    );
    const data = await response.json();
    if (response.status === 200) {
      return anOk({
        LOW: data.slow,
        MEDIUM: data.medium,
        HIGH: data.fast,
      });
    }
    return anOk({
      LOW: "10",
      MEDIUM: "10",
      HIGH: "10",
    });
  } catch (err) {
    // On failed case show all gas price as 10 gwei
    return anOk({
      LOW: "10",
      MEDIUM: "10",
      HIGH: "10",
    });
  }
};
