import firebase from "firebase/app";
import "firebase/analytics";
import { FIREBASE_APP_ID, FIREBASE_MEASUREMENT_ID } from "./configs";

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

function createFirebaseConfig(): FirebaseConfig | undefined {
  if (FIREBASE_APP_ID === undefined || FIREBASE_MEASUREMENT_ID === undefined) {
    return;
  }

  return {
    apiKey: "AIzaSyDXg5jOKUtrEbtiJV9vPb59XL41aqH0Sv0",
    authDomain: "fetch-ai-agent-platform.firebaseapp.com",
    projectId: "fetch-ai-agent-platform",
    storageBucket: "fetch-ai-agent-platform.appspot.com",
    messagingSenderId: "274073637691",
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
  };
}

// build the firebase configuration
const firebaseConfig = createFirebaseConfig();
let firebaseCfg: firebase.remoteConfig.RemoteConfig | undefined;
let firebaseAnalytics: firebase.analytics.Analytics | undefined;

// Initialize Firebase
if (firebaseConfig !== undefined && firebase.remoteConfig !== undefined) {
  try {
    firebase.initializeApp(firebaseConfig);
    firebaseAnalytics = firebase.analytics();
    firebaseCfg = firebase.remoteConfig();
    firebase.performance();

    firebaseAnalytics.setUserProperties({});
  } catch (e) {
    console.log("Unable to initialise the remote configuration", e);
  }

}

export function getFirebaseConfig():
  | firebase.remoteConfig.RemoteConfig
  | undefined {
  try {
    return firebaseCfg;
  } catch {}
}

export function getFirebaseAnalytics():
  | firebase.analytics.Analytics
  | undefined {
  try {
    return firebaseAnalytics;
  } catch {}
}
