import {Result} from "../../result";
import {ZERO_ROUTER_ADDRESS} from "../../configs";

/**
 * Checks if pool is valid BUT if only determines this correctly is pair is fetched.
 * If pair not fetched returns null.
 *
 * @param token0
 * @param token1
 * @param chainId
 */
export const isInvalidPool =  (pairAddress: Result<string>) : boolean | null => {

    if (!pairAddress.success) {
        return null;
    }

    if (pairAddress.success) {
        if (pairAddress.value === ZERO_ROUTER_ADDRESS) {
            return true;
        }
    }
    return false;
}

