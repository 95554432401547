import BN from "bn.js";

/**
 *  @see {@link https://ethereum.stackexchange.com/questions/73288/web3py-how-to-convert-from-gwei-to-wei-or-ether}
 *
 * @param gwei
 * @return wei
 */
export function gweiToWei(gwei: string): string {
  // Gigaweis are billions
  const multiplier = new BN(10).pow(new BN(9));
  // Convert gwei to wei
  const weiAmount = new BN(gwei).mul(multiplier);
  return weiAmount.toString();
}
