import { Agent, AgentType } from "../state/agents";
import { Network } from "../configs";
import { AccessToken } from "../state/token";
import Provider from "../services/Provider";
import { gql } from "@apollo/client";
import { anErr, anOk, isErr, Result } from "../result";
import { getBalance } from "../utils/networkRequests/getBalance";
import {
  createAgent,
  deleteAgent,
  updateAgent,
  fetchAgentsQuery,
} from "./AgentsQueries";
import client from "./client";
import { apiChainToNetwork, networkToApiChain } from "./common";

export type AgentAPIResult = Omit<Agent, "maxTriggers" | "triggers">;

export class AgentsAPI {
  public static buildAgent(apiData: any, balance: string): Agent {
    const network = apiChainToNetwork(apiData.chain);
    return {
      agentAddress: apiData.agentAddress,
      uuid: apiData.uuid,
      network,
      type: AgentType.STOP_LOSS,
      userAddress: apiData.userAddress,
      maxTriggers: 1,
      createdAt: new Date(apiData.createdAt),
      balance,
      name: apiData.name,
      withdrawPending: apiData.withdrawPending,
      deletePending: apiData.deletePending,
    };
  }

  static async createAgent(
    token: AccessToken,
    address: string,
    type: AgentType,
    network: Network
  ): Promise<Agent> {
    let createAgentError = "GraphQL error";
    try {
      const { data, errors } = await client.mutate({
        mutation: gql(createAgent),
        variables: {
          input: {
            userAddress: address,
            chain: networkToApiChain(network),
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors) createAgentError = errors[0].message;
      else if (data.createAgent) {
        const agent = data.createAgent;
        const { web3 } = Provider;
        const balance = await getBalance(agent.agentAddress, web3);
        if (isErr(balance)) throw new Error(balance.error.message);
        return AgentsAPI.buildAgent(agent, balance.value);
      }
    } catch (e) {}
    throw new Error(`Unable to create agent: ${createAgentError}`);
  }

  static async deleteAgent(token: AccessToken, uuid: string): Promise<void> {
    let deleteAgentError = "GraphQL error";
    try {
      const { errors } = await client.mutate({
        mutation: gql(deleteAgent),
        variables: {
          deleteAgentId: uuid,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (!errors) return;
      deleteAgentError = errors[0].message;
    } catch (e) {}
    throw new Error(`Unable to delete agent: ${deleteAgentError}`);
  }

  static async fetchAgents(
    token: AccessToken
  ): Promise<Result<AgentAPIResult[]>> {
    try {
      const { data, errors } = await client.query({
        query: gql(fetchAgentsQuery),
        fetchPolicy: "no-cache",
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (errors)
        return anErr(
          `Unable to fetch agent: ${errors[0].message ?? "unknown error"}`
        );
      const { agents } = data;
      return anOk(agents);
    } catch (e) {
      return anErr(`Unable to fetch user agents: GraphQL Error`);
    }
  }

  static async updateAgentName(
    token: AccessToken,
    uuid: string,
    name: string
  ): Promise<void> {
    try {
      const { data } = await client.mutate({
        mutation: gql(updateAgent),
        variables: {
          updateAgentId: uuid,
          input: {
            name,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        },
      });
      if (data.updateAgent.name === name) return;
      throw new Error(`Unable to rename agent: ${uuid}`);
    } catch (e) {
      throw new Error(`Unable to rename agent: API Error`);
    }
  }
}
