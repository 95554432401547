import React, { useEffect, useState } from "react";
import { Decimal } from "decimal.js";
import { PoolDropdown } from "../../../components/PoolDropdown/index";
import { HoldingPool } from "../../../state/assets";
import { hasTooManyDecimalPlaces } from "../../../utils/numeric/hasTooManyDecimalPlaces";
import { isNumeric } from "../../../utils/numeric/isNumeric";
import { formatBigNumber } from "../../../utils/numeric/formatBigNumber";
import { Tooltip } from "../../../components/Tooltip";

interface PoolAutomationProps {
  poolList: HoldingPool[];
  selected: HoldingPool;
  amount: string | undefined;
  setAmount: Function;
  setSelected: Function;
  inputError: boolean;
  setInputError: Function;
  decimalsExceeded: boolean;
  setDecimalsExceeded: Function;
}

const PoolAutomation = ({
  poolList,
  selected,
  amount,
  setAmount,
  setSelected,
  inputError,
  setInputError,
  decimalsExceeded,
  setDecimalsExceeded,
}: PoolAutomationProps) => {
  const [amountUSD, setAmountUSD] = useState("0.00");
  useEffect(() => {
    const input: string | undefined = !amount ? "0" : amount;
    const amountUSD = new Decimal(selected.priceUSD).mul(new Decimal(input));
    setAmountUSD(amountUSD.toString());
  }, [selected, amount]);
  return (
    <div className="tw-shadow-lg tw-rounded-xl tw-overflow-hidden">
      <div className="tw-bg-coolGray-200 tw-text-coolGray-900 tw-text-xl tw-font-medium tw-pl-7 tw-py-2">
        Pool
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-10 lg:tw-gap-20 tw-p-7 tw-bg-white">
        <div className="tw-flex tw-justify-between tw-items-center tw-border tw-border-coolGray-300 tw-bg-coolGray-50 tw-p-8 tw-rounded-2xl">
          <PoolDropdown
            poolList={poolList}
            selected={selected}
            setSelected={setSelected}
          />
        </div>

        <div className="tw-flex tw-justify-end tw-items-center tw-border tw-border-coolGray-300 tw-bg-coolGray-50 tw-p-3 tw-rounded-2xl">
          <div className="tw-flex tw-justify-end tw-flex-col tw-mr-8">
            <div className="tw-text-sm tw-font-normal tw-flex tw-justify-end">
              {!formatBigNumber(selected.balance).same ? (
                <Tooltip
                  id="pool-balance-tooltip"
                  tooltipText={`${formatBigNumber(selected.balance).full} ${selected.symbol
                    }`}
                >
                  {`Current balance: ${formatBigNumber(
                    selected.balance,
                    parseInt(selected.decimal)
                  ).trunc
                    } ${selected.symbol}`}
                </Tooltip>
              ) : (
                `Current balance: ${formatBigNumber(selected.balance, parseInt(selected.decimal))
                  .trunc
                } ${selected.symbol}`
              )}
              <span
                className="tw-bg-green-300 tw-rounded tw-text-coolGray-900 tw-text-xs tw-font-medium tw-px-3 tw-py-1 tw-ml-7 tw-cursor-pointer"
                onClick={() => {
                  setInputError(false);
                  setDecimalsExceeded(false);
                  setAmount(selected.balance);
                }}
              >
                {" "}
                Add max{" "}
              </span>
            </div>
            <div className="tw-w-full">
              <input
                className="tw-w-full tw-bg-white tw-h-12 tw-rounded tw-text-xl tw-font-medium tw-text-coolGray-900 tw-text-right tw-border tw-border-coolGray-300 tw-mt-4 tw-mb-1 tw-px-3 focus:tw-ring-2 focus:tw-ring-blue-600 "
                type="text"
                value={amount}
                onChange={(e) => {
                  const val = e.target.value;

                  if (val === "") {
                    setInputError(false);
                    setDecimalsExceeded(false);
                    setAmount("");
                    return;
                  }

                  if (val === "-") {
                    setInputError(false);
                    setDecimalsExceeded(false);
                    setAmount("");
                    return;
                  }

                  if (!isNumeric(val) && val !== ".") {
                    setInputError(true);
                    setDecimalsExceeded(false);
                    return;
                  }

                  if (new Decimal(val).lt(new Decimal(0))) {
                    setInputError(false);
                    setDecimalsExceeded(false);
                    setAmount("");
                    return;
                  }

                  if (
                    new Decimal(val).gt(
                      new Decimal(selected.balance.toString())
                    )
                  ) {
                    setInputError(true);
                    setDecimalsExceeded(false);
                    return;
                  }

                  if (
                    hasTooManyDecimalPlaces(val, parseInt(selected.decimal))
                  ) {
                    setInputError(false);
                    setDecimalsExceeded(true);
                    return;
                  }

                  setInputError(false);
                  setDecimalsExceeded(false);
                  setAmount(val);
                }}
                placeholder="00.00"
              />
            </div>
            <div className="tw-text-base tw-font-normal tw-text-coolGray-900 tw-text-right tw-mr-3">
              <div className="tw-text-red-700 tw-float-left tw-ml-px-15">
                {inputError ? "Invalid input" : ""}
              </div>
              <div className="tw-text-red-700 tw-float-left ">
                {decimalsExceeded ? "Max decimals exceeded" : ""}
              </div>
              {!formatBigNumber(amountUSD).same ? (
                <Tooltip
                  id="pool-balance-tooltip"
                  tooltipText={`$ ${formatBigNumber(amountUSD).full}`}
                >
                  {`$ ${formatBigNumber(amountUSD).trunc}`}
                </Tooltip>
              ) : (
                `$ ${formatBigNumber(amountUSD).trunc}`
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolAutomation;
