import { Card, Col, Image, Nav, Row } from "react-bootstrap";
import React from "react";
import { observer } from "mobx-react-lite";
import "holderjs";

export const ProfilePage = observer(() => {
  // const state = useContext(StateContext);

  return (
    <>
      <Nav variant="tabs" defaultActiveKey="/home">
        <Nav.Item>
          <Nav.Link href="/home">Profile</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-1">Billing</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-1">Settings</Nav.Link>
        </Nav.Item>
      </Nav>
      <h1>Profiles</h1>
      <Row>
        <Col md={7}>
          <Card>
            <Card.Body>This is some text within a card body.</Card.Body>
          </Card>
        </Col>
        <Col md={5}>
          <Card>
            <Card.Body>
              <Image src="holder.js/300x300" roundedCircle />
              <br />
              <br />
              <h3>Username</h3>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={12}>
          <Card>
            <Card.Body>This is some text within a card body.</Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});
