import BN from "bn.js";
import { FetInfo } from "../../configs";
import { stripRightwardZeros } from "../../utils/display/toNonCanonicalDisplay";




/**
 * Count how many significant decimal places of a string number
 *
 * Taken from top answer https://stackoverflow.com/questions/10454518/javascript-how-to-retrieve-the-number-of-decimals-of-a-string-number
 *
 * @example // outputs 4
 *   countDecimalPlaces("2.00020")
 *
 * @example // outputs 2
 *   countDecimalPlaces("2.03")
 *
 *
 * @param num a numeric string to test
 * @returns {number} number of non zero-digits to right of decimal point
 */
export const countDecimalPlaces = (num: string): number => {
  const match = num.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);

  if (!match) {
    return 0;
  }

  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
};



/**
 * This takes a number and moves the decimal rightwards by decimals param number of places.
 * Dec library means this works with decimals
 *
 * All decimal libraries seemed to fail to do this task and return it as plain string so I just wrote this myself to move decimal point rightwards.
 *
 * @example
 * // outputs 12121200
 * console.log(multiplyByDecimals("12.1212", 6))
 * @param amount numeric amount as a string by which we will multiply
 * @param decimals a number which is the number of places rightwards on number-line by which decimal will be shifted by multiplication
 * @param truncate truncates result to integer
 * @returns {string} The result of param1*10^param2
 */
export const multiplyByDecimals = (
  amount: string,
  decimals: number
): string => {
  amount = stripRightwardZeros(amount);
  const decimalCount = countDecimalPlaces(amount);
  if (decimalCount > decimals) {
    throw new Error(
      `decimal count is greater than decimals and therefore should not be here with count: ${decimalCount} and max allowed decimals : ${decimals}`
    );
  }
  // then lets pad it
  const pad = decimals - decimalCount;
  const paddedAmount = amount + "0".repeat(pad);
  const result = paddedAmount.split(".").join("");
  return result;
};
