import React, { ChangeEvent, useEffect } from "react";
import { Agent } from "../../state/agents";
import { Form } from "react-bootstrap";
import { StateStore } from "../../state/state";
import classNames from "classnames";
import style from "./SelectAgent.module.scss";
import { NetworkNameText } from "../NetworkNameText";
import { calcAvailableAgents } from "../../utils/misc/calcAvailableAgents";

interface SelectAgentProps {
  web3Address: string;
  allAgents: Agent[];
  agent?: Agent;
  onAgentUpdate: (a: Agent | undefined) => void;
  state: StateStore;
}

export function SelectAgent(props: SelectAgentProps) {
  const { state } = props;
  const errorMessage = determineErrorMessage(props.allAgents);

  function determineErrorMessage(allAgents: Agent[]) {
    if (!allAgents.length) {
      return "You have no agents, please create one first";
    }

    const hasNewTriggerCapacityLeft = allAgents.some((agent) => {
      return (
        state.triggers.agentNonCompletedTriggersCount(agent.uuid) <
        agent.maxTriggers
      );
    });

    if (!hasNewTriggerCapacityLeft) {
      return "You have no trigger capacity remaining";
    }

    const agentsAtCurrentAddress = allAgents.some((agent) => {
      return agent.userAddress === props.web3Address;
    });

    if (!agentsAtCurrentAddress) {
      return "No agents at connected to current Ethereum address";
    }

    return "";
  }

  const availableAgents = calcAvailableAgents(
    props.allAgents,
    state.web3.networkId,
    props.web3Address,
    // @ts-ignore
    state.triggers.agentNonCompletedTriggersCount.bind(state.triggers)
  );

  // set initially selected agent if it is possible ie there is one or more available agent
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (availableAgents.length) {
      props.onAgentUpdate(availableAgents[0]);
    }
  }, []);

  const handleAgentUpdate = (agent: Agent | undefined) => {
    if (props.onAgentUpdate !== undefined) {
      props.onAgentUpdate(agent);
    }
  };

  let ctrl;
  if (availableAgents.length === 0) {
    ctrl = (
      <Form.Control
        type="text"
        readOnly
        value="No available agents"
        className={style.ctrl}
      />
    );
  } else {
    ctrl = (
      <Form.Control
        data-testid="select-agent-option"
        as="select"
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          handleAgentUpdate(availableAgents[e.target.selectedIndex])
        }
        className={style.ctrl}
      >
        {availableAgents.map((a, idx) => (
          <option className={style.option} key={idx} value={idx}>
            {a.name}
          </option>
        ))}
      </Form.Control>
    );
  }

  return (
    <Form.Group>
      <Form.Label className={style.ctrlLableTextColor}>Network</Form.Label>
      <div className={style.valueBox}>
        <NetworkNameText chainId={state.web3.chainId} />
      </div>
      <Form.Label className={style.ctrlLableTextColor}>Select Agent</Form.Label>
      {ctrl}
      <span className={classNames("invalid-feedback", style.errorMessage)}>
        {errorMessage}
      </span>
    </Form.Group>
  );
}
