import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";
import withdrawAllProxyABI from "../../../abis/WithdrawAllProxy.json";
import { AgentsAPI } from "../../../api/AgentsAPI";
import { TriggersAPI } from "../../../api/TriggersAPI";
import {
  ProgressModal,
  ProgressModalData,
  progressStatus,
} from "../../../components/ProgressModal";
import {
  getNetworkDetails,
  mapChainId,
  Network,
  protocols,
} from "../../../configs";
import { isErr, isOk } from "../../../result";
import ERC20Contract from "../../../services/contracts/ERC20Contract";
import {
  toDecimalWithPrecision,
  toMultiplyWithPrecision,
} from "../../../services/contracts/PairContract";
import { ProxyContract } from "../../../services/contracts/ProxyContract";
import RouterV2 from "../../../services/contracts/RouterV2";
import { asAbi } from "../../../services/contracts/utils";
import Provider from "../../../services/Provider";
import { Agent, AgentType } from "../../../state/agents";
import { HoldingToken } from "../../../state/assets";
import { StateStore } from "../../../state/state";
import {
  NewTrigger,
  Trigger,
  TriggerBase,
  TriggerType,
} from "../../../state/triggers";
import { gweiToWei } from "../../../utils/currency/GweiToWei";
import { eventAnalysis } from "../../../utils/misc/trackEvent";
import { getBalance } from "../../../utils/networkRequests/getBalance";
import { addDecimal } from "../../../utils/numeric/addBigNumber";
import { amountSendToAgent } from "../utils";

export const TradingAutomationProgress = ({
  setOpen,
  trigger,
  agent,
  state,
  token1,
  token2,
  token1Amount,
  token2Amount,
  onComplete,
}: {
  setOpen: (val: boolean) => void;
  trigger: NewTrigger;
  agent: Agent | undefined;
  state: StateStore;
  token1: HoldingToken;
  token2: HoldingToken;
  token1Amount: string;
  token2Amount: string;
  onComplete: () => void;
}) => {
  const [token1ApproveStatus, setToken1ApproveStatus] =
    useState<progressStatus>(progressStatus.PENDING);
  const [token2ApproveStatus, setToken2ApproveStatus] =
    useState<progressStatus>(progressStatus.PENDING);
  const [tokensApproveStatus, setTokensApproveStatus] =
    useState<progressStatus>(progressStatus.PENDING);
  const [addLiquidityStatus, setAddLiquidityStatus] = useState<progressStatus>(
    progressStatus.PENDING
  );

  const [agentCreationState, setAgentCreationState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [approvalState, setApprovalState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [registerAgentState, setRegisterAgentState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [createTriggerState, setCreateTriggerState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [stages, setStages] = useState<ProgressModalData[]>([]);

  const [newAgent, setNewAgent] = useState<Agent | undefined>();

  const [errorMessage, setErrorMessage] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);

  const approveToken1 = async () => {
    try {
      let result;
      setToken1ApproveStatus(progressStatus.RUNNING);
      setTokensApproveStatus(progressStatus.RUNNING);
      setErrorMessage("");

      if (state?.web3?.address === undefined) {
        setToken1ApproveStatus(progressStatus.FAILED);
        setErrorMessage("No web3 address");
        return;
      }
      if (state.web3.chainId === undefined) {
        setToken1ApproveStatus(progressStatus.FAILED);
        setErrorMessage("No web3 chain ID");
        return;
      }
      if (trigger.pair.token0Address === undefined) {
        setToken1ApproveStatus(progressStatus.FAILED);
        setErrorMessage("Token address not found");
        return;
      }

      const networkDetails = getNetworkDetails(state.web3.chainId);

      if (isErr(networkDetails)) {
        setToken1ApproveStatus(progressStatus.FAILED);
        eventAnalysis(
          "approve_token_1_err",
          TriggerType.TRADING_AUTOMATION,
          networkDetails.error.message
        );
        setErrorMessage(networkDetails.error.message);
        return;
      }

      const proxyAddress = networkDetails.value.swapProxyContractAddress;
      const routerAddress = networkDetails.value.routerV2ContractAddress;
      const allowance = await ERC20Contract.getAllowance(
        token1.address,
        state.web3.address,
        routerAddress
      );
      if (isErr(allowance)) {
        setToken1ApproveStatus(progressStatus.FAILED);
        eventAnalysis(
          "approve_token_1_err",
          TriggerType.TRADING_AUTOMATION,
          allowance.error.message
        );
        setErrorMessage(allowance.error.message);
        return;
      }

      // Addition of approval amount of triggers having same swap token for swap or pair for withdraw
      let AdditionOfTriggerApprovalAmount = "0";
      state.triggers.list().forEach((trig) => {
        const tokenAddress =
          trig.swap_token === 0
            ? trig.pair.token0Address
            : trig.pair.token1Address;
        const hasTrigger =
          trig.triggerType === TriggerType.SWAP &&
          tokenAddress === trig.pair.token0Address;

        if (hasTrigger) {
          AdditionOfTriggerApprovalAmount = addDecimal(
            trig.approveBalance.balance,
            AdditionOfTriggerApprovalAmount
          );
        }
      });

      const decimal = await ERC20Contract.getDecimals(
        trigger.pair.token0Address
      );
      if (isErr(decimal)) {
        setToken1ApproveStatus(progressStatus.FAILED);
        eventAnalysis(
          "approve_token_1_err",
          TriggerType.TRADING_AUTOMATION,
          decimal.error.message
        );
        setErrorMessage(decimal.error.message);
        return;
      }

      const toApprove = toMultiplyWithPrecision(
        token1Amount,
        Number(decimal.value)
      );
      const totalApprovalAmount = addDecimal(
        toApprove,
        AdditionOfTriggerApprovalAmount
      );

      // if we have allowance greater or equal to total approval amount then no need to approve current amount
      // else approve total approval balance
      if (new Decimal(allowance.value).gte(totalApprovalAmount)) {
        token1Amount = toApprove;
        setToken1ApproveStatus(progressStatus.SUCCESS);
        eventAnalysis(
          "approve_token_1_success",
          TriggerType.TRADING_AUTOMATION,
          "Token 1 approves successful"
        );
        setErrorMessage("");
      } else {
        const gasPriceInWai = gweiToWei(
          state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
        );
        result = await ERC20Contract.approve(
          token1.address,
          state.web3.address,
          routerAddress,
          totalApprovalAmount,
          gasPriceInWai
        );
        if (isErr(result)) {
          setToken1ApproveStatus(progressStatus.FAILED);
          eventAnalysis(
            "approve_token_1_err",
            TriggerType.TRADING_AUTOMATION,
            result.error.message
          );
          setErrorMessage(result.error.message);
        } else {
          setToken1ApproveStatus(progressStatus.SUCCESS);
          eventAnalysis(
            "approve_token_1_success",
            TriggerType.TRADING_AUTOMATION,
            "Token 1 approved successful"
          );
          setErrorMessage("");
        }
      }
    } catch (err: any) {
      setToken1ApproveStatus(progressStatus.FAILED);
      eventAnalysis(
        "approve_token_1_err",
        TriggerType.TRADING_AUTOMATION,
        err.message
      );
      setErrorMessage(err.message);
    }
  };

  const approveToken2 = async () => {
    try {
      let result;
      setToken2ApproveStatus(progressStatus.RUNNING);
      setErrorMessage("");

      if (state?.web3?.address === undefined) {
        setToken2ApproveStatus(progressStatus.FAILED);
        setErrorMessage("No web3 address");
        return;
      }

      if (state.web3.chainId === undefined) {
        setToken2ApproveStatus(progressStatus.FAILED);
        setErrorMessage("No web3 chain ID");
        return;
      }
      if (trigger.pair.token1Address === undefined) {
        setToken2ApproveStatus(progressStatus.FAILED);
        setErrorMessage("Token address not found");
        return;
      }

      const networkDetails = getNetworkDetails(state.web3.chainId);

      if (isErr(networkDetails)) {
        setToken2ApproveStatus(progressStatus.FAILED);
        eventAnalysis(
          "approve_token_2_err",
          TriggerType.TRADING_AUTOMATION,
          networkDetails.error.message
        );
        setErrorMessage(networkDetails.error.message);
        return;
      }

      const proxyAddress = networkDetails.value.swapProxyContractAddress;
      const routerAddress = networkDetails.value.routerV2ContractAddress;
      const allowance = await ERC20Contract.getAllowance(
        token2.address,
        state.web3.address,
        routerAddress
      );
      if (isErr(allowance)) {
        setToken2ApproveStatus(progressStatus.FAILED);
        eventAnalysis(
          "approve_token_2_err",
          TriggerType.TRADING_AUTOMATION,
          allowance.error.message
        );
        setErrorMessage(allowance.error.message);
        return;
      }

      // Addition of approval amount of triggers having same swap token for swap or pair for withdraw
      let AdditionOfTriggerApprovalAmount = "0";
      state.triggers.list().forEach((trig) => {
        const tokenAddress =
          trig.swap_token === 0
            ? trig.pair.token0Address
            : trig.pair.token1Address;
        const hasTrigger =
          trig.triggerType === TriggerType.SWAP &&
          tokenAddress === trigger.pair.token1Address;

        if (hasTrigger) {
          AdditionOfTriggerApprovalAmount = addDecimal(
            trig.approveBalance.balance,
            AdditionOfTriggerApprovalAmount
          );
        }
      });

      const decimal = await ERC20Contract.getDecimals(
        trigger.pair.token1Address
      );
      if (isErr(decimal)) {
        setToken2ApproveStatus(progressStatus.FAILED);
        eventAnalysis(
          "approve_token_2_err",
          TriggerType.TRADING_AUTOMATION,
          decimal.error.message
        );
        setErrorMessage(decimal.error.message);
        return;
      }

      const toApprove = toMultiplyWithPrecision(
        token2Amount,
        Number(decimal.value)
      );
      const totalApprovalAmount = addDecimal(
        toApprove,
        AdditionOfTriggerApprovalAmount
      );

      // if we have allowance greater or equal to total approval amount then no need to approve current amount
      // else approve total approval balance
      if (new Decimal(allowance.value).gte(totalApprovalAmount)) {
        token2Amount = toApprove;
        setToken2ApproveStatus(progressStatus.SUCCESS);
        eventAnalysis(
          "approve_token_2_success",
          TriggerType.TRADING_AUTOMATION,
          "Token 2 approved successful"
        );
        setErrorMessage("");
      } else {
        const gasPriceInWai = gweiToWei(
          state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
        );
        result = await ERC20Contract.approve(
          token2.address,
          state.web3.address,
          routerAddress,
          totalApprovalAmount,
          gasPriceInWai
        );

        if (isErr(result)) {
          setToken2ApproveStatus(progressStatus.FAILED);
          eventAnalysis(
            "approve_token_2_err",
            TriggerType.TRADING_AUTOMATION,
            result.error.message
          );
          setErrorMessage(result.error.message);
        } else {
          setToken2ApproveStatus(progressStatus.SUCCESS);
          eventAnalysis(
            "approve_token_2_success",
            TriggerType.TRADING_AUTOMATION,
            "Token 2 approved successful"
          );
          setErrorMessage("");
        }
      }
    } catch (err: any) {
      setToken2ApproveStatus(progressStatus.FAILED);
      eventAnalysis(
        "approve_token_2_err",
        TriggerType.TRADING_AUTOMATION,
        err.message
      );
      setErrorMessage(err.message);
    }
  };

  const addLiquidityFunc = async () => {
    try {
      const slippage = trigger.slippage || 2;
      const minimumPercentage = (100 - slippage) / 100;
      setAddLiquidityStatus(progressStatus.RUNNING);
      setErrorMessage("");
      if (state.web3.chainId === undefined) {
        setAddLiquidityStatus(progressStatus.FAILED);
        setErrorMessage("No web3 chain ID");
        return;
      }
      if (state?.web3?.address === undefined) {
        setAddLiquidityStatus(progressStatus.FAILED);
        setErrorMessage("No web3 address");
        return;
      }
      const decimal1 = await ERC20Contract.getDecimals(
        trigger.pair.token0Address
      );
      const decimal2 = await ERC20Contract.getDecimals(
        trigger.pair.token1Address
      );
      if (isErr(decimal1) || isErr(decimal2)) {
        setAddLiquidityStatus(progressStatus.FAILED);
        eventAnalysis(
          "add_liquidity_err",
          TriggerType.TRADING_AUTOMATION,
          "Add liquidity failed"
        );
        return;
      }
      const amountADesired = toMultiplyWithPrecision(
        token1Amount,
        Number(decimal1.value)
      );
      const amountAMin = toMultiplyWithPrecision(
        (parseFloat(token1Amount) * minimumPercentage)
          .toFixed(Number(decimal1.value))
          .toString(),
        Number(decimal1.value)
      );
      const amountBDesired = toMultiplyWithPrecision(
        token2Amount,
        Number(decimal2.value)
      );
      const amountBMin = toMultiplyWithPrecision(
        (parseFloat(token2Amount) * minimumPercentage)
          .toFixed(Number(decimal2.value))
          .toString(),
        Number(decimal2.value)
      );
      const networkDetails = getNetworkDetails(state.web3.chainId);

      if (isErr(networkDetails)) {
        setAddLiquidityStatus(progressStatus.FAILED);
        eventAnalysis(
          "add_liquidity_err",
          TriggerType.TRADING_AUTOMATION,
          networkDetails.error.message
        );
        setErrorMessage(networkDetails.error.message);
        return;
      }

      const gasPriceInWai = gweiToWei(
        state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
      );
      const routerContract = new RouterV2(state.web3.chainId);

      let result;
      if (token1.isNative) {
        result = await routerContract.addLiquidityETH({
          ethereumAddress: state.web3.address,
          gasPrice: gasPriceInWai,
          token: token2.address,
          amountTokenDesired: amountBDesired,
          amountETH: amountADesired,
          amountTokenMin: amountBMin,
          amountETHMin: amountAMin,
          to: state.web3.address,
          deadline: (new Date().getTime() + 30 * 60000).toString(),
        });
      } else if (token2.isNative) {
        result = await routerContract.addLiquidityETH({
          ethereumAddress: state.web3.address,
          gasPrice: gasPriceInWai,
          token: token1.address,
          amountTokenDesired: amountADesired,
          amountETH: amountBDesired,
          amountTokenMin: amountAMin,
          amountETHMin: amountBMin,
          to: state.web3.address,
          deadline: (new Date().getTime() + 30 * 60000).toString(),
        });
      } else
        result = await routerContract.addLiquidity({
          ethereumAddress: state.web3.address,
          gasPrice: gasPriceInWai,
          tokenA: trigger.pair.token0Address,
          tokenB: trigger.pair.token1Address,
          amountADesired,
          amountBDesired,
          amountAMin,
          amountBMin,
          to: state.web3.address,
          deadline: (new Date().getTime() + 30 * 60000).toString(),
        });

      if (isErr(result)) {
        setAddLiquidityStatus(progressStatus.FAILED);
        eventAnalysis(
          "add_liquidity_err",
          TriggerType.TRADING_AUTOMATION,
          result.error.message
        );
        setErrorMessage(result.error.message);
      } else {
        trigger.amount = result.value;
        setAddLiquidityStatus(progressStatus.SUCCESS);
        eventAnalysis(
          "add_liquidity_success",
          TriggerType.TRADING_AUTOMATION,
          "Add liquidity successful"
        );
        setErrorMessage("");
      }
    } catch (err: any) {
      setAddLiquidityStatus(progressStatus.FAILED);
      eventAnalysis(
        "add_liquidity_err",
        TriggerType.TRADING_AUTOMATION,
        err.message
      );
      setErrorMessage(err.message);
    }
  };

  // const approveLiquidityPoolToken = async () => {
  //   try {
  //     let result;
  //     setApproveLiquidityPoolTokenStatus(progressStatus.RUNNING);
  //     setErrorMessage("");

  //     if (state?.web3?.address === undefined) {
  //       setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //       setErrorMessage("No web3 address");
  //       return;
  //     }

  //     if (state.web3.chainId === undefined) {
  //       setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //       setErrorMessage("No web3 chain ID");
  //       return;
  //     }

  //     const networkDetails = getNetworkDetails(state.web3.chainId);

  //     if (isErr(networkDetails)) {
  //       setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //       eventAnalysis(
  //         "approve_liq_pool_err",
  //         TriggerType.TRADING_AUTOMATION,
  //         networkDetails.error.message
  //       );
  //       setErrorMessage(networkDetails.error.message);
  //       return;
  //     }

  //     const proxyAddress =
  //       networkDetails.value.withdrawLiquidityProxyContractAddress;

  //     const allowance = await ERC20Contract.getAllowance(
  //       trigger.pair.address,
  //       state.web3.address,
  //       proxyAddress
  //     );
  //     if (isErr(allowance)) {
  //       setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //       eventAnalysis(
  //         "approve_liq_pool_err",
  //         TriggerType.TRADING_AUTOMATION,
  //         allowance.error.message
  //       );
  //       setErrorMessage(allowance.error.message);
  //       return;
  //     }

  //     // Addition of approval amount of triggers having same swap token for swap or pair for withdraw
  //     let AdditionOfTriggerApprovalAmount = "0";
  //     state.triggers.list().forEach((localTrigger) => {
  //       const hasTrigger =
  //         localTrigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY &&
  //         localTrigger.pair.address === trigger.pair.address;

  //       if (hasTrigger) {
  //         AdditionOfTriggerApprovalAmount = addDecimal(
  //           localTrigger.approveBalance.balance,
  //           AdditionOfTriggerApprovalAmount
  //         );
  //       }
  //     });

  //     const decimal = await ERC20Contract.getDecimals(trigger.pair.address);
  //     if (isErr(decimal)) {
  //       setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //       eventAnalysis(
  //         "approve_liq_pool_err",
  //         TriggerType.TRADING_AUTOMATION,
  //         decimal.error.message
  //       );
  //       setErrorMessage(decimal.error.message);
  //       return;
  //     }

  //     const amount = toDecimalWithPrecision(
  //       trigger.amount,
  //       Number(decimal.value)
  //     );

  //     const toApprove = toMultiplyWithPrecision(amount, Number(decimal.value));
  //     const totalApprovalAmount = addDecimal(
  //       toApprove,
  //       AdditionOfTriggerApprovalAmount
  //     );

  //     // if we have allowance greater or equal to total approval amount then no need to approve current amount
  //     // else approve total approval balance
  //     if (new Decimal(allowance.value).gte(totalApprovalAmount)) {
  //       setApproveLiquidityPoolTokenStatus(progressStatus.SUCCESS);
  //       eventAnalysis(
  //         "approve_liq_pool_success",
  //         TriggerType.TRADING_AUTOMATION,
  //         "Approved liquidity pool token successful"
  //       );
  //       setErrorMessage("");
  //     } else {
  //       const gasPriceInWai = gweiToWei(
  //         state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
  //       );
  //       result = await ERC20Contract.approve(
  //         trigger.pair.address,
  //         state.web3.address,
  //         proxyAddress,
  //         totalApprovalAmount,
  //         gasPriceInWai
  //       );

  //       if (isErr(result)) {
  //         setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //         eventAnalysis(
  //           "approve_liq_pool_err",
  //           TriggerType.TRADING_AUTOMATION,
  //           result.error.message
  //         );
  //         setErrorMessage(result.error.message);
  //       } else {
  //         trigger.amount = toApprove;
  //         setApproveLiquidityPoolTokenStatus(progressStatus.SUCCESS);
  //         eventAnalysis(
  //           "approve_liq_pool_success",
  //           TriggerType.TRADING_AUTOMATION,
  //           "Approved liquidity pool token success"
  //         );
  //         setErrorMessage("");
  //       }
  //     }
  //   } catch (err: any) {
  //     setApproveLiquidityPoolTokenStatus(progressStatus.FAILED);
  //     eventAnalysis(
  //       "approve_liq_pool_err",
  //       TriggerType.TRADING_AUTOMATION,
  //       err.message
  //     );
  //     setErrorMessage(err.message);
  //   }
  // };

  // return contract instance
  const getTriggerTypeContract = () => {
    if (state.web3.chainId === undefined) {
      return;
    }

    const networkDetailsResult = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetailsResult)) {
      return;
    }

    const proxyContractAddress =
      networkDetailsResult.value.withdrawLiquidityProxyContractAddress;

    return new Provider._web3.eth.Contract(
      asAbi(withdrawAllProxyABI),
      proxyContractAddress
    );
  };

  const checkIfAgentIsRegistered = async (
    address: string,
    uuid: string
  ): Promise<boolean> => {
    if (state.web3.chainId === undefined) {
      return false;
    }
    const agentManagerContractAddress = await getTriggerTypeContract()
      ?.methods.getAgentManager()
      .call();

    const result = await ProxyContract.isAgentRegisteredForSpecificUser(
      address,
      uuid,
      state.web3.chainId,
      agentManagerContractAddress
    );

    if (isOk(result)) {
      return result.value;
    } else {
      return false;
    }
  };

  const createAgent = async (type: AgentType) => {
    setAgentCreationState(progressStatus.RUNNING);

    const token = state.account.user?.token;
    if (token === undefined) {
      setAgentCreationState(progressStatus.FAILED);
      eventAnalysis(
        "new_agent_err",
        TriggerType.TRADING_AUTOMATION,
        "Unable to create new agent"
      );
      setErrorMessage("Unable to create new agent");
      return;
    }

    const { address } = state.web3;

    if (typeof address === "undefined") {
      setAgentCreationState(progressStatus.FAILED);
      setErrorMessage("No Address selected in MetaMask");
      return;
    }

    if (typeof state.web3.chainId === "undefined") {
      setAgentCreationState(progressStatus.FAILED);
      setErrorMessage("select Network in MetaMask");
      return;
    }

    try {
      const newAgent = await AgentsAPI.createAgent(
        token,
        address,
        type,
        state.web3.networkId
      );
      state.agents.add(newAgent);
      setNewAgent(newAgent);
      setAgentCreationState(progressStatus.SUCCESS);
      setErrorMessage("");
      eventAnalysis(
        "new_agent_success",
        TriggerType.TRADING_AUTOMATION,
        "Agent created successful"
      );
    } catch (err) {
      setAgentCreationState(progressStatus.FAILED);
      eventAnalysis(
        "new_agent_err",
        TriggerType.TRADING_AUTOMATION,
        "Unable to create new agent"
      );
      setErrorMessage("Unable to create users new agent");
    }
  };

  const runApproval = async () => {
    try {
      let result;
      setApprovalState(progressStatus.RUNNING);
      setErrorMessage("");

      if (state?.web3?.address === undefined) {
        setApprovalState(progressStatus.FAILED);
        setErrorMessage("No web3 address");
        return;
      }

      if (state.web3.chainId === undefined) {
        setApprovalState(progressStatus.FAILED);
        setErrorMessage("No web3 chain ID");
        return;
      }

      const networkDetails = getNetworkDetails(state.web3.chainId);

      if (isErr(networkDetails)) {
        setApprovalState(progressStatus.FAILED);
        eventAnalysis(
          "approve_contract_err",
          TriggerType.TRADING_AUTOMATION,
          networkDetails.error.message
        );
        setErrorMessage(networkDetails.error.message);
        return;
      }

      const proxyAddress =
        networkDetails.value.withdrawLiquidityProxyContractAddress;

      const allowance = await ERC20Contract.getAllowance(
        trigger.pair.address,
        state.web3.address,
        proxyAddress
      );
      if (isErr(allowance)) {
        setApprovalState(progressStatus.FAILED);
        eventAnalysis(
          "approve_contract_err",
          TriggerType.TRADING_AUTOMATION,
          allowance.error.message
        );
        setErrorMessage(allowance.error.message);
        return;
      }

      // Addition of approval amount of triggers having same swap token for swap or pair for withdraw
      let AdditionOfTriggerApprovalAmount = "0";
      state.triggers.list().forEach((localTrigger) => {
        const hasTrigger =
          localTrigger.triggerType === TriggerType.WITHDRAW_LIQUIDITY &&
          localTrigger.pair.address === trigger.pair.address;

        if (hasTrigger) {
          AdditionOfTriggerApprovalAmount = addDecimal(
            localTrigger.approveBalance.balance,
            AdditionOfTriggerApprovalAmount
          );
        }
      });

      const decimal = await ERC20Contract.getDecimals(trigger.pair.address);
      if (isErr(decimal)) {
        setApprovalState(progressStatus.FAILED);
        eventAnalysis(
          "approve_contract_err",
          TriggerType.TRADING_AUTOMATION,
          decimal.error.message
        );
        setErrorMessage(decimal.error.message);
        return;
      }
      const amount = toDecimalWithPrecision(
        trigger.amount,
        Number(decimal.value)
      );

      const toApprove = toMultiplyWithPrecision(amount, Number(decimal.value));
      const totalApprovalAmount = addDecimal(
        toApprove,
        AdditionOfTriggerApprovalAmount
      );

      // if we have allowance greater or equal to total approval amount then no need to approve current amount
      // else approve total approval balance
      if (new Decimal(allowance.value).gte(totalApprovalAmount)) {
        trigger.amount = toApprove;
        setApprovalState(progressStatus.SUCCESS);
        eventAnalysis(
          "approve_contract_success",
          TriggerType.TRADING_AUTOMATION,
          "Contract approved successful"
        );
        setErrorMessage("");
      } else {
        const gasPriceInWai = gweiToWei(
          state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
        );
        result = await ERC20Contract.approve(
          trigger.pair.address,
          state.web3.address,
          proxyAddress,
          totalApprovalAmount,
          gasPriceInWai
        );

        if (isErr(result)) {
          setApprovalState(progressStatus.FAILED);
          eventAnalysis(
            "approve_contract_err",
            TriggerType.TRADING_AUTOMATION,
            result.error.message
          );
          setErrorMessage(result.error.message);
        } else {
          trigger.amount = toApprove;
          setApprovalState(progressStatus.SUCCESS);
          eventAnalysis(
            "approve_contract_success",
            TriggerType.TRADING_AUTOMATION,
            "Contract approved successful"
          );
          setErrorMessage("");
        }
      }
    } catch (err: any) {
      setApprovalState(progressStatus.FAILED);
      eventAnalysis(
        "approve_contract_err",
        TriggerType.TRADING_AUTOMATION,
        err.message
      );
      setErrorMessage(err.message);
    }
  };

  const runRegisterAgent = async () => {
    setRegisterAgentState(progressStatus.RUNNING);
    setErrorMessage("");

    if (state.web3.chainId === undefined) {
      setRegisterAgentState(progressStatus.FAILED);
      setErrorMessage("No chain detected");
      return;
    }

    if (state.web3.address === undefined) {
      setRegisterAgentState(progressStatus.FAILED);
      setErrorMessage("No web3 address");
      return;
    }
    if (!newAgent) {
      setRegisterAgentState(progressStatus.FAILED);
      eventAnalysis(
        "register_agent_err",
        TriggerType.TRADING_AUTOMATION,
        "Agent not found"
      );
      setErrorMessage("Agent not found");
      return;
    }
    const alreadyRegistered = await checkIfAgentIsRegistered(
      state.web3.address,
      newAgent.uuid
    );

    if (alreadyRegistered) {
      eventAnalysis(
        "register_agent_success",
        TriggerType.TRADING_AUTOMATION,
        "Agent registered successful"
      );
      runTopUpAgent()
    } else {
      // If agent is not already register then register it
      const chainId = state.web3.chainId as number;

      const address = state.web3.address;

      const agentManagerContractAddress = await getTriggerTypeContract()
        ?.methods.getAgentManager()
        .call();

      const gasPriceinWai = gweiToWei(
        state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
      );

      let amount = await amountSendToAgent(newAgent, state, trigger)

      const result = await ProxyContract.registerAgentForUser({
        agentAddress: newAgent.agentAddress,
        agentUUID: newAgent.uuid,
        chainId: chainId,
        userAddress: address as string,
        contractAddress: agentManagerContractAddress as any,
        amount: amount.toFixed(0),
        gasPrice: gasPriceinWai as string,
      });

      if (isErr(result)) {
        setRegisterAgentState(progressStatus.FAILED);
        eventAnalysis(
          "register_agent_err",
          TriggerType.TRADING_AUTOMATION,
          result.error.message
        );
        setErrorMessage(result.error.message);
      } else {
        setRegisterAgentState(progressStatus.SUCCESS);
        eventAnalysis(
          "register_agent_success",
          TriggerType.TRADING_AUTOMATION,
          "Agent registered successful"
        );
        setErrorMessage("");
      }
    }
  };

  const runTopUpAgent = async () => {
    setRegisterAgentState(progressStatus.RUNNING);
    setErrorMessage("");

    if (state.web3.address === undefined) {
      setRegisterAgentState(progressStatus.FAILED);
      setErrorMessage("No web3 address");
      return;
    }
    let currencyType;
    if (state.web3.chainId) {
      currencyType =
        mapChainId(state.web3.chainId) === Network.ETH ||
          mapChainId(state.web3.chainId) === Network.RINKEBY
          ? "eth"
          : "bnb";
    }
    if (!newAgent) {
      setRegisterAgentState(progressStatus.FAILED);
      eventAnalysis(
        `send_${currencyType}_to_agent_err`,
        TriggerType.TRADING_AUTOMATION,
        "Agent not found"
      );
      setErrorMessage("Agent not found");
      return;
    }


    /**
     * We check if there is enough gas already on the agent for all the triggers and if this is true then we do not add further gas
     *
     * We go through all triggers and calculate the gas that they need and then compare this to the amount of gas on the agent.
     */

    let ethToSend = await amountSendToAgent(newAgent, state, trigger)
    if (ethToSend.isZero()) {
      setRegisterAgentState(progressStatus.SUCCESS);
      eventAnalysis(
        `send_${currencyType}_to_agent_success`,
        TriggerType.TRADING_AUTOMATION,
        `Sending ${currencyType} to agent successful`
      );
      setErrorMessage("");
      return;
    }
    const gasPriceInWai = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );

    let result;
    if (ethToSend !== undefined) {
      result = await Provider.sendFunds(
        state.web3.address,
        newAgent.agentAddress,
        ethToSend.toFixed(0),
        gasPriceInWai
      );
    }

    if (result !== undefined && isErr(result)) {
      setRegisterAgentState(progressStatus.FAILED);
      eventAnalysis(
        `send_${currencyType}_to_agent_err`,
        TriggerType.TRADING_AUTOMATION,
        result.error.toString()
      );
      setErrorMessage(result.error.toString());
    } else {
      setRegisterAgentState(progressStatus.SUCCESS);
      eventAnalysis(
        `send_${currencyType}_to_agent_success`,
        TriggerType.TRADING_AUTOMATION,
        `Sending ${currencyType} to agent successful`
      );
      setErrorMessage("");
    }
  };

  const runCreateTrigger = async () => {
    setCreateTriggerState(progressStatus.RUNNING);
    setErrorMessage("");

    if (state.account.user === undefined) {
      setCreateTriggerState(progressStatus.FAILED);
      setErrorMessage("No selected user");
      return;
    }
    if (!newAgent) {
      setCreateTriggerState(progressStatus.FAILED);
      setErrorMessage("Agent not found");
      return;
    }
    const triggersAPI = new TriggersAPI();
    if (state.web3.chainId === undefined) {
      setCreateTriggerState(progressStatus.FAILED);
      setErrorMessage("No network selected");
      return;
    }
    // This is a temporary way to calculate the protocol type based on the chain which will be changed in future
    const protocolType =
      mapChainId(state.web3.chainId) === Network.ETH
        ? protocols.TYPE_UNISWAP
        : protocols.TYPE_PANCAKESWAP;

    // Add agent uuid in trigger object here if agent not available
    const triggerWithAgent = {
      ...trigger,
      agentUuid: newAgent.uuid,
    };

    const createdTrigger = await triggersAPI.createTrigger(
      state.account.user.token,
      triggerWithAgent,
      state.web3.networkId,
      state.web3.chainId,
      TriggerType.WITHDRAW_LIQUIDITY,
      protocolType
    );

    if (isErr(createdTrigger)) {
      setCreateTriggerState(progressStatus.FAILED);
      eventAnalysis(
        "create_trigger_err",
        TriggerType.TRADING_AUTOMATION,
        "Unable to publish trigger to agent"
      );
      setErrorMessage("Unable to publish trigger to agent");
      return;
    }
    state.triggers.add(createdTrigger.value);
    setCreateTriggerState(progressStatus.SUCCESS);
    eventAnalysis(
      "create_trigger_success",
      TriggerType.TRADING_AUTOMATION,
      "Trigger created successful"
    );
    setErrorMessage("");
  };

  const runCreation = async () => {
    if (!agent && agentCreationState === progressStatus.PENDING) {
      await createAgent(AgentType.STOP_LOSS);
      return;
    }

    if (newAgent && token1ApproveStatus === progressStatus.PENDING) {
      await approveToken1();
    }
    if (
      newAgent &&
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.PENDING
    ) {
      await approveToken2();
    }

    if (
      newAgent &&
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.SUCCESS &&
      addLiquidityStatus === progressStatus.PENDING
    ) {
      await addLiquidityFunc();
    }

    if (
      newAgent &&
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.SUCCESS &&
      addLiquidityStatus === progressStatus.SUCCESS &&
      approvalState === progressStatus.PENDING
    ) {
      await runApproval();
      return;
    }

    if (
      newAgent &&
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.SUCCESS &&
      addLiquidityStatus === progressStatus.SUCCESS &&
      approvalState === progressStatus.SUCCESS &&
      registerAgentState === progressStatus.PENDING
    ) {
      await runRegisterAgent();
      return;
    }

    if (
      newAgent &&
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.SUCCESS &&
      addLiquidityStatus === progressStatus.SUCCESS &&
      approvalState === progressStatus.SUCCESS &&
      registerAgentState === progressStatus.SUCCESS &&
      createTriggerState === progressStatus.PENDING
    ) {
      await runCreateTrigger();
      return;
    }

    if (
      newAgent &&
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.SUCCESS &&
      addLiquidityStatus === progressStatus.SUCCESS &&
      approvalState === progressStatus.SUCCESS &&
      registerAgentState === progressStatus.SUCCESS &&
      createTriggerState === progressStatus.SUCCESS
    ) {
      setIsCompleted(true);
      onComplete();
    }
  };
  useEffect(() => {
    if (
      token1ApproveStatus === progressStatus.SUCCESS &&
      token2ApproveStatus === progressStatus.SUCCESS
    ) {
      setTokensApproveStatus(progressStatus.SUCCESS);
    } else if (
      token1ApproveStatus === progressStatus.FAILED ||
      token2ApproveStatus === progressStatus.FAILED
    ) {
      setTokensApproveStatus(progressStatus.FAILED);
    }
  }, [token1ApproveStatus, token2ApproveStatus]);

  useEffect(() => {

    const getApproveContractAndAgentCreationState = () => {
      return agentCreationState === progressStatus.SUCCESS
        ? tokensApproveStatus
        : agentCreationState
    }

    const getRegisterAgentAndPublishingState = () => {
      return registerAgentState === progressStatus.SUCCESS
        ? createTriggerState
        : registerAgentState
    }

    let data: ProgressModalData[] = [
      {
        title: `Approve tokens: ${trigger.pair.token0} & ${trigger.pair.token1}`,
        statusRunningText: "approving...",
        statusSuccessText: "Approved",
        status: agent ? tokensApproveStatus : getApproveContractAndAgentCreationState(),
        onRetry: () => {
          if (agent) {
            setToken1ApproveStatus(progressStatus.PENDING);
            setToken2ApproveStatus(progressStatus.PENDING);
            setTokensApproveStatus(progressStatus.PENDING);
          } else {
            if (agentCreationState === progressStatus.FAILED) {
              setAgentCreationState(progressStatus.PENDING)
            }
            if (tokensApproveStatus === progressStatus.FAILED) {
              setToken1ApproveStatus(progressStatus.PENDING);
              setToken2ApproveStatus(progressStatus.PENDING);
              setTokensApproveStatus(progressStatus.PENDING);
            }
          }
        },
      },
      {
        title: "Add Liquidity",
        statusRunningText: "adding...",
        statusSuccessText: "Added",
        status: addLiquidityStatus,
        onRetry: () => setAddLiquidityStatus(progressStatus.PENDING),
      },
      {
        title: "Approve contract access to funds",
        statusRunningText: "approving...",
        statusSuccessText: "Approved",
        status: approvalState,
        onRetry: () => setApprovalState(progressStatus.PENDING),
      },
      {
        title: "Register Agent",
        statusRunningText: "registering...",
        statusSuccessText: "Registered",
        status: getRegisterAgentAndPublishingState(),
        onRetry: () => {
          if (registerAgentState === progressStatus.FAILED) {
            setRegisterAgentState(progressStatus.PENDING)
          }
          if (createTriggerState === progressStatus.FAILED) {
            setCreateTriggerState(progressStatus.PENDING)
          }
        },
      },
    ];

    if (agent) {
      setNewAgent(agent);
      setAgentCreationState(progressStatus.SUCCESS);
    }
    setStages([...data]);
  }, [
    agentCreationState,
    token1ApproveStatus,
    token2ApproveStatus,
    tokensApproveStatus,
    addLiquidityStatus,
    approvalState,
    registerAgentState,
    createTriggerState,
  ]);

  useEffect(() => {
    runCreation()
      .then(() => { })
      .catch((err) => {
        setErrorMessage("Network error");
      });
  }, [
    agentCreationState,
    token1ApproveStatus,
    token2ApproveStatus,
    addLiquidityStatus,
    approvalState,
    registerAgentState,
    createTriggerState,
  ]);

  return (
    <ProgressModal
      data={stages}
      isCompleted={isCompleted}
      errorMessage={errorMessage}
      setOpen={setOpen}
      completedMessaage="Transaction successful"
    />
  );
};
