import React from "react";
import { NewTrigger, TriggerChoices, TriggerType } from "../../state/triggers";
import style from "./ConfirmTrigger.module.scss";
import { EtherScanLink } from "../EtherScanLink";
import { Decimal } from "decimal.js";
import { BSCInfo, EthereumInfo, Network } from "../../configs";
import Tooltip from "../../utils/tooltip/tooltip";
import { addCommas } from "../../utils/display/addCommas";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { toNonCanonicalDisplay } from "../../utils/display/toNonCanonicalDisplay";

interface ConfirmTriggerProps {
  trigger: NewTrigger;
  chainId: number | undefined;
  network: Network;
  triggerType: TriggerType;
}

export function ConfirmTrigger(props: ConfirmTriggerProps) {
  if (props.trigger === undefined) {
    return <p>Please select a trigger</p>;
  }

  function triggerToken(token: TriggerChoices | undefined) {
    return token === 0 ? props.trigger.pair.token0 : props.trigger.pair.token1;
  }

  function nonTriggerToken(token: TriggerChoices | undefined) {
    return token !== 0 ? props.trigger.pair.token0 : props.trigger.pair.token1;
  }

  return (
    <>
      <div className={style.confirmContainer}>
        <p>
          You are about to create the following stop loss trigger. Please
          confirm these settings are correct.
        </p>

        {props.triggerType === TriggerType.SWAP ? (
          <table className={style.confirmTable}>
            <tbody>
            <tr>
              <td>From</td>
              <td>
                <span className={style.Highlight} data-testid="fromSwapToken">
                  {triggerToken(props.trigger.swap_token)}
                </span>
              </td>
            </tr>
            <tr>
              <td>To</td>
              <td>
                <span className={style.Highlight} data-testid="toSwapToken">
                  {nonTriggerToken(props.trigger.swap_token)}
                </span>
              </td>
            </tr>
            <tr>
              <td>Pool</td>
              <td>
                <EtherScanLink
                  address={props.trigger.pair.address}
                  text={`${props.trigger.pair.token0} - ${props.trigger.pair.token1}`}
                  className={style.Highlight}
                  chainId={props.chainId}
                />
              </td>
            </tr>
            <tr>
              <td>Slippage tolerance</td>
              <td>
                <span
                  className={style.Highlight}
                >{`${props.trigger.slippage}%`}</span>
              </td>
            </tr>
            <tr>
              <td>Threshold price for tokenIn</td>
              <td>
                <span className={style.Highlight} data-testid="thresholdPrice">
                  {props.trigger.threshold}{" "}
                  {`${nonTriggerToken(props.trigger.token)}/${triggerToken(
                    props.trigger.token
                  )}`}
                </span>
              </td>
            </tr>{" "}
            <tr>
              <td>Amount of tokens to swap</td>
              <td>
                <span className={style.Highlight}>
                  <Tooltip
                    child={
                      <div data-testid="amountOfTokensToSwap">
                        {!formatBigNumber(props.trigger.amount).same ? (
                          <>&#8776; </>
                        ) : (
                          ""
                        )}
                        {formatBigNumber(props.trigger.amount).trunc}
                      </div>
                    }
                    tooltipTextClassName="tool-tip-approve-balance"
                    text={formatBigNumber(props.trigger.amount).full}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>Total Gas</td>
              <td>
                <span className={style.Highlight} data-testid="totalGas">
                  {toNonCanonicalDisplay(
                    new Decimal(props.trigger.gasPrice)
                      .mul(new Decimal(props.trigger.gasAmount))
                      .toString(),
                    props.network === Network.BSC ? BSCInfo : EthereumInfo
                  )}
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        ) : (
          <table className={style.confirmTable}>
            <tbody>
            <tr>
              <td>Pool</td>
              <td>
                <EtherScanLink
                  address={props.trigger.pair.address}
                  text={`${props.trigger.pair.token0} - ${props.trigger.pair.token1}`}
                  className={style.Highlight}
                  chainId={props.chainId}
                />
              </td>
            </tr>
            <tr>
              <td>Threshold</td>
              <td>
                <span className={style.Highlight} data-testid="threshold">
                  {props.trigger.threshold}{" "}
                  {`${nonTriggerToken(props.trigger.token)}/${triggerToken(
                    props.trigger.token
                  )}`}
                </span>
              </td>
            </tr>
            <tr>
              <td>Withdrawal Gas Price</td>
              <td>
                <span
                  className={style.Highlight}
                  data-testid="withdrawalGasPrice"
                >
                  {toNonCanonicalDisplay(
                    props.trigger.gasPrice,
                    props.network === Network.BSC ? BSCInfo : EthereumInfo
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td>Withdrawal Gas Amount</td>
              <td>
                <Tooltip
                  child={
                    <span
                      className={style.Highlight}
                      data-testid="withdrawGasAmount"
                    >
                      {addCommas(props.trigger.gasAmount)}
                    </span>
                  }
                  tooltipTextClassName="tool-tip-gas-threshold"
                  text="Gas required to make the liquidity withdrawal"
                />
              </td>
            </tr>{" "}
            <tr>
              <td>Amount to approve for withdraw</td>
              <td>
                <span className={style.Highlight} data-testid="amount">
                  <Tooltip
                    child={
                      <>
                        {!formatBigNumber(props.trigger.amount).same ? (
                          <>&#8776; </>
                        ) : (
                          ""
                        )}
                        {formatBigNumber(props.trigger.amount).trunc}
                      </>
                    }
                    tooltipTextClassName="tool-tip-approve-balance"
                    text={formatBigNumber(props.trigger.amount).full}
                  />
                </span>
              </td>
            </tr>
            <tr>
              <td>Total Gas</td>
              <td>
                <span className={style.Highlight} data-testid="totalGas">
                  {toNonCanonicalDisplay(
                    new Decimal(props.trigger.gasPrice)
                      .mul(new Decimal(props.trigger.gasAmount))
                      .toString(),
                    props.network === Network.BSC ? BSCInfo : EthereumInfo
                  )}
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
}
