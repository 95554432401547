import { Decimal } from "decimal.js";

export function toDecimal(value: any | undefined): Decimal {
  if (value !== undefined) {
    try {
      return new Decimal(value);
    } catch {}
  }

  return new Decimal(0);
}
