export enum BASIC_STORAGE_KEYS {
  TOKEN_KEY = "TOKEN_KEY",
  LOGGED_IN = "LOGGED_IN",
}

export enum INDEXED_STORAGE_KEYS {
  HAS_EVER_HAD_TRIGGER = "HAS_EVER_HAD_TRIGGER",
  HAS_EVER_HAD_AGENT = "HAS_EVER_HAD_AGENT",
}

export interface storageItem {
  address: string;
  value: any;
}
