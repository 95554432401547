import { getNetworkDetails } from "../../configs";
import { isErr } from "../../result";

/**
 * if we have wrapped native token then we call it eth rather than weth else we just return the symbol as is
 *
 * @param chainId
 * @param symbol to change if weth and address is of wrapped native token
 * @param address
 */
export function wrappedNameConversion(
  chainId: number | undefined,
  symbol: string | undefined,
  address: string | undefined
) {
  if (chainId === undefined || symbol === undefined || address === undefined) {
    return symbol;
  }

  const networkDetails = getNetworkDetails(chainId);

  if (isErr(networkDetails)) {
    return symbol;
  }

  if (
    address.toUpperCase() !==
    networkDetails.value.wrappedNativeTokenAddress.toUpperCase()
  ) {
    return symbol;
  }

  if (symbol.toUpperCase() === "WETH") {
    return "ETH";
  }
  if (symbol.toUpperCase() === "WBNB") {
    return "BNB";
  }

  return symbol;
}
