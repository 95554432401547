/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { DebounceInput } from "react-debounce-input";
import { Network } from "../../configs";

import style from "./dashBoard.module.scss";
import FilterSandwitchIcon from "../../assets/dashboard-filter-sandwitch.svg";

const DISPLAY_NETWORKS = [Network.ETH, Network.BSC];

export interface FilteringOptions {
  query: string;
  ordering: string | null;
  chain: number | null;
}

export const FiltersBlock = ({
  updateFilteringOptions,
}: {
  updateFilteringOptions: (filteringOptions: FilteringOptions) => void;
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [ordering, setOrdering] = useState<string | null>(null);
  const [chain, setChain] = useState<number | null>(null);
  const [query, setQuery] = useState<string>("");

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setQuery(event.target.value.toLowerCase())
    updateFilteringOptions({chain: chain, ordering: ordering, query: event.target.value.toLowerCase() });
  };

  const handleFilterIconClick = (): void => {
    setShowFilters(!showFilters);
  };

  const handleChainOrderingClick = (): void => {
    if (ordering === "chain") {
      setOrdering(null);
    } else {
      setOrdering("chain");
    }
  };

  const handleFilterChainClick = (chainId: number): void => {
    if (chain === chainId) {
      setChain(null);
    } else {
      setChain(chainId);
    }
  };

  const handleFiltersResetClick = (): void => {
    setOrdering(null);
    setChain(null);
  };

  const handleSaveClick = (): void => {
    updateFilteringOptions({chain: chain, ordering: ordering, query: query})
  }

  const getChainFilters = (): Array<JSX.Element> => {
    const entries: Array<JSX.Element> = [];
    for (const chainId in DISPLAY_NETWORKS) {
      if (!isNaN(Number(chainId))) {
        const numChain = Number(chainId);
        entries.push(
          <div
            className={`tw-cursor-pointer tw-bg-inherit tw-shadow tw-rounded-2xl tw-w-full tw-h-8 tw-pt-1 tw-text-center tw-mt-1 ${
              chain === numChain && style.filterDropdownBtnActive
            }`}
            key={chainId}
            onClick={() => handleFilterChainClick(numChain)}
          >
            {Network[chainId]}
          </div>
        );
      }
    }
    return entries;
  };

  return (
    <div className="tw-inline-block">
      <DebounceInput
        minLength={1}
        debounceTimeout={300}
        onChange={handleSearchChange}
        className={`tw-w-36 tw-h-8 bg-white tw-shadow-md tw-rounded-2xl indent-4 tw-ml-5 ${style.searchInput}`}
      />
      <FontAwesomeIcon
        icon={faSearch}
        className="tw-relative tw-right-8 tw-text-gray-400"
      />

      {/* Remove tw-hidden class in next item to show filters button */}
      <div className="tw-inline-block tw-hidden">
        <img
          alt=""
          src={FilterSandwitchIcon}
          className={`tw-mr-7 hover:cursor-pointer ${style.filterSandwitchIcon}`}
          onClick={handleFilterIconClick}
        />
        {showFilters && (
          <div
            className={`${style.filterDropdown} tw-w-80 tw-box-border tw-bg-gray-50 tw-border-solid tw-border-t-0 tw-absolute tw-mt-4 tw-rounded-b-2xl tw-font-inter tw-text-base tw-text-black tw-z-10`}
          >
            <div className="tw-p-4 tw-inline-flex">
              Sort by
              <div
                className={`tw-cursor-pointer tw-bg-inherit tw-shadow tw-rounded-2xl tw-w-28 tw-h-8 tw-pt-1 tw-text-center tw-ml-2.5 ${
                  ordering === "chain" && style.filterDropdownBtnActive
                }`}
                onClick={handleChainOrderingClick}
              >
                Chain
              </div>
            </div>

            <hr className="tw-m-0" />

            <div className="tw-p-4">
              Select chain
              {getChainFilters()}
            </div>

            <div className="tw-p-4 tw-inline-flex">
              <div
                className="tw-cursor-pointer tw-bg-inherit tw-shadow tw-rounded-2xl tw-w-32 tw-h-10 tw-pt-2 tw-text-center tw-mr-6"
                onClick={handleFiltersResetClick}
              >
                Reset
              </div>
              <div
                className={`tw-cursor-pointer tw-bg-inherit tw-shadow tw-rounded-2xl tw-w-32 tw-h-10 tw-pt-2 tw-text-center tw-mr-6 ${style.filterDropdownBtnActive}`}
                onClick={handleSaveClick}
              >
                Save changes
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
