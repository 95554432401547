import React from "react";
import { ENABLE_ETH_ADDRESS_DISPLAY } from "../configs";
import style from "./EthereumAddressTable.module.scss";

interface EthereumAddressTableProps {
  addresses: string[];
}

export function EthereumAddressTable(props: EthereumAddressTableProps) {
  return (
    <>
      {ENABLE_ETH_ADDRESS_DISPLAY && (
        <div className={style.container}>
          <table className={style.addressTable}>
            <thead>
              <tr>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {props.addresses.length === 0 && (
                <tr>
                  <td className={style.center}>
                    No Addresses currently associated with this account
                  </td>
                </tr>
              )}
              {props.addresses.map((address, idx) => (
                <tr key={idx}>
                  <td className={style.address}>{address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
