import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";
import {
  ProgressModal,
  ProgressModalData,
  progressStatus,
} from "../../../components/ProgressModal";
import {
  getNetworkDetails,
  mapChainId,
  Network,
  protocols,
} from "../../../configs";
import { isErr, isOk } from "../../../result";
import ERC20Contract from "../../../services/contracts/ERC20Contract";
import { toMultiplyWithPrecision } from "../../../services/contracts/PairContract";
import { Weth } from "../../../services/contracts/Weth";
import { HoldingToken } from "../../../state/assets";
import { StateStore } from "../../../state/state";
import {
  NewTrigger,
  Trigger,
  TriggerBase,
  TriggerType,
} from "../../../state/triggers";
import { gweiToWei } from "../../../utils/currency/GweiToWei";
import { addDecimal } from "../../../utils/numeric/addBigNumber";
import swapProxyABI from "../../../abis/SwapProxy.json";
import { asAbi } from "../../../services/contracts/utils";
import Provider from "../../../services/Provider";
import { ProxyContract } from "../../../services/contracts/ProxyContract";
import { Agent, AgentType } from "../../../state/agents";
import { getBalance } from "../../../utils/networkRequests/getBalance";
import { TriggersAPI } from "../../../api/TriggersAPI";
import { AgentsAPI } from "../../../api/AgentsAPI";
import { eventAnalysis } from "../../../utils/misc/trackEvent";
import { amountSendToAgent } from "../utils";

export const SwapProgress = ({
  setOpen,
  trigger,
  fromToken,
  agent,
  state,
  onComplete,
}: {
  setOpen: (val: boolean) => void;
  trigger: NewTrigger;
  fromToken: HoldingToken;
  agent: Agent | undefined;
  state: StateStore;
  onComplete: () => void;
}) => {
  const [agentCreationState, setAgentCreationState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [wethConversionState, setWethConversionState] =
    useState<progressStatus>(progressStatus.PENDING);
  const [approvalState, setApprovalState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [registerAgentState, setRegisterAgentState] = useState<progressStatus>(
    progressStatus.PENDING
  );

  const [registerAgentAndPublishingState, setRegisterAgentAndPublishingState] =
    useState<progressStatus>(progressStatus.PENDING);

  const [createTriggerState, setCreateTriggerState] = useState<progressStatus>(
    progressStatus.PENDING
  );
  const [stages, setStages] = useState<ProgressModalData[]>([]);

  const [newAgent, setNewAgent] = useState<Agent | undefined>();

  const [errorMessage, setErrorMessage] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);

  const checkIfAgentIsRegistered = async (
    address: string,
    uuid: string
  ): Promise<boolean> => {
    if (state.web3.chainId === undefined) {
      return false;
    }
    const agentManagerContractAddress = await getTriggerTypeContract()
      ?.methods.getAgentManager()
      .call();

    const result = await ProxyContract.isAgentRegisteredForSpecificUser(
      address,
      uuid,
      state.web3.chainId,
      agentManagerContractAddress
    );

    if (isOk(result)) {
      return result.value;
    } else {
      return false;
    }
  };

  const getTriggerTypeContract = () => {
    if (state.web3.chainId === undefined) {
      return;
    }

    const networkDetailsResult = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetailsResult)) {
      return;
    }

    const triggerTypeAddress =
      networkDetailsResult.value.swapProxyContractAddress;

    return new Provider._web3.eth.Contract(
      asAbi(swapProxyABI),
      triggerTypeAddress
    );
  };

  const createAgent = async (type: AgentType) => {
    setAgentCreationState(progressStatus.RUNNING);

    const token = state.account.user?.token;
    if (token === undefined) {
      setAgentCreationState(progressStatus.FAILED);
      eventAnalysis(
        "new_agent_err",
        TriggerType.SWAP,
        "Unable to create new agent"
      );
      setErrorMessage("Unable to create new agent");
      return;
    }

    const { address } = state.web3;

    if (typeof address === "undefined") {
      setAgentCreationState(progressStatus.FAILED);
      setErrorMessage("No Address selected in MetaMask");
      return;
    }

    if (typeof state.web3.chainId === "undefined") {
      setAgentCreationState(progressStatus.FAILED);
      setErrorMessage("select Network in MetaMask");
      return;
    }

    try {
      const newAgent = await AgentsAPI.createAgent(
        token,
        address,
        type,
        state.web3.networkId
      );
      state.agents.add(newAgent);
      setNewAgent(newAgent);
      setAgentCreationState(progressStatus.SUCCESS);
      setErrorMessage("");
      eventAnalysis(
        "new_agent_success",
        TriggerType.SWAP,
        "Agent created successful"
      );
    } catch (err) {
      setAgentCreationState(progressStatus.FAILED);
      eventAnalysis(
        "new_agent_err",
        TriggerType.SWAP,
        "Unable to create new agent"
      );
      setErrorMessage("Unable to create users new agent");
    }
  };

  const convertEthToWeth = async () => {
    setWethConversionState(progressStatus.RUNNING);

    if (state.web3.address === undefined) {
      setWethConversionState(progressStatus.FAILED);
      setErrorMessage("No web3 address");
      return;
    }

    if (state.web3.chainId === undefined) {
      setWethConversionState(progressStatus.FAILED);
      setErrorMessage("No web3 chain ID");
      return;
    }

    const networkDetails = getNetworkDetails(state.web3.chainId);

    if (isErr(networkDetails)) {
      setWethConversionState(progressStatus.FAILED);
      eventAnalysis(
        "wrap_conversion_err",
        TriggerType.SWAP,
        networkDetails.error.message
      );
      setErrorMessage(networkDetails.error.message);
      return;
    }

    let decimals = networkDetails.value.nativeCurrency.decimals;

    const gasPriceinWei = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );

    const result = await Weth.convert(
      state.web3.address,
      toMultiplyWithPrecision(trigger.amount, decimals).toString(),
      state.web3.chainId,
      gasPriceinWei
    );
    if (isErr(result)) {
      setWethConversionState(progressStatus.FAILED);
      eventAnalysis(
        "wrap_conversion_err",
        TriggerType.SWAP,
        result.error.message
      );
      setErrorMessage(result.error.message);
    } else {
      setWethConversionState(progressStatus.SUCCESS);
      eventAnalysis(
        "wrap_conversion_success",
        TriggerType.SWAP,
        "Wrapped conversion successful"
      );
      setErrorMessage("");
    }
  };

  const runApproval = async () => {
    try {
      let result;
      setApprovalState(progressStatus.RUNNING);
      setErrorMessage("");

      if (state?.web3?.address === undefined) {
        setApprovalState(progressStatus.FAILED);
        setErrorMessage("No web3 address");
        return;
      }

      if (state.web3.chainId === undefined) {
        setApprovalState(progressStatus.FAILED);
        setErrorMessage("No web3 chain ID");
        return;
      }
      if (fromToken.address === undefined) {
        setApprovalState(progressStatus.FAILED);
        setErrorMessage("Token address not found");
        return;
      }

      const networkDetails = getNetworkDetails(state.web3.chainId);

      if (isErr(networkDetails)) {
        setApprovalState(progressStatus.FAILED);
        eventAnalysis(
          "approve_contract_err",
          TriggerType.SWAP,
          networkDetails.error.message
        );
        setErrorMessage(networkDetails.error.message);
        return;
      }

      const proxyAddress = networkDetails.value.swapProxyContractAddress;

      const allowance = await ERC20Contract.getAllowance(
        fromToken.address,
        state.web3.address,
        proxyAddress
      );
      if (isErr(allowance)) {
        setApprovalState(progressStatus.FAILED);
        eventAnalysis(
          "approve_contract_err",
          TriggerType.SWAP,
          allowance.error.message
        );
        setErrorMessage(allowance.error.message);
        return;
      }

      // Addition of approval amount of triggers having same swap token for swap or pair for withdraw
      let AdditionOfTriggerApprovalAmount = "0";
      state.triggers.list().forEach((trigger) => {
        const tokenAddress =
          trigger.swap_token === 0
            ? trigger.pair.token0Address
            : trigger.pair.token1Address;
        const hasTrigger =
          trigger.triggerType === TriggerType.SWAP &&
          tokenAddress === fromToken.address;

        if (hasTrigger) {
          AdditionOfTriggerApprovalAmount = addDecimal(
            trigger.approveBalance.balance,
            AdditionOfTriggerApprovalAmount
          );
        }
      });

      const decimal = await ERC20Contract.getDecimals(fromToken.address);
      if (isErr(decimal)) {
        setApprovalState(progressStatus.FAILED);
        eventAnalysis(
          "approve_contract_err",
          TriggerType.SWAP,
          decimal.error.message
        );
        setErrorMessage(decimal.error.message);
        return;
      }

      const toApprove = toMultiplyWithPrecision(
        trigger.amount,
        Number(decimal.value)
      );
      const totalApprovalAmount = addDecimal(
        toApprove,
        AdditionOfTriggerApprovalAmount
      );

      // if we have allowance greater or equal to total approval amount then no need to approve current amount
      // else approve total approval balance
      if (new Decimal(allowance.value).gte(totalApprovalAmount)) {
        trigger.amount = toApprove;
        setApprovalState(progressStatus.SUCCESS);
        eventAnalysis(
          "approve_contract_success",
          TriggerType.SWAP,
          "Contract approved succesfully"
        );
        setErrorMessage("");
      } else {
        const gasPriceInWai = gweiToWei(
          state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
        );
        result = await ERC20Contract.approve(
          fromToken.address,
          state.web3.address,
          proxyAddress,
          totalApprovalAmount,
          gasPriceInWai
        );

        if (isErr(result)) {
          setApprovalState(progressStatus.FAILED);
          eventAnalysis(
            "approve_contract_err",
            TriggerType.SWAP,
            result.error.message
          );
          setErrorMessage(result.error.message);
        } else {
          trigger.amount = toApprove;
          setApprovalState(progressStatus.SUCCESS);
          eventAnalysis(
            "approve_contract_success",
            TriggerType.SWAP,
            "Contract approved succesfully"
          );
          setErrorMessage("");
        }
      }
    } catch (err: any) {
      setApprovalState(progressStatus.FAILED);
      eventAnalysis(
        "approve_contract_err",
        TriggerType.SWAP,
        "Wrapped conversion failed"
      );
      setErrorMessage(err.message);
    }
  };

  const runRegisterAgent = async () => {
    setRegisterAgentState(progressStatus.RUNNING);
    setErrorMessage("");

    if (state.web3.chainId === undefined) {
      setRegisterAgentState(progressStatus.FAILED);
      setErrorMessage("no chain detected");
      return;
    }

    if (state.web3.address === undefined) {
      setRegisterAgentState(progressStatus.FAILED);
      setErrorMessage("No web3 address");
      return;
    }
    if (!newAgent) {
      setRegisterAgentState(progressStatus.FAILED);
      eventAnalysis("register_agent_err", TriggerType.SWAP, "Agent not found");
      setErrorMessage("Agent not found");
      return;
    }
    const alreadyRegistered = await checkIfAgentIsRegistered(
      state.web3.address,
      newAgent.uuid
    );

    if (alreadyRegistered) {
      eventAnalysis(
        "register_agent_success",
        TriggerType.SWAP,
        "Agent registered successful"
      );
      runTopUpAgent();
      // setRegisterAgentState(progressStatus.SUCCESS);
    } else {
      // If agent is not already register then register it
      const chainId = state.web3.chainId as number;

      const address = state.web3.address;

      const agentManagerContractAddress = await getTriggerTypeContract()
        ?.methods.getAgentManager()
        .call();

      const gasPriceinWai = gweiToWei(
        state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
      );

      let ethToSend: Decimal;

      ethToSend = await amountSendToAgent(newAgent, state, trigger);

      const result = await ProxyContract.registerAgentForUser({
        agentAddress: newAgent.agentAddress,
        agentUUID: newAgent.uuid,
        chainId: chainId,
        userAddress: address as string,
        contractAddress: agentManagerContractAddress as any,
        amount: ethToSend?.toFixed(0),
        gasPrice: gasPriceinWai as string,
      });

      if (isErr(result)) {
        setRegisterAgentState(progressStatus.FAILED);
        eventAnalysis(
          "register_agent_err",
          TriggerType.SWAP,
          result.error.message
        );
        setErrorMessage(result.error.message);
      } else {
        setRegisterAgentState(progressStatus.SUCCESS);
        eventAnalysis(
          "register_agent_success",
          TriggerType.SWAP,
          "Agent registered successful"
        );
        setErrorMessage("");
      }
    }
  };

  const runTopUpAgent = async () => {
    setRegisterAgentState(progressStatus.RUNNING);
    setErrorMessage("");

    if (state.web3.address === undefined) {
      setRegisterAgentState(progressStatus.FAILED);
      setErrorMessage("No web3 address");
      return;
    }
    let currencyType;
    if (state.web3.chainId) {
      currencyType =
        mapChainId(state.web3.chainId) === Network.ETH ||
        mapChainId(state.web3.chainId) === Network.RINKEBY
          ? "eth"
          : "bnb";
    }

    if (!newAgent) {
      setRegisterAgentState(progressStatus.FAILED);
      eventAnalysis(
        `send_${currencyType}_to_agent_err`,
        TriggerType.SWAP,
        "Agent not found"
      );
      setErrorMessage("Agent not found");
      return;
    }

    let ethToSend: Decimal | undefined;

    ethToSend = await amountSendToAgent(newAgent, state, trigger);

    if (ethToSend.isZero()) {
      setRegisterAgentState(progressStatus.SUCCESS);
      eventAnalysis(
        `send_${currencyType}_to_agent_success`,
        TriggerType.SWAP,
        `Sending ${currencyType} to agent successful`
      );
      setErrorMessage("");
      return;
    }
    const gasPriceInWai = gweiToWei(
      state.account.gasPrice?.price ?? state.eth.gasPrices.LOW
    );

    let result;
    if (ethToSend !== undefined) {
      result = await Provider.sendFunds(
        state.web3.address,
        newAgent.agentAddress,
        ethToSend.toFixed(0),
        gasPriceInWai
      );
    }

    if (result !== undefined && isErr(result)) {
      setRegisterAgentState(progressStatus.FAILED);
      eventAnalysis(
        `send_${currencyType}_to_agent_err`,
        TriggerType.SWAP,
        result.error.toString()
      );
      setErrorMessage(result.error.toString());
    } else {
      setRegisterAgentState(progressStatus.SUCCESS);
      eventAnalysis(
        `send_${currencyType}_to_agent_success`,
        TriggerType.SWAP,
        `Sending ${currencyType} to agent successful`
      );
      setErrorMessage("");
    }
  };

  const runCreateTrigger = async () => {
    setCreateTriggerState(progressStatus.RUNNING);
    setErrorMessage("");

    if (state.account.user === undefined) {
      setCreateTriggerState(progressStatus.FAILED);
      setErrorMessage("No selected user");
      return;
    }
    if (!newAgent) {
      setCreateTriggerState(progressStatus.FAILED);
      setErrorMessage("Agent not found");
      return;
    }
    const triggersAPI = new TriggersAPI();
    if (state.web3.chainId === undefined) {
      setCreateTriggerState(progressStatus.FAILED);
      setErrorMessage("No network selected");
      return;
    }
    // This is a temporary way to calculate the protocol type based on the chain which will be changed in future
    const protocolType =
      mapChainId(state.web3.chainId) === Network.ETH
        ? protocols.TYPE_UNISWAP
        : protocols.TYPE_PANCAKESWAP;

    // Add agent uuid in trigger object here if agent not available
    const triggerWithAgent = {
      ...trigger,
      agentUuid: newAgent.uuid,
    };

    const createdTrigger = await triggersAPI.createTrigger(
      state.account.user.token,
      triggerWithAgent,
      state.web3.networkId,
      state.web3.chainId,
      TriggerType.SWAP,
      protocolType
    );

    if (isErr(createdTrigger)) {
      setCreateTriggerState(progressStatus.FAILED);
      eventAnalysis(
        "create_trigger_err",
        TriggerType.SWAP,
        "Unable to publish trigger to agent"
      );
      setErrorMessage("Unable to publish trigger to agent");
      return;
    }
    state.triggers.add(createdTrigger.value);
    setCreateTriggerState(progressStatus.SUCCESS);
    eventAnalysis(
      "create_trigger_success",
      TriggerType.SWAP,
      "Trigger created successful"
    );
    setErrorMessage("");
  };

  const checkWrappedConversionApproval = () => {
    if (fromToken?.isNative) {
      return wethConversionState === progressStatus.SUCCESS;
    }
    return true;
  };

  const runCreation = async () => {
    if (!agent && agentCreationState === progressStatus.PENDING) {
      await createAgent(AgentType.STOP_LOSS);
      return;
    }

    if (
      newAgent &&
      fromToken.isNative &&
      wethConversionState === progressStatus.PENDING
    ) {
      await convertEthToWeth();
      return;
    }

    if (
      newAgent &&
      checkWrappedConversionApproval() &&
      approvalState === progressStatus.PENDING
    ) {
      await runApproval();
      return;
    }

    if (
      newAgent &&
      checkWrappedConversionApproval() &&
      approvalState === progressStatus.SUCCESS &&
      registerAgentState === progressStatus.PENDING
    ) {
      await runRegisterAgent();
      return;
    }

    if (
      newAgent &&
      checkWrappedConversionApproval() &&
      approvalState === progressStatus.SUCCESS &&
      registerAgentState === progressStatus.SUCCESS &&
      createTriggerState === progressStatus.PENDING
    ) {
      await runCreateTrigger();
      return;
    }

    if (
      newAgent &&
      checkWrappedConversionApproval() &&
      approvalState === progressStatus.SUCCESS &&
      registerAgentState === progressStatus.SUCCESS &&
      createTriggerState === progressStatus.SUCCESS
    ) {
      setIsCompleted(true);
      onComplete();
    }
  };

  useEffect(() => {
    let nativeTokenSymbol =
      state.web3.networkId === Network.ETH ? "ETH" : "BNB";
    let wrappedTokenSymbol =
      state.web3.networkId === Network.ETH ? "WETH" : "WBNB";

    const getWethConversionAndAgentCreationState = () => {
      return agentCreationState === progressStatus.SUCCESS
        ? wethConversionState
        : agentCreationState;
    };

    const getApproveContractAndAgentCreationState = () => {
      if (agent) return approvalState;

      return agentCreationState === progressStatus.SUCCESS
        ? approvalState
        : agentCreationState;
    };

    const getRegisterAgentAndPublishingState = () => {
      return registerAgentState === progressStatus.SUCCESS
        ? createTriggerState
        : registerAgentState;
    };

    const wethToEthConversionStage = {
      title: `${nativeTokenSymbol} to ${wrappedTokenSymbol} conversion`,
      statusRunningText: "converting...",
      statusSuccessText: "Converted",
      status: agent
        ? wethConversionState
        : getWethConversionAndAgentCreationState(),
      onRetry: () => {
        if (agent) {
          setWethConversionState(progressStatus.PENDING);
        } else {
          if (agentCreationState === progressStatus.FAILED) {
            setAgentCreationState(progressStatus.PENDING);
          }
          if (wethConversionState === progressStatus.FAILED) {
            setWethConversionState(progressStatus.PENDING);
          }
        }
      },
    };

    let data: ProgressModalData[] = [
      {
        title: "Approve contract access to funds",
        statusRunningText: "approving...",
        statusSuccessText: "Approved",
        status: fromToken.isNative
          ? approvalState
          : getApproveContractAndAgentCreationState(),
        onRetry: () => {
          if (agent) {
            setApprovalState(progressStatus.PENDING);
          } else {
            if (agentCreationState === progressStatus.FAILED) {
              setAgentCreationState(progressStatus.PENDING);
            }
            if (approvalState === progressStatus.FAILED) {
              setApprovalState(progressStatus.PENDING);
            }
          }
        },
      },
      {
        title: "Register Agent",
        statusRunningText: "registering...",
        statusSuccessText: "Registered",
        status: getRegisterAgentAndPublishingState(),
        onRetry: () => {
          if (registerAgentState === progressStatus.FAILED) {
            setRegisterAgentState(progressStatus.PENDING);
          }
          if (createTriggerState === progressStatus.FAILED) {
            setCreateTriggerState(progressStatus.PENDING);
          }
        },
      },
    ];

    if (fromToken.isNative) {
      data.unshift(wethToEthConversionStage);
    }
    if (agent) {
      setNewAgent(agent);
      setAgentCreationState(progressStatus.SUCCESS);
    }
    setStages([...data]);
  }, [
    agentCreationState,
    wethConversionState,
    approvalState,
    registerAgentState,
    createTriggerState,
  ]);

  useEffect(() => {
    runCreation()
      .then(() => {})
      .catch((err) => {
        setErrorMessage("Network error: " + err);
      });
  }, [
    agentCreationState,
    wethConversionState,
    approvalState,
    registerAgentState,
    createTriggerState,
  ]);

  return (
    <ProgressModal
      data={stages}
      isCompleted={isCompleted}
      errorMessage={errorMessage}
      setOpen={setOpen}
      completedMessaage="Swap Transaction Successful"
    />
  );
};
