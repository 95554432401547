import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import Tooltip from "../../utils/tooltip/tooltip";

const DOLLAR_DECIMALS = 2;

export const PricePercentageInput = ({
  inputPercentage,
  handleInputPercentage,
  price,
}: {
  inputPercentage: number | undefined;
  handleInputPercentage: Function;
  price: { usdPrice: number; spotPrice: number };
}) => {
  return (
    <div className="tw-col-span-2 tw-mr-6">
      <div
        className={`tw-relative tw-flex tw-shadow-md tw-rounded-full tw-pr-4 tw-h-7 tw-mb-4 ${
          inputPercentage ? "tw-bg-green-300" : ""
        }`}
      >
        <input
          type="number"
          className={`tw-w-full tw-rounded-full tw-text-right focus:tw-outline-none tw-indent-8 tw-px-1 ${
            inputPercentage ? "tw-bg-transparent" : ""
          }`}
          onChange={(event) => {
            handleInputPercentage(parseFloat(event.target.value));
          }}
          value={inputPercentage === undefined ? 0 : inputPercentage}
          placeholder="0"
        />
        <div className="tw-absolute tw-right-1 tw-top-2/4 tw-transform tw--translate-y-2/4">
          %
        </div>
      </div>
      <div className="tw-flex">
        <span className="tw-text-xl tw-font-medium tw-mr-4">Price</span>
        <span>
          <div className="tw-text-center">
            {price.spotPrice && inputPercentage && (
              <Tooltip
                child={`${
                  formatBigNumber(price.spotPrice.toString() || "0.00").trunc
                }`}
                tooltipTextClassName="tool-tip-center"
                text={`${
                  formatBigNumber(price.spotPrice.toString() || "0.00").full
                }`}
                hideTooltip={
                  formatBigNumber(price.spotPrice.toString() || "0.00").same
                }
              />
            )}
          </div>
          <div className="tw-text-center tw-text-xs">
            (
            <Tooltip
              child={`$ ${
                formatBigNumber(
                  price.spotPrice ? price.usdPrice.toString() : "0.00",
                  DOLLAR_DECIMALS
                ).trunc
              }`}
              tooltipTextClassName={"tool-tip-center"}
              text={`$ ${
                formatBigNumber(
                  price.spotPrice
                    ? price.usdPrice.toString() + "00000000"
                    : "0.00",
                  DOLLAR_DECIMALS
                ).full
              }`}
              hideTooltip={
                formatBigNumber(
                  price.spotPrice
                    ? price.usdPrice.toString() + "00000000"
                    : "0.00",
                  DOLLAR_DECIMALS
                ).same
              }
            />
            )
          </div>
        </span>
      </div>
    </div>
  );
};
