import appLogo from "../../assets/fetch-logo.svg";
import { Link, useLocation } from "react-router-dom";
import React from "react";

import style from "./Navbar.module.scss";
import classNames from "classnames";
import { Web3AddressNav } from "../Web3AddressNav";
import { NetworkSlider } from "../NetworkSlider";
import { MAIN_WEBSITE_URI, Network } from "../../configs";
import { SelectGasPrice } from "../SelectGasPrice/SelectGasPrice";

function showEthSlider(
  address: string,
  web3Unlocked: boolean,
  web3Installed: boolean
) {
  return !!(web3Installed && web3Unlocked && address !== "");
}

interface NavbarProps {
  address: string;
  chainId: number;
  network: Network;
  web3Unlocked: boolean;
  web3Installed: boolean;
}

interface LinkItem {
  text: string;
  url: string;
  screenName: string;
}

function buildNavItemsClasses(url: string, currentUrl: string) {
  if (url === currentUrl) {
    return classNames(style.NavLink, style.NavLinkActive);
  }
  return classNames(style.NavLink, style.NavLinkInactive);
}

export const links: LinkItem[] = [
  {
    text: "Dashboard",
    url: "/dashboard",
    screenName: "Dashboard",
  },
  {
    text: "Agents",
    url: "/agents",
    screenName: "Agents",
  },
  {
    text: "Automations",
    url: "/automations",
    screenName: "Automations",
  },
  {
    text: "Account",
    url: "/account",
    screenName: "Account",
  },
];

export function Navbar(props: NavbarProps) {
  const { pathname } = useLocation();

  return (
    <nav className={style.NavBar}>
      <div className={style.NavLeftColumn}>
        <a href={MAIN_WEBSITE_URI} target="_blank" rel="noopener noreferrer">
          <img src={appLogo} alt="Fetch.ai logo" className={style.NavLogo} />
        </a>
      </div>
      <div className={style.NavCenterColumn}>
        {links.map((l, i) => (
          <Link
            to={l.url}
            className={buildNavItemsClasses(l.url, pathname)}
            key={i}
          >
            {/* <FontAwesomeIcon icon={l.icon} className={style.NavLinkIcon} /> */}
            <span>{l.text}</span>
          </Link>
        ))}
      </div>

      <div
        className={classNames(
          style.NavRightColumn,
          showEthSlider(props.address, props.web3Unlocked, props.web3Installed)
            ? style.ethSliderAdjustments
            : ""
        )}
      >
        <SelectGasPrice />
        {showEthSlider(
          props.address,
          props.web3Unlocked,
          props.web3Installed
        ) && (
          <NetworkSlider
            address={props.address}
            chainId={props.chainId}
            web3Unlocked={props.web3Unlocked}
            web3Installed={props.web3Installed}
          />
        )}
        <Web3AddressNav
          address={props.address}
          chainId={props.chainId}
          network={props.network}
          web3Unlocked={props.web3Unlocked}
          web3Installed={props.web3Installed}
          pathname={pathname}
        />
      </div>
    </nav>
  );
}
