import { ethers } from "ethers";

const removeTailingZero = (num: string) => {
  return num.replace(/(\.0+|0+)$/, "");
};
export const addDecimal = (num1: string, num2: string) => {
  let [num1DecimalPart, num1FractionalPart] = num1.split(".");
  let [num2DecimalPart, num2FractionalPart] = num2.split(".");

  const haveFractionalPart =
    num1FractionalPart !== undefined || num2FractionalPart !== undefined;
  num1FractionalPart = num1FractionalPart ?? "";
  num2FractionalPart = num2FractionalPart ?? "";
  if (haveFractionalPart) {
    // Append zeros for small length string before addtion
    if (num1FractionalPart.length > num2FractionalPart.length) {
      const noOfZeroAppend =
        num1FractionalPart.length - num2FractionalPart.length;
      const zeroStr = new Array(noOfZeroAppend).fill(0).join("");
      num2FractionalPart = num2FractionalPart.concat(zeroStr);
    } else {
      const noOfZeroAppend =
        num2FractionalPart.length - num1FractionalPart.length;
      const zeroStr = new Array(noOfZeroAppend).fill(0).join("");
      num1FractionalPart = num1FractionalPart.concat(zeroStr);
    }
  }

  const decimalPointPosition = num1FractionalPart.length;
  // Add number without decimal point
  const resultWithoutDecimalPoint = ethers.BigNumber.from(
    `${num1DecimalPart}${num1FractionalPart}`
  ).add(`${num2DecimalPart}${num2FractionalPart}`);

  if (decimalPointPosition === 0) {
    return resultWithoutDecimalPoint.toString();
  }
  const resultWithoutDecimalPointArray = Array.from(
    resultWithoutDecimalPoint.toString()
  );
  // add decimal point
  resultWithoutDecimalPointArray.splice(-decimalPointPosition, 0, ".");
  return removeTailingZero(resultWithoutDecimalPointArray.join(""));
};
