import { getNetworkDetails, Network, NetworkParameters } from "../../configs";
import { asAbi, tokenDataToUnitInfo } from "./utils";
import { UniswapERC20 } from "./UniswapERC20";
import { Pair } from "../../state/pairCache";
import { Decimal } from "decimal.js";
import Provider from "../Provider";
import UniswapPairABI from "../../abis/UniswapPair.json";
import { anErr, anOk, isErr, Result } from "../../result";
import ERC20Contract from "./ERC20Contract";
import BN from "bn.js";
import { Trigger, TriggerType } from "../../state/triggers";
import { utils } from "ethers";
import { divideByDecimals } from "../../utils/numeric/divideByDecimals";
import { formatBigNumber } from "../../utils/numeric/formatBigNumber";
import { toNonCanonicalDisplay } from "../../utils/display/toNonCanonicalDisplay";
import { getProxyContractAddressForTrigger } from "../../utils/misc/getProxyContractAddressForTrigger";

 interface PairAddresses {
  token0: string;
  token1: string;
}

export interface ReservesAPIResult {
  reserve1: Decimal;
  reserve0: Decimal;
}

export enum NETWORK_SYMBOLS {
  CAKE_LP = "Cake-LP",
  UNI_V2 = "UNI-V2",
  UNI_V3 = "UNI-V3",
}

export interface CalcLPTokenRes {
  lpString: string;
  numLpTokens: string;
  numToken0: string;
  numToken1: string;
  hasLiquidity: boolean;
}

export function toDecimalWithPrecision(
  value: string | Decimal,
  precision: number
): string {
  return formatBigNumber(
    new Decimal(value.toString())
      .div(new Decimal(10).pow(precision).toString())
      .toString()
  ).full;
}

export function toMultiplyWithPrecision(
  value: string | Decimal,
  precision: number
): string {
  return utils.parseUnits(value.toString(), precision).toString();
}

export class PairContract {
  /**
   * Fetch all info for the pool, but return null if any part of it fails including pool not existing
   *
   * @param poolAddress
   */
  public static async fetchPoolInformation(
    poolAddress: string,
    network: Network,
    chainId: number | undefined
  ): Promise<Result<Pair>> {
    const tokenAddresses = await PairContract.getTokenAddresses(poolAddress);
    if (isErr(tokenAddresses)) return tokenAddresses;
    const token0Data = await UniswapERC20.getTokenData(
      tokenAddresses.value.token0,
      chainId
    );
    const token1Data = await UniswapERC20.getTokenData(
      tokenAddresses.value.token1,
      chainId
    );
    if (isErr(token0Data)) return token0Data;
    if (isErr(token1Data)) return token1Data;

    const price = await PairContract.fetchPairPrice(
      poolAddress,
      token0Data.value.decimals,
      token1Data.value.decimals
    );

    if (isErr(price)) return price;

    return anOk({
      network,
      token0Decimals: token0Data.value.decimals,
      token1Decimals: token1Data.value.decimals,
      address: poolAddress,
      token0: token0Data.value.symbol,
      token1: token1Data.value.symbol,
      price: price.value.toString(),
      token0Address: tokenAddresses.value.token0,
      token1Address: tokenAddresses.value.token1,
    });
  }

  /**
   * from a pool we get the price using a way of calculating the price as specified in the below url
   *
   * check that the pool address is a  real pool address before calling this function.
   *
   * If price is greater than
   *
   * https://ethereum.stackexchange.com/questions/83701/how-to-infer-token-price-from-ethereum-blockchain-uniswap-data
   *
   * @param poolAddress
   * @param token0decimals
   * @param token1decimals
   */
  public static async fetchPairPrice(
    poolAddress: string,
    token0decimals: number,
    token1decimals: number
  ): Promise<Result<Decimal>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    try {
      const reserves: ReservesAPIResult = await contract.methods
        .getReserves()
        .call();

      const reserves0 = toDecimalWithPrecision(
        reserves.reserve0,
        token0decimals
      );
      const reserves1 = new Decimal(
        toDecimalWithPrecision(reserves.reserve1, token1decimals)
      );
      const pairPrice = new Decimal(reserves0).lte(0)
        ? new Decimal(0)
        : reserves1.dividedBy(reserves0);
      return anOk(pairPrice);
    } catch (err) {
      return anErr("Unable determine pair price", err);
    }
  }

  /**
  /**
   * From a valid Uniswap pool address we return the addresses of the two tokens 0 and 1, or null if any error
   *
   * @param poolAddress
   */
  public static async getTokenAddresses(
    poolAddress: string
  ): Promise<Result<PairAddresses>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );
    try {
      return anOk({
        token0: await contract.methods.token0().call(),
        token1: await contract.methods.token1().call(),
      });
    } catch (error) {
      return anErr(`Unable to lookup pair token addresses`, error);
    }
  }

  public static async isNetwork(
    poolAddress: string,
    network: string | undefined
  ): Promise<Result<boolean>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    let res;
    try {
      res = await contract.methods.symbol().call();
      return anOk(Boolean(network === res));
    } catch (error) {
      return anErr(`Unable to lookup symbol of pair contract`);
    }
  }

  /**
   * Gets balance on proxy contract of user by Ethereum Address
   *
   * @returns {Promise<unknown> string of balance
   * @param poolAddress
   * @param userAddress
   */
  public static async getBalanceOnPairContract(
    poolAddress: string,
    userAddress: string
  ): Promise<Result<Decimal>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    try {
      const ret = new Decimal(
        await contract.methods.balanceOf(userAddress).call()
      );
      return anOk(ret);
    } catch (error) {
      return anErr(`Unable to lookup balance of pair contract`);
    }
  }

  public static async getDecimalOnPairContract(
    poolAddress: string
  ): Promise<Result<Decimal>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    try {
      const ret = new Decimal(await contract.methods.decimals().call());
      return anOk(ret);
    } catch (error) {
      return anErr(`Unable to lookup decimal of pair contract`);
    }
  }

  public static async getSymbolOnPairContract(
    poolAddress: string
  ): Promise<Result<Decimal>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    try {
      const ret = await contract.methods.symbol().call();
      return anOk(ret);
    } catch (error) {
      return anErr(`Unable to lookup symbol of pair contract`);
    }
  }

  public static async approveAllFunds(
    poolAddress: string,
    userAddress: string,
    chainId: number,
    triggerType: TriggerType
  ): Promise<Result<undefined>> {
    const result = await PairContract.getBalanceOnPairContract(
      poolAddress,
      userAddress
    );

    if (isErr(result)) return result;
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      return networkDetails;
    }

    try {
      await contract.methods
        .approve(
          triggerType === TriggerType.WITHDRAW_LIQUIDITY
            ? networkDetails.value.withdrawLiquidityProxyContractAddress
            : networkDetails.value.swapProxyContractAddress,
          result.value.toString()
        )
        .send({ from: userAddress });
    } catch (err) {
      return anErr("Unable to approve funds transfer", err);
    }

    return anOk(undefined);
  }

  public static async approveFunds(
    poolAddress: string,
    userAddress: string,
    chainId: number,
    userApproveFund: string,
    triggerType: TriggerType
  ): Promise<Result<undefined>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      return networkDetails;
    }

    try {
      await contract.methods
        .approve(
          triggerType === TriggerType.WITHDRAW_LIQUIDITY
            ? networkDetails.value.withdrawLiquidityProxyContractAddress
            : networkDetails.value.swapProxyContractAddress,
          Number(userApproveFund).toLocaleString("fullwide", {
            useGrouping: false,
            maximumFractionDigits: 18,
          })
        )
        .send({ from: userAddress });
    } catch (err) {
      return anErr("Unable to approve funds transfer", err);
    }

    return anOk(undefined);
  }

  /**
   * Calculates liquidity of pool
   *
   * @param poolAddress
   * @param token0decimals
   * @param token1decimals
   * @param pair
   */
  public static async calcLiquidity(
    userAddress: string,
    pair: Pair
  ): Promise<Result<Decimal>> {
    /**
     *
     * This is the calculation which I am aiming to replicate. It is done within the
     * https://github.com/Uniswap/uniswap-v2-core/blob/master/contracts/UniswapV2Pair.sol#L140.
     *
     *  uint liquidity = balanceOf[address(this)];
     *      uint balance0 = IERC20(_token0).balanceOf(address(this));
     *      uint balance1 = IERC20(_token1).balanceOf(address(this));
     *     amount0 = liquidity.mul(balance0) / _totalSupply; // using balances ensures pro-rata distribution
     *     amount1 = liquidity.mul(balance1) / _totalSupply; // using balances ensures pro-rata distribution
     *   liquidity = Math.min(amount0.mul(_totalSupply) / _reserve0, amount1.mul(_totalSupply) / _reserve1)
     *
     *
     */
    const pairResult = await PairContract.getTokenAddresses(pair.address);

    if (isErr(pairResult)) {
      return anErr("error");
    }

    const pairAddresses = pairResult.value;

    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      pair.address
    );

    let liquidity;
    let totalSupply;
    let reserves;

    try {
      liquidity = await contract.methods.balanceOf(userAddress).call();
      totalSupply = await contract.methods.totalSupply().call();
      reserves = await contract.methods.getReserves().call();
    } catch {
      return anErr("error");
    }

    const balance0 = await ERC20Contract.getBalance(
      userAddress,
      pairAddresses.token0
    );
    const balance1 = await ERC20Contract.getBalance(
      userAddress,
      pairAddresses.token1
    );

    if (isErr(balance1) || isErr(balance0)) {
      return anErr("error");
    }

    if (
      new Decimal(reserves.reserve0).isZero() ||
      new Decimal(reserves.reserve1).isZero() ||
      new Decimal(totalSupply).isZero()
    ) {
      return anErr("error");
    }

    let liquidity0;

    if (!new Decimal(balance0.value).isZero()) {
      const amount0 = new Decimal(liquidity)
        .mul(new Decimal(balance0.value))
        .div(new Decimal(totalSupply));

      const reserves0 = toDecimalWithPrecision(
        reserves.reserve0,
        pair.token0Decimals
      );

      liquidity0 = amount0.div(reserves0);
    } else {
      liquidity0 = new Decimal("0");
    }

    let liquidity1;

    if (!new Decimal(balance1.value).isZero()) {
      const amount1 = new Decimal(liquidity)
        .mul(new Decimal(balance1.value))
        .div(new Decimal(totalSupply));

      const reserves1 = toDecimalWithPrecision(
        reserves.reserve1,
        pair.token1Decimals
      );

      liquidity1 = amount1.div(reserves1);
    } else {
      liquidity1 = new Decimal("0");
    }

    if (liquidity0.greaterThanOrEqualTo(liquidity1)) {
      return anOk(liquidity1);
    }
    return anOk(liquidity0);
  }

  /**
   * This string shows the different LP tokens for display to the user
   *
   * @param userAddress
   * @param poolAddress
   */
  public static async calcLPTokenString(
    userAddress: string,
    poolAddress: string,
    chainId: number | undefined
  ): Promise<Result<CalcLPTokenRes>> {
    /**
     *
     *   uint liquidity = balanceOf[address(this)];
        uint balance0 = IERC20(_token0).balanceOf(address(this));
        uint balance1 = IERC20(_token1).balanceOf(address(this));
        amount0 = liquidity.mul(balance0) / _totalSupply; // using balances ensures pro-rata distribution
        amount1 = liquidity.mul(balance1) / _totalSupply; // using balances ensures pro-rata distribution
     *
     */
    const tokenAddresses = await PairContract.getTokenAddresses(poolAddress);

    if (isErr(tokenAddresses)) {
      return anErr("error");
    }

    const token0Data = await UniswapERC20.getTokenData(
      tokenAddresses.value.token0,
      chainId
    );
    const token1Data = await UniswapERC20.getTokenData(
      tokenAddresses.value.token1,
      chainId
    );

    const liquidtyTokenData = await UniswapERC20.getTokenData(
      poolAddress,
      chainId
    );

    if (isErr(token0Data) || isErr(token1Data) || isErr(liquidtyTokenData)) {
      return anErr("error");
    }

    const pairAddresses = tokenAddresses.value;

    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    let liquidity;
    let totalSupply;

    try {
      liquidity = await contract.methods.balanceOf(userAddress).call();
      totalSupply = await contract.methods.totalSupply().call();
    } catch {
      return anErr("error");
    }

    const balance0 = await ERC20Contract.getBalance(
      poolAddress,
      pairAddresses.token0
    );
    const balance1 = await ERC20Contract.getBalance(
      poolAddress,
      pairAddresses.token1
    );

    if (isErr(balance1) || isErr(balance0)) {
      return anErr("error");
    }

    if (new Decimal(totalSupply).isZero()) {
      return anErr("error");
    }

    let amount0 = new BN("0");
    if (!new BN(balance0.value).isZero()) {
      amount0 = new BN(liquidity)
        .mul(new BN(balance0.value))
        .div(new BN(totalSupply));
    }

    let amount1 = new BN("0");
    if (!new BN(balance1.value).isZero()) {
      amount1 = new BN(liquidity)
        .mul(new BN(balance1.value))
        .div(new BN(totalSupply));
    }

    const token0Info = tokenDataToUnitInfo(token0Data.value);
    const token1Info = tokenDataToUnitInfo(token1Data.value);
    const liquidityInfo = tokenDataToUnitInfo(liquidtyTokenData.value);

    const numLpTokens = new Decimal(
      divideByDecimals(liquidity, liquidityInfo.decimals)
    )
      .toSignificantDigits(2)
      .toString();

    const numToken0 = toNonCanonicalDisplay(amount0.toString(), token0Info);
    const numToken1 = toNonCanonicalDisplay(amount1.toString(), token1Info);

    // // if no tokens rather than eg 0 LP tokens (0 DAI 0 DAI)
    if (
      new BN(liquidity).isZero() &&
      new BN(amount0).isZero() &&
      new BN(amount1).isZero()
    ) {
      return anOk({
        lpString: "no liquidity",
        numLpTokens,
        numToken0,
        numToken1,
        hasLiquidity: false,
      });
    }

    const lpString = `${numLpTokens} LP tokens (${numToken0} ${numToken1})`;

    return anOk({
      lpString,
      numLpTokens,
      numToken0,
      numToken1,
      hasLiquidity: true,
    });
  }

  public static async removeFundsApproval(
    poolAddress: string,
    userAddress: string,
    chainId: number,
    triggerType: TriggerType,
    proxyAddress: string | null | undefined
  ): Promise<Result<undefined>> {
    const contract = new Provider._web3.eth.Contract(
      asAbi(UniswapPairABI),
      poolAddress
    );

    const networkDetails = getNetworkDetails(chainId);

    if (isErr(networkDetails)) {
      return networkDetails;
    }

    try {
      const proxyContractAddress = getProxyContractAddressForTrigger(
        proxyAddress,
        triggerType,
        networkDetails.value
      );
      await contract.methods
        .approve(proxyContractAddress, "0")
        .send({ from: userAddress });
    } catch (err) {
      return anErr("Unable to remove approve funds transfer", err);
    }

    return anOk(undefined);
  }
}
