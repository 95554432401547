import BN from "bn.js";
import Decimal from "decimal.js";
import { multiplyByDecimals } from "../../components/TopUpVaultModal/utils";
import { formatBigNumber } from "../numeric/formatBigNumber";
import { normalizeDecimalStringContainingE } from "../numeric/normalizeDecimalStringContainingE";

const RIGHTWARDS_ZEROS = /^|0+$/;

interface Unit {
  power: number;
  name: string;
}

export interface UnitInfo {
  units: Unit[];
  decimals: number;
}

export function getMainUnit(info: UnitInfo): string {
  const unit = info.units.find((e) => e.power === info.decimals);

  return unit?.name ?? "";
}

/**
 * We check is if it zero by trimming and removing any decimal points and then seeing if every character is "0"
 *
 * @example
 * // returns true true true
 * equalsZero(".000"); equalsZero("000");  equalsZero("0");
 * @example
 * //returns false
 * equalsZero(".0001");
 * *
 * @param str Numeric amount as string
 * @returns {boolean} if it equals zero or not
 */
export const equalsZero = (str: string): boolean =>
  str
    .trim()
    .split("")
    .filter((el: string) => el !== ".")
    .every((el: string) => el === "0");
/**
 * Remove zeros to right of last non-zero character in string
 *
 * @example
 * //outputs 49901
 * console.log(stripRightwardZeros(499010000))
 * @param str to strip
 * @returns {string} string with zeros to right of last non-zero character removed
 */
export const stripRightwardZeros = (str: string): string => {
  const regexp = RegExp(RIGHTWARDS_ZEROS, "g");
  return str.replace(regexp, "");
};

// find the closest unit name to be chosen.
function closestUnit(
  largestSigFigurePosition: number,
  unitNames: UnitInfo
): Unit {
  return unitNames.units.reduce((closest, next): Unit => {
    const closestDiff = Math.abs(largestSigFigurePosition - closest.power);
    const nextDiff = Math.abs(largestSigFigurePosition - next.power);

    if (nextDiff < closestDiff) {
      return next;
    }
    return closest;
  });
}

/**
 * Converts Canonical to non Canonical crypto currency but as a string for displaying to the user.
 *
 * We convert to scientific notation if very small eg cannot be represented with 6 dps,
 * we format other decimal amounts < 1 to 6 dps precision, eg 0.000001 and if no smallest unit present in params
 * For > 1 non integer numbers of non-Canonical Currency we show to 2 dps, but if over 1000 we don't show dps.
 *
 * @param canonicalAmount string
 * @param unitNames
 * @returns {string} to display to user
 */
const toNonCanonicalDisplay = (
  canonicalAmount: string | undefined,
  unitNames: UnitInfo
): string => {
  if (typeof canonicalAmount === "undefined" || equalsZero(canonicalAmount)) {
    return `0 ${getMainUnit(unitNames)}`;
  }

  canonicalAmount = formatBigNumber(canonicalAmount).full

  const isNegative = new BN(canonicalAmount).isNeg();

  if (isNegative) {
    canonicalAmount = new BN(canonicalAmount).abs().toString();
  }

  canonicalAmount = normalizeDecimalStringContainingE(canonicalAmount);

  // todo probably change this to strip useless leading zeros first.
  let largestSigFigurePosition = canonicalAmount.length - 2; // prefer the smaller unit
  if (largestSigFigurePosition <= 0) {
    largestSigFigurePosition = 1;
  }

  const closest = closestUnit(largestSigFigurePosition, unitNames);

  const canonicalAmountBN = new Decimal(canonicalAmount);

  // compute the value in the closest units
  const divisor = new Decimal(10).pow(closest.power);
  const valueInClosestUnits = canonicalAmountBN.div(divisor);

  const neg = isNegative ? "-" : "";
  const value = valueInClosestUnits.toNumber().toLocaleString();

  return `${neg}${value} ${closest.name}`;
};

export { toNonCanonicalDisplay };
