import { Button } from "react-bootstrap";
import Provider from "../../services/Provider";
import React from "react";

interface ConfigureMetaMaskProps {
  metaMaskUnlocked: boolean;
  metaMaskInstalled: boolean;
}

export function ConfigureMetaMask(props: ConfigureMetaMaskProps) {
  return (
    <>
      {!props.metaMaskUnlocked && (
        <Button data-testid="configure-metamask" onClick={Provider.openLogin}>
          unlock metamask
        </Button>
      )}
      {!props.metaMaskInstalled && <Button>install metamask</Button>}
    </>
  );
}
