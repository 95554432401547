interface Enum {
  [id: number]: string;
}

/**
 * is value a value (not key) within an enum (enum also passed as param)
 *
 * @see {@link https://stackoverflow.com/questions/43804805/check-if-value-exists-in-enum-in-typescript}
 *
 * @param needle
 * @param haystack
 */
export const inEnum = (needle: unknown, haystack: Enum): boolean => {
  return Boolean(Object.values(haystack).includes(needle));
};
