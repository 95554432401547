import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleNotch,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import style from "./ProgressIcon.module.scss";
import classnames from "classnames";

export enum ConfigState {
  UNKNOWN,
  PENDING,
  IN_PROGRESS,
  CONFIGURED,
  ERROR,
}

interface ProgressIconProps {
  state: ConfigState;
}

export function ProgressIcon(props: ProgressIconProps) {
  switch (props.state) {
    case ConfigState.UNKNOWN:
      return (
        <FontAwesomeIcon
          icon={faQuestionCircle}
          size="2x"
          className={classnames(style.ProgressIcon)}
        />
      );
    case ConfigState.PENDING:
      return (
        <FontAwesomeIcon
          icon={faCircle}
          size="2x"
          className={classnames(style.ProgressIcon)}
        />
      );
    case ConfigState.IN_PROGRESS:
      return (
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
          size="2x"
          className={classnames(style.ProgressIcon)}
        />
      );
    case ConfigState.CONFIGURED:
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="2x"
          className={classnames(style.ProgressIcon, style.Success)}
        />
      );
    case ConfigState.ERROR:
      return (
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="2x"
          className={classnames(style.ProgressIcon, style.Failure)}
        />
      );
  }
}
