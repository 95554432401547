import { StateStore } from "../../state/state";
import { calcAvailableAgents } from "./calcAvailableAgents";

export const hasAvailableAgents = (state: StateStore) => {
  return Boolean(
    calcAvailableAgents(
      state.agents.list(),
      state.web3.networkId,
      state.web3.address,
      // @ts-ignore
      state.triggers.agentNonCompletedTriggersCount.bind(state.triggers)
    ).length > 0
  );
};
