import { TOKEN_RENEWAL_OFFSET } from "../configs";
import moment, { Moment } from "moment";

function getExpiryFromToken(accessToken: string): number {
  const part = accessToken.split(".")[1];
  const decoded = window.atob(part);
  const decodedObj = JSON.parse(decoded);
  return decodedObj.exp;
}

export class AccessToken {
  readonly accessToken: string;

  readonly refreshToken: string;

  readonly expiry: Moment;

  readonly renewal: Moment;

  constructor(accessToken: string, refreshToken: string) {
    const accessTokenExpiry = getExpiryFromToken(accessToken);
    const refreshTokenExpiry = getExpiryFromToken(refreshToken);

    // we compute the token pair's expiry time by taking the minimum of the two token expiries and then subtracting
    // a buffer period, usually ~2 minutes
    this.expiry = moment.unix(Math.min(accessTokenExpiry, refreshTokenExpiry));
    this.renewal = moment.unix(this.expiry.unix() - TOKEN_RENEWAL_OFFSET);
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  hasExpired(): boolean {
    return this.expiry.isBefore(moment());
  }

  /**
   * Has token almost expired or expired.
   */
  isAlmostExpired(): boolean {
    return this.expiry.isBefore(moment().add(3, "seconds"));
  }

  isRenewable(): boolean {
    return moment().isBetween(this.renewal, this.expiry);
  }
}
