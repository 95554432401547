import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { SelectAgent } from "./SelectAgent";
import { SelectThreshold } from "./SelectThreshold";
import { ConfirmTrigger } from "./ConfirmTrigger";
import { CreateTrigger } from "./CreateTrigger";
import { Agent } from "../../state/agents";
import { Pair } from "../../state/pairCache";
import {
  NewTrigger,
  SwapToken,
  TriggerChoices,
  TriggerType,
} from "../../state/triggers";
import { StateStore } from "../../state/state";
import Provider from "../../services/Provider";
import { ConfigureMetaMask } from "./ConfigureMetaMask";
import { TriggerInfo } from "./TriggerInfo";
import style from "./index.module.scss";
import classnames from "classnames";
import close from "../../assets/close.svg";
import { KEY_EVENT_ENTER, KEY_EVENT_SPACE, Network } from "../../configs";
import { SelectTokenAndPool } from "./SelectTokenAndPool";
import Decimal from "decimal.js";
import { CalcLPTokenRes } from "../../services/contracts/PairContract";
import { PoolData } from "../DropdownSearchPool/DropdownSearchPool.data";
import { Validation } from "../../validation";
import { trackEvents } from "../../utils/misc/trackEvent";
import {
  BNB_ID,
  enumToString,
  ETH_ID,
  networkToApiChain,
} from "../../api/common";
import { Chain, HoldingPool, HoldingToken } from "../../state/assets";
import { PoolSearchInput } from "../PoolSearchInput";
import { eventNameAnalysis } from "../../utils/misc/trackEvent";

export enum Stage {
  CONFIGURE_METAMASK,
  TRIGGER_INFO,
  SELECT_AGENT,
  SELECT_POOL,
  SELECT_THRESHOLD,
  CONFIRM_TRIGGER,
  CREATE_TRIGGER,
  SELECT_TOKEN_POOL,
}

export enum InvalidReason {
  ALREADY_REGISTERED,
  NO_BALANCE,
  NETWORK_ERROR,
  INVALID_ADDRESS,
  INVALID_POOL,
  POOL_NOT_v2,
  UNSUPPORTED_NETWORK,
  POOL_NOT_ON_NETWORK,
  POOL_NOT_PANCAKE_SWAP,
  TWO_TRIGGERS_ON_POOL,
  NO_POOL_DATA,
  POOL_PRICE_NOT_FOUND,
}

export interface PoolValidation {
  validity: Validation;
  reason?: InvalidReason;
}

interface NewTriggerModalProps {
  allAgents: Agent[];
  show?: boolean;
  initialStage: Stage;
  triggerType?: TriggerType;
  onDone?: (t: NewTrigger | undefined) => void;
  state: StateStore;
  fromToken?: SwapToken;
  selectedPool?: HoldingPool;
}

export function NewTriggerModal(props: NewTriggerModalProps) {
  const [stage, setStage] = useState<Stage>(props.initialStage);
  const [agent, setAgent] = useState<Agent | undefined>(undefined);
  const [pair, setPair] = useState<Pair | undefined>(undefined);
  const [trigger, setTrigger] = useState<NewTrigger | undefined>(undefined);
  const [approveAmount, setApproveAmount] = useState<string>("");
  const [triggerType, setTriggerType] = useState<TriggerType>(
    props.triggerType ?? TriggerType.WITHDRAW_LIQUIDITY
  );
  const [hasBalance, setHasBalance] = useState<boolean>(false);
  const [twoAgentsRegisteredToPool, setTwoAgentsRegisteredToPool] =
    useState<boolean>(false);
  const [uniqueTokenPoolCombination, setUniqueTokenPoolCombination] =
    useState<boolean>(false);
  const [isAllowBack, setAllowBack] = useState<boolean>(true);
  const [lpToken, setLpToken] = useState<CalcLPTokenRes | undefined>();
  const [swapTokensList, setTokenList] = useState<SwapToken[]>([]);
  const [selectedFromToken, setSelectedFromToken] = useState<
    SwapToken | undefined
  >();
  const [toTokenList, setToTokenList] = useState<SwapToken[]>([]);
  const [selectedToToken, setSelectedToToken] = useState<
    SwapToken | undefined
  >();
  const [balance, setBalance] = useState<string>("");
  const [tokenIndex, setTokenIndex] = useState<TriggerChoices>();
  const [holdingTokens, setHoldingTokens] = useState<SwapToken[]>([]);
  const [holdingPools, setHoldingPools] = useState<PoolData[]>([]);
  const [selectedThresholdTokenIdx, setselectedThresholdTokenIdx] =
    useState<number>(0);
  const [poolValidation, setPoolValidation] = useState<PoolValidation>({
    validity: Validation.UNKNOWN,
  });
  const [allTokens, setAllTokens] = useState<SwapToken[]>([]);

  const { state } = props;

  const withdrawNextStageFlow = (current: Stage) => {
    switch (current) {
      case Stage.TRIGGER_INFO:
        trackEvents(Stage[Stage.SELECT_AGENT], TriggerType.WITHDRAW_LIQUIDITY, {
          action: "click_next",
          stage: Stage[Stage.SELECT_AGENT],
        });
        return Stage.SELECT_AGENT;
      case Stage.SELECT_AGENT:
        trackEvents(Stage[Stage.SELECT_POOL], TriggerType.WITHDRAW_LIQUIDITY, {
          action: "click_next",
          stage: Stage[Stage.SELECT_POOL],
        });
        return Stage.SELECT_POOL;
      case Stage.CONFIGURE_METAMASK:
        return Stage.CONFIGURE_METAMASK;
      case Stage.SELECT_POOL:
        trackEvents(
          Stage[Stage.SELECT_THRESHOLD],
          TriggerType.WITHDRAW_LIQUIDITY,
          {
            action: "click_next",
            stage: Stage[Stage.SELECT_THRESHOLD],
          }
        );
        return Stage.SELECT_THRESHOLD;
      case Stage.SELECT_THRESHOLD:
        trackEvents(
          Stage[Stage.CONFIRM_TRIGGER],
          TriggerType.WITHDRAW_LIQUIDITY,
          {
            action: "click_next",
            stage: Stage[Stage.CONFIRM_TRIGGER],
          }
        );
        return Stage.CONFIRM_TRIGGER;
      case Stage.CONFIRM_TRIGGER:
        trackEvents(
          Stage[Stage.CREATE_TRIGGER],
          TriggerType.WITHDRAW_LIQUIDITY,
          {
            action: "click_next",
            stage: Stage[Stage.CREATE_TRIGGER],
          }
        );
        return Stage.CREATE_TRIGGER;
      case Stage.CREATE_TRIGGER:
        trackEvents(
          Stage[Stage.CREATE_TRIGGER],
          TriggerType.WITHDRAW_LIQUIDITY,
          {
            action: "click_next",
            stage: Stage[Stage.CREATE_TRIGGER],
          }
        );
        return Stage.CREATE_TRIGGER;
      default:
        return Stage.TRIGGER_INFO;
    }
  };

  const swapNextStageFlow = (current: Stage) => {
    switch (current) {
      case Stage.TRIGGER_INFO:
        trackEvents(Stage[Stage.SELECT_AGENT], TriggerType.SWAP, {
          action: "click_next",
          stage: Stage[Stage.SELECT_AGENT],
        });
        return Stage.SELECT_AGENT;
      case Stage.SELECT_AGENT:
        trackEvents(Stage[Stage.SELECT_TOKEN_POOL], TriggerType.SWAP, {
          action: "click_next",
          stage: Stage[Stage.SELECT_TOKEN_POOL],
        });
        return Stage.SELECT_TOKEN_POOL;
      case Stage.CONFIGURE_METAMASK:
        return Stage.CONFIGURE_METAMASK;
      case Stage.SELECT_TOKEN_POOL:
        trackEvents(Stage[Stage.SELECT_THRESHOLD], TriggerType.SWAP, {
          action: "click_next",
          stage: Stage[Stage.SELECT_THRESHOLD],
        });
        return Stage.SELECT_THRESHOLD;
      case Stage.SELECT_THRESHOLD:
        trackEvents(Stage[Stage.CONFIRM_TRIGGER], TriggerType.SWAP, {
          action: "click_next",
          stage: Stage[Stage.CONFIRM_TRIGGER],
        });
        return Stage.CONFIRM_TRIGGER;
      case Stage.CONFIRM_TRIGGER:
        trackEvents(Stage[Stage.CREATE_TRIGGER], TriggerType.SWAP, {
          action: "click_next",
          stage: Stage[Stage.CREATE_TRIGGER],
        });
        return Stage.CREATE_TRIGGER;
      case Stage.CREATE_TRIGGER:
        trackEvents(Stage[Stage.CREATE_TRIGGER], TriggerType.SWAP, {
          action: "click_next",
          stage: Stage[Stage.CREATE_TRIGGER],
        });
        return Stage.CREATE_TRIGGER;
      default:
        return Stage.TRIGGER_INFO;
    }
  };

  const withdrawPreviousStageFlow = (current: Stage) => {
    switch (current) {
      case Stage.CONFIGURE_METAMASK:
        return Stage.CONFIGURE_METAMASK;
      case Stage.CREATE_TRIGGER:
        trackEvents(
          Stage[Stage.CONFIRM_TRIGGER],
          TriggerType.WITHDRAW_LIQUIDITY,
          {
            action: "click_previous",
            stage: Stage[Stage.CONFIRM_TRIGGER],
          }
        );
        return Stage.CONFIRM_TRIGGER;
      case Stage.CONFIRM_TRIGGER:
        trackEvents(
          Stage[Stage.SELECT_THRESHOLD],
          TriggerType.WITHDRAW_LIQUIDITY,
          {
            action: "click_previous",
            stage: Stage[Stage.SELECT_THRESHOLD],
          }
        );
        return Stage.SELECT_THRESHOLD;
      case Stage.SELECT_THRESHOLD:
        trackEvents(Stage[Stage.SELECT_POOL], TriggerType.WITHDRAW_LIQUIDITY, {
          action: "click_previous",
          stage: Stage[Stage.SELECT_POOL],
        });
        return Stage.SELECT_POOL;
      case Stage.SELECT_POOL:
        trackEvents(Stage[Stage.SELECT_AGENT], TriggerType.WITHDRAW_LIQUIDITY, {
          action: "click_previous",
          stage: Stage[Stage.SELECT_AGENT],
        });
        return Stage.SELECT_AGENT;
      case Stage.SELECT_AGENT:
        trackEvents(Stage[Stage.TRIGGER_INFO], TriggerType.WITHDRAW_LIQUIDITY, {
          action: "click_previous",
          stage: Stage[Stage.TRIGGER_INFO],
        });
        return Stage.TRIGGER_INFO;
      case Stage.TRIGGER_INFO:
        trackEvents(Stage[Stage.TRIGGER_INFO], TriggerType.WITHDRAW_LIQUIDITY, {
          action: "click_previous",
          stage: Stage[Stage.TRIGGER_INFO],
        });
        return Stage.TRIGGER_INFO;
      default:
        return Stage.TRIGGER_INFO;
    }
  };

  const swapPreviousStageFlow = (current: Stage) => {
    switch (current) {
      case Stage.CONFIGURE_METAMASK:
        return Stage.CONFIGURE_METAMASK;
      case Stage.CREATE_TRIGGER:
        trackEvents(Stage[Stage.CONFIRM_TRIGGER], TriggerType.SWAP, {
          action: "click_previous",
          stage: Stage[Stage.CONFIRM_TRIGGER],
        });
        return Stage.CONFIRM_TRIGGER;
      case Stage.CONFIRM_TRIGGER:
        trackEvents(Stage[Stage.SELECT_THRESHOLD], TriggerType.SWAP, {
          action: "click_previous",
          stage: Stage[Stage.SELECT_THRESHOLD],
        });
        return Stage.SELECT_THRESHOLD;
      case Stage.SELECT_THRESHOLD:
        trackEvents(Stage[Stage.SELECT_TOKEN_POOL], TriggerType.SWAP, {
          action: "click_previous",
          stage: Stage[Stage.SELECT_TOKEN_POOL],
        });
        return Stage.SELECT_TOKEN_POOL;
      case Stage.SELECT_TOKEN_POOL:
        trackEvents(Stage[Stage.SELECT_AGENT], TriggerType.SWAP, {
          action: "click_previous",
          stage: Stage[Stage.SELECT_AGENT],
        });
        return Stage.SELECT_AGENT;
      case Stage.SELECT_AGENT:
        trackEvents(Stage[Stage.TRIGGER_INFO], TriggerType.SWAP, {
          action: "click_previous",
          stage: Stage[Stage.TRIGGER_INFO],
        });
        return Stage.TRIGGER_INFO;
      case Stage.TRIGGER_INFO:
        trackEvents(Stage[Stage.TRIGGER_INFO], TriggerType.SWAP, {
          action: "click_previous",
          stage: Stage[Stage.TRIGGER_INFO],
        });
        return Stage.TRIGGER_INFO;
      default:
        return Stage.TRIGGER_INFO;
    }
  };

  function nextStage(
    current: Stage,
    triggerType: TriggerType | undefined
  ): Stage {
    if (triggerType === TriggerType.WITHDRAW_LIQUIDITY) {
      return withdrawNextStageFlow(current);
    }
    return swapNextStageFlow(current);
  }

  function prevStage(
    current: Stage,
    triggerType: TriggerType | undefined
  ): Stage {
    if (triggerType === TriggerType.WITHDRAW_LIQUIDITY) {
      return withdrawPreviousStageFlow(current);
    }
    return swapPreviousStageFlow(current);
  }

  useEffect(() => {
    const { loggedIn } = state.web3;

    if (loggedIn && Provider._onboardingInProgress) {
      Provider.stopOnboarding();
    }

    if (loggedIn && stage === Stage.CONFIGURE_METAMASK) {
      setStage(props.initialStage);
    }

    if (!loggedIn) {
      setStage(Stage.CONFIGURE_METAMASK);
    }
  }, [state.web3.setLoggedIn, state.web3.loggedIn, stage]);

  const onTriggerUpdate = (trigger: NewTrigger | undefined) => {
    setTrigger(trigger);
  };

  // this function resets state data
  // need to use this function when swich between different type of trigger
  const clearData = () => {
    setTwoAgentsRegisteredToPool(false);
    setAgent(undefined);
    setPair(undefined);
    setTrigger(undefined);
    setApproveAmount("");
    setLpToken(undefined);
    setTokenList([]);
    setSelectedFromToken(undefined);
    setToTokenList([]);
    setSelectedToToken(undefined);
    setBalance("");
    setStage(props.initialStage);
  };

  const handleCancel = async () => {
    if (props.onDone !== undefined) {
      props.onDone(undefined);
    }

    const { loggedIn } = state.web3;

    if (loggedIn) {
      setStage(stage);
    } else {
      setStage(Stage.CONFIGURE_METAMASK);
    }
    clearData();
  };

  const handleComplete = () => {
    if (typeof props.onDone === "undefined") return;

    if (trigger === undefined || agent === undefined) {
      props.onDone(undefined);
    } else {
      eventNameAnalysis("trigger_created", {
        pair: trigger.pair.address,
        token: trigger.token.toString(),
        gasPrice: trigger.gasPrice,
        gasAmount: trigger.gasAmount,
      });
      props.onDone({
        network: trigger.network,
        agentUuid: agent.uuid,
        threshold: trigger.threshold,
        price0Min: "",
        price0Max: "",
        price1Min: "",
        price1Max: "",
        pair: trigger.pair,
        token: trigger.token,
        swap_token: trigger.swap_token,
        gasPrice: trigger.gasPrice,
        gasAmount: trigger.gasAmount,
        amount: approveAmount,
        proxyAddress: trigger.proxyAddress,
      });
    }

    const { loggedIn } = state.web3;

    if (loggedIn) {
      setStage(stage);
    } else {
      setStage(Stage.CONFIGURE_METAMASK);
    }

    clearData();
  };

  const onSelectFromToken = (fromTokenAddress: string) => {
    return state.assets.allTokens
      .filter(
        (token) =>
          token.chain === enumToString(Chain, state.web3.networkId) &&
          token.address.toUpperCase() !== fromTokenAddress.toUpperCase()
      )
      .map((token) => ({
        address: token.address,
        symbol: token.symbol.toUpperCase(),
        chain: token.chain,
        users: 0,
        isNative: token.id === ETH_ID || token.id === BNB_ID,
      }));
  };

  let body = null;
  let canProceed = false;
  let canBack = true;
  let validApproveAmount;
  let hasPrice;
  switch (stage) {
    case Stage.TRIGGER_INFO:
      body = (
        <TriggerInfo
          setTriggerType={setTriggerType}
          triggerType={triggerType}
          clearData={clearData}
        />
      );
      canProceed = triggerType !== undefined;
      break;
    case Stage.SELECT_AGENT:
      body = (
        <SelectAgent
          state={state}
          web3Address={state.web3.address as string}
          agent={agent}
          allAgents={props.allAgents}
          onAgentUpdate={setAgent}
        />
      );
      canProceed = agent !== undefined;
      break;
    case Stage.CONFIGURE_METAMASK:
      body = (
        <ConfigureMetaMask
          metaMaskInstalled={state.web3.installed}
          metaMaskUnlocked={state.web3.loggedIn}
        />
      );
      canProceed = agent !== undefined;
      break;
    case Stage.SELECT_POOL:
      let pools = [];
      if (state.web3.networkId === Network.ETH) {
        pools = state.assets.holdingPoolsUniswap;
      } else {
        pools = state.assets.holdingPoolCake;
      }
      body = (
        <PoolSearchInput
          state={state}
          pools={pools}
          preSelectedPoolAddress={
            props.selectedPool ? props.selectedPool.address : undefined
          }
          setHasBalance={setHasBalance}
          setTwoAgentsRegisteredToPool={setTwoAgentsRegisteredToPool}
          twoAgentsRegisteredToPool={twoAgentsRegisteredToPool}
          pair={pair}
          cachedPairs={state.pairCache}
          onPairChange={setPair}
          agent={agent as Agent}
          lpToken={lpToken}
          setLpToken={setLpToken}
          balance={balance}
          setBalance={setBalance}
          approveAmount={approveAmount}
          setApproveAmount={setApproveAmount}
          holdingPools={holdingPools}
          setHoldingPools={setHoldingPools}
          poolValidation={poolValidation}
          setPoolValidation={setPoolValidation}
          alreadySelectedPool={props.selectedPool}
        />
      );
      validApproveAmount = false;
      hasPrice = false;
      if (approveAmount && balance) {
        validApproveAmount =
          new Decimal(approveAmount).gt(0) &&
          new Decimal(approveAmount).lte(balance);
      }
      if (pair !== undefined) {
        hasPrice = new Decimal(pair.price).gt(0);
      }
      canProceed =
        validApproveAmount &&
        pair !== undefined &&
        hasPrice &&
        hasBalance &&
        !twoAgentsRegisteredToPool &&
        Boolean(approveAmount);
      break;
    case Stage.SELECT_THRESHOLD:
      body = (
        <SelectThreshold
          state={state}
          pair={pair as Pair}
          onTriggerUpdate={onTriggerUpdate}
          agent={agent as Agent}
          initialThreshold={
            trigger !== undefined ? `${trigger.threshold}` : undefined
          }
          initialGasPrice={
            trigger !== undefined ? `${trigger.gasPrice}` : undefined
          }
          triggerType={triggerType}
          tokenIndex={tokenIndex}
          approveAmount={approveAmount}
          selectedThresholdTokenIdx={selectedThresholdTokenIdx}
          setselectedThresholdTokenIdx={setselectedThresholdTokenIdx}
        />
      );
      canProceed = trigger !== undefined;
      break;
    case Stage.CONFIRM_TRIGGER:
      body = (
        <ConfirmTrigger
          trigger={trigger as NewTrigger}
          chainId={state.web3.chainId}
          network={state.web3.networkId}
          triggerType={triggerType}
        />
      );
      canProceed = true;
      break;
    case Stage.CREATE_TRIGGER:
      body = (
        <CreateTrigger
          trigger={trigger as Required<NewTrigger>}
          onComplete={handleComplete}
          pair={pair as Pair}
          agent={agent as Agent}
          setAllowBack={setAllowBack}
          triggerType={triggerType}
          selectedToken={props.fromToken ?? selectedFromToken}
          state={state}
        />
      );
      canBack = isAllowBack;
      break;
    case Stage.SELECT_TOKEN_POOL:
      let holdings;
      if (state.web3.networkId === Network.ETH) {
        holdings = state.assets.holdingTokensETH.map((token) => ({
          address: token.address,
          symbol: token.symbol,
          chain: token.chain,
          users: 0,
          isNative: token.isNative,
          balance: token.balance,
          decimal: token.decimal,
        }));
      } else {
        holdings = state.assets.holdingTokensBSC.map((token) => ({
          address: token.address,
          symbol: token.symbol,
          chain: token.chain,
          users: 0,
          isNative: token.isNative,
          balance: token.balance,
          decimal: token.decimal,
        }));
      }

      body = (
        <SelectTokenAndPool
          allTokens={allTokens}
          setAllTokens={setAllTokens}
          holdingTokens={holdings}
          approveAmount={approveAmount}
          setApproveAmount={setApproveAmount}
          selectedFromToken={selectedFromToken}
          setSelectedFromToken={setSelectedFromToken}
          balance={balance}
          setBalance={setBalance}
          pair={pair}
          setPair={setPair}
          selectedToToken={selectedToToken}
          setSelectedToToken={setSelectedToToken}
          setUniqueTokenPoolCombination={setUniqueTokenPoolCombination}
          setTokenIndex={setTokenIndex}
          preSelectedToken={props.fromToken}
        />
      );
      validApproveAmount = false;
      if (approveAmount && balance) {
        validApproveAmount =
          new Decimal(approveAmount).gt(0) &&
          new Decimal(approveAmount).lte(balance);
      }
      hasPrice = false;
      if (approveAmount && balance) {
        validApproveAmount =
          new Decimal(approveAmount).gt(0) &&
          new Decimal(approveAmount).lte(balance);
      }
      if (pair !== undefined) {
        hasPrice = new Decimal(pair.price).gt(0);
      }
      canProceed =
        validApproveAmount &&
        pair !== undefined &&
        hasPrice &&
        !uniqueTokenPoolCombination;
      break;
  }

  const handleNext = () => {
    setStage(nextStage(stage, triggerType));
  };

  const handleBack = () => {
    setStage(prevStage(stage, triggerType));
  };

  const allowBack = () => {
    if (props.initialStage === Stage.TRIGGER_INFO) {
      // If trigger modal open from trigger page
      return stage !== Stage.TRIGGER_INFO;
    }
    // If trigger modal open from dashboard page
    return stage !== Stage.SELECT_AGENT;
  };

  return (
    <Modal show={props.show} className={style.modalContainer}>
      <Modal.Header onHide={handleCancel} className={style.modalHeader}>
        <img
          onClick={handleCancel}
          src={close}
          alt="close"
          className={`${style.closeButton} ${!canBack && style.disabledIcon}`}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === KEY_EVENT_ENTER || e.key === KEY_EVENT_SPACE) {
              handleCancel();
            }
          }}
        />
        <Modal.Title>Create New Trigger</Modal.Title>
      </Modal.Header>

      <Modal.Body className={style.modalBody}>{body}</Modal.Body>

      <Modal.Footer className={`${style.modalFooter} "trigger-modal-footer"`}>
        {allowBack() ? (
          <Button
            variant="secondary"
            onClick={handleBack}
            disabled={!canBack}
            className={`${style.secondaryButton} ${style.modalButton}`}
            style={{ marginRight: "auto" }}
          >
            Back
          </Button>
        ) : (
          ""
        )}
        <Button
          data-testid="trigger-modal-next"
          variant="primary"
          onClick={handleNext}
          disabled={!canProceed}
          className={`${style.primaryButton} ${style.modalButton} ${classnames(
            stage === Stage.TRIGGER_INFO ? style.nextButton : ""
          )}`}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
