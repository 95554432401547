import Provider from "../Provider";
import { asAbi } from "./utils";
import RouterV2ABI from "../../abis/RouterV2.json";
import { anErr, anOk, isErr, Result } from "../../result";
import { getNetworkDetails, ZERO_ROUTER_ADDRESS } from "../../configs";
import Decimal from "decimal.js";

/**
 * @classdesc Logic to make calls to contract with each public method being the same as a method name on the staking contract
 *
 */

interface addLiquidityETHParams {
  ethereumAddress: string;
  gasPrice: string;
  token: string;
  amountTokenDesired: string;
  amountTokenMin: string;
  amountETH: string;
  amountETHMin: string;
  to: string;
  deadline: string;
}

interface addLiquidityParams {
  tokenA: string;
  ethereumAddress: string;
  gasPrice: string;
  tokenB: string;
  amountADesired: string;
  amountBDesired: string;
  amountAMin: string;
  amountBMin: string;
  to: string;
  deadline: string;
}

export default class RouterV2 {
  private contract: any;

  constructor(chainId: number) {
    const networkDetails = getNetworkDetails(chainId);
    if (isErr(networkDetails)) {
      this.contract = undefined;
    } else {
      this.contract = new Provider._web3.eth.Contract(
        asAbi(RouterV2ABI),
        networkDetails.value.routerV2ContractAddress
      );
    }
  }

  public async addLiquidity(
    params: addLiquidityParams
  ): Promise<Result<string>> {
    try {
      const result = await this.contract.methods
        .addLiquidity(
          params.tokenA,
          params.tokenB,
          params.amountADesired,
          params.amountBDesired,
          params.amountAMin,
          params.amountBMin,
          params.to,
          params.deadline
        )
        .send({ from: params.ethereumAddress, gasPrice: params.gasPrice });
      let liquidity = "0";
      Object.keys(result.events).map((key) => {
        const event = result.events[key];
        if (event.raw.topics.includes(ZERO_ROUTER_ADDRESS)) {
          liquidity = new Decimal(event.raw.data).toString();
        }
      });
      return anOk(liquidity);
    } catch (e) {
      return anErr("Unable to add liquidity funds to account", e);
    }
  }

  public async addLiquidityETH(
    params: addLiquidityETHParams
  ): Promise<Result<string>> {
    try {
      const result = await this.contract.methods
        .addLiquidityETH(
          params.token,
          params.amountTokenDesired,
          params.amountTokenMin,
          params.amountETHMin,
          params.to,
          params.deadline
        )
        .send({
          from: params.ethereumAddress,
          value: params.amountETH,
          gasPrice: params.gasPrice,
        });
      let liquidity = "0";
      Object.keys(result.events).map((key) => {
        const event = result.events[key];
        if (event.raw.topics.includes(ZERO_ROUTER_ADDRESS)) {
          liquidity = new Decimal(event.raw.data).toString();
        }
      });
      return anOk(liquidity);
    } catch (e) {
      return anErr("Unable to add liquidity funds to account", e);
    }
  }
}
