import { BINANCE_API } from "../configs";
import { anErr, anOk } from "../result";
import { countDecimalPlaces } from "../components/TopUpVaultModal/utils";
import { addCommas } from "../utils/display/addCommas";
import { stripRightwardZeros } from "../utils/display/toNonCanonicalDisplay";

/**
 * Removes excess decimals so 10 does not show as 10.00 but 10 and 10.1111 or 10.11 show as 10.11
 *
 * @param s
 */
export const truncateToCorrectNumberOfDecimals = (s: string): string => {
  s = stripRightwardZeros(s);
  const decimals = countDecimalPlaces(s);

  let correctNumberOfDecimals;

  if (decimals > 0) {
    correctNumberOfDecimals = parseFloat(s).toFixed(2);
  } else {
    correctNumberOfDecimals = parseFloat(s).toFixed(0);
  }
  return correctNumberOfDecimals;
};

export const toDisplayString = (s: string): string => {
  return `${addCommas(s)} USD`;
};

export default class Binance {
  static async FetchTokenDollarPrice(symbol: string) {
    let error = false;
    const response = await fetch(
      `${BINANCE_API}ticker/24hr?symbol=${symbol.toUpperCase()}USDT`
    ).catch(() => (error = true));
    if (error || response === true || response === false)
      return anErr("Error fetching Fet Price");
    if (response.status === 429)
      return anErr("Rate limit error fetching Fet Price");
    if (
      typeof response === "undefined" ||
      response.status > 200 ||
      response.status > 300
    )
      return anErr("Error fetching Fet Price");
    const json = await response.json().catch(() => (error = true));
    if (error || typeof json.symbol === "undefined")
      return anErr("Error fetching Fet Price");
    return anOk({
      lastPrice: json.lastPrice,
      lowPrice: json.lowPrice,
      highPrice: json.highPrice,
    });
  }
}
