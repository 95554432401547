import { stripRightwardZeros } from "../display/toNonCanonicalDisplay";

export const StripRightwardZerosFromDecimalComponent = (
  str: string
): string => {
  if (!str.includes(".") || str.split(".").length > 2) {
    return str;
  }
  const [whole, decimal] = str.split(".");
  if (stripRightwardZeros("." + decimal).length < 2) {
    return whole;
  }
  return whole + stripRightwardZeros("." + decimal);
};
